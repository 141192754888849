/**
 * reset-password functionality
 * Created By : #1223
 * Date : 25 November, 2020
 */
/***************************** Angular component *****************************/
import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, Renderer2, NgModule } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService, CommonService } from 'src/providers/providers';
import { Router } from '@angular/router';
import { ResetPasswordDTO } from 'src/models/reset-password-dto';
import { CommonModule } from '@angular/common';
// import { Toaster } from 'ngx-toast-notifications';


@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['../auth.scss'],
    encapsulation: ViewEncapsulation.None
})


export class ResetPasswordComponent implements OnInit, OnDestroy {
  
  formSubmitted: boolean;
  public passwordData: ResetPasswordDTO = {};
  inputType: string = 'password';
  confirmInputType: string = 'password';
  
  newPasswordCheck: boolean;
  confirmPasswordCheck: boolean;

  setPassword = {
    email: "",
    password: "",
    transactionId: ""
  }

    constructor(private commonService: CommonService, private authService: AuthService, private router: Router) {
      var extras = this.router.getCurrentNavigation().extras;
      this.setPassword.transactionId = extras && extras.state && extras.state.transactionId ? extras.state.transactionId : "";
      this.setPassword.email = extras && extras.state && extras.state.email ? extras.state.email : "";
    }

  /**
   * Function on page load
   * 
  */
    ngOnInit() {
    }

  /**
   * Function on reset password
   * 
  */
    resetPasswordClick = (form: NgForm) =>{    
        this.formSubmitted = true;
        if (form.valid) {
        let newPassword = this.passwordData.newPassword;
        let confirmPassword = this.passwordData.confirmPassword;
        this.formSubmitted = false;
        if (newPassword == confirmPassword) {
          this.commonService.showLoading();
            this.setPassword.password = this.passwordData.newPassword;
            this.setPassword.password.replace(/^\s+|\s+$/g, '');      
            this.authService.resetPassword(this.setPassword).subscribe( 
            res => {
                if ( res ) {
                  this.commonService.hideLoading();
                    console.log( "ResetPasswordComponent :: resetPasswordClick :: res :: ", res );
                    this.commonService.clearToast();
                this.commonService.showSuccess("Password reset successfully"); 
                    // this.toaster.open({ text: "Password reset successfully", type: 'success', position: 'top-center' })
                    this.router.navigate(['']);
                }
            },
            error => {
              this.commonService.hideLoading();
                console.log( "ResetPasswordComponent :: resetPasswordClick :: error :: ", error );
                if ( error.message ) {
                  this.commonService.clearToast();
                  this.commonService.showError(error.message);
              } else {
                  this.commonService.clearToast();
                  this.commonService.showError(error.error_description);
              }
            }); 
        }
      }      
    }

    /**
    * Function for hide/show new password
    * */
   hideShowPassword = (type) => {
    if (type == 'newPassword') {
      this.commonService.hideShowPassword(this.inputType, this.newPasswordCheck, (showPassword, inputType) => {
          this.inputType = inputType;
          this.newPasswordCheck = showPassword;
      });
  } else {
      this.commonService.hideShowPassword(this.confirmInputType, this.confirmPasswordCheck, (showPassword, inputType) => {
          this.confirmInputType = inputType;
          this.confirmPasswordCheck = showPassword;
      });
  }
  }

    /**
     * Remove conditional class only for login page
     * */
    ngOnDestroy() {
    }

    

}
