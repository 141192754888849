/**
 * Metadata service
 * Service to fetch PM status, action items, renewal status like data
 * Created By : #1116
 * Date : 08 Jan, 2021
 */

import { Injectable, Injector } from '@angular/core';
import { TitleCasePipe } from "@angular/common";

import { PropertyManagerService, TrendsService } from 'src/providers/providers';
import { ReplacePipe } from "src/providers/common-service/replace-pipe";

@Injectable()
export class MetaDataService {

    public pmStatusList: any = []; //PM Status List
    
    public ownersNameList: any = []; // owner's name list

    public actionItemList: any = []; //Last Action Items List

    public unitList: any = []; //Last unit Items List

    public customRenewalStatusList: any = []; //Custom Renewal Status List

    public evictionStatusList: any = []; //Eviction Status List

    public pmEaNameList: any = [];

    public acronymList: any = [];
    
    public propertyGroupList: any = [];

    constructor( private injector: Injector, private toTitleCase: TitleCasePipe,private trendsService: TrendsService ) {
    }



    /**
     * Function to get PM status
     */
    public getPMStatusList = ( onSuccess, onFailure ) => {
        const propertyManagerService = this.injector.get( PropertyManagerService );
        const replacePipe = this.injector.get( ReplacePipe );

        if ( this.pmStatusList.length == 0 ) {

            propertyManagerService.getPMStatusList().subscribe(
                ( res ) => {
                    if ( res.code == 200 || res.status == 200 ) {
                        res.data.map( status =>
                            this.pmStatusList.push( { label: this.toTitleCase.transform( replacePipe.transform( status, '_', ' ' ) ), value: status } )
                        );
                        for(var i in this.pmStatusList){
                            if(this.pmStatusList){                              
                            console.log("this.customRenewalStatusList[i].label >>>", this.pmStatusList[i].label);
                            if( this.pmStatusList[i].label == "Paid Copp/rpp"){
                                this.pmStatusList[i].label =  "Paid COPP/RPP";
                            } 
                            if(this.pmStatusList[i].label == "Refused All: Rpp/cfk/move-out"){
                                this.pmStatusList[i].label = "Refused All: RPP/CFK/Move-out";
                            } 
                        }
                        }
                        onSuccess( this.pmStatusList );
                    } else {
                        this.pmStatusList = [];
                        onSuccess( this.pmStatusList );
                    }
                },
                ( error ) => {
                    this.pmStatusList = [];
                    onFailure( this.pmStatusList );
                }
            );
        } else {
            onSuccess( this.pmStatusList );
        }

    }

    /**
     * Function to get PM status
     */
     public getPropertyGroupList = ( onSuccess, onFailure ) => {
        const replacePipe = this.injector.get( ReplacePipe );

        if ( this.propertyGroupList.length == 0 ) {
            var trendsPayload = {       
                pageNumber: 0,
                pageSize: 50
            };
        this.trendsService.getPropertyGroupList(trendsPayload).subscribe(
                ( res ) => {
                    if ( res.code == 200 || res.status == 200 ) {
                        res.data.list.map( item =>
                            this.propertyGroupList.push( { label: item.propertyGroupName, value: item.propertyGroupId, isHide: item.isHide, id: item.id } )
                           
                        );
                        
                        onSuccess( this.propertyGroupList );
                    } else {
                        this.propertyGroupList = [];
                        onSuccess( this.propertyGroupList );
                    }
                },
                ( error ) => {
                    this.propertyGroupList = [];
                    onFailure( this.propertyGroupList );
                }
            );
        } else {
            onSuccess( this.propertyGroupList );
        }

    }

    /**
     * Function to get Owner's name list
     */
    public getOwnersNameList = ( onSuccess, onFailure ) => {
        const propertyManagerService = this.injector.get( PropertyManagerService );
        const replacePipe = this.injector.get( ReplacePipe );

        if ( this.ownersNameList.length == 0 ) {

            propertyManagerService.getOwnersNameList().subscribe(
                ( res ) => {
                    if ( res.code == 200 || res.status == 200 ) {
                        res.data.map( status =>
                            this.ownersNameList.push( { label: this.toTitleCase.transform( replacePipe.transform( status, '_', ' ' ) ), value: status } )
                        );
                        onSuccess( this.ownersNameList );
                    } else {
                        this.ownersNameList = [];
                        onSuccess( this.ownersNameList );
                    }
                },
                ( error ) => {
                    this.ownersNameList = [];
                    onFailure( this.ownersNameList );
                }
            );
        } else {
            onSuccess( this.ownersNameList );
        }
    }

    /**
     * Function to get action item
     */
    public getActionItemList = ( onSuccess, onFailure ) => {
        const propertyManagerService = this.injector.get( PropertyManagerService );
        const replacePipe = this.injector.get( ReplacePipe );

        if ( this.actionItemList.length == 0 ) {

            propertyManagerService.getActionItemList().subscribe(
                ( res ) => {
                    if ( res.code == 200 || res.status == 200 ) {
                        res.data.map( status =>
                            this.actionItemList.push( { label: this.toTitleCase.transform( replacePipe.transform( status, '_', ' ' ) ), value: status } )
                        );
                        onSuccess( this.actionItemList );
                    } else {
                        this.actionItemList = [];
                        onSuccess( this.actionItemList );
                    }
                },
                ( error ) => {
                    this.pmStatusList = [];
                    onFailure( this.actionItemList );
                }
            );
        } else {
            onSuccess( this.actionItemList );
        }
    }


    /**
     * Function to get unit item
     */
    public getUnitList = ( onSuccess, onFailure ) => {
        const propertyManagerService = this.injector.get( PropertyManagerService );
        const replacePipe = this.injector.get( ReplacePipe );

        if ( this.unitList.length == 0 ) {

            propertyManagerService.getUnitList().subscribe(
                ( res ) => {
                    if ( res.code == 200 || res.status == 200 ) {
                        res.data.map( item =>
                            this.unitList.push( { label: item.unitName, value: item.unitId } )
                        );
                        onSuccess( this.unitList );
                    } else {
                        this.unitList = [];
                        onSuccess( this.unitList );
                    }
                },
                ( error ) => {
                    this.unitList = [];
                    onFailure( this.unitList );
                }
            );
        } else {
            onSuccess( this.unitList );
        }
    }

    /**
     * Function to get PM item
     */
    public getPMList = ( isReport, onSuccess, onFailure ) => {
        if(!isReport){
            isReport = false;
        }
        const propertyManagerService = this.injector.get( PropertyManagerService );
        const replacePipe = this.injector.get( ReplacePipe );

        if ( this.pmEaNameList.length == 0 ) {

            propertyManagerService.getPMEANameList(isReport).subscribe(
                ( res ) => {
                    if ( res.code == 200 || res.status == 200 ) {
                        res.data.map( item =>
                            this.pmEaNameList.push( { label: this.toTitleCase.transform( replacePipe.transform( item, '_', ' ' ) ), value: item } )
                        );
                        console.log(" this.pmEaNameList:::::",  res.data);
                        
                        onSuccess( this.pmEaNameList );
                    } else {
                        this.pmEaNameList = [];
                        onSuccess( this.pmEaNameList );
                    }
                },
                ( error ) => {
                    this.pmEaNameList = [];
                    onFailure( this.pmEaNameList );
                }
            );
        } else {
            onSuccess( this.pmEaNameList );
        }
    }

    /**
     * Function to get custom renewal status (Renewed and Not renewed)
     */
    public getCustomRenewalStatusList = ( onSuccess, onFailure ) => {
        const propertyManagerService = this.injector.get( PropertyManagerService );
        const replacePipe = this.injector.get( ReplacePipe );

        if ( this.customRenewalStatusList.length == 0 ) {

            propertyManagerService.getCustomRenewalStatusList().subscribe(
                ( res ) => {
                    if ( res.code == 200 || res.status == 200 ) {
                        res.data.map( status =>
                            this.customRenewalStatusList.push( { label: this.toTitleCase.transform( replacePipe.transform( status, '_', ' ' ) ), value: status } )
                        );
                        for(var i in this.customRenewalStatusList){
                            if(this.customRenewalStatusList){                              
                            console.log("this.customRenewalStatusList[i].label >>>", this.customRenewalStatusList[i].label);
                            if( this.customRenewalStatusList[i].label == "Wpd Should Not Renew Due To Rent Balance"){
                                this.customRenewalStatusList[i].label =  "WPD Should Not Renew Due To Rent Balance";
                            } 
                            if(this.customRenewalStatusList[i].label == "Wpd Should Not Renew Due To Behavior"){
                                this.customRenewalStatusList[i].label = "WPD Should Not Renew Due To Behavior";
                            } 
                        }
                        }
                        console.log("this.customRenewalStatusList >>>", this.customRenewalStatusList);
                        onSuccess( this.customRenewalStatusList );
                    } else {
                        this.customRenewalStatusList = [];
                        onSuccess( this.customRenewalStatusList );
                    }
                },
                ( error ) => {
                    this.pmStatusList = [];
                    onFailure( this.customRenewalStatusList );
                }
            );
        } else {
            onSuccess( this.customRenewalStatusList );
        }
    }


    /**
     * Function to get Eviction status
     */
    public getEvictionStatusList = ( onSuccess, onFailure ) => {
        const propertyManagerService = this.injector.get( PropertyManagerService );
        const replacePipe = this.injector.get( ReplacePipe );

        if ( this.evictionStatusList.length == 0 ) {

            propertyManagerService.getEvictionStatusList().subscribe(
                ( res ) => {
                    if ( res.code == 200 || res.status == 200 ) {
                        res.data.map( status =>
                            this.evictionStatusList.push( { label: this.toTitleCase.transform( replacePipe.transform( status, '_', ' ' ) ), value: status } )
                        );
                        onSuccess( this.evictionStatusList );
                    } else {
                        this.evictionStatusList = [];
                        onSuccess( this.evictionStatusList );
                    }
                },
                ( error ) => {
                    this.evictionStatusList = [];
                    onFailure( this.evictionStatusList );
                }
            );
        } else {
            onSuccess( this.evictionStatusList );
        }

    }

    /**
     * Function to get Resident Acronym List
     */
    public getAcronymList = ( onSuccess, onFailure ) => {
        const propertyManagerService = this.injector.get( PropertyManagerService );
        const replacePipe = this.injector.get( ReplacePipe );

        if ( this.acronymList.length == 0 ) {

            propertyManagerService.getAcronymList().subscribe(
                ( res ) => {
                    if ( res.code == 200 || res.status == 200 ) {
                        res.data.map( status =>
                            this.acronymList.push( { label: status, value: status } )
                        );
                        this.acronymList.splice(0, 1);
                        onSuccess( this.acronymList );
                    } else {
                        this.acronymList = [];
                        onSuccess( this.acronymList );
                    }
                },
                ( error ) => {
                    this.acronymList = [];
                    onFailure( this.acronymList );
                }
            );
        } else {
            onSuccess( this.acronymList );
        }

    }
}