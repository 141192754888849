// Angular dependencies
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, TitleCasePipe, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { from } from 'rxjs';

//Prime NG dependencies
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SliderModule } from 'primeng/slider';
//Providers dependencies
import {
    LocalStorageService, RestService, AppSettings, AuthService, ManageUserService, Constants, CommonService,
    Broadcaster, HeaderService, HighchartServiceService,
    SidebarService, HttpNetworkInterceptor, PropertyManagerService, MessageService,
    TrendsService, MetaDataService, AdminService, RentRollService, StatsService,
    EvictionAdminService,ReportsService
} from 'src/providers/providers';


//Components dependencies
import { FooterComponent } from '../pages/footer/footer.component';
import { LoginComponent } from 'src/pages/auth-module/login/login.component';
import { ChangePasswordComponent } from 'src/pages/change-password/change-password.component';
import { ForgotPasswordComponent } from 'src/pages/auth-module/forgot-password/forgot-password.component';
import { EmailVerificationComponent } from 'src/pages/auth-module/email-verification/email-verification.component';
import { ResetPasswordComponent } from 'src/pages/auth-module/reset-password/reset-password.component';
import { HeaderComponent } from '../pages/header/header.component';
import { SidebarComponent } from '../pages/sidebar/sidebar.component';
import { UserListComponent } from 'src/pages/admin-module/user-List/user-list.component';
import { CreateUserComponent } from 'src/pages/admin-module/create-user/create-user.component';
import { DashboardComponent } from 'src/pages/property-manager-module/dashboard/dashboard.component';
import { DelinquencyComponent } from 'src/pages/property-manager-module/delinquencies/delinquencies.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminDashboardComponent } from 'src/pages/admin-module/dashboard/dashboard.component';
import { SettingComponent } from 'src/pages/settings/setting.component';
import { CommonModalComponent } from 'src/pages/common-modal/common-modal.component';
import { DashboardFilterComponent } from 'src/pages/property-manager-module/dashboard-filter/dashboard-filter.component';
import { ClickOutsideDirective } from "src/providers/common-service/click-outside-directive"
import { GoalComponent } from 'src/pages/admin-module/goal/goal.component';
import { CanDeactivateGuard } from '../pages/admin-module/can-deactivate/can-deactivate.guard';
import { TrendsComponent } from 'src/pages/property-manager-module/trends/trends.component';
import { ExportPopupComponent } from 'src/pages/export-popup/export-popup.component';
import { ViewDelinquencyComponent } from 'src/pages/property-manager-module/view-delinquency/view-delinquency.component';
import { ViewRenewalComponent } from 'src/pages/property-manager-module/view-renewal/view-renewal.component';
import { RentRollComponent } from 'src/pages/property-manager-module/rent-roll/rent-roll.component'
import { RenewalsComponent } from 'src/pages/property-manager-module/renewals/renewals.component';

//Admin Role Components
import { ActivityComponent } from 'src/pages/admin-module/activity/activity.component';

//Eviction Role Components
import { EvictionPipelineComponent } from 'src/pages/eviction-admin-module/eviction-pipeline/eviction-pipeline.component';
import { PipelineFilterComponent } from 'src/pages/eviction-admin-module/pipeline-filter/pipeline-filter.component';
import { EvictionPipelineDetailsComponent } from 'src/pages/eviction-admin-module/eviction-pipeline-details/eviction-pipeline-details.component';

import { AppService } from './app.service';
// import { TrendFilterComponent } from 'src/pages/property-manager-module/trends/trend-filter/trend-filter.component';
import { ReplacePipe } from 'src/providers/common-service/replace-pipe';

//Directives dependencies
import { TwoDigitDecimaNumberDirective } from './../directives/two-digit-decima-number.directive';
import { RentRollFilterComponent } from 'src/pages/property-manager-module/rent-roll/rent-roll-filter/rent-roll-filter.component';
import { TenantProfileComponent } from 'src/pages/tenant-profile/tenant-profile.component';
import { ActivityLogComponent } from 'src/pages/tenant-activity-log/tenant-activity-log.component';
import { ActivityFilterComponent } from 'src/pages/admin-module/activity-filter/activity-filter.component';
import { MyProfileComponent } from 'src/pages/my-profile/my-profile.component';
import { EditProfileComponent } from 'src/pages/edit-profile/edit-profile.component';
import { NotificationsComponent } from 'src/pages/notifications/notifications.component';
import { StatsComponent } from 'src/pages/stats/stats.component';
import { ReportsComponent } from 'src/pages/admin-module/reports/reports.component';
import { PMDelinquencyFilterComponent } from 'src/pages/admin-module/reports/pm-delinquency-filter/pm-delinquency-filter.component';
import { PMStatsFilterComponent } from 'src/pages/admin-module/reports/pm-stats-filter/pm-stats-filter.component';
import { ClientDelinquencyFilterComponent } from 'src/pages/admin-module/reports/client-delinquency-filter/client-delinquency-filter.component';

//Mobile View Components
import { TurnListComponent } from 'src/pages/property-manager-module/turn-list/turn-list.component';
import { propertyGroupFilterComponent } from 'src/pages/property-manager-module/trends/property-group-filter/property-group-filter.component';

export function createTranslateLoader( http: HttpClient ) {
    return new TranslateHttpLoader( http, './assets/i18n/', '.json' );
}

@NgModule( {
    declarations: [
        AppComponent,
        FooterComponent,
        ChangePasswordComponent,
        ForgotPasswordComponent,
        EmailVerificationComponent,
        ResetPasswordComponent,
        HeaderComponent,
        SidebarComponent,
        UserListComponent,
        CreateUserComponent,
        DashboardComponent,
        AdminDashboardComponent,
        DelinquencyComponent,
        LoginComponent,
        SettingComponent,
        CommonModalComponent,
        DashboardFilterComponent,
        propertyGroupFilterComponent,
        ClickOutsideDirective,
        GoalComponent,
        TrendsComponent,
        ExportPopupComponent,
        ViewDelinquencyComponent,
        ViewRenewalComponent,
        ReplacePipe,
        TwoDigitDecimaNumberDirective,
        RentRollComponent,
        RentRollFilterComponent,
        TenantProfileComponent,
        RenewalsComponent,
        ActivityComponent,
        ActivityLogComponent,
        ActivityFilterComponent,
        MyProfileComponent,
        EditProfileComponent,
        NotificationsComponent,
        StatsComponent,
        EvictionPipelineComponent,
        PipelineFilterComponent,
        EvictionPipelineDetailsComponent,
        ReportsComponent,
        TurnListComponent,
        PMDelinquencyFilterComponent,
        PMStatsFilterComponent,
        ClientDelinquencyFilterComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgxSpinnerModule,
        InputTextModule,
        TableModule,
        CheckboxModule,
        MultiSelectModule,
        PanelModule,
        InputSwitchModule,
        DropdownModule,
        CalendarModule,
        PaginatorModule,
        HttpClientModule,
        ConfirmDialogModule,

        MessagesModule,
        ProgressSpinnerModule,
        SliderModule,
        TranslateModule.forChild(),
        TranslateModule.forRoot( {
            loader: {
                provide: TranslateLoader,
                useFactory: ( createTranslateLoader ),
                deps: [HttpClient]
            }
        } ),
        FormsModule,
        MessageModule,
        ToastModule
    ],
    entryComponents: [CommonModalComponent],
    providers: [
        ReplacePipe,
        TitleCasePipe,
        CanDeactivateGuard,
        LocalStorageService,
        CommonService,
        RestService,
        AuthService,
        ManageUserService,
        PropertyManagerService,
        Constants,
        Broadcaster,
        HeaderService,
        SidebarService,
        MessageService,
        HighchartServiceService,
        AppService,
        TrendsService,
        CurrencyPipe,
        MetaDataService,
        AdminService,
        RentRollService,
        StatsService,
        EvictionAdminService,
        ReportsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpNetworkInterceptor,
            multi: true
        }
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent],
    exports: [ReplacePipe]
} )
export class AppModule { }
