import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from './../../app/app.service';

import {
    CommonService,
    HeaderService,
    PropertyManagerService,
    SidebarService,
    HighchartServiceService,
    LocalStorageService
} from "src/providers/providers";
import * as _ from "lodash";

@Component( {
    selector: "app-my-profile",
    templateUrl: "./my-profile.component.html",
    styleUrls: ["./my-profile.component.scss"],
} )
export class MyProfileComponent implements OnInit {

    public userData: any;
    public accessList: any;
    public access :any=[]

    constructor(
        public header: HeaderService,
        public sidebar: SidebarService,
        protected commonService: CommonService,
        private appService: AppService,
        public localStr: LocalStorageService,
        public router: Router
    ) { }

    ngOnInit(): void {

        this.localStr.setObj("breadcrum", "");
        this.sidebar.show();
        this.header.show();
        this.appService.setPreTitle( "" );
        this.appService.setTitle( 'My Profile' );
        this.userData = this.localStr.getObj( 'userData' );
        console.log("this.userData.userAccessList >>>",this.userData.userAccessList);
        

        if ( this.userData.userAccessList ) {
            this.access = []
            for ( let i = 0; i < this.userData.userAccessList.length; i++ ) {
                if ( this.userData.userAccessList[i] == 'USER_MANAGEMENT' ) {
                    this.access.push( 'User Management')
                }
                if ( this.userData.userAccessList[i] == 'ACTIVITY' ) {
                    this.access.push( 'Activity')
                }
                if ( this.userData.userAccessList[i] == 'REPORTS' ) {
                    this.access.push( 'Reports')
                }
                if ( this.userData.userAccessList[i] == 'GOALS' ) {
                    this.access.push( 'Goals')
                }
                if ( this.userData.userAccessList[i] == 'STATS' ) {
                    this.access.push( 'Stats')
                }
            }
            this.access= _.cloneDeep( this.access );
            console.log("this.access >>>>>>>", this.access);
            

        }
    }


    /**
     * Function coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo( "Coming soon" );
    }

    /**
     * Function to Edit Profile
    */
    public goToEditProfile = () => {
        this.localStr.setObj("breadcrum", 'My Profile');
        this.router.navigate(['/edit-profile']);
    }

}
