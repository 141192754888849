
<div class="row page-wrap">
    <div class="container-fluid">
        
        <div class="row m-0">
            <div class="dashboard-tab-wrap container-fluid p-0">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs full-width">
                  
                    <li class="search-wrap float-right">
                        <div class="form-group has-search common-search-box">
                            <span class="form-control-feedback"><i class="search-icon search-icon-cls"></i></span>
                            <input type="text" id="search-bar" class="form-control" spellcheck="false" autocomplete="false" name="searchUser" placeholder="Search by Resident Name/Address/Notes" pInputText 
                            [(ngModel)]="activityFilterPayload.searchText" (input)="isSearchCall=true;getActivitiesList($event);" />
                        </div>
                        <button class="btn btn-icon-bg" (click)="openExportPopup()"><i class="forward-icon filter-icon-cls" title="Export"></i></button>
                        <button class="btn btn-icon-bg" (click)="clickOnFilter($event)"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>
                    </li>

                </ul>

                <!-- Tab panes -->
                <div class="tab-content full-width">
                    <div id="tab-1" class="container-fluid tab-pane active">
                        <div class="card clearfix">

                            <p-table class="custom-table-cls" #dt [value]="activitiesList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 295px)'" [responsive]="true" (onLazyLoad)="customSort($event,'DELINQUENCY')" [lazy]="true">
                                <!-- [loading]="loading"  Add this for Inplace loader-->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="pmName"> {{ "ACTIVITY.PM_EA_NAME" | translate }}
                                            <p-sortIcon field="pmName"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="residentName"> {{ "ACTIVITY.TENANT_NAME" | translate }}
                                            <p-sortIcon field="residentName"></p-sortIcon>
                                        </th> 
                                        <th pSortableColumn="address"> {{ "ACTIVITY.ADDRESS" | translate }}
                                            <p-sortIcon field="address"></p-sortIcon>
                                        </th> 
                                        <th pSortableColumn="ownersName"> {{ "ACTIVITY.OWNERS_NAME" | translate }}
                                            <p-sortIcon field="ownersName"></p-sortIcon>
                                        </th> 
                                        <th pSortableColumn="actionItem"> {{ "ACTIVITY.ACTION_TAKEN" | translate }}
                                            <p-sortIcon field="actionItem"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="followUpDate"> {{ "ACTIVITY.FOLLOW_UP_DATE" | translate }}
                                            <p-sortIcon field="followUpDate"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="promiseToPay"> {{ "ACTIVITY.PROMISE_TO_PAY" | translate }}
                                            <p-sortIcon field="promiseToPay"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="updatedAt"> {{ "ACTIVITY.UPDATE_AT" | translate }}
                                            <p-sortIcon field="updatedAt"></p-sortIcon>
                                        </th>                                                                              
                                        <th>{{ "ACTIVITY.NOTES" | translate }}</th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-activity>
                                    <tr>
                                        <td title="{{activity.pmName}}">{{activity.pmName? activity.pmName: '-'}}</td>
                                        <td title="{{activity.tenantName}}" (click)="openTenantDetails(activity.unitId)" [ngClass]="!activity.tenantName? 'rmLine': ''" class="hyperlink-cls pointer">{{activity.tenantName? activity.tenantName: '-'}}</td>
                                        <td title="{{activity.address }}">{{activity.address? activity.address: '-'}}</td>
                                        <td title="{{activity.buildingOwner }}">{{activity.buildingOwner? activity.buildingOwner: '-'}}</td> 
                                        <td title="{{activity.actionTaken | replace : '_' : ' ' | titlecase}}">{{ activity.actionTaken ? (activity.actionTaken | replace : '_' : ' ' | titlecase) : '-' }}</td>                                                                          
                                        <td title="{{activity.followUpDate | date: 'MMM dd, yyyy' : ' - '}}">{{ activity.followUpDate ? (activity.followUpDate | date: 'MMM dd, yyyy') : '-' }}</td>
                                        <td title="{{activity.promiseToPay | date: 'MMM dd, yyyy' : ' - '}}">{{ activity.promiseToPay ? (activity.promiseToPay | date: 'MMM dd, yyyy') : '-' }}</td>
                                        <td title="{{activity.updatedAt | date: 'MMM dd, yyyy hh:mm a' : ' - '}}">{{ activity.updatedAt ? (activity.updatedAt | date: 'MMM dd, yyyy hh:mm a') : '-' }}</td>
                                        <td title="{{activity.delinquencyNotes}}" class="txt-initial txt-trans">{{activity.delinquencyNotes? activity.delinquencyNotes: '-'}}</td>

                                    </tr>                 
                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="activitiesList.length == 0">
                                <div class="no-data-found" *ngIf="!loading" text-center>
                                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                          </div>
                        </div>
                        <p-paginator styleClass="ui-paginator-bottom" [rows]="25" #p  (onLazyLoad)="activitiespaginate($event)" (onPageChange)="activitiespaginate($event)" [totalRecords]="activityFilterPayload.totalRecords" class="list-paginator table-paginator"></p-paginator>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>