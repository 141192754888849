/**
 * Trends Functionality
 * Created By : #1116
 * Date : 27 December, 2020
 */


/*--------------------Angular related components---------------*/
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { Observable, timer, throwError } from "rxjs";
import { Router } from "@angular/router";
import { map, catchError, mergeMap } from "rxjs/operators";

/*-----------------App Providers---------------------*/
import { AppSettings } from "../app-settings-service/app-settings.service";
import { Constants } from "../app-settings-service/app-constant.service";
import { LocalStorageService } from "../local-storage-service/local-storage.service";
import { RestService } from "../rest-service/rest.service";
import { CommonService } from "../common-service/common-service";
import { HttpRequest, HttpHandler } from "@angular/common/http";

@Injectable()
export class TrendsService {
    errorMsg: string;
    successMsg: string;

    constructor(
        private commonService: CommonService,
        private restService: RestService
    ) { }

    /**
     * Function to get PM LeaderBoard data list
     */
    public getLeaderBoardList( param ): Observable<any> {
        const path = AppSettings.LEADERBOARD_URL + "?size=" +
            param.pageSize +
            "&page=" +
            param.pageNumber + "&month=" +
            param.month;
        return this.restService.getCall( path, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /**
     * Function to get PM LeaderBoard data list
     */
    public getOwnerLeaderBoardList( param ): Observable<any> {
        const path = AppSettings.OWNERS_LEADERBOARD_URL + "?size=" +
            param.pageSize +
            "&page=" +
            param.pageNumber + "&month=" +
            param.month;
        return this.restService.getCall( path, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /**
     * Function to get Property Group List
     */
        public getPropertyGroupList(param): Observable<any> {
            const path = AppSettings.MANAGE_PROPERTY_GROUP_URL + "?size=" +
                param.pageSize +
                "&page=" +
                param.pageNumber;
            return this.restService.getCall( path, false, 60000 ).pipe(
                map(( res ) => {
                    return res;
                } ),
                catchError(( e ) =>
                    timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
                )
            );
        }

        /**
         * Function to update Property Group List
         */
         public updatePropertyGroupList = (data) => {
            console.log("updatePropertyGroupList :: Data :: ", data);
            const path = AppSettings.UPDATE_PROPERTY_GROUP_URL;
            return this.restService.putCall(path, data, false, 60000)
               .map(function (res) {
                  if (typeof res == 'string') {
                     return JSON.parse(res);
                  } else {
                     return res;
                  }
               })
               .catch(this.restService.handleError);
         }

    /**
     * Function to get PM Delinquency Tracker Data
     */
    public getDelinquencyTrackerData( param ): Observable<any> {
        const path = AppSettings.DELINQUENCIES_TRACKER_URL + "?size=" +
            param.pageSize +
            "&page=" +
            param.pageNumber + "&month=" +
            param.month +
            "&filter=" +
            param.filter;
        return this.restService.getCall( path, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /** 
     * Function to get previous months for delinquency tracker data
     * **/
    public getLastMonthsName = (countOFMonths, isCurrentMonth) => {
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let today = new Date();
        let month = today.getMonth();
        var year = today.getFullYear();
        let i = 0;
        let previousMonthsNames = [];

        do {
            if ( month < 0 ) {
                month = 12;
                year--;
            }

            if(!isCurrentMonth) {
                i++;
                month--;

                if ( monthNames[month] ) {
                    previousMonthsNames.push( { label: monthNames[month], value: month + 1 } );
                }
            } else {
                if(i != 0) {
                    month--;  
                }

                i++;

                if ( monthNames[month] ) {
                    previousMonthsNames.push( { label: monthNames[month], value: month +1 } );
                } 
            }
           

        } while ( i < countOFMonths );

        return previousMonthsNames;
    }

    /** 
     * Function to get all month list
     * **/
    public getAllMonthsName = (countOFMonths, isCurrentMonth) => {
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let today = new Date();
        let month = today.getMonth();
        var year = today.getFullYear();
        let i = 0;
        let previousMonthsNames = [];
        do {
            if ( month < 0 ) {
                month = 12;
                year--;
            }
            if(!isCurrentMonth) {
                i++;
                month--;
                if ( monthNames[month] ) {
                    previousMonthsNames.push( { label: monthNames[month], value: month + 1 } );
                }
            } else {
                if(i != 0) {
                    month--;  
                }
                i++;
                if ( monthNames[month] ) {
                    previousMonthsNames.push( { label: monthNames[month], value: month +1 } );
                } 
            }
        } while ( i <= countOFMonths );

        return previousMonthsNames;
    }

    /** 
     * Function to get previous months for delinquency tracker data
     * **/
    public getMonthsName = (countOFMonths, isCurrentMonth) => {
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let today = new Date();
        let month = today.getMonth();
        var year = today.getFullYear();
        let i = 0;
        let previousMonthsNames = [];

        do {
            if ( month < 0 ) {
                month = 12;
                year--;
            }

            if(!isCurrentMonth) {
                i++;
                month--;

                if ( monthNames[month] ) {
                    previousMonthsNames.push( { label: monthNames[month], value: month + 1 } );
                }
            } else {
                if(i != 0) {
                    month--;  
                }

                i++;

                if ( monthNames[month] ) {
                    previousMonthsNames.push( { label: monthNames[month], value: month +1 } );
                } 
            }
           

        } while ( i < countOFMonths );

        return previousMonthsNames;
    }

    /** 
     * Function to get month name
     * **/
    public getMonthName = ( monthNumber?: number ) => {
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let today;
        let month;
        if ( !monthNumber ) {
            today = new Date();
            monthNumber = today.getMonth();
            month = monthNames[monthNumber];
        } else {
            monthNumber = monthNumber - 1;
            month = monthNames[monthNumber];
        }

        return month;
    }


    /**
     * Function to get PM Renewal Tracker Data
     */
    public getRenewalTrackerData( param ): Observable<any> {
        const path = AppSettings.RENEWAL_TRACKER_URL + "?size=" +
            param.pageSize +
            "&page=" +
            param.pageNumber + "&month=" +
            param.month +
            "&filter=" +
            param.filter;
        return this.restService.getCall( path, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

}
