/**
 * Report Functionality
 * Created By : #1223
 * Date : 15 Feb, 2021
 */

import { Component, OnInit, ViewEncapsulation } from "@angular/core";


import {
    CommonService,
    HeaderService,
    SidebarService,
    MetaDataService,
    Constants,
    LocalStorageService,
    AdminService
} from "src/providers/providers";

import { AppService } from './../../../app/app.service';
import { ExportPopupComponent } from 'src/pages/export-popup/export-popup.component';
import { ReplacePipe } from "src/providers/common-service/replace-pipe";
import { TitleCasePipe } from "@angular/common";
import { ReportsService } from 'src/providers/reports-service/reports-service';
import { PMStatsFilterComponent } from './pm-stats-filter/pm-stats-filter.component';
import { ClientDelinquencyFilterComponent } from './client-delinquency-filter/client-delinquency-filter.component';
import { PMDelinquencyFilterComponent } from "./pm-delinquency-filter/pm-delinquency-filter.component";
import * as _ from "lodash";
@Component({
    selector: "app-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit {

    public reportsList: any = [];
    public pmStatsList: any = [];
    public clientList: any = [];
    public originalReportsList: any = [];
    public originalPMStatsList: any = [];
    public originalClientList: any = [];
    isPMDelinquencyListNoData: boolean = false;
    ispmStatsListNoData: boolean = false;
    isClientDelinquencyListNoData: boolean = false;
    isDelinquencyTableData: boolean = false;
    isStatsTableData: boolean = false;
    isClientTableData: boolean = false;

    public isYearlyReport: boolean = true;
    public pmDelinquencySelected: boolean = true;
    public pmStatsSelect: boolean = false;
    public clientDelinquencySelect: boolean = false;


    public isHistoricalStats: boolean = false;
    public isPMStatsHistoricalStats: boolean = false;
    public isClientHistoricalStats: boolean = false;

    public isSearchCall: boolean = false;    
    
    public selectMonthStatus = [
        { label: "Current Month", value: "CURRENT_MONTH" },
        { label: "Historical Stats", value: "HISTORICAL_STATS" }
    ];
    public isDataFilter: boolean = false;

    public pmDelinquencyFilterPayload: any = {
        pmName: "",
        totalUnitFrom: 0,
        totalUnitTo: 0,
        totalOccupiedUnitFrom: 0,
        totalOccupiedUnitTo: 0,
        totalRentRollTo: 0,
        totalResidentPaidRentFrom: 0,
        totalResidentPaidRentTo: 0,
        totalSubsidizedPaidRentFrom: 0,
        totalSubsidizedPaidRentTo: 0,
        totalCollectedFrom: 0,
        totalCollectedTo: 0,
        totalResidentCollectedFrom: 0,
        totalResidentCollectedTo: 0,
        pageNumber: 0,
        pageSize: 200,        
        month: '',
        selectedPMDelinquency: "",
        isCurrentMonth: true
    };

    public pmStatsFilterPayload: any = {
        pmName: "",
        totalNumberOfResidentsUpForRenewalFrom: 0,
        totalNumberOfResidentsUpForRenewalTo: 0,
        hashOfResidentsRenewedForMonthFrom: 0,
        hashOfResidentsRenewedForMonthTo: 0,
        repaymentPlansCraftedFrom: 0,
        hashOfTenantsWithBalanceOf15ResidentPaidFrom: 0,
        hashOfTenantsWithBalanceOf15ResidentPaidTo: 0,
        hashResidentInEvictionStatusFrom: 0,
        hashResidentInEvictionStatusTo: 0,
        hashOfResidentInEvictionOnPaymentPlansFrom: 0 ,
        hashOfResidentInEvictionOnPaymentPlansTo: 0,
        pageNumber: 0,
        pageSize: 200,
        month: '',
        selectedPMStats: "",
        isCurrentMonth: true        
    };

    
    public clientDelinquencyFilterPayload: any = {
        buildingOwner : "",
        totalUnitFrom : 0,
        totalUnitTo : 0,
        totalOccupiedUnitFrom : 0,
        totalOccupiedUnitTo : 0,
        totalRentRollTo : 0,
        totalResidentPaidRentFrom : 0,
        totalResidentPaidRentTo : 0,
        totalSubsidizedRentFrom : 0,
        totalSubsidizedRentTo : 0,
        totalCollectedFrom : 0,
        totalCollectedTo : 0,
        residentPaidRentCollectedFrom:0,
        residentPaidRentCollectedTo:0,
        subsidizedRentCollectedFrom:0,
        subsidizedRentCollectedTo:0,
        pageNumber: 0,
        pageSize: 200,
        //month: 'CURRENT_MONTH',
        month: '',
        selectedClientDelinquency: "",
        isCurrentMonth: true
    };


    filterData: any = {};
    public userData: any;
    public Check: any;


    constructor(
        private replaceChar: ReplacePipe, private toTitleCase: TitleCasePipe,
        public header: HeaderService,
        public sidebar: SidebarService,
        protected commonService: CommonService,
        private appService: AppService,
        private reportsService: ReportsService,
        public metaDataService: MetaDataService,
        public constants: Constants,
        public localStr: LocalStorageService,
        private adminService: AdminService,
    ) {

        this.pmDelinquencySelected = true;
        this.pmStatsSelect = false;
        this.clientDelinquencySelect = false;
    }

    ngOnInit(): void {
        this.userData = this.localStr.getObj( 'userData' );

        //Get current months for client Delinquency
        this.selectMonthStatus[0].label = this.reportsService.getMonthName();
        this.appService.setTitle('Reports');
        var isSliderShow = this.localStr.getObj("isSliderShow");
        if (isSliderShow) {
            this.sidebar.show();
            this.localStr.setObj("isSliderShow", true);
        } else {
            this.sidebar.hide();
            this.localStr.setObj("isSliderShow", false);
        }
        this.header.show();
        this.getPMDelinquencyList();
    }


    /**
      * Function to get Reports list
      */
    public getPMDelinquencyList() {
        this.reportsList = [];
        this.pmDelinquencySelected = true;
        this.pmStatsSelect = false
        this.clientDelinquencySelect = false;
        this.isDelinquencyTableData = false;
        this.commonService.showLoading();
        this.filterData = {};
        this.filterData.isHistoricalStats = this.isHistoricalStats;

        console.log("this.pmDelinquencyFilterPayload >>>>", this.pmDelinquencyFilterPayload);
        

        this.reportsService.getPMDelinquencyList(this.pmDelinquencyFilterPayload).subscribe(
            (res) => {
                if (res.code == 200 || res.status == 200) {
                    this.isDelinquencyTableData = true;
                    this.commonService.hideLoading();
                    console.log("res.data ....", res.data);
                    
                    this.reportsList = _.cloneDeep(res.data);
                    this.originalReportsList = _.cloneDeep(res.data);
                    console.log("this.reportsList[i].getPMDelinquencyList...>>>", this.reportsList);

                    if (res.data.list.length != 0) {
                        this.isPMDelinquencyListNoData = false;
                    } else {
                        // Added condition for page 1  - show no data found
                        if (this.reportsList.pageNumber == 0) {
                            this.isPMDelinquencyListNoData = true;
                        }
                    }
                } else {
                    this.commonService.hideLoading();
                    this.reportsList = [];
                }

            },
            (error) => {
                this.commonService.hideLoading();
                this.reportsList = [];
                this.isPMDelinquencyListNoData = true;
                if (error && error.message) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.message);
                } else if (error && error.error_description) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.error_description);
                }
            }
        );
    }

    /**
      * Function to get owner's LeaderBoard list
      */
    public getPMStatsList() {
        this.pmStatsList = [];
        this.pmDelinquencySelected = false;
        this.pmStatsSelect = true;
        this.clientDelinquencySelect = false;
        this.isStatsTableData = false;
        this.commonService.showLoading();

        this.filterData = {};
        this.filterData.isPMStatsHistoricalStats = this.isPMStatsHistoricalStats;

        this.reportsService.getPMStatsList(this.pmStatsFilterPayload).subscribe(
            (res) => {
                if (res.code == 200 || res.status == 200) {
                    this.isStatsTableData = true;
                    this.commonService.hideLoading();
                    this.pmStatsList = _.cloneDeep(res.data);
                    this.originalPMStatsList = _.cloneDeep(res.data);
                    console.log("this.pmStatsList[i].getPMStatsList...>>>", this.pmStatsList);

                    if (res.data.list.length != 0) {
                        this.ispmStatsListNoData = false;
                    } else {
                        // Added condition for page 1  - show no data found
                        if (this.pmStatsList.pageNumber == 0) {
                            this.ispmStatsListNoData = true;
                        }
                    }
                } else {
                    this.commonService.hideLoading();
                    this.pmStatsList = [];
                }

            },
            (error) => {
                this.commonService.hideLoading();
                this.pmStatsList = [];
                this.ispmStatsListNoData = true;
                if (error && error.message) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.message);
                } else if (error && error.error_description) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.error_description);
                }
            }
        );
    }

    /**
      * Function to get owner's LeaderBoard list
      */
    public getClientDelinquencyList() {
        // if(checkValue){
        //     this.isClientHistoricalStats = true;
        // } else {
        //     this.clientDelinquencyFilterPayload.selectedClientDelinquency = 'CURRENT_MONTH';
        //     this.clientDelinquencyFilterPayload.isCurrentMonth = true;
        //     this.isClientHistoricalStats = false;
        // }
        this.clientList = [];
        this.pmDelinquencySelected = false;
        this.pmStatsSelect = false;
        this.clientDelinquencySelect = true;
        this.isClientTableData = false;
        this.commonService.showLoading();
        // const date = new Date();
        // var currentMonth = (date.getMonth() + 1);
        // console.log("month:", currentMonth);
        
        this.filterData = {};
        this.filterData.isClientHistoricalStats = this.isClientHistoricalStats;

        this.reportsService.getClientDelinquencyList(this.clientDelinquencyFilterPayload).subscribe(
            (res) => {
                if (res.code == 200 || res.status == 200) {
                    this.isClientTableData = true;
                    this.commonService.hideLoading();
                    this.clientList = _.cloneDeep(res.data);
                    this.originalClientList =_.cloneDeep(res.data);
                    console.log("this.clientList[i].getClientDelinquencyList...>>>", this.clientList);

                    if (res.data.list.length != 0) {
                        this.isClientDelinquencyListNoData = false;
                    } else {
                        // Added condition for page 1  - show no data found
                        if (this.clientList.pageNumber == 0) {
                            this.isClientDelinquencyListNoData = true;
                        }
                    }
                } else {
                    this.commonService.hideLoading();
                    this.clientList = [];
                }

            },
            (error) => {
                this.commonService.hideLoading();
                this.clientList = [];
                this.isClientDelinquencyListNoData = true;
                if (error && error.message) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.message);
                } else if (error && error.error_description) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.error_description);
                }
            }
        );
    }


    /**
     *  Function for select month
     * @param eve 
     * @param isCurrentMonth
     */
    getPMDelinquencySelect = (eve, isCurrentMonth) => {
        console.log("eve PM Delinquency", eve);
        if(eve.value == 'HISTORICAL_STATS'){
            this.isHistoricalStats = true;
        }else {
            this.isHistoricalStats = false;
        }

        if (this.pmDelinquencyFilterPayload.selectedPMDelinquency == 'CURRENT_MONTH') {
            this.pmDelinquencyFilterPayload.isCurrentMonth = true;
        } else {
            this.pmDelinquencyFilterPayload.isCurrentMonth = false;
        }
        //this.pmDelinquencyFilterPayload.isCurrentMonth = 'HISTORICAL_STATS';
        this.getPMDelinquencyList();
    }

    /**
     *  Function for select month
     * @param eve 
     * @param isCurrentMonth
     */
    getPMStatsSelect = (eve, isCurrentMonth) => {
        console.log("eve PM Stats", eve);
        if(eve.value == 'HISTORICAL_STATS'){
            this.isPMStatsHistoricalStats = true;
        }else {
            this.isPMStatsHistoricalStats = false;
        }

        if (this.pmStatsFilterPayload.selectedPMStats == 'CURRENT_MONTH') {
            this.pmStatsFilterPayload.isCurrentMonth = true;
        } else {
            this.pmStatsFilterPayload.isCurrentMonth = false;
        }
        // this.pmStatsFilterPayload.month = 'HISTORICAL_STATS';
        this.getPMStatsList();

    }

    /**
     *  Function for select month
     *  * @param eve 
     * @param isCurrentMonth
     */
    getClientDelinquencySelect = (eve, isCurrentMonth) => {
        console.log("eve Client Delinquency", eve);
        if(eve.value == 'HISTORICAL_STATS'){
            this.isClientHistoricalStats = true;
        }else {
            this.isClientHistoricalStats = false;
        }

        if (this.clientDelinquencyFilterPayload.selectedClientDelinquency == 'CURRENT_MONTH') {
            this.clientDelinquencyFilterPayload.isCurrentMonth = true;
        } else {
            this.clientDelinquencyFilterPayload.isCurrentMonth = false;
        }
        //this.clientDelinquencyFilterPayload.isCurrentMonth = 'HISTORICAL_STATS';
        //this.clientDelinquencyFilterPayload.isCurrentMonth = eve.value;
        this.getClientDelinquencyList();
    }


    /**
  * Function for open PM Delinquency fiter popup
  */
    public clickPMDelinquencyOnFilter = ($event) => {

        console.log("click PM Delinquency On Filter");


        this.commonService.openConfirmModal(PMDelinquencyFilterComponent, this.filterData, (data) => {
            console.log("filtrdata clickPMDelinquencyOnFilter", data);
            if (data) {
                let filterDataList =[]
                // this.pmDelinquencyFilterPayload.pmName = data.pmName;
                if(data.pmName ){
                    filterDataList.push({
                        name:"PMDelinquencyName",
                        type:"DROP_DOWN",
                        data:{id:data.pmName}
                    })
                }
                // this.pmDelinquencyFilterPayload.totalUnitFrom = data.totalUnitFrom;
                // this.pmDelinquencyFilterPayload.totalUnitTo = data.totalUnitTo;
                if(data.totalUnitTo || data.totalUnitFrom){
                    filterDataList.push({
                        name:"PMDelinquencyTotalUnit",
                        type:"RANGE",
                        data:{to:data.totalUnitTo,from:data.totalUnitFrom}
                    })
                }
                // this.pmDelinquencyFilterPayload.totalOccupiedUnitFrom = data.totalOccupiedUnitFrom;
                // this.pmDelinquencyFilterPayload.totalOccupiedUnitTo = data.totalOccupiedUnitTo;
                if(data.totalOccupiedUnitTo || data.totalOccupiedUnitFrom){
                    filterDataList.push({
                        name:"PMDelinquencyOccupiedUnit",
                        type:"RANGE",
                        data:{to:data.totalOccupiedUnitTo,from:data.totalOccupiedUnitFrom}
                    })
                }
                // this.pmDelinquencyFilterPayload.totalRentRollFrom = data.totalRentRollFrom;
                // this.pmDelinquencyFilterPayload.totalRentRollTo = data.totalRentRollTo;
                if(data.totalRentRollTo || data.totalRentRollFrom){
                    filterDataList.push({
                        name:"PMDelinquencyTotalRentRoll",
                        type:"RANGE",
                        data:{to:data.totalRentRollTo,from:data.totalRentRollFrom}
                    })
                }
                // this.pmDelinquencyFilterPayload.totalResidentPaidRentFrom = data.totalResidentPaidRentFrom;
                // this.pmDelinquencyFilterPayload.totalResidentPaidRentTo = data.totalResidentPaidRentTo;
                if(data.totalResidentPaidRentTo || data.totalResidentPaidRentFrom){
                    filterDataList.push({
                        name:"PMDelinquencyTotalResidentPaidRent",
                        type:"RANGE",
                        data:{to:data.totalResidentPaidRentTo,from:data.totalResidentPaidRentFrom}
                    })
                }
                // this.pmDelinquencyFilterPayload.totalSubsidizedPaidRentFrom = data.totalSubsidizedPaidRentFrom;
                // this.pmDelinquencyFilterPayload.totalSubsidizedPaidRentTo = data.totalSubsidizedPaidRentTo;
                if(data.totalSubsidizedPaidRentTo || data.totalSubsidizedPaidRentFrom){
                    filterDataList.push({
                        name:"PMDelinquencyTotalSubsidizedPaidRent",
                        type:"RANGE",
                        data:{to:data.totalSubsidizedPaidRentTo,from:data.totalSubsidizedPaidRentFrom}
                    })
                }
                // this.pmDelinquencyFilterPayload.totalCollectedFrom = data.totalCollectedFrom;
                // this.pmDelinquencyFilterPayload.totalCollectedTo = data.totalCollectedTo;
                if(data.totalCollectedTo || data.totalCollectedFrom){
                    filterDataList.push({
                        name:"PMDelinquencyTotalCollectedRent",
                        type:"RANGE",
                        data:{to:data.totalCollectedTo,from:data.totalCollectedFrom}
                    })
                }
                // this.pmDelinquencyFilterPayload.totalResidentCollectedFrom = data.totalResidentCollectedFrom;
                // this.pmDelinquencyFilterPayload.totalResidentCollectedTo = data.totalResidentCollectedTo;
                // this.getPMDelinquencyList()

                if(data.totalResidentCollectedTo || data.totalResidentCollectedFrom){
                    filterDataList.push({
                        name:"PMDelinquencyTotalResidentCollected",
                        type:"RANGE",
                        data:{to:data.totalResidentCollectedTo,from:data.totalResidentCollectedFrom}
                    })
                }
               console.log("this.originalReportsList",this.originalReportsList);
               this.reportsList = _.cloneDeep(this.originalReportsList);
               if(filterDataList.length>0){
                this.isDataFilter = true;
              this.reportsList.list = this.applyLocalfilter(filterDataList,this.originalReportsList.list,this.reportsList,"PMDELINQUENCY")
               } else {
                this.isDataFilter = false;
                this.isPMDelinquencyListNoData = false; 
               }
            }
        });
    }

    /**
  * Function for open PM Stats fiter popup
  */
    public clickPMStatsOnFilter = ($event) => {
        console.log("click PM Stats On Filter");

        this.commonService.openConfirmModal(PMStatsFilterComponent, this.filterData, (data) => {
            console.log("filtrdata clickPMStatsOnFilter", data);
            if (data) {
                let filterDataList =[]
                // this.pmStatsFilterPayload.pmNameStats = data.pmNameStats;
                if(data.pmNameStats ){
                    filterDataList.push({
                        name:"PMName",
                        type:"DROP_DOWN",
                        data:{id:data.pmNameStats}
                    })
                }
                // this.pmStatsFilterPayload.totalNumberOfResidentsUpForRenewalFrom = data.totalNumberOfResidentsUpForRenewalFrom;
                // this.pmStatsFilterPayload.totalNumberOfResidentsUpForRenewalTo = data.totalNumberOfResidentsUpForRenewalTo;
                if(data.totalNumberOfResidentsUpForRenewalTo || data.totalNumberOfResidentsUpForRenewalFrom){
                    filterDataList.push({
                        name:"PMTotalNumberOfResident",
                        type:"RANGE",
                        data:{to:data.totalNumberOfResidentsUpForRenewalTo,from:data.totalNumberOfResidentsUpForRenewalFrom}
                    })
                }
                // this.pmStatsFilterPayload.hashOfResidentsRenewedForMonthFrom = data.hashOfResidentsRenewedForMonthFrom;
                // this.pmStatsFilterPayload.hashOfResidentsRenewedForMonthTo = data.hashOfResidentsRenewedForMonthTo;
                if(data.hashOfResidentsRenewedForMonthTo || data.hashOfResidentsRenewedForMonthFrom){
                    filterDataList.push({
                        name:"PMHashOfResidentRenewed",
                        type:"RANGE",
                        data:{to:data.hashOfResidentsRenewedForMonthTo,from:data.hashOfResidentsRenewedForMonthFrom}
                    })
                }
                // this.pmStatsFilterPayload.repaymentPlansCraftedFrom = data.repaymentPlansCraftedFrom;
                // this.pmStatsFilterPayload.repaymentPlansCraftedTo = data.repaymentPlansCraftedTo;
                if(data.repaymentPlansCraftedTo || data.repaymentPlansCraftedFrom){
                    filterDataList.push({
                        name:"PMRepaymentPlans",
                        type:"RANGE",
                        data:{to:data.repaymentPlansCraftedTo,from:data.repaymentPlansCraftedFrom}
                    })
                }
                // this.pmStatsFilterPayload.hashOfTenantsWithBalanceOf15ResidentPaidFrom = data.hashOfTenantsWithBalanceOf15ResidentPaidFrom;
                // this.pmStatsFilterPayload.hashOfTenantsWithBalanceOf15ResidentPaidTo = data.hashOfTenantsWithBalanceOf15ResidentPaidTo;
                if(data.hashOfTenantsWithBalanceOf15ResidentPaidTo || data.hashOfTenantsWithBalanceOf15ResidentPaidFrom){
                    filterDataList.push({
                        name:"PMHashOfTenantWithBalance",
                        type:"RANGE",
                        data:{to:data.hashOfTenantsWithBalanceOf15ResidentPaidTo,from:data.hashOfTenantsWithBalanceOf15ResidentPaidFrom}
                    })
                }
                // this.pmStatsFilterPayload.hashEsidentInEvictionStatusFrom = data.hashEsidentInEvictionStatusFrom;
                // this.pmStatsFilterPayload.hashEsidentInEvictionStatusTo = data.hashEsidentInEvictionStatusTo;
                if(data.hashResidentInEvictionStatusTo || data.hashResidentInEvictionStatusFrom){
                    filterDataList.push({
                        name:"PMHashOfResidentInEvictionStatus",
                        type:"RANGE",
                        data:{to:data.hashResidentInEvictionStatusTo,from:data.hashResidentInEvictionStatusFrom}
                    })
                }
                // this.pmStatsFilterPayload.hashOfResidentInEvictionOnPaymentPlansFrom = data.hashOfResidentInEvictionOnPaymentPlansFrom;
                // this.pmStatsFilterPayload.hashOfResidentInEvictionOnPaymentPlansTo = data.hashOfResidentInEvictionOnPaymentPlansTo;
                if(data.hashOfResidentInEvictionOnPaymentPlansTo || data.hashOfResidentInEvictionOnPaymentPlansFrom){
                    filterDataList.push({
                        name:"PMHashOfResidentInEvictionOnPayment",
                        type:"RANGE",
                        data:{to:data.hashOfResidentInEvictionOnPaymentPlansTo,from:data.hashOfResidentInEvictionOnPaymentPlansFrom}
                    })
                }
                console.log("this.originalPMStatsList",this.originalPMStatsList);
                this.pmStatsList = _.cloneDeep(this.originalPMStatsList);
                if(filterDataList.length>0){
                    this.isDataFilter = true;                    
               this.pmStatsList.list = this.applyLocalfilter(filterDataList,this.originalPMStatsList.list,this.pmStatsList,"PMSTATS")
                } else {
                    this.isDataFilter = false;
                    this.ispmStatsListNoData = false;
                }
            }
        });
    }

    /**
    * Function for open fiter popup
    */
    public clickClientDelinquencyOnFilter = ($event) => {
        console.log("click Client Delinquency On Filter");

        this.commonService.openConfirmModal(ClientDelinquencyFilterComponent, this.filterData, (data) => {
            console.log("filtrdata", data);
            if (data) {
                let filterDataList =[]
                // this.clientDelinquencyFilterPayload.buildingOwner = data.buildingOwner;
                if(data.buildingOwner ){
                    filterDataList.push({
                        name:"ClientBuildingOwner",
                        type:"DROP_DOWN",
                        data:{id:data.buildingOwner}
                    })
                }
                // this.clientDelinquencyFilterPayload.totalUnitFrom = data.totalUnitFrom;
                // this.clientDelinquencyFilterPayload.totalUnitTo = data.totalUnitTo;
                if(data.totalUnitTo || data.totalUnitFrom){
                    filterDataList.push({
                        name:"ClientTotalUnit",
                        type:"RANGE",
                        data:{to:data.totalUnitTo,from:data.totalUnitFrom}
                    })
                }
                // this.clientDelinquencyFilterPayload.totalOccupiedUnitFrom = data.totalOccupiedUnitFrom;
                // this.clientDelinquencyFilterPayload.totalOccupiedUnitTo = data.totalOccupiedUnitTo;
                if(data.totalOccupiedUnitTo || data.totalOccupiedUnitFrom){
                    filterDataList.push({
                        name:"ClientOccupiedUnit",
                        type:"RANGE",
                        data:{to:data.totalOccupiedUnitTo,from:data.totalOccupiedUnitFrom}
                    })
                }
                // this.clientDelinquencyFilterPayload.totalRentRollTo = data.totalRentRollTo;
                // this.clientDelinquencyFilterPayload.totalResidentPaidRentFrom = data.totalResidentPaidRentFrom;
                if(data.totalRentRollTo || data.totalRentRollFrom){
                    filterDataList.push({
                        name:"ClientTotalRentRoll",
                        type:"RANGE",
                        data:{to:data.totalRentRollTo,from:data.totalRentRollFrom}
                    })
                }
                // this.clientDelinquencyFilterPayload.totalResidentPaidRentTo = data.totalResidentPaidRentTo;
                if(data.totalResidentPaidRentTo || data.totalResidentPaidRentFrom){
                    filterDataList.push({
                        name:"ClientTotalResidentPaidRent",
                        type:"RANGE",
                        data:{to:data.totalResidentPaidRentTo,from:data.totalResidentPaidRentFrom}
                    })
                }
                // this.clientDelinquencyFilterPayload.totalSubsidizedPaidRentFrom = data.acronym;
                // this.clientDelinquencyFilterPayload.totalSubsidizedPaidRentTo = data.totalSubsidizedPaidRentTo;
                if(data.totalSubsidizedRentTo || data.totalSubsidizedRentFrom){
                    filterDataList.push({
                        name:"ClientTotalSubsidizedRent",
                        type:"RANGE",
                        data:{to:data.totalSubsidizedRentTo,from:data.totalSubsidizedRentFrom}
                    })
                }
                // this.clientDelinquencyFilterPayload.totalCollectedFrom = data.totalCollectedFrom;
                // this.clientDelinquencyFilterPayload.totalCollectedTo = data.totalCollectedTo;               
                if(data.totalCollectedTo || data.totalCollectedFrom){
                    filterDataList.push({
                        name:"ClientTotalCollectedRent",
                        type:"RANGE",
                        data:{to:data.totalCollectedTo,from:data.totalCollectedFrom}
                    })
                }
                if(data.residentPaidRentCollectedTo || data.residentPaidRentCollectedFrom){
                    filterDataList.push({
                        name:"ClientResidentPaidRentCollectedTo",
                        type:"RANGE",
                        data:{to:data.residentPaidRentCollectedTo,from:data.residentPaidRentCollectedFrom}
                    })
                }
                if(data.subsidizedRentCollectedTo || data.subsidizedRentCollectedFrom){
                    filterDataList.push({
                        name:"ClientSubsidizedRentCollected",
                        type:"RANGE",
                        data:{to:data.subsidizedRentCollectedTo,from:data.subsidizedRentCollectedFrom}
                    })
                }
               // this.getClientDelinquencyList()
               console.log("this.originalClientList",this.originalClientList);
               this.clientList = _.cloneDeep(this.originalClientList);
               if(filterDataList.length>0){
                this.isDataFilter = true;
              this.clientList.list = this.applyLocalfilter(filterDataList,this.originalClientList.list,this.clientList,"CLIENT")
               } else {
                this.isDataFilter = false;
                this.isClientDelinquencyListNoData = false;
               }
            }
        });
    }

    protected applyLocalfilter(filterDataList,dataList,allData,type){
        console.log("filterData::dataList",filterDataList,dataList,this.clientList);
      
        //    if(type==="CLIENT"){
        //     allData.totalUnit = 0;
        //     allData.occupiedUnit = 0;
        //     allData.totalRentRoll = 0;
        //     allData.totalTenantPaidRent = 0;
        //     allData.totalSubsidizeRent = 0;
        //     allData.totalRentCollected = 0;
        //     allData.tenantPaidRentCollected = 0;
        //     allData.subsidizedRentCollected = 0;
           
        //    }
      
        for(let cnt=0;cnt < filterDataList.length;cnt++){
        switch(filterDataList[cnt].type) {

            case "RANGE" : {


                             switch (filterDataList[cnt].name){

                                 case "ClientTotalUnit" : {

                                    dataList = dataList.filter(function(item) {
                                        return item.totalUnit <= filterDataList[cnt].data.to && item.totalUnit >= filterDataList[cnt].data.from;

                                       
                                        // if(item.totalUnit <= filterDataList[cnt].data.to && item.totalUnit >= filterDataList[cnt].data.from)
                                        // {
                                        //     allData.totalUnit = allData.totalUnit+item.totalUnit;
                                        //     allData.occupiedUnit = allData.occupiedUnit+item.occupiedUnit;
                                        //     allData.totalRentRoll = allData.totalRentRoll+item.totalRentRoll;
                                        //     allData.totalTenantPaidRent = allData.totalTenantPaidRent+item.totalTenantPaidRent;
                                        //     allData.totalSubsidizeRent = allData.totalSubsidizeRent+item.totalSubsidizeRent;
                                        //     allData.totalRentCollected = allData.totalRentCollected+item.totalRentCollected;
                                        //     allData.tenantPaidRentCollected = allData.tenantPaidRentCollected+item.tenantPaidRentCollected;
                                        //     allData.subsidizedRentCollected = allData.subsidizedRentCollected+item.subsidizedRentCollected;
                                        //     return item;
                                        // }
                                      });

                                 }
                                 break;
                                 case "ClientOccupiedUnit" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.occupiedUnit <= filterDataList[cnt].data.to && item.occupiedUnit >= filterDataList[cnt].data.from;
                                        // if(item.occupiedUnit <= filterDataList[cnt].data.to && item.occupiedUnit >= filterDataList[cnt].data.from)
                                        // {
                                        //     allData.totalUnit = allData.totalUnit+item.totalUnit;
                                        //     allData.occupiedUnit = allData.occupiedUnit+item.occupiedUnit;
                                        //     allData.totalRentRoll = allData.totalRentRoll+item.totalRentRoll;
                                        //     allData.totalTenantPaidRent = allData.totalTenantPaidRent+item.totalTenantPaidRent;
                                        //     allData.totalSubsidizeRent = allData.totalSubsidizeRent+item.totalSubsidizeRent;
                                        //     allData.totalRentCollected = allData.totalRentCollected+item.totalRentCollected;
                                        //     allData.tenantPaidRentCollected = allData.tenantPaidRentCollected+item.tenantPaidRentCollected;
                                        //     allData.subsidizedRentCollected = allData.subsidizedRentCollected+item.subsidizedRentCollected;
                                        //     return item;
                                        // }      
                                      });

                                 }
                                 break;
                                 case "ClientTotalRentRoll" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.totalRentRoll <= filterDataList[cnt].data.to && item.totalRentRoll >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "ClientTotalResidentPaidRent" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.totalTenantPaidRent <= filterDataList[cnt].data.to && item.totalTenantPaidRent >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "ClientTotalSubsidizedRent" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.totalSubsidizeRent <= filterDataList[cnt].data.to && item.totalSubsidizeRent >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "ClientTotalCollectedRent" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.totalRentCollected <= filterDataList[cnt].data.to && item.totalRentCollected >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "ClientResidentPaidRentCollectedTo" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.tenantPaidRentCollected <= filterDataList[cnt].data.to && item.tenantPaidRentCollected >= filterDataList[cnt].data.from;
                                      });
                                 }
                                 break;
                                 case "ClientSubsidizedRentCollected" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.subsidizedRentCollected <= filterDataList[cnt].data.to && item.subsidizedRentCollected >= filterDataList[cnt].data.from;
                                      });
                                 }
                                 break;
                                 case "PMTotalNumberOfResident" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.totalTenantUpForRenewal <= filterDataList[cnt].data.to && item.totalTenantUpForRenewal >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMHashOfResidentRenewed" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.totalRenewedForMonth <= filterDataList[cnt].data.to && item.totalRenewedForMonth >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMRepaymentPlans" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.repaymentPlanCrafted <= filterDataList[cnt].data.to && item.repaymentPlanCrafted >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMHashOfTenantWithBalance" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.tenantBalanceMoreThanOneHalf <= filterDataList[cnt].data.to && item.tenantBalanceMoreThanOneHalf >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMHashOfResidentInEvictionStatus" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.tenenatInEviction <= filterDataList[cnt].data.to && item.tenenatInEviction >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMHashOfResidentInEvictionOnPayment" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.tenantINEvictionPaymentPlan <= filterDataList[cnt].data.to && item.tenantINEvictionPaymentPlan >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMDelinquencyTotalUnit" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.totalUnit <= filterDataList[cnt].data.to && item.totalUnit >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMDelinquencyOccupiedUnit" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.occupiedUnit <= filterDataList[cnt].data.to && item.occupiedUnit >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMDelinquencyTotalRentRoll" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.totalRentRoll <= filterDataList[cnt].data.to && item.totalRentRoll >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMDelinquencyTotalResidentPaidRent" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.totalTenantPaidRent <= filterDataList[cnt].data.to && item.totalTenantPaidRent >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMDelinquencyTotalSubsidizedPaidRent" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.totalSubsidizeRent <= filterDataList[cnt].data.to && item.totalSubsidizeRent >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMDelinquencyTotalCollectedRent" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.overallCollected <= filterDataList[cnt].data.to && item.overallCollected >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                                 case "PMDelinquencyTotalResidentCollected" : {

                                    dataList = dataList.filter(function(item) {
                                       
                                        return item.tenantPaidRentCollected <= filterDataList[cnt].data.to && item.tenantPaidRentCollected >= filterDataList[cnt].data.from;
                                      });

                                 }
                                 break;
                             }


            }
            break;
            case "DROP_DOWN" : {


                switch (filterDataList[cnt].name){

                    case "ClientBuildingOwner" : {

                       dataList = dataList.filter(function(item) {
                          
                           return item?.name === filterDataList[cnt]?.data?.id;
                         });

                    }
                    break;
                    case "PMName" : {

                        dataList = dataList.filter(function(item) {
                           
                            return item?.name === filterDataList[cnt]?.data?.id;
                          });
 
                     }
                     break;
                     case "PMDelinquencyName" : {

                        dataList = dataList.filter(function(item) {
                           
                            return item?.name === filterDataList[cnt]?.data?.id;
                          });
 
                     }
                     break;
                }


        }
        break;

        }
    }
    console.log("dataList.... ",dataList);
    console.log("type...",type);
    // Condition for 'No Data Found' after the filter
    
    if(type == 'PMDELINQUENCY'){
        if (dataList.length < 1 ) {
            this.isPMDelinquencyListNoData = true;
        } else {
            this.isPMDelinquencyListNoData = false;            
        }
    } else if(type == 'PMSTATS'){
        if (dataList.length < 1) {
            this.ispmStatsListNoData = true;
        } else {
            this.ispmStatsListNoData = false;
        }
    } else if(type == 'CLIENT'){
        if (dataList.length < 1) {
            this.isClientDelinquencyListNoData = true;
        } else {
            this.isClientDelinquencyListNoData = false;
        }
    }
    
    return dataList;

    }

    /**
     *  Function for PM Delinquency Export dialog 
     */
    openPMDelinquencyExportPopup() {
        this.commonService.openConfirmModal(ExportPopupComponent, this.pmDelinquencyFilterPayload, ( data ) => {
            console.log( "log..data", this.filterData, data );
            if ( data.format ) {
                this.exportPMDelinquency( data.format );
            }
        } );
    }

    /**
     *  Function for PM Stats Export dialog 
     */
    openPMStatsExportPopup() {
        this.commonService.openConfirmModal(ExportPopupComponent, this.pmStatsFilterPayload, ( data ) => {
            console.log( "log..data", this.filterData, data );
            if ( data.format ) {
                this.exportPMStats( data.format );
            }
        } );
    }

    /**
     *  Function for Client Delinquency Export dialog 
     */
    openClientDelinquencyExportPopup() {
        this.commonService.openConfirmModal(ExportPopupComponent, this.clientDelinquencyFilterPayload, ( data ) => {
            console.log( "log..data", this.filterData, data );
            if ( data.format ) {
                this.exportCllientDelinquency( data.format );
            }
        } );
    }



    /**
      * Function to export PM Delinquency
      */
     public exportPMDelinquency( reportFormat ) {
        console.log('reportFormat....',reportFormat);

        this.adminService.exportPMDelinquency( this.pmDelinquencyFilterPayload, reportFormat, this.userData.id ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "res", res );
                    window.location.assign (res.data);
                }
            },
            ( error ) => {
                this.commonService.hideLoading();  
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    /**
      * Function to export PM Stats
      */
     public exportPMStats( reportFormat ) {
        console.log('reportFormat....',reportFormat);

        this.adminService.exportPMStats( this.pmStatsFilterPayload, reportFormat, this.userData.id ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "res", res );
                    window.location.assign (res.data);
                }  
            },
            ( error ) => {
                this.commonService.hideLoading();  
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    /**
      * Function to export Cllient Delinquency
      */
     public exportCllientDelinquency( reportFormat ) {
        console.log('reportFormat....',reportFormat);

        this.adminService.exportCllientDelinquency( this.clientDelinquencyFilterPayload, reportFormat, this.userData.id ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "res", res );
                    window.location.assign (res.data);
                }  
            },
            ( error ) => {
                this.commonService.hideLoading();  
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }



    /**
     * Function to show results based on search string if no data is matched
     * @param e 
     */
    handleFilter(e, isPmLeaderboard) {
        console.log("value  >>>>", e);
        if (isPmLeaderboard) {
            if (e && e["filteredValue"] && e["filteredValue"].length == 0) {
                this.isPMDelinquencyListNoData = true;
            } else {
                this.isPMDelinquencyListNoData = false;
            }
        } else {

            if (e && e["filteredValue"] && e["filteredValue"].length == 0) {
                this.ispmStatsListNoData = true;
            } else {
                this.ispmStatsListNoData = false;
            }
        }

    }

    // reset the table
    clearFilters(dt) {
        dt.reset(); // reset the table
    }


    /**
     * Function coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo("Coming soon");    
    }

    /**
     * Function clear coming soon
     */
    public clearCommingSoon = () => {
        this.commonService.clearToast();   
    }
}
