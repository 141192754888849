/**
 * Eviction Admin Role Related Services
 * Created By : #1116
 * Date : 05 Feb, 2021
 */


import { Injectable, Injector } from '@angular/core';
import { AppSettings, RestService } from '../providers';
import { Observable, timer, throwError } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";
import { TitleCasePipe } from "@angular/common";

import { ReplacePipe } from "src/providers/common-service/replace-pipe";

@Injectable( {
    providedIn: 'root'
} )
export class EvictionAdminService {

    public evictionTypeList: any = []; //Eviction Type List

    constructor( private restService: RestService, private injector: Injector, private toTitleCase: TitleCasePipe ) { }

    /**
      * Function to get Eviction Pipeline data list
      */
    public getEvictionPipelineList( payloadData ): Observable<any> {
        const path = AppSettings.POST_PIPELINE_URL + "?size=" +
            payloadData.pageSize +
            "&page=" +
            payloadData.pageNumber;
        return this.restService.postCall( path, payloadData, true, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
      * Function to export Eviction Admin table data
      */
    public exportPipelineData( param, reportFormat, userId, getTimeZone ): Observable<any> {
        
        const path = AppSettings.PIPELINE_EXPORT_URL + "?reportFormat=" +
            reportFormat +
            "&userId=" +
            userId + "&getTimeZone=" + getTimeZone;
        return this.restService.postCall( path, param, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /**
     * Function to update Eviction Pipeline data
     */
    public updatePipelineData( payload ): Observable<any> {
        const path = AppSettings.UPDATE_PIPELINE_DETAILS_URL;
        return this.restService.putCall( path, payload, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /**
      * Function to get Eviction Types
      */
    public getEvictionTypeList(): Observable<any> {
        const path = AppSettings.GET_EVICTION_TYPE_URL;

        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function to get Eviction Type 
     */
    public getEvictionTypeMetadata = ( onSuccess, onFailure ) => {
        const replacePipe = this.injector.get( ReplacePipe );

        if ( this.evictionTypeList.length == 0 ) {

            this.getEvictionTypeList().subscribe(
                ( res ) => {
                    if ( res.code == 200 || res.status == 200 ) {
                        res.data.map( status =>
                            this.evictionTypeList.push( { label: this.toTitleCase.transform( replacePipe.transform( status, '_', ' ' ) ), value: status } )
                        );
                        onSuccess( this.evictionTypeList );
                    } else {
                        this.evictionTypeList = [];
                        onSuccess( this.evictionTypeList );
                    }
                },
                ( error ) => {
                    this.evictionTypeList = [];
                    onFailure( this.evictionTypeList );
                }
            );
        } else {
            onSuccess( this.evictionTypeList );
        }
    }
}
