import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService, HeaderService, LocalStorageService, SidebarService, AuthService } from 'src/providers/providers';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from 'src/pages/change-password/change-password.component';

import { AppService } from './../../app/app.service';
@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
    paramData: any = {};
    public pmDashboard: boolean = true;
    public isNotification: boolean = true;
    public notificationPayload: any = {
        notificationEmailOn: true,
        notificationOn: false,
        notificationWebNotificationOn: true
    }
    public userData: any;

    constructor(public header: HeaderService, public sidebar: SidebarService, private router: Router, private locstr: LocalStorageService,
        private commonService: CommonService, private appService: AppService,
        public localStr: LocalStorageService, public authService: AuthService) {


    }

    ngOnInit(): void {
        this.userData = this.locstr.getObj('userData');
        var isSliderShow = this.localStr.getObj("isSliderShow");
        if (isSliderShow) {
            this.sidebar.show();
            this.localStr.setObj("isSliderShow", true);
        } else {
            this.sidebar.hide();
            this.localStr.setObj("isSliderShow", false);
        }
        this.header.show();
        this.appService.setTitle('Settings');
        this.getNotificationSetting();
    }

    checked1: boolean = false;
    checked2: boolean = true;
    cities2: any[] = [];

    changePassword = () => {
        this.router.navigate(['/change-password']);
    }

    /**
    * Function to set Notification
    */
    setNotification(eve, notificationFor) {
        if(notificationFor == 'isNotificationON'){
            if (eve.checked) {
                this.isNotification = true;
            } else {
                this.isNotification = false;
            }
        }
        if (notificationFor == 'email') {
            this.notificationPayload.notificationEmailOn = eve.checked;
            console.log("email eve >>>>>", this.notificationPayload.notificationEmailOn);
        } 
        if (notificationFor == 'web') {
            this.notificationPayload.notificationWebNotificationOn = eve.checked;
            console.log("web eve >>>>>", this.notificationPayload.notificationWebNotificationOn);
        }
        this.updateNotificationSetting();
    }
   

    /**
     * Function to get settings
    */
    public getNotificationSetting = () => {
        this.commonService.showLoading();
        this.authService.getNotificationSetting().subscribe(
            (res) => {
                this.commonService.hideLoading();
                if (res.code == 200 || res.status == 200) {
                    console.log("Notification:: res :: ", res.data);
                    this.notificationPayload = res.data;
                    if (this.notificationPayload.notificationOn) {
                        this.isNotification = true;
                    } else {
                        this.isNotification = false;
                    }
                }
            },
            (error) => {
                this.commonService.hideLoading();
                if (error.message) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.message);
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError(error.error_description);
                }
            }
        );
        this.commonService.hideLoading();
    }


    /**
     * Function to Update settings
    */
    public updateNotificationSetting = () => {
        this.commonService.showLoading();
        this.authService.updateNotificationSetting(this.notificationPayload).subscribe(
            (res) => {
                this.commonService.hideLoading();
                if (res.code == 200 || res.status == 200) {
                    console.log("Notification :: res :: ", res);
                    this.commonService.clearToast();
                    this.commonService.showSuccess(res.message);
                }
            },
            (error) => {
                this.commonService.hideLoading();
                if (error.message) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.message);
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError(error.error_description);
                }
            }
        );
        this.commonService.hideLoading();
    }

    /**
     *  Function for Open dialog 
     */
    goToChangePassword = () => {
        this.commonService.openConfirmModal( ChangePasswordComponent, this.paramData, () => { } );
    }

}
