<div class="row page-wrap">
    <div class="container-fluid" *ngIf="!isMobile">
        <div class="row m-0">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 dashboard-card-cls">
                <div class="card dashboard-card clearfix">
                    <div class="row m-0">
                        <div class="d-flex align-items-center col-4">
                            <div class="mr-2"><span class="text-orange display-5"><i class="mdi mdi-wallet"></i></span></div>
                            <div><span class="card-value">{{dashboardGoalsPieChartData.actualRent | currency:'USD':true :'1.2-2'}}</span>
                                <h3 class="font-medium mb-0 card-txt"> {{ "PM_DASHBOARD.TENANT_RENT_COLLECTED" | translate }} </h3>
                            </div>
                        </div>
                        <div class="d-flex align-items-center col flex-end-item">
                            <div class="mr-2"><span class="text-orange display-5"><i class="mdi mdi-wallet"></i></span></div>
                            <div class="pie-chart-container">
                                <div id="d-pie-1"></div>
                            </div>
                            <div class="pie-chart-label-cls">
                                <span class="card-value">{{dashboardGoalsPieChartData.actualRentPercentage}}%</span>
                                <h3 class="font-medium mb-0 card-txt"> {{ "PM_DASHBOARD.ACTUAL" | translate }} </h3>
                            </div>
                        </div>
                        <div class="d-flex align-items-center col flex-end-item">
                            <div class="mr-2"><span class="text-orange display-5"><i class="mdi mdi-wallet"></i></span></div>
                            <div class="pie-chart-container">
                                <div id="d-pie-2"></div>
                            </div>
                            <div class="pie-chart-label-cls">
                                <span class="card-value">{{dashboardGoalsPieChartData.rentGoalPercentage}}%</span>
                                <h3 class="font-medium mb-0 card-txt"> {{ "PM_DASHBOARD.GOAL" | translate }} </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 dashboard-card-cls">
                <div class="card dashboard-card clearfix">
                    <div class="row m-0">
                        <div class="d-flex align-items-center col-4">
                            <div class="mr-2"><span class="text-orange display-5"><i class="mdi mdi-wallet"></i></span></div>
                            <div><span class="card-value">{{dashboardGoalsPieChartData.actualSubsidy | currency:'USD':true :'1.2-2'}}</span>
                                <h3 class="font-medium mb-0 card-txt"> {{ "PM_DASHBOARD.SUBSIDIZED_RENT_COLLECTED" | translate }} </h3>
                            </div>
                        </div>
                        <div class="d-flex align-items-center col flex-end-item">
                            <div class="mr-2"><span class="text-orange display-5"><i class="mdi mdi-wallet"></i></span></div>
                            <div class="pie-chart-container">
                                <div id="d-pie-3"></div>
                            </div>
                            <div class="pie-chart-label-cls">
                                <span class="card-value">{{dashboardGoalsPieChartData.actualSubsidyPercentage}}%</span>
                                <h3 class="font-medium mb-0 card-txt"> {{ "PM_DASHBOARD.ACTUAL" | translate }} </h3>
                            </div>
                        </div>
                        <div class="d-flex align-items-center col flex-end-item">
                            <div class="mr-2"><span class="text-orange display-5"><i class="mdi mdi-wallet"></i></span></div>
                            <div class="pie-chart-container">
                                <div id="d-pie-4"></div>
                            </div>
                            <div class="pie-chart-label-cls">
                                <span class="card-value">{{dashboardGoalsPieChartData.subsidyGoalPercentage}}%</span>
                                <h3 class="font-medium mb-0 card-txt"> {{ "PM_DASHBOARD.GOAL" | translate }} </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row m-0">
            <div class="dashboard-tab-wrap container-fluid p-0 mar-top15">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs full-width">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tab-1" (click)="getDelinquenciesList(); isDeliquence=true">  {{ "PM_DASHBOARD.TODAY_DELINQUENT_TENANTS" | translate }} </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tab-2" (click)="getRenewalList(); isDeliquence=false"> {{ "PM_DASHBOARD.TODAY_RENEWABLE_TENANTS" | translate }} </a>
                    </li>

                    <li class="search-wrap float-right">
                        <button class="btn btn-icon-bg" (click)="openExportPopup()"><i class="forward-icon filter-icon-cls" title="Export"></i></button>
                        <button class="btn btn-icon-bg" (click)="clickOnFilter($event)"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>
                    </li>

                    <!-- <li class="nav-tabs-btn">
                        <button class="btn btn-icon-bg" (click)="clickOnFilter()"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>
                    </li>
                    <li class="nav-tabs-btn">
                        <button class="btn btn-icon-bg" (click)="openExportPopup()"><i class="forward-icon filter-icon-cls" title="Export"></i></button>
                    </li> -->

                </ul>

                <!-- Tab panes -->
                <div class="tab-content full-width">
                    <div id="tab-1" class="container-fluid tab-pane active">
                        <div class="card clearfix">

                            <p-table class="custom-table-cls pm-dashboard-table" #dt [value]="deliquenciesTenantList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 398px)'" (onLazyLoad)="customSort($event,'DELINQUENCY')"
                                [lazy]="true">
                                <!-- [loading]="loading1" Add this for Inplace loader -->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th style="width: 3rem">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th> -->
                                        <th> {{ "PM_DASHBOARD.ADDRESS" | translate }} </th>
                                        <th pSortableColumn="name"> {{ "PM_DASHBOARD.NAME" | translate }}
                                            <p-sortIcon field="name"></p-sortIcon>
                                        </th>

                                        <th pSortableColumn="amountReceivable"> {{ "PM_DASHBOARD.TOTAL_BALANCE" | translate }}
                                            <p-sortIcon field="amountReceivable"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="tenantBalance"> {{ "PM_DASHBOARD.TENANT_BALANCE" | translate }}
                                            <p-sortIcon field="tenantBalance"></p-sortIcon>
                                        </th>
                                        <th> {{ "PM_DASHBOARD.APP_FOLIO_NOTES" | translate }} </th>
                                        <th pSortableColumn="promiseToPay"> {{ "TENANT.PROMISE_TO_PAY" | translate }}
                                            <!-- <p-sortIcon field="promiseToPay"></p-sortIcon> -->
                                        </th>
                                        <th pSortableColumn="actionItem"> {{ "PM_DASHBOARD.ACTION_ITEM" | translate }}
                                            <p-sortIcon field="actionItem"></p-sortIcon>
                                        </th>
                                        <th> {{ "PM_DASHBOARD.PM_STATUS" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.MOST_RECENT_NOTES" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.30To60" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.60Plus" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.60To90" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.90Plus" | translate }} </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-deliquenciesTenant>
                                    <tr class="cusr-pointer" (click)="openDelinquencyDetails(deliquenciesTenant)">
                                        <!-- <td>
                                        <p-tableCheckbox [value]="product"></p-tableCheckbox>
                                    </td> -->
                                        <td title="{{deliquenciesTenant.propertyAddress}}">{{deliquenciesTenant.propertyAddress? deliquenciesTenant.propertyAddress: '-'}}</td>
                                        <td title="{{deliquenciesTenant.name}}" (click)="openTenantDetails(deliquenciesTenant.unitId, 'deliquencies')" [ngClass]="!deliquenciesTenant.name? 'rmLine': ''" class="hyperlink-cls">{{deliquenciesTenant.name? deliquenciesTenant.name: '-'}}</td>
                                        <td title="{{deliquenciesTenant.amountReceivable | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.amountReceivable ? (deliquenciesTenant.amountReceivable | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.tenantBalance | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.tenantBalance ? (deliquenciesTenant.tenantBalance | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.appFolioDelinquencyNotes }}">{{deliquenciesTenant.appFolioDelinquencyNotes? deliquenciesTenant.appFolioDelinquencyNotes: '-' }}</td>
                                        <td title="{{deliquenciesTenant.promiseToPay | date: 'MM/dd/yyyy' : ' - '}}">{{ deliquenciesTenant.promiseToPay ? (deliquenciesTenant.promiseToPay | date: 'MM/dd/yyyy' : ' ') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.lastActionTaken | replace : '_' : ' ' | titlecase}}">{{ deliquenciesTenant.lastActionTaken ? (deliquenciesTenant.lastActionTaken | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{deliquenciesTenant.pmStatus | replace : '_' : ' ' | titlecase}}">{{ deliquenciesTenant.pmStatus ? (deliquenciesTenant.pmStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{deliquenciesTenant.evictionStatus | replace : '_' : ' ' | titlecase}}">{{ deliquenciesTenant.evictionStatus ? (deliquenciesTenant.evictionStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{deliquenciesTenant.mostRecentNotes }}">{{deliquenciesTenant.mostRecentNotes? deliquenciesTenant.mostRecentNotes: '-' }}</td>
                                        <td title="{{deliquenciesTenant.thirtyTo60 | currency:'USD': 'symbol' :'1.2-2'}}">{{ deliquenciesTenant.thirtyTo60 ? (deliquenciesTenant.thirtyTo60 | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.sixtyPlus | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.sixtyPlus ? (deliquenciesTenant.sixtyPlus | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.sixtyTo90 | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.sixtyTo90 ? (deliquenciesTenant.sixtyTo90 | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.ninetyPlus | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.ninetyPlus ? (deliquenciesTenant.ninetyPlus | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                    </tr>


                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="deliquenciesTenantList.length == 0">
                                <div class="no-data-found" *ngIf="!loading1" text-center>
                                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                        <p-paginator styleClass="ui-paginator-bottom" [rows]="10" maxSize="5" #p (onLazyLoad)="delinquenciespaginate($event)" (onPageChange)="delinquenciespaginate($event)" [totalRecords]=" deliquenciesPayload.totalRecords" class="list-paginator table-paginator"></p-paginator>
                    </div>
                    <div id="tab-2" class="container-fluid tab-pane">
                        <div class="card clearfix">

                            <p-table class="custom-table-cls pm-dashboard-table" #dt [value]="renewalsTenantList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 398px)'" (onLazyLoad)="customSort($event,'RENEWAL')" [lazy]="true">
                                <!-- [loading]="loading2" Add this for Inplace loader-->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th style="width: 3rem ">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th> -->
                                        <th> {{ "PM_DASHBOARD.ADDRESS" | translate }} </th>
                                        <th pSortableColumn="tenant"> {{ "PM_DASHBOARD.NAME" | translate }}
                                            <p-sortIcon field="tenant"></p-sortIcon>
                                        </th>

                                        <th pSortableColumn="unit"> {{ "PM_DASHBOARD.UNIT" | translate }}
                                            <p-sortIcon field="unit"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="rent"> {{ "PM_DASHBOARD.RENT" | translate }}
                                            <p-sortIcon field="rent"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="leaseTo"> {{ "PM_DASHBOARD.LEASE_EXPIRATION" | translate }}
                                            <p-sortIcon field="leaseTo"></p-sortIcon>
                                        </th>
                                        <th> {{ "PM_DASHBOARD.RENEWAL_STATUS" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.SUBSIDY" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.TARGET_RENT" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.MOST_RECENT_NOTES" | translate }} </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-renewalsTenant>

                                    <tr class="cusr-pointer" (click)="openRenewalDetails(renewalsTenant)">
                                        <!-- <td>
                                        <p-tableCheckbox [value]="product "></p-tableCheckbox>
                                    </td> -->
                                        <td title="{{renewalsTenant.property}}">{{renewalsTenant.property? renewalsTenant.property: '-'}}</td>
                                        <td title="{{renewalsTenant.tenant}}" (click)="openTenantDetails(renewalsTenant.unitId, 'renewals')" [ngClass]="!renewalsTenant.tenant? 'rmLine': ''" class="hyperlink-cls">{{renewalsTenant.tenant? renewalsTenant.tenant: '-'}}</td>
                                        <td title="{{renewalsTenant.unit}}">{{renewalsTenant.unit? renewalsTenant.unit: '-'}}</td>
                                        <td title="{{renewalsTenant.rent | currency:'USD':'symbol' :'1.2-2'}}">{{ renewalsTenant.rent ? (renewalsTenant.rent | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{renewalsTenant.leaseTo | date: 'MMM dd, yyyy' : ' - '}}">{{ renewalsTenant.leaseTo ? (renewalsTenant.leaseTo | date: 'MMM dd, yyyy' : ' - ') : '-' }}</td>
                                        <td title="{{renewalsTenant.rentdexRenewalStatus  | replace : '_' : ' ' | titlecase }}">{{ renewalsTenant.rentdexRenewalStatus ? (renewalsTenant.rentdexRenewalStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{renewalsTenant.evictionStatus | replace : '_' : ' ' | titlecase}}">{{ renewalsTenant.evictionStatus ? (renewalsTenant.evictionStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{renewalsTenant.subsidy | currency:'USD':'symbol' :'1.2-2'}}">{{ renewalsTenant.subsidy ? (renewalsTenant.subsidy | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{renewalsTenant.marketRent}}">{{ renewalsTenant.marketRent ? (renewalsTenant.marketRent) : '-' }}</td>
                                        <td title="{{renewalsTenant.renewableNotes}}" class="txt-initial">{{renewalsTenant.renewableNotes? renewalsTenant.renewableNotes: '-'}}</td>
                                    </tr>

                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="renewalsTenantList.length == 0">
                                <div class="no-data-found" *ngIf="!loading2" text-center>
                                    <div class="no-found-txt" text-center> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                        <p-paginator styleClass="ui-paginator-bottom" [rows]="10" #p (onLazyLoad)="renewalpaginate($event)" (onPageChange)="renewalpaginate($event)" [totalRecords]="renewalPayload.totalRecords" class="list-paginator table-paginator"></p-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isMobile">

        <div class="row m-0">
            <div class="dashboard-tab-wrap container-fluid p-0">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs full-width">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tab-1" (click)="getDelinquenciesList(); isDeliquence=true">  {{ "PM_DASHBOARD.TODAY_DELINQUENT_TENANTS" | translate }} </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tab-2" (click)="getRenewalList(); isDeliquence=false"> {{ "PM_DASHBOARD.TODAY_RENEWABLE_TENANTS" | translate }} </a>
                    </li>

                </ul>

                <!-- Tab panes -->
                <div class="tab-content full-width">
                    <div id="tab-1" class="container-fluid tab-pane active">
                        <div class="card">

                            <p-table class="custom-table-cls" #dt1 [value]="deliquenciesTenantList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 295px)'" (onLazyLoad)="customSort($event,'DELINQUENCY')" [lazy]="true">
                                <!-- [loading]="loading1" Add this for Inplace loader -->
                                <ng-template pTemplate="header">
                                    <tr>

                                        <th> {{ "PM_DASHBOARD.ADDRESS" | translate }} </th>
                                        <th pSortableColumn="name"> {{ "PM_DASHBOARD.NAME" | translate }}
                                            <p-sortIcon field="name"></p-sortIcon>
                                        </th>
                                        <th> {{ "PM_DASHBOARD.MOST_RECENT_NOTES" | translate }} </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-deliquenciesTenant>
                                    <tr class="cusr-pointer" (click)="openDelinquencyDetails(deliquenciesTenant)">

                                        <td title="{{deliquenciesTenant.propertyAddress}}">{{deliquenciesTenant.propertyAddress? deliquenciesTenant.propertyAddress: '-'}}</td>
                                        <td *ngIf="!isMobile" title="{{deliquenciesTenant.name}}" (click)="openTenantDetails(deliquenciesTenant.unitId, 'deliquencies')" [ngClass]="!deliquenciesTenant.name? 'rmLine': ''" class="hyperlink-cls">{{deliquenciesTenant.name? deliquenciesTenant.name: '-'}}</td>

                                        <td *ngIf="isMobile" title="{{deliquenciesTenant.name}}">{{deliquenciesTenant.name? deliquenciesTenant.name: '-'}}</td>
                                        <td title="{{deliquenciesTenant.mostRecentNotes}}">{{deliquenciesTenant.mostRecentNotes? deliquenciesTenant.mostRecentNotes: '-'}}</td>
                                    </tr>


                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="deliquenciesTenantList.length == 0">
                                <div class="no-data-found" *ngIf="!loading1" text-center>
                                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                        <p-paginator styleClass="ui-paginator-bottom" [rows]="25" maxSize="5" #p (onLazyLoad)="delinquenciespaginate($event)" (onPageChange)="delinquenciespaginate($event)" [totalRecords]=" deliquenciesPayload.totalRecords" class="list-paginator table-paginator"></p-paginator>
                    </div>
                    <div id="tab-2" class="container-fluid tab-pane">
                        <div class="card">

                            <p-table class="custom-table-cls" #dt2 [value]="renewalsTenantList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 295px)'" (onLazyLoad)="customSort($event,'RENEWAL')" [lazy]="true">
                                <!-- [loading]="loading2" Add this for Inplace loader-->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th style="width: 3rem ">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th> -->
                                        <th> {{ "PM_DASHBOARD.ADDRESS" | translate }} </th>
                                        <th pSortableColumn="tenant"> {{ "PM_DASHBOARD.NAME" | translate }}
                                            <p-sortIcon field="tenant"></p-sortIcon>
                                        </th>
                                        <th> {{ "PM_DASHBOARD.MOST_RECENT_NOTES" | translate }} </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-renewalsTenant>

                                    <tr class="cusr-pointer" (click)="openRenewalDetails(renewalsTenant)">
                                        <!-- <td>
                                        <p-tableCheckbox [value]="product "></p-tableCheckbox>
                                    </td> -->
                                        <td title="{{renewalsTenant.property}}">{{renewalsTenant.property? renewalsTenant.property: '-'}}</td>
                                        <td *ngIf="!isMobile" title="{{renewalsTenant.tenant}}" (click)="openTenantDetails(renewalsTenant.unitId, 'renewals')" [ngClass]="!renewalsTenant.tenant? 'rmLine': ''" class="hyperlink-cls">{{renewalsTenant.tenant? renewalsTenant.tenant: '-'}}</td>
                                        <td *ngIf="isMobile" title="{{renewalsTenant.tenant}}">{{renewalsTenant.tenant? renewalsTenant.tenant: '-'}}</td>

                                        <td title="{{renewalsTenant.renewableNotes}}">{{renewalsTenant.renewableNotes? renewalsTenant.renewableNotes: '-'}}</td>
                                    </tr>

                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="renewalsTenantList.length == 0">
                                <div class="no-data-found" *ngIf="!loading2" text-center>
                                    <div class="no-found-txt" text-center> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                        <p-paginator styleClass="ui-paginator-bottom" [rows]="25" (onLazyLoad)="renewalpaginate($event)" (onPageChange)="renewalpaginate($event)" [totalRecords]="renewalPayload.totalRecords" class="list-paginator table-paginator"></p-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>