<div class="row page-wrap">
    <div class="container-fluid">



        <div class="row m-0">
            <div class="dashboard-tab-wrap container-fluid p-0">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs full-width">
                    <!-- <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tab-1" (click)="getDelinquenciesList(); isDeliquence=true">Today’s Delinquent Tenants</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tab-2" (click)="getRenewalList(); isDeliquence=false">Today’s Renewable Tenants</a>
                    </li> -->
                    <li class="search-wrap float-right">
                        <div class="form-group has-search common-search-box">
                            <span class="form-control-feedback"><i class="search-icon search-icon-cls"></i></span>
                            <input type="text" id="search-bar" class="form-control" spellcheck="false" autocomplete="false" name="searchUser" placeholder="Search by Name/Address/Unit" pInputText [(ngModel)]="deliquenciesPayload.searchText" (input)="isSearchCall=true;getDelinquenciesList($event);"
                            />
                        </div>
                        <button class="btn btn-icon-bg" (click)="openExportPopup()"><i class="forward-icon filter-icon-cls" title="Export"></i></button>
                        <button class="btn btn-icon-bg" (click)="clickOnFilter($event)"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>
                    </li>

                </ul>

                <!-- Tab panes -->
                <div class="tab-content full-width">
                    <div id="tab-1" class="container-fluid tab-pane active">
                        <div class="card clearfix">

                            <p-table class="custom-table-cls" #dt [value]="deliquenciesTenantList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 295px)'" [responsive]="true" (onLazyLoad)="customSort($event,'DELINQUENCY')"
                                [lazy]="true">
                                <!-- [loading]="loading"  Add this for Inplace loader-->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th style="width: 3rem">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th> -->
                                        <th> {{ "PM_DASHBOARD.ADDRESS" | translate }} </th>
                                        <th pSortableColumn="name"> {{ "PM_DASHBOARD.NAME" | translate }}
                                            <p-sortIcon field="name"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="unit"> {{ "PM_DASHBOARD.UNIT" | translate }}
                                            <p-sortIcon field="unit"></p-sortIcon>

                                        </th>
                                        <th> {{ "PM_DASHBOARD.PM_STATUS" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }} </th>
                                        <th pSortableColumn="amountReceivable"> {{ "PM_DASHBOARD.TOTAL_BALANCE" | translate }}
                                            <p-sortIcon field="amountReceivable"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="tenantBalance"> {{ "PM_DASHBOARD.TENANT_BALANCE" | translate }}
                                            <p-sortIcon field="tenantBalance"></p-sortIcon>
                                        </th>
                                        <th> {{ "PM_DASHBOARD.APP_FOLIO_NOTES" | translate }} </th>
                                        <th pSortableColumn="delinquentSubsidyAmount"> {{ "PM_DASHBOARD.SUBSIDY_BALANCE" | translate }}
                                            <p-sortIcon field="delinquentSubsidyAmount"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="actionItem"> {{ "PM_DASHBOARD.ACTION_ITEM" | translate }}
                                            <p-sortIcon field="actionItem"></p-sortIcon>
                                        </th>

                                        <th> {{ "PM_DASHBOARD.MOST_RECENT_NOTES" | translate }} </th>

                                        <th> {{ "PM_DASHBOARD.30To60" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.60Plus" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.60To90" | translate }} </th>
                                        <th> {{ "PM_DASHBOARD.90Plus" | translate }} </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-deliquenciesTenant>
                                    <tr class="cusr-pointer" (click)="openDelinquencyDetails(deliquenciesTenant)">
                                        <!-- <td>
                                        <p-tableCheckbox [value]="product"></p-tableCheckbox>
                                    </td> -->
                                        <td title="{{deliquenciesTenant.propertyAddress}}">{{deliquenciesTenant.propertyAddress? deliquenciesTenant.propertyAddress: '-'}}</td>
                                        <td title="{{deliquenciesTenant.name}}" (click)="openTenantDetails(deliquenciesTenant.unitId)" [ngClass]="!deliquenciesTenant.name? 'rmLine': ''" class="hyperlink-cls">{{deliquenciesTenant.name? deliquenciesTenant.name: '-'}}</td>
                                        <td title="{{deliquenciesTenant.unit}}">{{deliquenciesTenant.unit? deliquenciesTenant.unit: '-'}}</td>
                                        <td title="{{deliquenciesTenant.pmStatus | replace : '_' : ' ' | titlecase}}">{{ deliquenciesTenant.pmStatus ? (deliquenciesTenant.pmStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{deliquenciesTenant.evictionStatus | replace : '_' : ' ' | titlecase}}">{{ deliquenciesTenant.evictionStatus ? (deliquenciesTenant.evictionStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{deliquenciesTenant.amountReceivable | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.amountReceivable ? (deliquenciesTenant.amountReceivable | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.tenantBalance | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.tenantBalance ? (deliquenciesTenant.tenantBalance | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.appFolioDelinquencyNotes }}">{{deliquenciesTenant.appFolioDelinquencyNotes? deliquenciesTenant.appFolioDelinquencyNotes: '-' }}</td>
                                        <td title="{{deliquenciesTenant.delinquentSubsidyAmount | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.delinquentSubsidyAmount ? (deliquenciesTenant.delinquentSubsidyAmount | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.lastActionTaken | replace : '_' : ' ' | titlecase}}">{{ deliquenciesTenant.lastActionTaken ? (deliquenciesTenant.lastActionTaken | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{deliquenciesTenant.mostRecentNotes}}" class="txt-initial">{{deliquenciesTenant.mostRecentNotes? deliquenciesTenant.mostRecentNotes: '-'}}</td>
                                        <td title="{{deliquenciesTenant.thirtyTo60 | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.thirtyTo60 ? (deliquenciesTenant.thirtyTo60 | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.sixtyPlus | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.sixtyPlus ? (deliquenciesTenant.sixtyPlus | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.sixtyTo90 | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.sixtyTo90 ? (deliquenciesTenant.sixtyTo90 | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{deliquenciesTenant.ninetyPlus | currency:'USD':'symbol' :'1.2-2'}}">{{ deliquenciesTenant.ninetyPlus ? (deliquenciesTenant.ninetyPlus | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="deliquenciesTenantList.length == 0">
                                <div class="no-data-found" *ngIf="!loading" text-center>
                                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                        <p-paginator styleClass="ui-paginator-bottom" [rows]="25" #p (onLazyLoad)="delinquenciespaginate($event)" (onPageChange)="delinquenciespaginate($event)" [totalRecords]=" deliquenciesPayload.totalRecords" class="list-paginator table-paginator"></p-paginator>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>