import { Component, OnInit, ViewEncapsulation, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, PropertyManagerService, MetaDataService } from 'src/providers/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplacePipe } from 'src/providers/common-service/replace-pipe';
import { TitleCasePipe } from '@angular/common';
import * as _ from "lodash";
@Component( {
    selector: 'app-dashboard-filter',
    templateUrl: './dashboard-filter.component.html',
    styleUrls: ['./dashboard-filter.component.scss'],

    encapsulation: ViewEncapsulation.None
} )
export class DashboardFilterComponent implements OnInit {

    public pageName: string;
    public firstButton: string;
    public secondButton: string;

    @Input() filterPayload: any
    // = {
    //   lastActionTaken: "",
    //   evictionStatus: "",
    //   pmStatus: "",
    //   subsidyFrom: 0,
    //   subsidyTo: 0,
    //   tenantAmountFrom: 0,
    //   tenantAmountTo: 0,
    //   totalBalanceFrom: 0,
    //   totalBalanceTo: 0,
    //   unitId: "",
    //   propertyId: "",
    //   renewalStatus: "",
    //   rentFrom: 0,
    //   rentTo: 0,
    //   targetRentFrom: 0,
    //   targetRentTo: 0,
    //   leaseExpirationFrom:null,
    //   leaseExpirationTO:null

    // }

    public actionItemList: any = [];
    public pmStatusList: any = [];
    public evictionStatusList: any = [];
    public acronymList: any = [];
    
    public renewalStatusList: any = [];

    tab: any;


    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = ( this.currentYear + 99 );
    public minyear = ( this.currentYear - 99 );
    range: string = ( this.minyear ).toString() + ":" + ( this.maxyear ).toString();
    //public replaceChar= new ReplacePipe();
    constructor( private replaceChar: ReplacePipe, private toTitleCase: TitleCasePipe, private router: Router, public pmService: PropertyManagerService, private commonService: CommonService, public activeModal: NgbActiveModal,
        public metaDataService: MetaDataService ) {
        let pageName = 'Filter';
        let firstButton = 'Cancel';
        let secondButton = 'Apply';
        //console.log("filterData",this.formData);
    }

    ngOnInit(): void {
        this.getAcronymList();
        console.log( "filterData :: ", this.filterPayload );
        if ( this.filterPayload.totalAmountTo == 0 ) {
            this.filterPayload.totalAmountTo = null;
        }
        if ( this.filterPayload.totalAmountFrom == 0 ) {
            this.filterPayload.totalAmountFrom = null;
        }
        if ( this.filterPayload.tenantAmountFrom == 0 ) {
            this.filterPayload.tenantAmountFrom = null;
        }
        if ( this.filterPayload.tenantAmountTo == 0 ) {
            this.filterPayload.tenantAmountTo = null;
        }
        if(this.filterPayload.promiseToPayFrom == 0){
            this.filterPayload.promiseToPayFrom = null;
        }
        if(this.filterPayload.promiseToPayTo == 0){
            this.filterPayload.promiseToPayTo = null;
        }
        if ( this.filterPayload.rentFrom == 0 ) {
            this.filterPayload.rentFrom = null;
        }
        if ( this.filterPayload.rentTo == 0 ) {
            this.filterPayload.rentTo = null;
        }
        if ( this.filterPayload.targetRentFrom == 0 ) {
            this.filterPayload.targetRentFrom = null;
        }
        if ( this.filterPayload.targetRentTo == 0 ) {
            this.filterPayload.targetRentTo = null;
        }
        if ( this.filterPayload.subsidyFrom == 0 ) {
            this.filterPayload.subsidyFrom = null;
        }
        if ( this.filterPayload.subsidyTo == 0 ) {
            this.filterPayload.subsidyTo = null;
        }
        if ( this.filterPayload.tenantAmountFrom == 0 ) {
            this.filterPayload.tenantAmountFrom = null;
        }
        if ( this.filterPayload.totalBalanceFrom == 0 ) {
            this.filterPayload.totalBalanceFrom = null;
        }
        if ( this.filterPayload.totalBalanceTo == 0 ) {
            this.filterPayload.totalBalanceTo = null;
        }

        this.getEvictionStatusList();
        if ( this.filterPayload.isDeliquence ) {
            this.tab = 4;
            this.getPMStatusList();
            this.getActionItemList();
            if ( this.filterPayload.isDeliquenceList ) {
               
            }
        } else if(this.filterPayload.isDeliquenceDashboard){
            this.tab = 1;
            this.getPMStatusList();
            this.getActionItemList();
            if ( this.filterPayload.isDeliquenceList ) {
               
            }
        } else if(this.filterPayload.isRenewalDashboard) {
            this.tab = 8;
            this.getRenewalStatusList();
        } else if(this.filterPayload.isRenewal) {
            this.tab = 10;
            this.getRenewalStatusList();
        }

    }

    /**
     * function on cancel Click
     */
    cancelClick = () => {
        if ( typeof this.filterPayload.acronym == 'object' ) {
            this.filterPayload.acronym = "";
        }  else {
            this.filterPayload.acronym = "";
        }
        
        if ( typeof this.filterPayload.rentdexRenewalStatus == 'object' ) {
            this.filterPayload.rentdexRenewalStatus = "";
        }   else {
            this.filterPayload.rentdexRenewalStatus = "";
        }
        this.activeModal.dismiss( '' );
    }
    /**
       * function on clear Click
       */
    clearFilter = () => {
        this.filterPayload.lastActionTaken = "";
        this.filterPayload.pmStatus = "";
        this.filterPayload.evictionStatus = "";
        this.filterPayload.subsidyFrom = null;
        this.filterPayload.subsidyTo = null;
        this.filterPayload.tenantAmountFrom = null;
        this.filterPayload.tenantAmountTo = null;
        this.filterPayload.totalAmountFrom = null;
        this.filterPayload.totalAmountTo = null;
        this.filterPayload.unitId = "";
        this.filterPayload.propertyId = "";
        this.filterPayload.renewalStatus = "";
        this.filterPayload.promiseToPayFrom = null;
        this.filterPayload.promiseToPayTo = null;
        this.filterPayload.rentFrom = null;
        this.filterPayload.rentTo = null;
        this.filterPayload.targetRentFrom = null;
        this.filterPayload.targetRentTo = null;
        this.filterPayload.leaseExpirationFrom = null;
        this.filterPayload.leaseExpirationTO = null;

        if ( typeof this.filterPayload.acronym == 'object' ) {
            this.filterPayload.acronym = "";
        } else {
            this.filterPayload.acronym = "";
        }
        
        if ( typeof this.filterPayload.rentdexRenewalStatus == 'object' ) {
            this.filterPayload.rentdexRenewalStatus = "";
        } else {
            this.filterPayload.rentdexRenewalStatus = "";
        }

    }
    /**
     * function on cancel Click
     */
    applyFilter = () => {
        if ( this.filterPayload.totalAmountTo == null ) {
            this.filterPayload.totalAmountTo = 0;
        }
        if ( this.filterPayload.totalAmountFrom == null ) {
            this.filterPayload.totalAmountFrom = 0;
        }
        if ( this.filterPayload.tenantAmountFrom == null ) {
            this.filterPayload.tenantAmountFrom = 0;
        }
        if ( this.filterPayload.tenantAmountTo == null ) {
            this.filterPayload.tenantAmountTo = 0;
        }   
             
        if ( this.filterPayload.rentFrom == null ) {
            this.filterPayload.rentFrom = 0;
        }
        if ( this.filterPayload.rentTo == null ) {
            this.filterPayload.rentTo = 0;
        }
        if ( this.filterPayload.targetRentFrom == null ) {
            this.filterPayload.targetRentFrom = 0;
        }
        if ( this.filterPayload.targetRentTo == null ) {
            this.filterPayload.targetRentTo = 0;
        }
        if ( this.filterPayload.subsidyFrom == null ) {
            this.filterPayload.subsidyFrom = 0;
        }
        if ( this.filterPayload.subsidyTo == null ) {
            this.filterPayload.subsidyTo = 0;
        }
        if ( typeof this.filterPayload.acronym == 'object' ) {
            this.filterPayload.acronym = "";
        }

        if ( typeof this.filterPayload.rentdexRenewalStatus == 'object' ) {
            this.filterPayload.rentdexRenewalStatus = "";
        }

        this.activeModal.dismiss( this.filterPayload );
    }

    //   public getSelectedGender( eve ) {
    //     console.log( "eb", eve, this.userToView.gender );
    // }

    /**
       * Function to get eviction status
       */
    private getEvictionStatusList = () => {
        this.evictionStatusList = [];
        this.metaDataService.getEvictionStatusList(
            ( response ) => {
                this.commonService.hideLoading();
                this.evictionStatusList.push( { label: "Select", value: "" } );

                response.map( status =>
                    this.evictionStatusList.push( status )
                );

                this.evictionStatusList = _.cloneDeep( this.evictionStatusList );
                this.commonService.hideLoading();
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.evictionStatusList = reason;
                this.commonService.hideLoading();
            } );
    }

    /**
       * Function to get Resident Acronym List
       */
      private getAcronymList = () => {
        this.acronymList = [];
        this.metaDataService.getAcronymList(
            ( response ) => {
                this.commonService.hideLoading();
                this.acronymList.push( { label: "Select", value: "" } );

                response.map( status =>
                    this.acronymList.push( status )
                );

                if ( !this.filterPayload.acronym ) {
                    // this.acronymList.splice(1, 1);
                    this.filterPayload.acronym = this.acronymList[0];
                }

                this.acronymList = _.cloneDeep( this.acronymList );
                this.commonService.hideLoading();
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.acronymList = reason;
                this.commonService.hideLoading();
            } );
    }

    /**
      * Function to get action item
      */
    private getActionItemList = () => {
        this.actionItemList = [];
        this.metaDataService.getActionItemList(
            ( response ) => {
                this.commonService.hideLoading();
                this.actionItemList.push( { label: "Select", value: "" } );

                response.map( status =>
                    this.actionItemList.push( status )
                );

                this.actionItemList = _.cloneDeep( this.actionItemList );
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.actionItemList = reason;
            } );
    }

    /**
      * Function to get PM status
      */
    private getPMStatusList = () => {
        this.pmStatusList = [];

        this.metaDataService.getPMStatusList(
            ( response ) => {

                this.pmStatusList.push( { label: "Select", value: "" } );
                response.map( status =>
                    this.pmStatusList.push( status )
                );

                if ( !this.filterPayload.pmStatus ) {
                    this.filterPayload.pmStatus = this.pmStatusList[0];
                }
                this.pmStatusList = _.cloneDeep( this.pmStatusList );
            },
            ( reason ) => {

                this.pmStatusList = reason;
            } );
    }

    /**
     * Function to get Custom Renewal status
     */
    private getRenewalStatusList = () => {
        this.renewalStatusList = [];

        this.metaDataService.getCustomRenewalStatusList(
            ( response ) => {

                this.renewalStatusList.push( { label: "Select", value: "" } );
                response.map( status =>
                    this.renewalStatusList.push( status )
                );

                if ( !this.filterPayload.rentdexRenewalStatus ) {
                    this.filterPayload.rentdexRenewalStatus = this.renewalStatusList[0];
                }
                this.renewalStatusList = _.cloneDeep( this.renewalStatusList );
                this.commonService.hideLoading();
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.renewalStatusList = reason;
            } );
    }

   
    /**
   * Function coming soon
   */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo( "Coming soon" );
    }

    /**
      * Function clear coming soon
      */
    public clearCommingSoon = () => {
        this.commonService.clearToast();
    }
}
