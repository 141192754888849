<div class="background-image-wrapper">

    <!-- Container -->
    <div class="form-container change-form-cls">

        <div class="">

            <!-- Heading -->
            <div class="text-center col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h2 class="form-title txt-formate"> {{ "EXPORT.EXPORT_TO" | translate }} </h2>
            </div>
            <!-- Form -->

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <form class="common-form-cls" novalidate>
                    <!-- <div class="p-field-radiobutton">
                        <p-radioButton name="pdf" value="PDF" inputId="pdf"></p-radioButton>
                        <label for="pdf">PDF</label>
                    </div>
                    <div class="p-field-radiobutton">
                        <p-radioButton name="excel" value="Excel" inputId="excel"></p-radioButton>
                        <label for="excel">Excel</label>
                    </div>
                    <div class="p-field-radiobutton">
                        <p-radioButton name="csv" value="CSV" inputId="csv"></p-radioButton>
                        <label for="csv">CSV</label>
                    </div> -->

                    <div class="radio-groups">                       
                        <div>
                            <input type="radio" name="radio" id="radio2" value="EXCEL" [(ngModel)]="format" required #formatCtrl="ngModel" /> 
                            <label for="radio2"> {{ "EXPORT.EXCEL" | translate }} </label> 
                        </div>
                        <div>
                            <input type="radio" name="radio" id="radio3" value="CSV" [(ngModel)]="format" required #formatCtrl="ngModel"/> 
                            <label for="radio3"> {{ "EXPORT.CSV" | translate }} </label> 
                        </div>
                        <!-- <div> 
                            <input type="radio" name="radio" id="radio1" value="PDF" [(ngModel)]="format" required #formatCtrl="ngModel" /> 
                            <label class="radio" for="radio1">PDF</label> 
                        </div> -->
                    </div>

                    <!--------------- submit btn  --------->
                    <div class="row d-flex modal-footer">
                        <button type="button" class="btn link-btn cancel-btn-font" (click)="cancelClick()"> {{ "COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
                        <button type="submit" class="btn common-btn" (click)="okClick()"> {{ "COMMON_COMPONENT.APPLY_BUTTON" | translate }} </button>
                    </div>


                </form>
                <!--------------- end of form  --------->
            </div>

        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>


</div>