import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class HeaderService {
    visible: boolean;
    isUpdate:EventEmitter<any> = new EventEmitter();
    constructor() { this.visible = false; }

    /**
     * Function to hide side menu & header on login
    */
    hide() {

        console.log( "header hide" );
        this.visible = false;
        this.isUpdate.emit({visible:this.visible});
    }

    /**
     * Function to show side menu & header other components
    */
    show() {
        console.log( "header show" );

        this.visible = true;
        this.isUpdate.emit({visible:this.visible});
    }
    /**
     * Function to toggle hide & show of side menu & header
    */
    toggle() { this.visible = !this.visible; }

    /**
     * Function on page leave
     * 
    */
    ngOnDestroy() {

    }

} 