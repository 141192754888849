/**
 * Eviction Pipeline View Details Functionality
 * Created By : #1116
 * Date : 5 Feb, 2021
 */

import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CommonService, MetaDataService } from 'src/providers/providers';

import * as _ from "lodash";

@Component( {
    selector: 'app-eviction-pipeline-details',
    templateUrl: './eviction-pipeline-details.component.html',
    styleUrls: ['./eviction-pipeline-details.component.scss']
} )
export class EvictionPipelineDetailsComponent implements OnInit {

    @Input() formData: any;
    public evictionStatusList: any = [];
    public isInitalSelect: boolean = true;

    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = ( this.currentYear + 99 );
    public minyear = ( this.currentYear - 99 );
    range: string = ( this.minyear ).toString() + ":" + ( this.maxyear ).toString();

    public evictionMaxDate = new Date();

    initalRange: string;
    minDateValue: any;
    minOtherDateValue: any;
    public evictionTypeList: any = [];

    constructor( private commonService: CommonService, public activeModal: NgbActiveModal,
        public metaDataService: MetaDataService ) {          
           
         }

    ngOnInit(): void {
        console.log( "EvictionPipelineDetailsComponent :: filterData :: ", this.formData.evictionFiled );        
        this.getEvictionStatusList();
         if(this.formData.evictionFiled){
            this.formData.evictionFiled = new Date( this.commonService.formatDate(this.formData.evictionFiled));
        }
        if(this.formData.initialCourtDate){
            this.formData.initialCourtDate = new Date(this.commonService.formatDate(this.formData.initialCourtDate));
        }
        if(this.formData.secondCourtDate){
            this.formData.secondCourtDate = new Date(this.commonService.formatDate(this.formData.secondCourtDate));
        }
        if(this.formData.otherCourtDate){
            this.formData.otherCourtDate = new Date(this.commonService.formatDate(this.formData.otherCourtDate));
        }
        if(this.formData.evictionStayDate){
            this.formData.evictionStayDate = new Date(this.commonService.formatDate(this.formData.evictionStayDate));
        }
        if(this.formData.sheriffOrdered){
            this.formData.sheriffOrdered = new Date(this.commonService.formatDate(this.formData.sheriffOrdered));
        }
        if(this.formData.possessionOfUnit){
            this.formData.possessionOfUnit = new Date(this.commonService.formatDate(this.formData.possessionOfUnit));
        }
        if(this.formData.coppDate){
            this.formData.coppDate = new Date(this.commonService.formatDate(this.formData.coppDate));
        }


        if(this.formData.initialCourtDate){
            this.isInitalSelect = false;
        }
        this.evictionTypeList = [
            { label: "Select", value: null },
            { label: "No Deals", value: "NO_DEALS" },
            { label: "Pay Balance", value: "PAY_BALANCE" },
            { label: "Payment Plan", value: "PAYMENT_PLAN" }
        ];
        
    }
    

    getInitalCourtDate =(date) => {
        console.log("getInitalCourtDate : event >>>", date);
        var initalCourtDate = new Date(date.getTime() + 24 * 60 * 60 * 1000);
        var initalCurrentYear = initalCourtDate.getFullYear();
        var initalMaxyear = ( initalCurrentYear + 99 );
        var initalMinyear = ( initalCurrentYear );
        this.minDateValue = initalCourtDate;
        var initalRange: string = ( initalMinyear ).toString() + ":" + ( initalMaxyear ).toString();
    }

    getSecondCourtDate = (date) => {
        console.log("getInitalCourtDate : event >>>", date);
        var secondCourtDate = new Date(date.getTime() + 24 * 60 * 60 * 1000);
        var secondCurrentYear = secondCourtDate.getFullYear();
        var secondMaxyear = ( secondCurrentYear + 99 );
        var secondMinyear = ( secondCurrentYear );
        this.minOtherDateValue = (secondCourtDate);
        var secondRange: string = ( secondMinyear ).toString() + ":" + ( secondMaxyear ).toString();
    }

    /**
      * function on cancel Click
      */
    cancelClick = () => {
        this.activeModal.dismiss( '' );
    }

    applyFilter = () => {
        this.activeModal.dismiss( this.formData );
    }

    /**
      * Function to get eviction status
      */
    private getEvictionStatusList = () => {
        this.evictionStatusList = [];
        this.metaDataService.getEvictionStatusList(
            ( response ) => {
                this.commonService.hideLoading();
                this.evictionStatusList.push( { label: "Select", value: "" } );

                response.map( status =>
                    this.evictionStatusList.push( status )
                );

                if ( !this.formData.evictionStatus ) {
                    this.formData.evictionStatus = this.evictionStatusList[0];
                }

                this.evictionStatusList = _.cloneDeep( this.evictionStatusList );
                this.commonService.hideLoading();
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.evictionStatusList = reason;
                this.commonService.hideLoading();
            } );
    }

    /** 
     * Function to get Ongoing Days 
     */
    getOngoingDays( dateSent ) {
        let currentDate = new Date();

        if ( dateSent ) {
            dateSent = new Date( dateSent );
            return Math.floor(( Date.UTC( currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() ) - Date.UTC( dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate() ) ) / ( 1000 * 60 * 60 * 24 ) );
        }
    }

}
