import { Injectable } from '@angular/core';
import { AppSettings, RestService } from '../providers';
import { map, catchError, mergeMap } from "rxjs/operators";
import { timer } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GoalsService {

  constructor(private restService: RestService) { }

   /**
     * Function for Create User
     * @param user data 
     */
    public updateGoals = (userData) => {
      const path = AppSettings.POST_GOALS_PERCENTAGE;
      return this.restService.postCall(path, userData, true, 60000)
         .map(function (res) {
            if (typeof res == 'string') {
               return JSON.parse(res);
            } else {
               return res;
            }
         })
         .catch(this.restService.handleError);
   }

   /**
     * Function for Create User
     * @param user data 
     */
    public goalsRentPercentage = () => {
      const path = AppSettings.GET_GOALS_PERCENTAGE;
      return this.restService.getCall( path, false, 60000 ).pipe(
         map(( res ) => {
             return res;
         } ),
         catchError(( e ) =>
             timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
         )
     );
      }
}
