<div class="row page-wrap">
    <div class="container-fluid">
        <div class="row m-0 pm-setting-panel-container">
            <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0" *ngIf="userData.roleType=='PROPERTY_MANAGER'">
                <p-panel class="custom-panel-ui">
                    <p-header class="panel-header">
                        <p class="panel-heading">Notifications</p>
                        <p-inputSwitch class="icons custom-switch-ui" (onChange)="setNotification($event, 'isNotificationON')" #notification [(ngModel)]="notificationPayload.notificationOn" binary="notificationPayload.notificationOn"></p-inputSwitch>
                    </p-header>
                    <hr class="panel-divider">
                    <div class="panel-content" [ngClass] = "isNotification?'set-enable':'set-disable'">
                        <p class="noti-txt">Notification Channel</p>
                        <div class="p-formgroup-inline">
                            <div class="p-field-checkbox">                             
                                <p-checkbox class="default-checkbox" name="mail" [(ngModel)]="notificationPayload.notificationEmailOn" #email (onChange)="setNotification($event, 'email')" binary="notificationPayload.notificationEmailOn"></p-checkbox>
                                <label for="city3" class="default-chk-lbl"> Email </label>
                            </div>
                            <div class="p-field-checkbox">
                                <p-checkbox class="default-checkbox" name="web" [(ngModel)]="notificationPayload.notificationWebNotificationOn" #web (onChange)="setNotification($event, 'web')" binary="notificationPayload.notificationWebNotificationOn"></p-checkbox>
                                <label for="city4" class="default-chk-lbl"> Web Notifications </label>
                            </div>
                        </div>
                    </div>

                </p-panel>
            </div> -->

            <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12" *ngIf="userData.roleType=='PROPERTY_MANAGER'">
                <p-panel class="custom-panel-ui">
                    <p-header class="panel-header">
                        <p class="panel-heading"> {{ "SETTING.NOTIFICATIONS"| translate }} </p>
                        <p-inputSwitch class="icons custom-switch-ui" (onChange)="setNotification($event, 'isNotificationON')" #notification [(ngModel)]="notificationPayload.notificationOn" binary="notificationPayload.notificationOn"></p-inputSwitch>
                    </p-header>
                    <hr class="panel-divider">
                    <div class="panel-content" [ngClass] = "isNotification?'set-enable':'set-disable'">
                        <p class="noti-txt"> {{ "SETTING.NOTIFICATION_CHANNEL"| translate }} </p>
                        <div class="p-formgroup-inline">                           
                            <div class="p-field-checkbox">
                                <p-checkbox class="default-checkbox" name="web" [(ngModel)]="notificationPayload.notificationWebNotificationOn" #web (onChange)="setNotification($event, 'web')" binary="notificationPayload.notificationWebNotificationOn"></p-checkbox>
                                <label for="city4" class="default-chk-lbl"> {{ "SETTING.WEB_NOTIFICATION"| translate }} </label>
                            </div>
                        </div>
                    </div>
                </p-panel>
            </div>


            <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12" *ngIf="userData.roleType=='PROPERTY_MANAGER'">
                <p-panel class="custom-panel-ui">
                    <p-header>
                        <p class="panel-heading"> {{ "SETTING.PASSWORD"| translate }} </p>
                    </p-header>
                    <hr class="panel-divider">
                    <div class="panel-content txt-center">
                        <button class="btn common-btn noti-btn" (click)="goToChangePassword()"> {{ "SETTING.CHANGE"| translate }} </button>
                    </div>
                </p-panel>
            </div>
            <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12" *ngIf="userData.roleType !='PROPERTY_MANAGER'">
                <p-panel class="custom-panel-ui">
                    <p-header>
                        <p class="panel-heading"> {{ "SETTING.PASSWORD"| translate }} </p>
                    </p-header>
                    <hr class="panel-divider">
                    <div class="panel-content txt-center">
                        <button class="btn common-btn noti-btn" (click)="goToChangePassword()"> {{ "SETTING.CHANGE"| translate }} </button>
                    </div>
                </p-panel>
            </div>
        </div>
    </div>
</div>