/**
 * AppSettings provider module
 * Contains Environment specific URL
 */

/*--------------------Angular related components---------------*/
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { environmentUat } from '../../environments/environment.uat';
import { environmentProd } from '../../environments/environment.prod';
import { Constants } from './app-constant.service';

@Injectable()
export class AppSettings {

    /******************** Base url ************************/

    public static get BASE_URL(): string {
        if ( Constants.CURRENT_ENVIRONMENT == 'production' ) {
            return 'http://rentdexprodserver-env.eba-gduckgsf.us-east-2.elasticbeanstalk.com/';
        } else if ( Constants.CURRENT_ENVIRONMENT == 'test' ) {
            return 'http://rentdexservertest-env.eba-jr9tytdd.us-east-2.elasticbeanstalk.com/'; //test url            
        } else if ( Constants.CURRENT_ENVIRONMENT == 'uat' ) {
            return ' http://rentdexuat-env.eba-j96gftmq.us-east-2.elasticbeanstalk.com/'; //uat url
        }
    }

    /******************** Build Version ************************/
    public static get buildVersion(): string {
        if ( Constants.CURRENT_ENVIRONMENT == 'production' ) {
            return environmentProd.buildVersion;
        } else if ( Constants.CURRENT_ENVIRONMENT == 'test' ) {
            console.log( "environment.test-buildVersion:::", environment.buildVersion );
            return environment.buildVersion;
        } else if ( Constants.CURRENT_ENVIRONMENT == 'uat' ) {
            console.log( "environment.uat-buildVersion:::", environmentUat.buildVersion );
            return environmentUat.buildVersion;
        }
    }

    /*---------------------- Login Module URL -------------------------------*/
    public static get LOGIN_OAUTH_URL(): string { return "oauth/token"; }
    public static get LOGOUT_URL(): string { return "api/v1/users/logout"; }

    /*---------------------- Password Module URL -------------------------------*/
    public static get FORGOT_PASSWORD_URL(): string { return "api/v1/otp/requestOtp"; }
    public static get VERIFY_OTP_URL(): string { return "api/v1/otp/verify"; }
    public static get RESEND_OTP_URL(): string { return "api/v1/otp/resendOtp"; }
    public static get RESET_PASSWORD_URL(): string { return "api/v1/reset/password"; }
    public static get CHANGE_PASSWORD_URL(): string { return "api/v1/user/changePassword"; }
    public static get UPDATE_NOTIFICATION_SETTING_URL(): string { return "api/v1/user/setting"; }
    public static get GET_NOTIFICATION_SETTING_URL(): string { return "api/v1/user/setting"; }
    public static get GET_NOTIFICATION_LIST_URL(): string { return "api/v1/users/notification/list"; }

    /*---------------------- User Profile Module URL ---------------------------*/
    public static get MY_PROFILE_UPDATE_URL(): string { return "api/v1/user/update"; }


    /*---------------------- User Module URL -------------------------------*/
    public static get ACTIVATE_USER_URL(): string { return "api/v1/user/activate"; }
    public static get CREATE_USER_URL(): string { return "api/v1/user/create"; }
    public static get DELETE_USER_URL(): string { return "api/v1/user/delete"; }
    public static get USER_LIST_URL(): string { return "api/v1/user/list"; }
    public static get USER_GRANT_ACCESS_URL(): string { return "api/v1/user/grantAccess"; }
    public static get USER_BLOCK_URL(): string { return "api/v1/user/block"; }
    public static get USER_UNBLOCK_URL(): string { return "api/v1/user/unblock"; }
    public static get LOGOUT_USER_URL(): string { return "api/v1/user/logout"; }
    public static get UPDATE_USER_URL(): string { return "api/v1/user/update"; }

    public static get UPDATE_MY_PROFILE_URL(): string { return "api/v1/user/create"; }

    /*---------------------- Admin Module URL -------------------------------*/
    public static get POST_GOALS_PERCENTAGE(): string { return "/api/v1/goal"; }
    public static get GET_GOALS_PERCENTAGE(): string { return "/api/v1/goal"; }
    public static get GET_ACTIVITIES(): string { return "api/v1/activities/list"; }
    public static get GET_PM_EA_NAME_LIST(): string { return "api/v1/user/PMEANamelist"; }
    public static get GET_PM_EA_NAME_ID_LIST(): string { return "api/v1/user/PMNameIdList"; }
    public static get ACTIVITIES_EXPORT_URL(): string { return "api/v1/activities/export"; }

    /*---------------------- Stats URL -------------------------------*/
    public static get RENEWAL_STATS_LIST_URL(): string { return "api/v1/renewal/stats"; }
    public static get DELINQUENCIES_STATS_LIST_URL(): string { return "api/v1/delinquency/stats"; }

    /*---------------------- Reports URL -------------------------------*/
    public static get PM_DELINQUENCY_LIST_URL(): string { return "api/v1/report/pmDelinquency"; }
    public static get PM_DELINQUENCY_EXPORT_URL(): string { return "api/v1/report/pmExport"; }
    public static get PM_STATS_LIST_URL(): string { return "api/v1/report/pmStats"; }
    public static get PM_STATS_EXPORT_URL(): string { return "api/v1/report/pmStatsExport"; }
    public static get CLIENT_DELINQUENCY_LIST_URL(): string { return "api/v1/report/clientDelinquency"; }
    public static get CLIENT_DELINQUENCY_EXPORT_URL(): string { return "api/v1/report/clientExport"; }

    
    /*---------------------- PM Module URL -------------------------------*/
    public static get RENEWAL_LIST_URL(): string { return "api/v1/renewals/list"; }
    public static get DELINQUENCIES_LIST_URL(): string { return "api/v1/delinquencies/list"; }
    public static get DELINQUENCIES_EXPORT_URL(): string { return "api/v1/delinquency/export"; }
    public static get RENEWAL_EXPORT_URL(): string { return "api/v1/renewal/export"; }
    public static get GOALS_DATA_URL(): string { return "api/v1/goal/userPercentage"; }
    public static get EVICTION_STATUS_LIST_URL(): string { return "api/v1/evictionstatus/list"; }
    public static get PM_STATUS_LIST_URL(): string { return "api/v1/pmstatus/list"; }
    public static get RENEWAL_STATUS_LIST_URL(): string { return "api/v1/renewalstatus/list"; }
    public static get OWNERS_NAME_LIST_URL(): string { return "api/v1/report/owner"; }
    public static get ACTION_ITEM_LIST_URL(): string { return "api/v1/actionitem/list"; }
    public static get UNIT_LIST_URL(): string { return "api/v1/unit/list"; }
    public static get LEADERBOARD_URL(): string { return "api/v1/trends/leaderBoard"; }
    public static get OWNERS_LEADERBOARD_URL(): string { return "api/v1/trends/owner/leaderBoard"; }
    public static get UPDATE_STATUS_NOTES_URL(): string { return "api/v1/createstatusandnotes"; }
    public static get UPDATE_RENEWALS_STATUS_NOTES_URL(): string { return "api/v1/renewal/createstatusandnotes"; }
    public static get DELINQUENCIES_TRACKER_URL(): string { return "api/v1/delinquency/tracker"; }
    public static get MANAGE_PROPERTY_GROUP_URL(): string { return "api/v1/trends/propertyGroup/list"; }
    public static get UPDATE_PROPERTY_GROUP_URL(): string { return "api/v1/trends/propertyGroup/update"; }
    public static get RENTDEX_RENEWAL_LIST_URL(): string { return "api/v1/rentdexRenewalStatus/list"; }
    public static get RENEWAL_TRACKER_URL(): string { return "api/v1/renewal/tracker"; }
    public static get RENT_ROLL_URL(): string { return "api/v1/unit/rentRoll"; }
    public static get RENT_ROLL_EXPORT_URL(): string { return "api/v1/rentRoll/export"; }

    public static get TENET_PROFILE_URL(): string { return "api/v1/tenant/details"; }
    public static get TENENT_ACTIVITY_URL(): string { return "api/v1/tenant/viewtenantactivities"; }
    public static get ADD_TENENT_ACTIVITY_URL(): string { return "api/v1/tenant/addtenantactivity"; }
    public static get UPDATE_TENANT_ACTIVITY_URL(): string { return "api/v1/tenant/updatetenantactivity" }
    public static get ACRONYM_LIST_URL(): string { return "api/v1/tenant/getAcronym"; }

    /*---------------------- Eviction Admin Module URL -------------------------------*/
    public static get POST_PIPELINE_URL(): string { return "api/v1/eviction/pipeline"; }
    public static get UPDATE_PIPELINE_DETAILS_URL(): string { return "api/v1/eviction/update"; }
    public static get GET_EVICTION_TYPE_URL(): string { return "api/v1/eviction/type/list"; }
    public static get PIPELINE_EXPORT_URL(): string { return "api/v1/evictionPipeline/export"; }
    
    public static get NOTIFICATION_URL(): string { return "api/v1/user/fcmToken"; }

}