<div class="row page-wrap">
    <div class="container-fluid">

        <div class="row m-0">
            <div class="dashboard-tab-wrap container-fluid p-0">
                <!-- Nav tabs -->
                <nav class="tabbable">
                    <ul class="nav nav-tabs full-width">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#tab-1" (click)="getPMDelinquencyList(); pmDelinquencySelected=true; pmStatsSelect=false; clientDelinquencySelect=false;"> {{ "REPORTS.PM_DELINQUENCY" | translate }} </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab-2" (click)="getPMStatsList(); pmStatsSelect=true; clientDelinquencySelect=false; pmDelinquencySelected=false;"> {{ "REPORTS.PM_STATS" | translate }} </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab-3" (click)="getClientDelinquencyList(); clientDelinquencySelect=true; pmStatsSelect=false; pmDelinquencySelected=false;"> {{ "REPORTS.CLIENT_DELINQUENCY" | translate }} </a>
                        </li>


                        <li class="search-wrap float-right" *ngIf="pmDelinquencySelected && !pmStatsSelect && !clientDelinquencySelect">

                            <p-dropdown class="form-dropdown small-dropdown" [options]="selectMonthStatus" name="monthSelection" [(ngModel)]="pmDelinquencyFilterPayload.selectedPMDelinquency" (onChange)="getPMDelinquencySelect($event, pmDelinquencyFilterPayload.selectedPMDelinquency)"></p-dropdown>

                            <button class="btn btn-icon-bg" (click)="clickPMDelinquencyOnFilter($event)"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>

                            <button class="btn btn-icon-bg" (click)="openPMDelinquencyExportPopup()"><i class="forward-icon filter-icon-cls" title="Export"></i></button>
                        </li>

                        <li class="search-wrap float-right" *ngIf="pmStatsSelect && !pmDelinquencySelected && !clientDelinquencySelect">

                            <p-dropdown class="form-dropdown small-dropdown" [options]="selectMonthStatus" name="monthSelection" [(ngModel)]="pmStatsFilterPayload.selectedPMStats" (onChange)="getPMStatsSelect($event, pmStatsFilterPayload.selectedPMStats)"></p-dropdown>

                            <button class="btn btn-icon-bg" (click)="clickPMStatsOnFilter($event)"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>

                            <button class="btn btn-icon-bg" (click)="openPMStatsExportPopup()"><i class="forward-icon filter-icon-cls" title="Export"></i></button>
                        </li>

                        <li class="search-wrap float-right" *ngIf="clientDelinquencySelect && !pmStatsSelect && !pmDelinquencySelected">

                            <p-dropdown class="form-dropdown small-dropdown" [options]="selectMonthStatus" name="monthSelection" [(ngModel)]="clientDelinquencyFilterPayload.selectedClientDelinquency" (onChange)="getClientDelinquencySelect($event, clientDelinquencyFilterPayload.selectedClientDelinquency)"></p-dropdown>

                            <button class="btn btn-icon-bg" (click)="clickClientDelinquencyOnFilter($event)"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>

                            <button class="btn btn-icon-bg" (click)="openClientDelinquencyExportPopup()"><i class="forward-icon filter-icon-cls" title="Export"></i></button>
                        </li>



                    </ul>
                </nav>

                <!----------PM Delinquency  ---------->
                <div class="tab-content full-width">
                    <div id="tab-1" class="container-fluid tab-pane active">
                        <div class="card clearfix">

                            <p-table class="custom-table-cls" #dt [value]="reportsList.list" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 320px)'" [responsive]="true">

                                <ng-template pTemplate="header">
                                    <tr>

                                        <th class="align-top" *ngIf="!isHistoricalStats"> {{ "REPORTS.PROPERTY_MANAGER" | translate }} </th>

                                        <th class="align-top" *ngIf="isHistoricalStats"> {{ "REPORTS.MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_UNIT" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_OCCUPIED_UNIT" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.OCCUPANCY_PERCENTAGE" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_RENT_ROLL" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_RESIDENT_PAID_RENT" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_SUBSIDIZED_PAID_RENT" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_RESIDENT_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_SUBSIDIZED_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_COLLECTED_FOR_MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.RESIDENT_PAID_COLLECTED_FOR_MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.MARKET_RESIDENT_PAID_COLLECTED_FOR_MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.CHA_RESIDENT_PAID_COLLECTED_FOR_MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.OTHER_SUBSIDIZED_RESIDENT_PAID_COLLECTED_FOR_MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.SUBSIDIZED_PAID_COLLECTED_FOR_MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.CHA_SUBSIDY_PAID_COLLECTED_FOR_MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.OTHER_SUBSIDY_PAID_COLLECTED_FOR_MONTH" | translate }} </th>

                                    </tr>

                                </ng-template>
                                <ng-template pTemplate="body" let-pmDelinquency>

                                    <tr>
                                        <td title="{{ pmDelinquency.name }}">{{ pmDelinquency.name}}</td>
                                        <td class="text-center" title="{{ pmDelinquency.totalUnit | number }}">{{ pmDelinquency.totalUnit | number }}</td>
                                        <td class="text-center" title="{{ pmDelinquency.occupiedUnit | number }}">{{ pmDelinquency.occupiedUnit | number }}</td>
                                        <td class="text-center" title="{{ pmDelinquency.occupiedPercentage | number : '1.2-2'}}%">{{ pmDelinquency.occupiedPercentage | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ pmDelinquency.totalRentRoll}}">{{ pmDelinquency.totalRentRoll | currency:'USD':'' :'1.2-2'}}</td>
                                        <td class="text-center" title="{{ pmDelinquency.totalTenantPaidRent}}">{{ pmDelinquency.totalTenantPaidRent | currency:'USD':'' :'1.2-2'}}</td>
                                        <td class="text-center" title="{{ pmDelinquency.totalSubsidizeRent }}">{{ pmDelinquency.totalSubsidizeRent | currency:'USD':'' :'1.2-2'}}</td>
                                        <td class="text-center" title="{{ pmDelinquency.totalRentCollected }}">{{ pmDelinquency.totalRentCollected | currency:'USD':'' :'1.2-2'}} </td>
                                        <td class="text-center" title="{{ pmDelinquency.tenantPaidRentCollected }} ">{{ pmDelinquency.tenantPaidRentCollected | currency:'USD':'' :'1.2-2'}} </td>
                                        <td class="text-center" title="{{ pmDelinquency.subsidizedPaidCollected  | number }} ">{{ pmDelinquency.subsidizedPaidCollected | currency:'USD':'' :'1.2-2' }} </td>
                                        <td class="text-center" title="{{ pmDelinquency.overallCollected | number : '1.2-2'}}%">{{ pmDelinquency.overallCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ pmDelinquency.tenantPaidCollected | number : '1.2-2'}}%">{{ pmDelinquency.tenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ pmDelinquency.marketTenantPaidCollected | number : '1.2-2'}}%">{{ pmDelinquency.marketTenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ pmDelinquency.chaTenantPaidCollected | number : '1.2-2'}}%">{{ pmDelinquency.chaTenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ pmDelinquency.otherSubsidizedTenantPaidCollected | number : '1.2-2'}}%">{{ pmDelinquency.otherSubsidizedTenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ pmDelinquency.subsidizedRentCollected | number : '1.2-2'}}%">{{ pmDelinquency.subsidizedRentCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ pmDelinquency.chaSubsidizedCollected | number : '1.2-2'}}%">{{ pmDelinquency.chaSubsidizedCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ pmDelinquency.otherSubsidyPaidCollected | number : '1.2-2'}}%">{{ pmDelinquency.otherSubsidyPaidCollected | number : '1.2-2'}}% </td>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr *ngIf="!isPMDelinquencyListNoData && isDelinquencyTableData && !isDataFilter">
                                        <td title="{{ 'REPORTS.TOTAL' | translate }}">{{ "REPORTS.TOTAL" | translate }} </td>
                                        <td class="text-center" title="{{ reportsList.totalUnit }}">{{ reportsList.totalUnit | number }} </td>
                                        <td class="text-center" title="{{ reportsList.occupiedUnit }}">{{ reportsList.occupiedUnit | number }} </td>
                                        <td class="text-center" title="{{ reportsList.occupiedPercentage | number : '1.2-2'}}%">{{ reportsList.occupiedPercentage | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ reportsList.totalRentRoll | currency:'USD':'' :'1.2-2'}}">{{ reportsList.totalRentRoll | currency:'USD':'' :'1.2-2' }} </td>
                                        <td class="text-center" title="{{ reportsList.totalTenantPaidRent | currency:'USD':'' :'1.2-2'}}">{{ reportsList.totalTenantPaidRent | currency:'USD':'' :'1.2-2' }} </td>
                                        <td class="text-center" title="{{ reportsList.totalSubsidizeRent | currency:'USD':'' :'1.2-2'}}">{{ reportsList.totalSubsidizeRent | currency:'USD':'' :'1.2-2' }} </td>
                                        <td class="text-center" title="{{ reportsList.totalRentCollected | currency:'USD':'' :'1.2-2'}}">{{ reportsList.totalRentCollected | currency:'USD':'' :'1.2-2' }} </td>
                                        <td class="text-center" title="{{ reportsList.tenantPaidRentCollected | currency:'USD':'' :'1.2-2'}} ">{{ reportsList.tenantPaidRentCollected | currency:'USD':'' :'1.2-2' }} </td>
                                        <td class="text-center" title="{{ reportsList.subsidizedPaidCollected  | currency:'USD':'' :'1.2-2'}} ">{{ reportsList.subsidizedPaidCollected | currency:'USD':'' :'1.2-2' }} </td>
                                        <td class="text-center" title="{{ reportsList.overallCollected | number : '1.2-2'}}%">{{ reportsList.overallCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ reportsList.tenantPaidCollected | number : '1.2-2'}}%">{{ reportsList.tenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ reportsList.marketTenantPaidCollected | number : '1.2-2'}}%">{{ reportsList.marketTenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ reportsList.chaTenantPaidCollected | number : '1.2-2'}}%">{{ reportsList.chaTenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ reportsList.otherSubsidizedTenantPaidCollected | number : '1.2-2'}}%">{{ reportsList.otherSubsidizedTenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ reportsList.subsidizedRentCollected | number : '1.2-2'}}%">{{ reportsList.subsidizedRentCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ reportsList.chaSubsidizedCollected | number : '1.2-2'}}%">{{ reportsList.chaSubsidizedCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{ reportsList.otherSubsidyPaidCollected | number : '1.2-2'}}%">{{ reportsList.otherSubsidyPaidCollected | number : '1.2-2'}}% </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                            <div class="control-label" *ngIf="isPMDelinquencyListNoData">
                                <div class="no-data-found" text-center>
                                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!----------PM Stats  ---------->
                    <div id="tab-2" class="container-fluid tab-pane">
                        <div class="card clearfix">
                            <p-table class="custom-table-cls" [value]="pmStatsList.list" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 320px)'" [responsive]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="align-top" *ngIf="!isPMStatsHistoricalStats"> {{ "REPORTS.PROPERTY_MANAGER" | translate }} </th>

                                        <th class="align-top" *ngIf="isPMStatsHistoricalStats"> {{ "REPORTS.MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_HASH_OF_RESIDENTS_UP_FOR_RENEWALS" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.HASH_OF_RESIDENTS_RENEWED_FOR_MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.RESIDENT_RETENTION_RATE" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.REPAYMENT_PLANS_CRAFTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.HASH_OF_RESIDENT_WITH_BALANCE_1_5x_RESIDENT_PAID" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.RESIDENT_IN_EVICTION_STATUS" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.RESIDENT_IN_EVICTION_OF_PAYMENT_PLAN" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_WORK_ORDERS" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_WO_SUBMITTED_BY_PM" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_WO_SUBMITTED_BY_RESIDENT" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_CANCELED_WO" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_CANCELED_WO_SUBMITTED_BY_PM" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_CANCELED_WO_SUBMITTED_BY_RESIDENT" | translate }} </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-pmStats>
                                    <tr>
                                        <td title="{{pmStats.name}}">{{pmStats.name}}</td>
                                        <td class="text-center" title="{{pmStats.totalTenantUpForRenewal }} ">{{pmStats.totalTenantUpForRenewal }} </td>
                                        <td class="text-center" title="{{pmStats.totalRenewedForMonth  }} ">{{pmStats.totalRenewedForMonth }} </td>
                                        <td class="text-center" title="{{pmStats.tenantRetantionRate }} ">{{pmStats.tenantRetantionRate }} </td>
                                        <td class="text-center" title="{{pmStats.repaymentPlanCrafted}}">{{pmStats.repaymentPlanCrafted}}</td>
                                        <td class="text-center" title="{{pmStats.tenantBalanceMoreThanOneHalf}}">{{pmStats.tenantBalanceMoreThanOneHalf}}</td>
                                        <td class="text-center" title="{{pmStats.tenenatInEviction  }} ">{{pmStats.tenenatInEviction }} </td>
                                        <td class="text-center" title="{{pmStats.tenantINEvictionPaymentPlan  }} ">{{pmStats.tenantINEvictionPaymentPlan }} </td>

                                        <td class="text-center" title="{{pmStats.totalWorkOrder }} ">{{pmStats.totalWorkOrder }} </td>
                                        <td class="text-center" title="{{pmStats.totalWorkOrderSubmitedByPM }} ">{{pmStats.totalWorkOrderSubmitedByPM }} </td>
                                        <td class="text-center" title="{{pmStats.totalWorkOrderSubmitedByTenant }} ">{{pmStats.totalWorkOrderSubmitedByTenant }} </td>
                                        <td class="text-center" title="{{pmStats.totalCanceledWo }} ">{{pmStats.totalCanceledWo }} </td>
                                        <td class="text-center" title="{{pmStats.totalCanceledWoSubmitedByPM  }} ">{{pmStats.totalCanceledWoSubmitedByPM }} </td>
                                        <td class="text-center" title="{{pmStats.totalCanceledWoSubmitedByTenant  }} ">{{pmStats.totalCanceledWoSubmitedByTenant }} </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr *ngIf="!ispmStatsListNoData && isStatsTableData && !isDataFilter">
                                        <td title="{{ 'REPORTS.TOTAL' | translate }}">{{ "REPORTS.TOTAL" | translate }}</td>
                                        <td class="text-center" title="{{pmStatsList.totalTenantUpForRenewal }} ">{{pmStatsList.totalTenantUpForRenewal | number }} </td>
                                        <td class="text-center" title="{{pmStatsList.totalRenewedForMonth  }} ">{{pmStatsList.totalRenewedForMonth | number }} </td>
                                        <td class="text-center" title="{{pmStatsList.tenantRetantionRate }} ">{{pmStatsList.tenantRetantionRate | number }} </td>
                                        <td class="text-center" title="{{pmStatsList.repaymentPlanCrafted}}">{{pmStatsList.repaymentPlanCrafted | number }}</td>
                                        <td class="text-center" title="{{pmStatsList.tenantBalanceMoreThanOneHalf}}">{{pmStatsList.tenantBalanceMoreThanOneHalf | number }}</td>
                                        <td class="text-center" title="{{pmStatsList.tenenatInEviction  }} ">{{pmStatsList.tenenatInEviction | number }} </td>
                                        <td class="text-center" title="{{pmStatsList.tenantINEvictionPaymentPlan  }} ">{{pmStatsList.tenantINEvictionPaymentPlan | number }} </td>

                                        <td class="text-center" title="{{pmStatsList.totalWorkOrder }} ">{{pmStatsList.totalWorkOrder }} </td>
                                        <td class="text-center" title="{{pmStatsList.totalWorkOrderSubmitedByPM }} ">{{pmStatsList.totalWorkOrderSubmitedByPM }} </td>
                                        <td class="text-center" title="{{pmStatsList.totalWorkOrderSubmitedByTenant }} ">{{pmStatsList.totalWorkOrderSubmitedByTenant }} </td>
                                        <td class="text-center" title="{{pmStatsList.totalCanceledWo }} ">{{pmStatsList.totalCanceledWo }} </td>
                                        <td class="text-center" title="{{pmStatsList.totalCanceledWoSubmitedByPM  }} ">{{pmStatsList.totalCanceledWoSubmitedByPM }} </td>
                                        <td class="text-center" title="{{pmStatsList.totalCanceledWoSubmitedByTenant  }} ">{{pmStatsList.totalCanceledWoSubmitedByTenant }} </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="ispmStatsListNoData">
                                <div class="no-data-found" text-center>
                                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!---------- Client Delinquency ---------->
                    <div id="tab-3" class="container-fluid tab-pane">
                        <div class="card clearfix">
                            <p-table class="custom-table-cls" [value]="clientList.list" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 320px)'" [responsive]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="align-top" *ngIf="!isClientHistoricalStats"> {{ "REPORTS.BUILDING_OWNER" | translate }} </th>

                                        <th class="align-top" *ngIf="isClientHistoricalStats"> {{ "REPORTS.MONTH" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_UNIT" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_OCCUPIED_UNIT" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.OCCUPANCY_PERCENTAGE" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_RENT_ROLL" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_RESIDENT_PAID_RENT" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_SUBSIDIZED_RENT" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.TOTAL_RENT_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.RESIDENT_PAID_RENT_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.SUBSIDIZED_RENT_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.OVERALL_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.RESIDENT_PAID_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.MARKET_TENANT_PAID_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.CHA_RESIDENT_PAID_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.OTHER_SUBSIDIZED_RESIDENT_PAID_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.SUBSIDIZED_PAID_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.CHA_SUBSIDY_PAID_COLLECTED" | translate }} </th>

                                        <th class="text-center align-top"> {{ "REPORTS.OTHER_SUBSIDY_PAID_COLLECTED" | translate }} </th>


                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-clientDelinquency>
                                    <tr>
                                        <td title="{{clientDelinquency.name }}">{{clientDelinquency.name}}</td>
                                        <td class="text-center" title="{{clientDelinquency.totalUnit }}">{{clientDelinquency.totalUnit | number }}</td>
                                        <td class="text-center" title="{{clientDelinquency.occupiedUnit }}">{{clientDelinquency.occupiedUnit | number }}</td>
                                        <td class="text-center" title="{{clientDelinquency.occupiedPercentage | number : '1.2-2'}}%">{{clientDelinquency.occupiedPercentage | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{clientDelinquency.totalRentRoll}}">{{clientDelinquency.totalRentRoll | currency:'USD':'' :'1.2-2'}}</td>
                                        <td class="text-center" title="{{clientDelinquency.totalTenantPaidRent}}">{{clientDelinquency.totalTenantPaidRent | currency:'USD':'' :'1.2-2'}}</td>
                                        <td class="text-center" title="{{clientDelinquency.totalSubsidizeRent }}">{{clientDelinquency.totalSubsidizeRent | currency:'USD':'' :'1.2-2'}}</td>
                                        <td class="text-center" title="{{clientDelinquency.totalRentCollected }}">{{clientDelinquency.totalRentCollected | currency:'USD':'' :'1.2-2'}} </td>
                                        <td class="text-center" title="{{clientDelinquency.tenantPaidRentCollected }} ">{{clientDelinquency.tenantPaidRentCollected | currency:'USD':'' :'1.2-2'}} </td>
                                        <td class="text-center" title="{{clientDelinquency.subsidizedPaidCollected | number : '1.2-2' }}%">{{clientDelinquency.subsidizedPaidCollected | number : '1.2-2' }}%</td>
                                        <td class="text-center" title="{{clientDelinquency.overallCollected | number : '1.2-2'}}%">{{clientDelinquency.overallCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{clientDelinquency.tenantPaidCollected | number : '1.2-2'}}%">{{clientDelinquency.tenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{clientDelinquency.marketTenantPaidCollected | number : '1.2-2'}}%">{{clientDelinquency.marketTenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{clientDelinquency.chaTenantPaidCollected | number : '1.2-2'}}%">{{clientDelinquency.chaTenantPaidCollected | number : '1.2-2'}}%</td>
                                        <td class="text-center" title="{{clientDelinquency.otherSubsidizedTenantPaidCollected | number : '1.2-2'}}%">{{clientDelinquency.otherSubsidizedTenantPaidCollected | number : '1.2-2'}}%</td>
                                        <td class="text-center" title="{{clientDelinquency.subsidizedRentCollected | number : '1.2-2'}}%">{{clientDelinquency.subsidizedRentCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{clientDelinquency.chaSubsidizedCollected | number : '1.2-2'}}%">{{clientDelinquency.chaSubsidizedCollected | number : '1.2-2'}}%</td>
                                        <td class="text-center" title="{{clientDelinquency.otherSubsidyPaidCollected | number : '1.2-2'}}%">{{clientDelinquency.otherSubsidyPaidCollected | number : '1.2-2'}}% </td>
                                    </tr>

                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr *ngIf="!isClientDelinquencyListNoData && isClientTableData && !isDataFilter">
                                        <td title="{{ 'REPORTS.TOTAL' | translate }}">{{ "REPORTS.TOTAL" | translate }}</td>
                                        <td class="text-center" title="{{clientList.totalUnit }}">{{clientList.totalUnit | number }}</td>
                                        <td class="text-center" title="{{clientList.occupiedUnit }}">{{clientList.occupiedUnit | number }}</td>
                                        <td class="text-center" title="{{clientList.occupiedPercentage | number : '1.2-2'}}%">{{clientList.occupiedPercentage | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{clientList.totalRentRoll | currency:'USD':'' :'1.2-2'}}">{{clientList.totalRentRoll | currency:'USD':'' :'1.2-2' }}</td>
                                        <td class="text-center" title="{{clientList.totalTenantPaidRent | currency:'USD':'' :'1.2-2'}}">{{clientList.totalTenantPaidRent | currency:'USD':'' :'1.2-2' }}</td>
                                        <td class="text-center" title="{{clientList.totalSubsidizeRent | currency:'USD':'' :'1.2-2'}}">{{clientList.totalSubsidizeRent | currency:'USD':'' :'1.2-2' }}</td>
                                        <td class="text-center" title="{{clientList.totalRentCollected | currency:'USD':'' :'1.2-2'}}">{{clientList.totalRentCollected | currency:'USD':'' :'1.2-2' }} </td>
                                        <td class="text-center" title="{{clientList.tenantPaidRentCollected | currency:'USD':'' :'1.2-2'}}">{{clientList.tenantPaidRentCollected | currency:'USD':'' :'1.2-2' }} </td>
                                        <td class="text-center" title="{{clientList.subsidizedPaidCollected | number : '1.2-2'}}%">{{clientList.subsidizedPaidCollected | number : '1.2-2' }}%</td>
                                        <td class="text-center" title="{{clientList.overallCollected | number : '1.2-2'}}%">{{clientList.overallCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{clientList.tenantPaidCollected | number : '1.2-2'}}%">{{clientList.tenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{clientList.marketTenantPaidCollected | number : '1.2-2'}}%">{{clientList.marketTenantPaidCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{clientList.chaTenantPaidCollected | number : '1.2-2'}}%">{{clientList.chaTenantPaidCollected | number : '1.2-2'}}%</td>
                                        <td class="text-center" title="{{clientList.otherSubsidizedTenantPaidCollected | number : '1.2-2'}}%">{{clientList.otherSubsidizedTenantPaidCollected | number : '1.2-2'}}%</td>
                                        <td class="text-center" title="{{clientList.subsidizedRentCollected | number : '1.2-2'}}%">{{clientList.subsidizedRentCollected | number : '1.2-2'}}% </td>
                                        <td class="text-center" title="{{clientList.chaSubsidizedCollected | number : '1.2-2'}}%">{{clientList.chaSubsidizedCollected | number : '1.2-2'}}%</td>
                                        <td class="text-center" title="{{clientList.otherSubsidyPaidCollected | number : '1.2-2'}}%">{{clientList.otherSubsidyPaidCollected | number : '1.2-2'}}% </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="isClientDelinquencyListNoData">
                                <div class="no-data-found" text-center>
                                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>