<!-- Container -->
<div class="form-container filter-form-cls">

  <div class="">

    <!-- Heading -->
    <div class="row filter-heading m-0">
      <div class="col-6">
        <h2 class="form-title"> {{"COMMON_COMPONENT.FILTER" | translate }} </h2>
      </div>
      <div class="col-6">
        <button class="btn link-btn clear-btn" (click)="clearFilter()"> {{"COMMON_COMPONENT.CLEAR_ALL" | translate }}
        </button>
      </div>
    </div>


    <div class="container custom-ver-tabs">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs tabs-left sideways" id="myTab" role="tablist" >
        <li class="nav-item active" [ngClass]="{active: tab == 1}" *ngIf="!this.filterPayload.isHistoricalStats"><a (click)=" tab=1;" class="nav-link active" href="#pm-v" data-toggle="tab" role="tab"> {{ "REPORTS.PROPERTY_MANAGER" | translate }} </a></li>
        
        <li class="nav-item" [ngClass]="{active: tab == 9}" *ngIf="this.filterPayload.isHistoricalStats"><a (click)=" tab=9;" class="nav-link active" href="#total-unit-active-v" data-toggle="tab" role="tab"> {{ "REPORTS.TOTAL_UNIT" | translate }} </a></li>
        
        <li class="nav-item" [ngClass]="{active: tab == 2}" *ngIf="!this.filterPayload.isHistoricalStats"><a (click)=" tab=2;" class="nav-link" href="#total-unit-v" data-toggle="tab" role="tab"> {{ "REPORTS.TOTAL_UNIT" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 3}"><a (click)=" tab=3;" class="nav-link" href="#total-occupied-unit-v" data-toggle="tab" role="tab"> {{ "REPORTS.TOTAL_OCCUPIED_UNIT" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 4}"><a (click)=" tab=4;" class="nav-link" href="#total-rent-roll-v" data-toggle="tab" role="tab"> {{ "REPORTS.TOTAL_RENT_ROLL_WITHOUT" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 5}"><a (click)=" tab=5;" class="nav-link" href="#total-resident-paid-rent-v" data-toggle="tab" role="tab"> {{ "REPORTS.TOTAL_RESIDENT_PAID_RENT_WITHOUT" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 6}"><a (click)=" tab=6;" class="nav-link" href="#total-subsidized-paid-rent-v" data-toggle="tab" role="tab"> {{ "REPORTS.TOTAL_SUBSIDIZED_PAID_RENT_WITHOUT" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 7}"><a (click)=" tab=7;" class="nav-link" href="#total-collected-v" data-toggle="tab" role="tab"> {{ "REPORTS.TOTAL_COLLECTED_WITHOUT" | translate }} </a> </li>
        <li class="nav-item" [ngClass]="{active: tab == 8}"><a (click)=" tab=8;" class="nav-link" href="#total-resident-collected-v" data-toggle="tab" role="tab">{{ "REPORTS.TOTAL_RESIDENT_COLLECTED_WITHOUT" | translate }} </a></li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div class="tab-pane" id="pm-v" [ngClass]="{active: !filterPayload.isHistoricalStats}" role="tabpanel">
          <!-- PM content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <p-dropdown class="form-dropdown" name="pmFilter" placeholder="Select" [options]="pmNameItemList"
                  [(ngModel)]="filterPayload.pmName"></p-dropdown>
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="total-unit-active-v" [ngClass]="{active: filterPayload.isHistoricalStats}" role="tabpanel">
          <!-- total unit content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalUnitFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalUnitTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="total-unit-v" role="tabpanel">
          <!-- total unit content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalUnitFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalUnitTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="total-occupied-unit-v" role="tabpanel">
          <!-- total occupied unit content...  -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalOccupiedUnitFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalOccupiedUnitTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="total-rent-roll-v" role="tabpanel">
          <!-- total rent roll content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalRentRollFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalRentRollTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="total-resident-paid-rent-v" role="tabpanel">
          <!-- total resident paid rent content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalResidentPaidRentFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalResidentPaidRentTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="total-subsidized-paid-rent-v" role="tabpanel">
          <!-- total subsidized paid rent content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalSubsidizedPaidRentFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalSubsidizedPaidRentTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="total-collected-v" role="tabpanel">
          <!-- total collected content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalCollectedFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalCollectedTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="total-resident-collected-v" role="tabpanel">
          <!-- total resident collected  content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalResidentCollectedFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalResidentCollectedTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

      </div>

      <div class="clearfix"></div>

    </div>

    <!--------------- modal btns  --------->
    <div class="row d-flex modal-footer m-0">
      <button type="button" class="btn link-btn" (click)="cancelClick()">
        {{"COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
      <button type="submit" class="btn common-btn" (click)="applyFilter()">
        {{"COMMON_COMPONENT.APPLY_BUTTON" | translate }} </button>
    </div>


  </div>
</div>