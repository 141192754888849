import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class SidebarService {
    isUpdate:EventEmitter<any> = new EventEmitter();
    visible: boolean 
    customHeader: boolean;

    constructor() { this.visible = false; this.customHeader = false; }

    /**
     * Function to hide side menu & header on login
    */
    hide() {
        console.log( "hide" );
        this.visible = false; this.customHeader = false;
        this.isUpdate.emit({visible:this.visible,customHeader:this.customHeader});
    }

    /**
     * Function to show side menu & header other components
    */
    show() {

        console.log( "show" );
        this.visible = true; this.customHeader = true;
        this.isUpdate.emit({visible:this.visible,customHeader:this.customHeader});
    }


    /**
     * Function to toggle hide & show of side menu & header
    */
    toggle() { this.visible = !this.visible; this.customHeader = !this.customHeader; }

    /**
     * Function on page leave
     * 
    */
    ngOnDestroy() {

    }
}