import { Component, OnInit, ViewEncapsulation, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, LocalStorageService, MetaDataService } from 'src/providers/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { DeviceDetectorService } from 'ngx-device-detector';
@Component( {
    selector: 'app-view-renewal',
    templateUrl: './view-renewal.component.html',
    styleUrls: ['./view-renewal.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class ViewRenewalComponent implements OnInit {

    public pageName: string;
    public firstButton: string;
    public secondButton: string;
    public optionList: any;

    @Input() formData: any;
    public filterPayload: any = {
        subsidyFrom: 0,
        subsidyTo: 0,
        tenantAmountFrom: 0,
        tenantAmountTo: 0,
        totalAmountFrom: 0,
        totalAmountTo: 0,
        rentFrom: 0,
        rentTo: 0

    }

    public userData: any;
    public renewalStatusList: any = [];
    public evictionStatusList: any = [];
    public isMobile :boolean = false;
    deviceInfo = null;
    public isCheckRenewalStatus: boolean;

    constructor( private router: Router, private commonService: CommonService, public activeModal: NgbActiveModal,
        public metaDataService: MetaDataService, 
        public localStr: LocalStorageService,
        private deviceService: DeviceDetectorService ) {
        
            this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        let pageName = 'Filter';
        let firstButton = 'Cancel';
        let secondButton = 'Apply';
    }

    ngOnInit(): void {
        this.userData = this.localStr.getObj( 'userData' );
        console.log( "filterData", this.formData );
        this.formData.renewableNotes = "";
        if(this.formData.rentdexRenewalStatus == "EVICTION" || this.formData.rentdexRenewalStatus == "eviction"){
            this.isCheckRenewalStatus = true;
        } else {
            this.isCheckRenewalStatus = false;
        }
        if (this.userData.roleType == 'EVICTION_ADMIN') {
            this.getEvictionStatusList();
        } else {
            this.getRenewalStatusList();
        }
    }

    /**
     * Function on cancel Click
     */
    cancelClick = () => {
        this.activeModal.dismiss( '' );
    }

    /**
     * Function on cancel Click
     */
    applyFilter = () => {
        this.activeModal.dismiss( this.filterPayload );
    }

    /**
     * Function to get Renewal status
     */
    private getRenewalStatusList = () => {
        this.renewalStatusList = [];

        this.metaDataService.getCustomRenewalStatusList(
            ( response ) => {

                this.renewalStatusList.push( { label: "Select", value: "" } );
                response.map( status =>
                    this.renewalStatusList.push( status )
                );

                if ( !this.filterPayload.rentdexRenewalStatus ) {
                    this.filterPayload.rentdexRenewalStatus = this.renewalStatusList[0];
                }
                this.renewalStatusList = _.cloneDeep( this.renewalStatusList );
                this.commonService.hideLoading();
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.renewalStatusList = reason;
            } );
    }

    /**
     * Function to get PM status
     */
    private getEvictionStatusList = () => {
        this.evictionStatusList = [];

        this.metaDataService.getEvictionStatusList(
            ( response ) => {
                this.evictionStatusList.push( { label: "Select", value: "" } );
                response.map( status =>
                    this.evictionStatusList.push( status )
                );

                if ( !this.formData.evictionStatus ) {
                    this.formData.evictionStatus = this.evictionStatusList[0];
                }
                this.evictionStatusList = _.cloneDeep( this.evictionStatusList );
                this.commonService.hideLoading();
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.evictionStatusList = reason;
            } );
    }

}
