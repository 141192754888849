<div class="close-sidebar" (click)="closeSidebar()"></div>
<div class="d-flex bg-img-wrapper" id="wrapper" *ngIf="sidebar?.visible" [ngClass]="{'hidden': hideSideNav }">
    <div id="sidebar-wrapper" *ngIf="!isMobile">
        <div class="sidebar-heading"><img class="app-sidebar-logo" src="assets/images/rentdex-icon.svg"> </div>
        <div *ngIf="userData?.roleType=='PROPERTY_MANAGER'" class="list-group list-group-flush">
            <a class="list-group-item" [routerLink]="'/property-manager-dashboard'" routerLinkActive="activated"><i class="dashboard-icon"></i> {{ "SIDE_BAR.DASHBOARD" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/trends'" routerLinkActive="activated"><i class="trends-icon"></i> {{ "SIDE_BAR.TRENDS" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/delinquencies'" routerLinkActive="activated"><i class="delinquencies-icon"></i> {{ "SIDE_BAR.DELINQUENCIES" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/renewals'" routerLinkActive="activated"><i class="renewals-icon"></i> {{ "SIDE_BAR.RENEWALS" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/rent-roll'" routerLinkActive="activated"><i class="rentroll-icon"></i> {{ "SIDE_BAR.RENT_ROLL" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/stats'" routerLinkActive="activated"><i class="trends-icon"></i> {{ "SIDE_BAR.STATS" | translate }} </a>
        </div>

        <div *ngIf="userData?.roleType=='SUPER_ADMIN'" class="list-group list-group-flush">
            <a class="list-group-item" [routerLink]="'/admin-dashboard'" routerLinkActive="activated"><i class="user-management-icon"></i> {{ "SIDE_BAR.USER_MANAGMENT" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/trends'" routerLinkActive="activated"><i class="trends-icon"></i> {{ "SIDE_BAR.TRENDS" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/activities'" routerLinkActive="activated"><i class="activity-icon"></i> {{ "SIDE_BAR.ACTIVITIES" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/reports'" routerLinkActive="activated"><i class="reports-icon"></i> {{ "SIDE_BAR.REPORTS" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/goals'" routerLinkActive="activated" ><i class="goals-icon"></i> {{ "SIDE_BAR.GOALS" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/stats'" routerLinkActive="activated"><i class="trends-icon"></i> {{ "SIDE_BAR.STATS" | translate }} </a>
        </div>

        <div *ngIf="userData?.roleType=='SUB_ADMIN'" class="list-group list-group-flush">            
            <a class="list-group-item" [routerLink]="'/admin-dashboard'" routerLinkActive="activated" *ngIf="isUserManagment"><i class="user-management-icon"></i> {{ "SIDE_BAR.USER_MANAGMENT" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/trends'" routerLinkActive="activated"><i class="trends-icon"></i> {{ "SIDE_BAR.TRENDS" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/activities'" routerLinkActive="activated" *ngIf="isActivity"><i class="activity-icon"></i> {{ "SIDE_BAR.ACTIVITIES" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/reports'" routerLinkActive="activated" *ngIf="isreport"><i class="reports-icon"></i> {{ "SIDE_BAR.REPORTS" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/goals'" routerLinkActive="activated" *ngIf="isGoals"><i class="goals-icon"></i> {{ "SIDE_BAR.GOALS" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/stats'" routerLinkActive="activated" *ngIf="isStats"><i class="trends-icon"></i> {{ "SIDE_BAR.STATS" | translate }} </a>
            <a class="list-group-item" [routerLink]="'/eviction-pipeline'" routerLinkActive="activated"><i class="e-dashboard-icon"></i> {{ "SIDE_BAR.EVICTION_PIPELINE" | translate }} </a>
        </div>

        <div *ngIf="userData?.roleType=='EVICTION_ADMIN'" class="list-group list-group-flush">
            <a class="list-group-item" [routerLink]="'/eviction-pipeline'" routerLinkActive="activated"><i class="e-dashboard-icon"></i> {{ "SIDE_BAR.EVICTION_PIPELINE" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/trends'" routerLinkActive="activated"><i class="trends-icon"></i> {{ "SIDE_BAR.TRENDS" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/delinquencies'" routerLinkActive="activated"><i class="delinquencies-icon"></i> {{ "SIDE_BAR.DELINQUENCIES" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/renewals'" routerLinkActive="activated"><i class="e-renewals-icon"></i> {{ "SIDE_BAR.RENEWALS" | translate }} </a>
            <a class="list-group-item pointer" [routerLink]="'/rent-roll'" routerLinkActive="activated"><i class="rentroll-icon"></i> {{ "SIDE_BAR.RENT_ROLL" | translate }} </a>
        </div>
    </div>
    <div id="sidebar-wrapper" class="mobile-view-sidebar" *ngIf="isMobile">
        <div  class="mobileOnly">
            <div class="nav-link user-info">
                <span class="username-text">{{userData?.firstName}} {{userData?.lastName}} <br />
                <span class="role-text">{{userData?.roleType | replace : '_' : ' ' | titlecase}}</span></span>
            </div>
        </div>
        <div *ngIf="userData?.roleType=='PROPERTY_MANAGER'" class="list-group list-group-flush">
            <a class="list-group-item pointer" [routerLink]="'/property-manager-dashboard'" [ngClass]="{'activated': step==='step1'}" (click)="step='step1'"><i class="dashboard-grey-icon"></i> {{ "SIDE_BAR.DASHBOARD" | translate }}</a>
            <a class="list-group-item pointer" [routerLink]="'/turn-list'"  [ngClass]="{'activated': step==='step2'}" (click)="step='step2';"><i class="move-out-icon"></i> {{ "SIDE_BAR.MOVE_OUT" | translate }}</a>
            <!-- <a class="list-group-item pointer" (click)="logoutClick();" routerLinkActive="activated" [routerLinkActiveOptions]="{exact: true}"><i class="logout-icon"></i> {{ "HEADER.LOGOUT" | translate }} </a> -->
            <a class="list-group-item pointer" [ngClass]="{'activated': step==='step3'}" (click)="step='step3'; logoutClick();"><i class="logout-icon"></i> {{ "HEADER.LOGOUT" | translate }} </a>
            
        </div>
           <div class="list-group list-group-flush">
        </div>
    </div>
    <!-- <p-toast styleClass="toast-card" position="top-center"></p-toast> -->
</div>