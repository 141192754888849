import { EventEmitter, Injectable } from '@angular/core';
/*--------------------- npm Providers---------------------------*/
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonModalComponent } from 'src/pages/common-modal/common-modal.component';

/*------------------Pages---------------------*/
import { MessageService } from 'primeng/api';

import * as _ from "lodash";
import { Router } from '@angular/router';
import { Broadcaster, LocalStorageService } from '../providers';

@Injectable()
export class CommonService {
    public showLoader: EventEmitter<any> = new EventEmitter();
    constructor( public toastMessage: MessageService, private spinner: NgxSpinnerService, private modalService: NgbModal,
        private router: Router,
        private broadcaster: Broadcaster,
        private locstr: LocalStorageService ) {

    }
    
    /**
     * Function to hide/show password
     * @param: inputType
     * @param: showPassword
     * @param: callback
     */
    hideShowPassword = ( inputType: string, showPassword: boolean, callback: any ) => {
        if ( inputType == 'password' ) {
            showPassword = true;
            inputType = 'text';
            callback( showPassword, inputType );
        }
        else {
            showPassword = false;
            inputType = 'password';
            callback( showPassword, inputType );
        }
    };

    /**
     * Function to handle toast
     * 
     */
    clearToast() {
        this.toastMessage.clear();
    }
    
    showWarning( message ) {
        this.toastMessage.add( { key: 'warn1', severity: 'warn', summary: '', detail: message } );
    }
    
    showSuccess( message ) {
        this.toastMessage.add( { key: 'success1', severity: 'success', summary: '', detail: message } );
    }

    showInfo( message ) {
        this.toastMessage.add( { key: 'info1', severity: 'info', summary: '', detail: message } );
    }

    showError( message ) {
        this.toastMessage.add( { key: 'error1', severity: 'error', summary: '', detail: message } );
    }
    
    /**
     * Function to show loader
     * 
     */
    showLoading = () => {
        console.log( "loader show" );
        //this.visible = false;
        this.showLoader.emit( { showLoader: true } );
        // setTimeout(() => {
        //     this.spinner.show("please wait");
        // }, 100); 
    };

    /**
     * Function to show loader
     * 
     */
    hideLoading = () => {
        // this.spinner.hide();
        console.log( "loader hide" );

        this.showLoader.emit( { showLoader: false } );
    };


    /**
     * Function to show confirm pop-up
     * @param titleText - Header text
     * @param messageText - Message to show
     * @param cancelText - Cancel button text
     * @param okText - Ok button text
     * @param callBack - Ok button callBack 
     */
    showConfirm( titleText: string, messageText: string, cancelText: string, actionText: string, cancelCallBack: any, callBack: any, crossCallBack: any ) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open( CommonModalComponent, ngbModalOptions );
        modalRef.componentInstance.title = titleText;
        modalRef.componentInstance.message = messageText;
        modalRef.componentInstance.firstButton = cancelText;
        modalRef.componentInstance.secondButton = actionText;
        modalRef.componentInstance.okCall = callBack;
        modalRef.componentInstance.cancelCall = cancelCallBack;
        modalRef.componentInstance.crossBtnCall = crossCallBack;
        // modalRef.result.then(
        //     (data: any) => {
        //         return true
        //     //   this.processData(data);
        //     },
        //     (reason: any) => { 
        //         return false
        //     }
        //   );
    }

    /**
     * Function to open modal filter modal
     * @param callBack - Ok button callBack 
     */
    openConfirmModal( component: any, formData: any, callback: any ) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open( component, ngbModalOptions );
        modalRef.componentInstance.formData = formData;
        modalRef.componentInstance.filterPayload = formData;
        modalRef.componentInstance.callback = callback;
        modalRef.result.then(
            ( data: any ) => {
                callback( data )
            },
            ( reason: any ) => {
                callback( reason )
            }
        );
    }

    /** 
     * Function to make a deep copy of an object
     * @param object to be copied
     */
    public copy = ( obj ) => {
        if ( obj != null ) {
            return JSON.parse( JSON.stringify( obj ) );
        } else {
            return null;
        }
    };

 /** 
     * Function to change the date format to support in all browsers
     * date string len is 28 we add : at 26 index
     * @param date incoming string
     */
    formatDate(date){
        return date.substr(0, 26) + ":" + date.substr(26);
    }

    public checkIsLoggedInUser = () => {
        var currUrl = this.locstr.getObj('currUrl');
        this.router.navigate([currUrl]);
        }
	
}   