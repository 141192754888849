<div class="row page-wrap">
    <div class="container-fluid">

        <div class="row m-0">
            <div class="dashboard-tab-wrap container-fluid p-0">
                <!-- Nav tabs -->
                <nav class="tabbable">
                    <ul class="nav nav-tabs full-width">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#tab-1" (click)="getLeaderBoardList(); isLeaderBoard=true; clearFilters(dt)"> {{ "TREND.PM_LEADERBOARD" | translate }} </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab-4" (click)="getOwnerLeaderBoardList(); isLeaderBoard=true; clearFilters(dt1)"> {{ "TREND.OWNERS_LEADERBOARD" | translate }} </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab-2" (click)="isLeaderBoard=false; isDelinquencyTracker = true; setTrackerOptions(isDelinquencyTracker);"> {{ "TREND.DELINQUENCY_TRACKER" | translate }} </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab-3" (click)="isLeaderBoard=false; isDelinquencyTracker = false; setTrackerOptions(isDelinquencyTracker);"> {{ "TREND.RENEWAL_TRACKER" | translate }} </a>
                        </li>

                        <li class="search-wrap float-right" *ngIf="isLeaderBoard">
                            <div class="form-group has-search common-search-box">
                                <span class="form-control-feedback"><i class="search-icon search-icon-cls"></i></span>
                                <input pInputText type="text" id="search-bar" *ngIf="isPmLeaderBoard" class="form-control" placeholder="Search" (input)="dt.filterGlobal($event.target.value, 'contains')">
                                <input pInputText type="text" id="search-bar" *ngIf="!isPmLeaderBoard" class="form-control" placeholder="Search" (input)="dt1.filterGlobal($event.target.value, 'contains')">
                            </div>

                            <div class="form-group has-search inline-items mar-btm10">
                                <span><i class="calendar-icon filter-icon-cls nan-cursor" title="Month Selection"></i></span>
                                <p-dropdown class="form-dropdown small-dropdown" *ngIf="isPmLeaderBoard" [options]="monthList" name="monthSelection" [(ngModel)]="leaderBoardPayload.selectedMonthObject" (onChange)="getPMLeaderBoardSelectedMonth($event, leaderBoardPayload.selectedMonthObject)"></p-dropdown>

                                <p-dropdown class="form-dropdown small-dropdown" *ngIf="!isPmLeaderBoard" [options]="monthList" name="monthSelection" [(ngModel)]="ownerLeaderBoardPayload.selectedMonthObject" (onChange)="getOwnerLeaderBoardSelectedMonth($event, ownerLeaderBoardPayload.selectedMonthObject)"></p-dropdown>

                            </div>
                            <button class="btn btn-icon-bg" *ngIf="!isPmLeaderBoard" (click)="clickOnFilter($event)"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>

                        </li>

                    </ul>
                </nav>

                <!---------- Owner's Leaderboard ---------->
                <div class="tab-content full-width">
                    <div id="tab-1" class="container-fluid tab-pane active">
                        <div class="card clearfix">

                            <p-table class="custom-table-cls" #dt [value]="leaderboardList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 300px)'" [responsive]="true" [globalFilterFields]="['name', 'totalCollection', 'tenantCollection', 'subsidyCollection', 'renewalsPercent' ]"
                                (onFilter)="handleFilter($event, true)">

                                <ng-template pTemplate="header">
                                    <tr>

                                        <th> {{ "TREND.PM_NAME" | translate }} </th>

                                        <th> {{ "TREND.TOTAL_RENT_COLLECTED" | translate }} </th>

                                        <th> {{ "TREND.TENANT_COLLECTION" | translate }} </th>

                                        <th> {{ "TREND.SUBSIDIZED_COLLECTION" | translate }} </th>

                                        <th> {{ "TREND.RENEWAL" | translate }} </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-pmLeaderBoard>

                                    <tr>
                                        <!-- <td>
                                        <p-tableCheckbox [value]="product"></p-tableCheckbox>
                                    </td> -->
                                        <td title="{{pmLeaderBoard.name}}">{{pmLeaderBoard.name? pmLeaderBoard.name: '-'}}</td>
                                        <td title="{{pmLeaderBoard.totalCollection  | number : '1.2-2'}}%">{{pmLeaderBoard.totalCollection | number : '1.2-2'}}%</td>
                                        <td title="{{pmLeaderBoard.tenantCollection  | number : '1.2-2'}}%">{{pmLeaderBoard.tenantCollection | number : '1.2-2'}}%</td>
                                        <td title="{{pmLeaderBoard.subsidyCollection | number : '1.2-2'}}%">{{pmLeaderBoard.subsidyCollection | number : '1.2-2'}}%</td>
                                        <td title="{{pmLeaderBoard.renewalsPercent ? (pmLeaderBoard.renewalsPercent | number : '1.2-2') : '0'}}%">{{pmLeaderBoard.renewals}}/{{pmLeaderBoard.totalRenewals}} &nbsp; ({{pmLeaderBoard.renewalsPercent ? (pmLeaderBoard.renewalsPercent | number : '1.2-2') : '0'}}%)</td>

                                    </tr>

                                </ng-template>
                            </p-table>

                            <div class="control-label" *ngIf="isLeaderboardListNoData">
                                <div class="no-data-found" text-center>
                                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!---------- Owner's Leaderboard ---------->
                    <div id="tab-4" class="container-fluid tab-pane">
                        <div class="card clearfix">
                            <p-table class="custom-table-cls" #dt1 [value]="ownerLeaderboardList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 300px)'" [responsive]="true" [globalFilterFields]="['name', 'totalCollection', 'tenantCollection', 'subsidyCollection', 'renewalsPercent' ]"
                                (onFilter)="handleFilter($event, false)">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th> {{ "TREND.OWNERS_NAME" | translate }} </th>
                                        <th> {{ "TREND.TOTAL_RENT_COLLECTED" | translate }} </th>
                                        <th> {{ "TREND.TENANT_COLLECTION" | translate }} </th>
                                        <th> {{ "TREND.SUBSIDIZED_COLLECTION" | translate }} </th>
                                        <th> {{ "TREND.RENEWAL" | translate }} </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-ownerLeaderboard>
                                    <tr>
                                        <td title="{{ownerLeaderboard.name}}">{{ownerLeaderboard.name? ownerLeaderboard.name: '-'}}</td>
                                        <td title="{{ownerLeaderboard.totalCollection  | number : '1.2-2'}}%">{{ownerLeaderboard.totalCollection | number : '1.2-2'}}%</td>
                                        <td title="{{ownerLeaderboard.tenantCollection  | number : '1.2-2'}}%">{{ownerLeaderboard.tenantCollection | number : '1.2-2'}}%</td>
                                        <td title="{{ownerLeaderboard.subsidyCollection | number : '1.2-2'}}%">{{ownerLeaderboard.subsidyCollection | number : '1.2-2'}}%</td>
                                        <td title="{{ownerLeaderboard.renewalsPercent ? (ownerLeaderboard.renewalsPercent | number : '1.2-2') : '0'}}%">{{ownerLeaderboard.renewals}}/{{ownerLeaderboard.totalRenewals}} &nbsp; ({{ownerLeaderboard.renewalsPercent ? (ownerLeaderboard.renewalsPercent | number : '1.2-2') : '0'}}%)</td>
                                    </tr>

                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="isOwnerLeaderboardListNoData">
                                <div class="no-data-found" text-center>
                                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!---------- Delinquency Tracker ---------->
                    <div id="tab-2" class="container-fluid tab-pane graph-container">
                        <div class="container-fluid mar-top15">

                            <div class="row graph-head m-0 d-flex">
                                <div class="col-sm-5 p-0">
                                    <h3 class="trend-title"> {{ "TREND.HISTORICAL_STATS" | translate }} </h3>
                                </div>
                                <div class="col-sm-7 ml-auto pr-0">
                                    <div class="form-inline trend-selections float-right">
                                        <div class="inline-items mar-btm10">
                                            <span><i class="calendar-icon filter-icon-cls nan-cursor" title="Month Selection"></i></span>
                                            <p-dropdown class="form-dropdown small-dropdown" [options]="monthList" name="monthSelection" [(ngModel)]="delinquencyTrackerPayload.selectedMonthObject" (onChange)="getSelectedMonth($event, delinquencyTrackerPayload.selectedMonthObject, isDelinquencyTracker)"></p-dropdown>
                                        </div>

                                        <div class="inline-items mar-btm10">
                                            <span><i class="filter-icon filter-icon-cls nan-cursor" title="Filter"></i> </span>
                                            <p-dropdown class="form-dropdown small-dropdown adjustSpan" [options]="pmStatusList" name="filerSelection" [(ngModel)]="delinquencyTrackerPayload.selectedFilterObject" (onChange)="getSelectedStatus($event, isDelinquencyTracker)"></p-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row graph-head">
                                <div class="col-sm-12">

                                    <div id="delinquency-tracker"></div>


                                </div>
                            </div>

                        </div>

                    </div>

                    <!---------- Renewal Tracker ---------->
                    <div id="tab-3" class="container-fluid tab-pane graph-container">
                        <div class="container-fluid mar-top15">

                            <div class="row graph-head m-0 d-flex">
                                <div class="col-sm-5 p-0">
                                    <h3 class="trend-title"> {{ "TREND.HISTORICAL_STATS" | translate }} </h3>
                                </div>
                                <div class="col-sm-7 ml-auto pr-0">
                                    <div class="form-inline trend-selections float-right">
                                        <div class="inline-items mar-btm10">
                                            <span><i class="calendar-icon filter-icon-cls nan-cursor" title="Month Selection"></i></span>
                                            <p-dropdown class="form-dropdown small-dropdown" [options]="monthList" name="monthSelection" [(ngModel)]="renewalTrackerPayload.selectedMonthObject" (onChange)="getSelectedMonth($event, renewalTrackerPayload.selectedMonthObject, isDelinquencyTracker)"></p-dropdown>
                                        </div>

                                        <div class="inline-items mar-btm10">
                                            <span><i class="filter-icon filter-icon-cls nan-cursor" title="Filter"></i> </span>
                                            <p-dropdown class="form-dropdown small-dropdown adjustSpan" [options]="renewalStatusList" name="filerSelection" [(ngModel)]="renewalTrackerPayload.selectedFilterObject" (onChange)="getSelectedStatus($event, isDelinquencyTracker)"></p-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row graph-head">
                                <div class="col-sm-12">

                                    <div id="renewal-tracker"></div>


                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>