import { Component, ViewChild, OnInit } from '@angular/core';
import { LocalStorageService } from '../providers/local-storage-service/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { SidebarComponent } from 'src/pages/sidebar/sidebar.component';
import { CommonService } from 'src/providers/providers';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(SidebarComponent) sideMenu: SidebarComponent;
  title = 'rentdex-angular';
  public innerHeight: any;
  public innerWidth: any;
  browserLang: any;
  public showLoader: boolean = false
  public isCollapsed: boolean = false;
  public isMobile: boolean = false;
  deviceInfo = null;
  public pageWrappWidth: any;
  public sidebarWidth: any;
  public isSubscribedCheck: boolean = false;

  constructor(public locstr: LocalStorageService, public translate: TranslateService, private commonService: CommonService,
    private deviceService: DeviceDetectorService, private router: Router) {

    this.deviceInfo = this.deviceService.getDeviceInfo();
    console.log('this.deviceInfo ==>', this.deviceInfo);
    this.isMobile = this.deviceService.isMobile();
    console.log('app component called')
    //calculate window height
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    this.locstr.setObj('windowHeight', this.innerHeight);
    this.locstr.setObj('windowWidth', this.innerWidth);
    this.commonService.showLoader.subscribe(
      (data) => {
        console.log("loader", data.showLoader);

        this.showLoader = data.showLoader;
      }
    );
    this.initTranslate();

  }

  /**
   * Function to Set the default language for translation strings, and the current language.
  */
  initTranslate() {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit() {

    //Toggle Click Function
    /*$("#menu-toggle").click(function(e) {
    e.preventDefault();
    $("#wrapper").toggleClass("toggled");
    });*/
    this.pageWrappWidth = document.getElementById('page-content-wrapper').offsetWidth;
    this.sidebarWidth = document.getElementById('sidebar-wrapper').offsetWidth;

    
    this.locstr.setObj("deviceTokenId", "")

    var that = this;
    var OneSignal = window['OneSignal'] || [];
    console.log(" OneSignalInit OneSignal", OneSignal);
    OneSignal.push(["init", {
      appId: "52070791-7e19-44da-aecd-4d4cfb46a844",
      autoRegister: false,
      allowLocalhostAsSecureOrigin: true,
      // notificationClickHandlerMatch: 'origin',
      // notificationClickHandlerOrigin:'origin',
      notificationClickHandlerMatch: 'exact', 
      notificationClickHandlerAction: 'navigate',
      notifyButton: {
        enable: false
      }
    }]);
    console.log('OneSignal Initialized');
    OneSignal.push(function () {
      console.log('OneSignal Register For Push');
      OneSignal.push(["registerForPushNotifications"])
    });
    OneSignal.push(function () {
      var isSubscribedCheck;
      console.log("OneSignal The user's subscription state is before:", OneSignal);
      // Occurs when the user's subscription changes to a new value.
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        console.log("OneSignal The user's subscription state is now:", isSubscribed);
        isSubscribedCheck = isSubscribed;
        
        OneSignal.getUserId().then(function (userId) {
          console.log("OneSignal User ID is 1", userId);
          that.locstr.setObj("deviceTokenId",userId)
        });  
      });
    });

    OneSignal.push(function () {
    OneSignal.getUserId().then(function (userId) {
      console.log("OneSignal User ID is 2 ", userId);
      that.locstr.setObj("deviceTokenId",userId)
    });    
  });

  OneSignal.push(["addListenerForNotificationOpened", function(event) {
    console.log("OneSignal notification clicked: 1 ", event);
    that.router.navigate(['/notifications']);
  }]);
  

  }

  //Toggle Click Function
  toggleMenu(data) {
    console.log("toggle", data);

    if (data) {
      this.sideMenu.toggleMenu()
    }
    // $( "#wrapper" ).toggleClass( "toggled" );
  }

  toggleNavbar() {
    this.isCollapsed = !this.isCollapsed;
  }


}
