import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from "@angular/core";

import { DashboardFilterComponent } from 'src/pages/property-manager-module/dashboard-filter/dashboard-filter.component';
import { AppService } from './../../../app/app.service';

import {
    CommonService,
    HeaderService,
    PropertyManagerService,
    SidebarService,
    HighchartServiceService,
    LocalStorageService,
    Constants
} from "src/providers/providers";
import { LazyLoadEvent } from 'primeng/api';
import { ExportPopupComponent } from 'src/pages/export-popup/export-popup.component';
import { ViewDelinquencyComponent } from "./../view-delinquency/view-delinquency.component";
import { Router } from "@angular/router";
import { Paginator } from "primeng/paginator";
import { AppComponent } from "src/app/app.component";

@Component( {
    selector: "delinquency-list",
    templateUrl: "./delinquencies.component.html",
    styleUrls: ["./delinquencies.component.scss"],
    encapsulation: ViewEncapsulation.None
} )
export class DelinquencyComponent implements OnInit {
    @Input()
    loading: boolean = true;

    @ViewChild('p', {static: false}) paginator: Paginator;
    filterData: any = {};

    public isSearchCall: boolean = false
    pageNumber: number = 0;

    delinquenciesDefaultPage = 0;
    delinquenciesDefaultSize = 25;

    // Today's table data
    public deliquenciesTenantList: any = [];
    isDeliquenciesNoData: boolean = false;

    public isDeliquence: boolean = true;
    public isDeliquenceList: boolean = true;

    public deliquenciesPayload: any = {
        lastActionTaken: "",
        evictionStatus: "",

        pmStatus: "",
        subsidyFrom: 0,
        subsidyTo: 0,
        searchText: "",
        tenantAmountFrom: 0,
        tenantAmountTo: 0,
        totalAmountFrom: 0,
        totalAmountTo: 0,
        unitId: "",
        propertyId: "",
        pageNumber: 0,
        pageSize: 25,
        sortBy: "",
        sortManner: ""
    };
    public userData: any;

    constructor(
        public header: HeaderService,
        public sidebar: SidebarService,
        protected pmService: PropertyManagerService,
        protected commonService: CommonService,
        private highchartServiceService: HighchartServiceService,
        private appService: AppService,
        public localStr: LocalStorageService,
        private router: Router,
        public constants: Constants
    ) {
        this.deliquenciesPayload.pageSize = this.constants.defaultPageSize
     }

    ngOnInit(): void {
        //Get Delequency list data
        this.getDelinquenciesList(null);

        var isSliderShow = this.localStr.getObj("isSliderShow");
        if(isSliderShow){
            this.sidebar.show();
            this.localStr.setObj("isSliderShow", true);
        } else {
            this.sidebar.hide();
            this.localStr.setObj("isSliderShow", false);
        }     
        this.header.show();

        this.localStr.setObj("breadcrum", "");
        this.appService.setTitle( 'Delinquencies' );
        this.userData = this.localStr.getObj( 'userData' );
    }




    /**
     * Function for Delinquencies list pagination
     */
    public delinquenciespaginate = ( event ) => {
        let currentPage: number;
        this.delinquenciesDefaultPage = event.first / event.rows;
        this.delinquenciesDefaultSize = event.rows;
        this.deliquenciesTenantList = [];
        this.deliquenciesPayload.pageSize = this.constants.defaultPageSize;        
        this.deliquenciesPayload.pageNumber = this.delinquenciesDefaultPage;
        this.getDelinquenciesList(null);
    }

    /**
     * Function to get deliquencies list
     */
    public getDelinquenciesList($event) {
        
        if(this.deliquenciesPayload.sortBy == "" || this.deliquenciesPayload.sortManner == ""){
            this.deliquenciesPayload.sortBy = "";
            this.deliquenciesPayload.sortManner = "";
        }

        // check data after visiting activity log
        if(this.localStr.getObj("beforeActivityData")){
            this.deliquenciesPayload = this.localStr.getObj("beforeActivityData");
        }

        if ( !this.isSearchCall ) {
            this.commonService.showLoading();  // remove this when we use inplace loader
        }else if(this.isSearchCall){
            this.deliquenciesPayload.pageNumber= 0;
        }
        this.loading = true;

        this.pmService.getDelinquenciesList( this.deliquenciesPayload ).subscribe(
            ( res ) => {
                if ( !this.isSearchCall ) {
                    this.commonService.hideLoading();
                }else if(this.isSearchCall){
                    this.paginator.changePageToFirst($event);
                }
                this.isSearchCall = false
                if ( res.code == 200 || res.status == 200 ) {
                    this.deliquenciesTenantList = res.data.list;
                    if ( this.deliquenciesTenantList && this.deliquenciesTenantList.length == 0 ) {
                        this.isDeliquenciesNoData = false;
                    } else {
                        // Added condition for page 1  - show no data found
                        if ( this.deliquenciesPayload.pageNumber == 0 ) {
                            this.isDeliquenciesNoData = true;
                        }
                    }

                    this.deliquenciesPayload.totalRecords = res.data.totalCount;
                    this.localStr.setObj("beforeActivityData", "");
                } else {
                    this.deliquenciesTenantList = [];
                }

                this.loading = false;
            },
            ( error ) => {
                if ( !this.isSearchCall ) {
                    this.commonService.hideLoading();
                }
                this.isSearchCall = false
                this.deliquenciesTenantList = [];
                this.isDeliquenciesNoData = true;
                if ( error && error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else if ( error && error.error_description ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }            
                this.loading = false;
            }
        );
        this.commonService.hideLoading();
    }

    /**
     * Function to  deliquencies export
     */
    public exportDelinquencies( reportFormat ) {
        this.commonService.showLoading();

        this.pmService.exportDelenquencies( this.deliquenciesPayload, reportFormat, this.userData.id ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "res", res );
                    // window.open( res.data )
                    window.location.assign (res.data);
                    //this.commonService.clearToast();
                    //this.commonService.showSuccess(res.message);
                }

            },
            ( error ) => {
                this.commonService.hideLoading();

                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }



    public loadData = ( event ) => {
        this.pageNumber = this.pageNumber + 1;
        this.deliquenciesPayload.page = this.pageNumber;
        this.getDelinquenciesList(null);
    }

    /**
     * Function coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo( "Coming soon" );
    }

    checkTypeOf = (dataCheck) => {
        if (typeof dataCheck == 'object'){
            return dataCheck = "";
        } else {
            return dataCheck;
        }
    }

    /**
     * Function for open fiter popup
     */
    public clickOnFilter = ($event) => {
        this.filterData = this.deliquenciesPayload
        this.filterData.isDeliquence = this.isDeliquence;
        this.filterData.isDeliquenceList = this.isDeliquenceList;

        this.commonService.openConfirmModal( DashboardFilterComponent, this.filterData, ( data ) => {
            console.log( "filtrdata", data );

            this.deliquenciesPayload.pmStatus = this.checkTypeOf(this.deliquenciesPayload.pmStatus);
            this.deliquenciesPayload.evictionStatus = this.checkTypeOf(this.deliquenciesPayload.evictionStatus);
            this.deliquenciesPayload.lastActionTaken = this.checkTypeOf(this.deliquenciesPayload.lastActionTaken);

            if ( data ) {
                if ( this.isDeliquence && this.isDeliquenceList ) {
                    this.deliquenciesPayload.evictionStatus = data.evictionStatus;
                    this.deliquenciesPayload.lastActionTaken = data.lastActionTaken;
                    this.deliquenciesPayload.pmStatus = data.pmStatus;
                    this.deliquenciesPayload.tenantAmountFrom = data.tenantAmountFrom;
                    this.deliquenciesPayload.tenantAmountTo = data.tenantAmountTo;
                    this.deliquenciesPayload.totalAmountFrom = data.totalAmountFrom;
                    this.deliquenciesPayload.totalAmountTo = data.totalAmountTo;
                    this.deliquenciesPayload.unitId = data.unitId;
                    this.deliquenciesPayload.subsidyFrom = data.subsidyFrom;
                    this.deliquenciesPayload.subsidyTo = data.subsidyTo;
                    this.deliquenciesPayload.pageNumber = 0;
                    this.paginator.changePageToFirst($event);
                    this.getDelinquenciesList(null)
                }
            }

        } );
    }

    customSort( data: LazyLoadEvent, type ) {
        console.log( "sort", data );
        if ( type == "DELINQUENCY" && data ) {
            switch ( data.sortField ) {
                case "name":
                    this.deliquenciesPayload.sortBy = "NAME";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;
                case "unit":
                this.deliquenciesPayload.sortBy = "UNIT";
                switch ( data.sortOrder ) {
                    case 1:
                        this.deliquenciesPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.deliquenciesPayload.sortManner = "ASC";
                        break;
                    default:
                        this.deliquenciesPayload.sortManner = "";
                        break;

                }
                break;
                case "amountReceivable":
                    this.deliquenciesPayload.sortBy = "TOTALBALANCE";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;
                case "tenantBalance":
                    this.deliquenciesPayload.sortBy = "TENANTBALANCE";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;
                case "actionItem":
                    this.deliquenciesPayload.sortBy = "ACTIONTAKEN";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;
                case "delinquentSubsidyAmount":
                    this.deliquenciesPayload.sortBy = "SUBSIDY";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;
                default:
                    this.deliquenciesPayload.sortBy = "";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;
            }

            this.getDelinquenciesList(null);
        }

    }


    /**
     *  Function for Export dialog 
     */
    openExportPopup() {
        this.commonService.openConfirmModal( ExportPopupComponent, this.filterData, ( data ) => {
            console.log( "log..data", this.filterData, data );
            if ( data.format ) {

                if ( this.isDeliquence ) {

                    this.exportDelinquencies( data.format );
                }
            }
        } );
    }

    /**
     * Function for open fiter popup
     */
    public openDelinquencyDetails = ( deliquenciesTenant ) => {        
        deliquenciesTenant.isDeliquenceList = this.isDeliquenceList
        console.log("deliquenciesTenant::>>>.", deliquenciesTenant);
        
        let requestPayLoad;
        this.commonService.openConfirmModal( ViewDelinquencyComponent, this.commonService.copy( deliquenciesTenant ), ( data ) => {
            
            if ( data ) {
                if(this.userData.roleType == 'EVICTION_ADMIN'){
                    requestPayLoad = {
                        "delinquencyNotes": data.delinquencyNotes,
                        "id": data.id,
                        "evictionStatus": data.evictionStatus,
                        "actionItem": data.lastActionTaken
                    }
                    console.log("requestPayLoad::>>>.", requestPayLoad);

                    if((deliquenciesTenant.evictionStatus == requestPayLoad.evictionStatus) && (deliquenciesTenant.mostRecentNotes == requestPayLoad.delinquencyNotes)){
                        console.log("No channge");                   
                     } else {
                         this.updateStatus( requestPayLoad );
                     }

                    // if((deliquenciesTenant.evictionStatus !="" && requestPayLoad.evictionStatus == "") || (deliquenciesTenant.lastActionTaken !="" && requestPayLoad.actionItem == "")){
                    //    console.log("No channge");                   
                    // } else {
                    //     this.updateStatus( requestPayLoad );
                    // } 
                } else {                    
                requestPayLoad = {
                    "delinquencyNotes": data.delinquencyNotes,
                    "id": data.id,
                    "pmStatus": data.pmStatus,
                    "actionItem": data.lastActionTaken
                }
                console.log("requestPayLoad::>>>.", requestPayLoad);

                if((deliquenciesTenant.pmStatus == requestPayLoad.pmStatus) && (deliquenciesTenant.lastActionTaken == requestPayLoad.actionItem) && (deliquenciesTenant.mostRecentNotes == requestPayLoad.delinquencyNotes)){
                    console.log("No channge");                   
                 } else {
                     this.updateStatus( requestPayLoad );
                 }

                // if((deliquenciesTenant.pmStatus !="" && requestPayLoad.pmStatus == "") || (deliquenciesTenant.lastActionTaken !="" && requestPayLoad.actionItem == "")){
                //    console.log("No channge");                   
                // } else {
                //     this.updateStatus( requestPayLoad );
                // }            

                //if ( requestPayLoad.pmStatus || requestPayLoad.delinquencyNotes || requestPayLoad.lastActionTaken ) {
                   
                //}
            }
        }
        } );
    }

    /** 
     * Function to update the Delinquency Data
     * Update PM status, Notes and Action items
     * */
    public updateStatus = ( requestPayLoad ) => {
        if ( requestPayLoad ) {
            this.commonService.showLoading();

            requestPayLoad.pmStatus = this.checkTypeOf(requestPayLoad.pmStatus);
            requestPayLoad.actionItem = this.checkTypeOf(requestPayLoad.actionItem);
    

            this.pmService.updateStatusNotes( requestPayLoad ).subscribe(
                ( res ) => {
                    this.commonService.hideLoading();
                    if ( res.code == 200 || res.status == 200 ) {
                        this.commonService.clearToast();
                        this.commonService.showSuccess( "Record updated successfully." );
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError( res.message );
                    }
                    this.getDelinquenciesList(null);
                },
                ( error ) => {
                    this.commonService.hideLoading();
                    if ( error.message ) {
                        this.commonService.clearToast();
                        this.commonService.showError( error.message );
                    }
                }
            );
        }
    }

    /** 
     * Function to open Tenant Details 
     */
    public openTenantDetails = ( tenantProfile ) => {
        console.log("this.deliquenciesPayload ....", this.deliquenciesPayload);
        this.localStr.setObj("beforeActivityData",this.deliquenciesPayload);

        this.localStr.setObj("breadcrum", 'Delinquencies');

        this.router.navigate( ['/resident-profile'], tenantProfile );
    }
}
