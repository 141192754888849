import { Component, OnInit, ViewEncapsulation, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, MetaDataService, LocalStorageService } from 'src/providers/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
@Component( {
    selector: 'app-activity-log',
    templateUrl: './tenant-activity-log.component.html',
    styleUrls: ['./tenant-activity-log.component.scss'],

    encapsulation: ViewEncapsulation.None
} )
export class ActivityLogComponent implements OnInit {

    public pageName: string;
    public firstButton: string;
    public secondButton: string;
    public actionItemList: any = [];
    public minDateValue:Date = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate());
    public notes: string;
    @Input() filterPayload: any;
    public userData;

    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = ( this.currentYear + 99 );
    public minyear = ( this.currentYear - 99 );
    range: string = ( this.minyear ).toString() + ":" + ( this.maxyear ).toString();
    
    constructor(private router: Router, 
        private commonService: CommonService, public activeModal: NgbActiveModal, public metaDataService: MetaDataService,
        private locstr: LocalStorageService) {
        let pageName = 'Filter';
        let firstButton = 'Cancel';
        let secondButton = 'Apply';
    }

    ngOnInit(): void {
        console.log( "pop-up Data :: ", this.filterPayload );
       if(this.filterPayload.followUpDate != null){
            this.filterPayload.followUpDate = this.commonService.formatDate(this.filterPayload.followUpDate);
            this.filterPayload.followUpDate = new Date(this.filterPayload.followUpDate);
        }

        if(this.filterPayload.promiseToPay != null){
		this.filterPayload.promiseToPay = this.commonService.formatDate(this.filterPayload.promiseToPay);																								 
        this.filterPayload.promiseToPay = new Date(this.filterPayload.promiseToPay);
        }     
        this.getActionItemList();

        this.userData = this.locstr.getObj('userData');
    }
/**
      * Function to get action item
      */
     private getActionItemList = () => {
        this.actionItemList = [];
        this.metaDataService.getActionItemList(
            ( response ) => {
                this.commonService.hideLoading();
                this.actionItemList.push( { label: "Select", value: "" } );
                response.map( status =>
                    this.actionItemList.push( status )
                );
                this.actionItemList = _.cloneDeep( this.actionItemList );
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.actionItemList = reason;
            } );
    }

    /**
     * function on cancel Click
     */
    cancelClick = () => {
        this.activeModal.dismiss( '' );
    }
   
    /**
     * function on cancel Click
     */
    applyFilter = () => {
        this.activeModal.dismiss( this.filterPayload );
    }
}
