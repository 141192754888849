/**
 * Trends Functionality
 * Created By : #1116
 * Date : 23 December, 2020
 */

import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import * as HighCharts from 'highcharts';

import {
    CommonService,
    HeaderService,
    TrendsService,
    SidebarService,
    HighchartServiceService,
    MetaDataService,
    Constants,
    LocalStorageService
} from "src/providers/providers";

import { AppService } from './../../../app/app.service';
import { ExportPopupComponent } from 'src/pages/export-popup/export-popup.component';
import { ReplacePipe } from "src/providers/common-service/replace-pipe";
import { TitleCasePipe } from "@angular/common";
import { propertyGroupFilterComponent } from "./property-group-filter/property-group-filter.component";

@Component( {
    selector: "app-trends",
    templateUrl: "./trends.component.html",
    styleUrls: ["./trends.component.scss"],
    encapsulation: ViewEncapsulation.None
} )
export class TrendsComponent implements OnInit {

    public leaderboardList: any = [];
    public ownerLeaderboardList: any = [];
    isLeaderboardListNoData: boolean = false;
    isOwnerLeaderboardListNoData: boolean = false;
    leaderBoardDefaultPage = 0;
    leaderBoardDefaultSize = 2;
    public isLeaderBoard: boolean = true;
    public isPmLeaderBoard: boolean = true;
    public preData: any;

    public leaderBoardPayload: any = {
        pageNumber: 0,
        pageSize: 0,
        month: '',
        selectedMonthObject: ''
    };

    public ownerLeaderBoardPayload: any = {
        pageNumber: 0,
        pageSize: 0,
        month: '',
        selectedMonthObject: ''
    };

    //Delinquency Tracker Data
    public delinquencyTrackerPayload: any = {
        month: '',
        filter: '',
        pageNumber: 0,
        pageSize: 0,
        selectedFilterObject: '',
        selectedMonthObject: ''
    };
    public pmStatusList: any = [];
    public deliquencySeriesTrackerData = {
        'currentMonthData': [],
        'selectedMonthData': [],
        'currentMonthLegend': '',
        'selectedMonthLegend': ''
    };
    public deliquencyTrackerData: any = {};

    public monthList = [];

    filterData: any = {};

    //Renewal Tracker Data
    public renewalStatusList: any = [];
    public renewalTrackerPayload: any = {
        month: '',
        filter: '',
        pageNumber: 0,
        pageSize: 0,
        selectedFilterObject: '',
        selectedMonthObject: ''
    };
    public renewalSeriesTrackerData = {
        'currentMonthData': [],
        'selectedMonthData': [],
        'currentMonthLegend': '',
        'selectedMonthLegend': ''
    };
    public renewalTrackerData: any = {};

    isDelinquencyTracker: boolean = false;
    dt:string = '';
    dt1:string = '';

    constructor(
        private replaceChar: ReplacePipe, private toTitleCase: TitleCasePipe,
        public header: HeaderService,
        public sidebar: SidebarService,
        protected commonService: CommonService,
        private appService: AppService,
        private trendsService: TrendsService,
        private highchartServiceService: HighchartServiceService,
        public metaDataService: MetaDataService,
        public constants: Constants,
        public localStr: LocalStorageService
    ) {


    }

    ngOnInit(): void {
        //Get last months for PM leaderboard
        this.monthList = this.trendsService.getLastMonthsName( 5 , true);
        
        this.leaderBoardPayload.month = this.monthList[0]["value"];
        this.ownerLeaderBoardPayload.month = this.monthList[0]["value"];

        this.getLeaderBoardList();
        var isSliderShow = this.localStr.getObj("isSliderShow");
        if(isSliderShow){
            this.sidebar.show();
            this.localStr.setObj("isSliderShow", true);
        } else {
            this.sidebar.hide();
            this.localStr.setObj("isSliderShow", false);
        }
        this.header.show();
        this.appService.setTitle( 'Trends' );

        //Get PM Status
        this.getPMStatusList();

        //Get Renewal Status
        this.getRenewalStatusList();
    }

    public clickOnFilter = (event) => {
        console.log("event ...>>>", event);
            this.commonService.openConfirmModal( propertyGroupFilterComponent, this.filterData, ( data ) => {
                this.filterData = data;
                if ( data.propertyGroupName != this.preData ) {
                    this.preData = data.propertyGroupName;
                    console.log("Data >>>>>", data);
                    this.trendsService.updatePropertyGroupList( data ).subscribe(
                        ( res ) => {
                            this.commonService.hideLoading();
                            if ( res.code == 200 || res.status == 200 ) {
                                console.log( "create user :: res :: ", res );
                                this.commonService.clearToast();
                                this.commonService.showSuccess( res.message);
                                
                            }
                        },
                        ( error ) => {
                            this.commonService.hideLoading();
                            console.log( "create user: ", error );
                            if ( error.message ) {
                                this.commonService.clearToast();
                                this.commonService.showError(error.message);
                            } else {
                                this.commonService.clearToast();
                                this.commonService.showError(error.error_description);
                            }
                        }
                    );                    
                   
                }

            } );

    }

    /**
      * Function to get PM LeaderBoard list
      */
    public getLeaderBoardList() {
        //Get last months for PM leaderboard
        this.isPmLeaderBoard = true;
        this.dt = '';
        this.monthList = this.trendsService.getLastMonthsName( 5, true );

        this.commonService.showLoading();

        this.trendsService.getLeaderBoardList( this.leaderBoardPayload ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    this.leaderboardList = res.data.list;

                    this.leaderboardList.sort(( a, b ) => b.totalCollection - a.totalCollection );

                    for (let i = 0; i < this.leaderboardList.length; i++) {
                        this.leaderboardList[i].renewalsPercent = (this.leaderboardList[i].renewals/this.leaderboardList[i].totalRenewals)*100;
                        console.log("this.leaderboardList[i].renewalsPercent...>>>", this.leaderboardList[i].renewalsPercent);
                        
                    }

                    if ( this.leaderboardList && this.leaderboardList.length == 0 ) {
                        this.isLeaderboardListNoData = false;
                    } else {
                        // Added condition for page 1  - show no data found
                        if ( this.leaderboardList.pageNumber == 0 ) {
                            this.isLeaderboardListNoData = true;
                        }
                    }

                    this.leaderboardList.totalRecords = res.data.totalCount;

                } else {
                    this.leaderboardList = [];
                }

            },
            ( error ) => {
                this.commonService.hideLoading();
                this.leaderboardList = [];
                this.isLeaderboardListNoData = true;
                if ( error && error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else if ( error && error.error_description ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    /**
      * Function to get owner's LeaderBoard list
      */
     public getOwnerLeaderBoardList() {
        //Get last months for owner's leaderboard
        this.isPmLeaderBoard = false;
        this.dt1 = '';
        this.monthList = this.trendsService.getLastMonthsName( 5, true );
        this.commonService.showLoading();
        this.trendsService.getOwnerLeaderBoardList( this.ownerLeaderBoardPayload ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    this.ownerLeaderboardList = res.data.list;
                    this.ownerLeaderboardList.sort(( a, b ) => b.totalCollection - a.totalCollection );
                    for (let i = 0; i < this.ownerLeaderboardList.length; i++) {
                        this.ownerLeaderboardList[i].renewalsPercent = (this.ownerLeaderboardList[i].renewals/this.ownerLeaderboardList[i].totalRenewals)*100;
                        console.log("this.ownerLeaderboardList[i].renewalsPercent...>>>", this.ownerLeaderboardList[i].renewalsPercent);
                    }
                    if ( this.ownerLeaderboardList && this.ownerLeaderboardList.length != 0 ) {
                        this.isOwnerLeaderboardListNoData = false;
                    } else {
                        // Added condition for page 1  - show no data found
                        if ( this.ownerLeaderboardList.length == 0 ) {
                            this.isOwnerLeaderboardListNoData = true;
                        }
                    }
                    this.ownerLeaderboardList.totalRecords = res.data.totalCount;
                } else {
                    this.ownerLeaderboardList = [];
                }
            },
            ( error ) => {
                this.commonService.hideLoading();
                this.ownerLeaderboardList = [];
                this.isOwnerLeaderboardListNoData = true;
                if ( error && error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else if ( error && error.error_description ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    /**
     * Function for Leader Board list pagination
     */
    public LeaderBoardpaginate = ( event ) => {
        let currentPage: number;
        this.leaderBoardDefaultPage = event.first / event.rows;
        this.leaderBoardDefaultSize = event.rows;
        this.leaderboardList = [];
        this.leaderBoardPayload.pageSize = this.constants.defaultPageSize;
        this.leaderBoardPayload.pageNumber = this.leaderBoardDefaultPage;
        this.getLeaderBoardList();
    }

    /**
     *  Function for Export dialog 
     */
    openTrendExportPopup() {
        this.commonService.openConfirmModal( ExportPopupComponent, this.filterData, () => {
        } );
    }

    public getSelectedStatus( eve, isDelinquencyTracker ) {
        if ( isDelinquencyTracker ) {
            this.getDelinquencyTrackerData();
            this.delinquencyTrackerPayload.filter = eve.value;
        } else {
            this.renewalTrackerPayload.filter = eve.value;
            this.getRenewalTrackerData();
        }
    }

    public setTrackerOptions = ( isDelinquencyTracker ) => {
        //Get last 6 months list for month dropdown
        this.monthList = this.trendsService.getLastMonthsName( 6, false );

        //Delinquency Tracker Data
        this.delinquencyTrackerPayload.selectedMonthObject = this.monthList[0];
        this.delinquencyTrackerPayload.month = this.monthList[0]["value"];

        //Renewal Tracker Data
        this.renewalTrackerPayload.selectedMonthObject = this.monthList[0];
        this.renewalTrackerPayload.month = this.monthList[0]["value"];

        if ( isDelinquencyTracker ) {
            this.getDelinquencyTrackerData();

            //Reset Renewal status dropdown
            this.renewalTrackerPayload.selectedFilterObject = this.renewalStatusList[0]["value"];
            this.renewalTrackerPayload.selectedMonthObject = this.monthList[0];
        } else {
            this.getRenewalTrackerData();

            //Reset PM status dropdown
            this.delinquencyTrackerPayload.selectedFilterObject = this.pmStatusList[0]["value"];
            this.delinquencyTrackerPayload.selectedMonthObject = this.monthList[0];
        }
    }

    public getSelectedMonth( eve, month, isDelinquencyTracker ) {
        if ( isDelinquencyTracker ) {
            this.delinquencyTrackerPayload.month = eve.value;
            this.getDelinquencyTrackerData();
        } else {
            this.renewalTrackerPayload.month = eve.value;
            this.getRenewalTrackerData();
        }
    }

    /**
     * Function to get data for PM LeaderBoard for selected month
     * @param eve 
     * @param month 
     */
    public getPMLeaderBoardSelectedMonth( eve, month ) {
        this.leaderBoardPayload.month = eve.value;
        this.getLeaderBoardList();
    }

    /**
     * Function to get data for PM LeaderBoard for selected month
     * @param eve 
     * @param month 
     */
    public getOwnerLeaderBoardSelectedMonth( eve, month ) {
        this.ownerLeaderBoardPayload.month = eve.value;
        this.getOwnerLeaderBoardList();
    }

    /**
      * Function to get PM Delinquency Tracker Data
      */
    public getDelinquencyTrackerData() {
        this.commonService.showLoading();

        this.deliquencySeriesTrackerData.currentMonthData = [];
        this.deliquencySeriesTrackerData.selectedMonthData = [];

        if ( this.delinquencyTrackerPayload.selectedFilterObject && this.delinquencyTrackerPayload.selectedFilterObject["label"] ) {
            this.delinquencyTrackerPayload.filter = this.delinquencyTrackerPayload.selectedFilterObject["label"];
        } else {
            if ( this.delinquencyTrackerPayload.selectedFilterObject ) {
                this.delinquencyTrackerPayload.filter = this.delinquencyTrackerPayload.selectedFilterObject;
            } else {
                this.delinquencyTrackerPayload.filter = this.pmStatusList[0]["label"];
            }
        }

        this.trendsService.getDelinquencyTrackerData( this.delinquencyTrackerPayload ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();

                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    this.deliquencyTrackerData = res.data;

                    if ( this.deliquencyTrackerData && this.deliquencyTrackerData.length > 0 ) {

                        this.deliquencyTrackerData.filter(( item ) => {
                            this.deliquencySeriesTrackerData.currentMonthData.push( item.currentCount );
                            this.deliquencySeriesTrackerData.selectedMonthData.push( item.lastMonthCount );
                        } );
                        this.deliquencySeriesTrackerData.currentMonthLegend = this.trendsService.getMonthName();
                        this.deliquencySeriesTrackerData.selectedMonthLegend = this.trendsService.getMonthName( this.delinquencyTrackerPayload.month );

                        this.setTrackerChart( 'delinquency-tracker', this.deliquencySeriesTrackerData );
                    }

                } else {
                    this.deliquencyTrackerData = [];
                }

            },
            ( error ) => {
                this.deliquencyTrackerData = [];
                this.commonService.hideLoading();
            }
        );
    }

    /**
     * Function to get Line Chart data
     **/
    private setTrackerChart = ( divElement, seriesData ) => {
        HighCharts.setOptions( {
            colors: this.highchartServiceService.getTrackerChartColors()
        } );
        HighCharts.chart( divElement, this.highchartServiceService.initializeTrackerLineChartConfiguration( seriesData ) );
    }

    /**
     * Function to get PM status
     */
    private getPMStatusList = () => {
        this.pmStatusList = [];

        this.metaDataService.getPMStatusList(
            ( response ) => {
                response.map( status =>
                    this.pmStatusList.push( status )
                );
                this.delinquencyTrackerPayload.selectedFilterObject = this.pmStatusList[0]["value"];
                this.commonService.hideLoading();
            },
            ( reason ) => {

                this.pmStatusList = reason;
                this.commonService.hideLoading();
            } );
    }

    /**
     * Function to get Renewal status
     */
    private getRenewalStatusList = () => {
        this.renewalStatusList = [];

        this.metaDataService.getCustomRenewalStatusList(
            ( response ) => {

                response.map( status =>
                    this.renewalStatusList.push( status )
                );

                this.renewalTrackerPayload.selectedFilterObject = this.renewalStatusList[0]["value"];

                this.commonService.hideLoading();
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.renewalStatusList = reason;
            } );
    }


    /**
      * Function to get PM Renewal Tracker Data
      */
    public getRenewalTrackerData() {
        this.commonService.showLoading();

        this.renewalSeriesTrackerData.currentMonthData = [];
        this.renewalSeriesTrackerData.selectedMonthData = [];

        if ( this.renewalTrackerPayload.selectedFilterObject && this.renewalTrackerPayload.selectedFilterObject["label"] ) {
            this.renewalTrackerPayload.filter = this.renewalTrackerPayload.selectedFilterObject["label"];
        } else {
            if ( this.renewalTrackerPayload.selectedFilterObject ) {
                this.renewalTrackerPayload.filter = this.renewalTrackerPayload.selectedFilterObject;
            } else {
                this.renewalTrackerPayload.filter = this.renewalStatusList[0]["label"];
            }
        }

        this.trendsService.getRenewalTrackerData( this.renewalTrackerPayload ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();

                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    this.renewalTrackerData = res.data;

                    if ( this.renewalTrackerData && this.renewalTrackerData.length > 0 ) {

                        this.renewalTrackerData.filter(( item ) => {
                            this.renewalSeriesTrackerData.currentMonthData.push( item.currentCount );
                            this.renewalSeriesTrackerData.selectedMonthData.push( item.lastMonthCount );
                        } );
                        this.renewalSeriesTrackerData.currentMonthLegend = this.trendsService.getMonthName();
                        this.renewalSeriesTrackerData.selectedMonthLegend = this.trendsService.getMonthName( this.renewalTrackerPayload.month );

                        this.setTrackerChart( 'renewal-tracker', this.renewalSeriesTrackerData );
                    }

                } else {
                    this.renewalTrackerData = [];
                }

            },
            ( error ) => {
                this.renewalTrackerData = [];
                this.commonService.hideLoading();
            }
        );
    }

    /**
     * Function to show results based on search string if no data is matched
     * @param e 
     */
    handleFilter(e, isPmLeaderboard){
        console.log("value  >>>>", e);
        if(isPmLeaderboard){
            if(e && e["filteredValue"] && e["filteredValue"].length == 0) {
                this.isLeaderboardListNoData = true;
            } else {
                this.isLeaderboardListNoData = false;
            }
         }else {
             
            if(e && e["filteredValue"] && e["filteredValue"].length == 0) {
                this.isOwnerLeaderboardListNoData = true;
            } else {
                this.isOwnerLeaderboardListNoData = false;
            }
        }

        }
        
        // reset the table
        clearFilters(dt) {
            dt.reset(); // reset the table
        }

}
