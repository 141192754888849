import { Component, OnInit, ViewEncapsulation, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, PropertyManagerService, MetaDataService, TrendsService } from 'src/providers/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplacePipe } from 'src/providers/common-service/replace-pipe';
import { TitleCasePipe } from '@angular/common';
import * as _ from "lodash";
@Component( {
    selector: 'app-property-group-filter',
    templateUrl: './property-group-filter.component.html',
    styleUrls: ['./property-group-filter.component.scss'],

    encapsulation: ViewEncapsulation.None
} )
export class propertyGroupFilterComponent implements OnInit {

    public pageName: string;
    public firstButton: string;
    public secondButton: string;

    @Input() filterPayload: any
    public propertyGroupList: any;
    groupList: any[];
    
    tab: any;
    public trendsPayload: any = {       
        pageNumber: 0,
        pageSize: 50
    };
    public cancelSelect: any;
    public propertyGroupData: any ={
        propertyGroupName: "",
        propertyGroupId: "",
        id: "",
        isHide: false
    };
    public accessCheck: boolean = false;

    constructor( private replaceChar: ReplacePipe, private toTitleCase: TitleCasePipe, private router: Router, public pmService: PropertyManagerService, private commonService: CommonService, public activeModal: NgbActiveModal,
            private trendsService: TrendsService, private metaDataService: MetaDataService ) {
        let pageName = 'Filter';
        let firstButton = 'Cancel';
        let secondButton = 'Apply';
        console.log("filterData",this.filterPayload);
    }

    ngOnInit(): void {  
        this.getPropertyGroupList();
    }


    /**
      * Function to get PM status
      */
     private getPropertyGroupList = () => {
        this.propertyGroupList = [];
        this.groupList = [];

        this.trendsService.getPropertyGroupList( this.trendsPayload ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();

                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    this.groupList.push( { label: "Select group", value: "" } );
                    this.propertyGroupList = res.data.list;
                    console.log("propertyGroupList ::: > ", this.propertyGroupList, "this.propertyGroupData.propertyGroupName", this.propertyGroupData.propertyGroupName);
                    
                    if ( !this.filterPayload ) {
                        this.propertyGroupData.propertyGroupName = this.groupList[0];
                        }  else {
                            this.propertyGroupData.propertyGroupName = this.filterPayload;
                        }
                        this.cancelSelect = this.propertyGroupData.propertyGroupName;
                    for (let i = 0; i <= this.propertyGroupList.length; i++) {
                            this.groupList.push({ label: this.propertyGroupList[i].propertyGroupName, value: this.propertyGroupList[i], disabled: this.propertyGroupList[i].isHide });
                    }
                    this.propertyGroupList = _.cloneDeep( this.propertyGroupList );
                } else {
                    this.propertyGroupList = [];
                }

            },
            ( error ) => {
                this.propertyGroupList = [];
                this.commonService.hideLoading();
            }
        );
    }

    public getSelectedGroup( eve ) {
        console.log( "eb", eve, this.propertyGroupData.propertyGroupName );
        this.filterPayload = eve.value;
    }

    /**
     * function on cancel Click
     */
    cancelClick = () => {       
        this.activeModal.dismiss( this.cancelSelect );
    }
    /**
       * function on clear Click
       */
    clearFilter = () => {
        

    }
    /**
     * function on Apply Click
     */
    applyFilter = () => {
        this.activeModal.dismiss( this.filterPayload );
    }

    /**
   * Function coming soon
   */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo( "Coming soon" );
    }

    /**
      * Function clear coming soon
      */
    public clearCommingSoon = () => {
        this.commonService.clearToast();
    }
}
