/**
 * RentDex
 * High Chart Configuration, constants and other related function
 * Created By #1116
 */

import { Injectable } from '@angular/core';

@Injectable( {
    providedIn: 'root'
} )
export class HighchartServiceService {

    public defaultPMDashboardPieChartColors = [
        "#f2fbee",
        "#8bc373",
        "#c5edb4"
    ];

    public PMDashboardPieChartConfig: any;

    public TrackerLineChartColors = [
        "#7cb5ec",
        "#416034"
    ];

    public TrackerLineChartConfig: any;

    constructor() { }

    /** 
     * Function to get Pie chart colors for PM Dashboard Rent Collected 
     */
    public getDefaultPMDashboardPieChartColors = () => {
        return this.defaultPMDashboardPieChartColors;
    }

    /** 
     * Function to get Pie chart colors for PM Dashboard Rent Collected 
     */
    public getTrackerChartColors = () => {
        return this.TrackerLineChartColors;
    }


    /**
     * Function to initialize PM Dashboard Rent Collected configuration
     */
    public initializePMDashboardPieChartConfiguration = ( data ) => {
        this.PMDashboardPieChartConfig = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor: 'transparent',
                margin: [5, 0, 5, 0],
            },

            title: {
                text: ''
            },

            tooltip: {
                enabled: false
            },

            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },

            plotOptions: {
                pie: {
                    allowPointSelect: false, //click and slice the pie chart divide disable
                    //cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                    },
                    size: 60,
                    borderColor: 'transparent'
                }
            },

            series: [{
                'data': data,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            }],

            credits: { //Disable highcharts.com URL from chart display
                enabled: false
            }
        }

        return this.PMDashboardPieChartConfig;
    };

    /**
     * Function to initialize PM Delinquency/Renewal Tracker Line Chart Configuration
     */
    public initializeTrackerLineChartConfiguration = ( seriesData ) => {

        this.TrackerLineChartConfig = {
            chart: {
                margin: [20, 5],
            },
            title: {
                text: ''
            },

            yAxis: {
                allowDecimals: false,
                title: {
                    text: 'Count'
                },
                tickAmount: 10
            },

            xAxis: {
                title: {
                    text: 'Days'
                },
                min: 1,
                tickInterval: 1
            },

            legend: {
                title: {
                    text: '',

                }
            },

            series: [{
                name: seriesData.currentMonthLegend,
                data: seriesData.currentMonthData,
                marker: {
                    symbol: 'square'
                }
            }, {
                name: seriesData.selectedMonthLegend,
                data: seriesData.selectedMonthData,
                marker: {
                    symbol: 'square'
                }
            }],

            credits: { //Disable highcharts.com URL from chart display
                enabled: false
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false
                    },

                }
            },

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        }

        return this.TrackerLineChartConfig;
    };

}
