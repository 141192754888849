<!-- Container -->
<div class="form-container filter-form-cls">

    <div class="">

        <!-- Heading -->
        <div class="row filter-heading m-0">
            <div class="col-6">
                <h2 class="form-title" *ngIf="userData?.roleType !=='SUPER_ADMIN'"> {{ "PM_DASHBOARD.EDIT_ACTIVITY" | translate }} </h2>
                <h2 class="form-title" *ngIf="userData?.roleType =='SUPER_ADMIN'"> {{ "PM_DASHBOARD.VIEW_ACTIVITY" | translate }} </h2>
            </div>
        </div>


        <div class="container">

            <form class="form-inline crud-form-cls log-form">
                <div class="row m-0">
                    <div class="row form-group">
                        <label class="set-lbl col-sm-3"> {{ "TENANT.PM_EA_NAME" | translate }} </label>
                        <div class="col-sm-9">
                            <p class="form-text">
                                {{ filterPayload.pmEAName | replace : '_' : ' ' | titlecase }}
                            </p>
                        </div>
                    </div>

                    <div class="row form-group">
                        <label class="set-lbl col-sm-3"> {{ "TENANT.PM_STATUS" | translate }} </label>
                        <div class="col-sm-9">
                            <p class="form-text">
                                {{ filterPayload.pmStatus | replace : '_' : ' ' | titlecase }}
                            </p>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="set-lbl col-sm-3"> {{ "TENANT.RENEWAL_STATUS" | translate }} </label>
                        <div class="col-sm-9">
                            <p class="form-text">
                                {{ filterPayload.renewalStatus | replace : '_' : ' ' | titlecase }}
                            </p>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="set-lbl col-sm-3"> {{ "TENANT.EVICTION_STATUS" | translate }} </label>
                        <div class="col-sm-9">
                            <p class="form-text">
                                {{ filterPayload.evictionStatus | replace : '_' : ' ' | titlecase }}
                            </p>
                        </div>
                    </div>


                    <div class="row form-group">
                        <label class="set-lbl col-sm-3"> {{ "TENANT.ACTION_TAKEN" | translate }} </label>
                        <div class="col-sm-9">
                            <p-dropdown class="form-dropdown" placeholder="Action taken" name="actionFilter" [options]="actionItemList" [(ngModel)]="filterPayload.lastActionTaken"></p-dropdown>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="set-lbl col-sm-3"> {{ "TENANT.FOLLOW_UP_DATE" | translate }} </label>
                        <div class="col-sm-9">
                            <!-- <p-calendar id="dateOf" [touchUI]="true" name="followUpDate" [minDate]="minDateValue" placeholder="Follow up date" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish"   [readonlyInput]="true" [showIcon]="true" #followUpDate="ngModel" [(ngModel)]="filterPayload.followUpDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range"></p-calendar> -->
                            <p-calendar id="dateOf" [touchUI]="true" name="followUpDate" placeholder="Follow up date" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #followUpDate="ngModel" [(ngModel)]="filterPayload.followUpDate"
                                [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range"></p-calendar>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="set-lbl col-sm-3"> {{ "TENANT.PROMISE_TO_PAY" | translate }} </label>
                        <div class="col-sm-9">
                            <!-- <p-calendar id="dateTo" [touchUI]="true" name="promiseToPay" [minDate]="minDateValue" placeholder="Promise to pay" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #promiseToPay="ngModel" [(ngModel)]="filterPayload.promiseToPay" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range" ></p-calendar> -->
                            <p-calendar id="dateTo" [touchUI]="true" name="promiseToPay" placeholder="Promise to pay" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #promiseToPay="ngModel" [(ngModel)]="filterPayload.promiseToPay"
                                [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range"></p-calendar>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="set-lbl col-sm-3"> {{ "TENANT.NOTES" | translate }} </label>
                        <div class="col-sm-9">
                            <textarea class="full-width" rows="4" cols="30" maxlength="255" placeholder="Notes" [(ngModel)]="filterPayload.tenantNotes" [ngModelOptions]="{standalone: true}">                  
                      </textarea>
                        </div>
                    </div>

                    <div class="row form-group">
                        <label class="set-lbl col-sm-3"> {{ "TENANT.UPDATE_AT" | translate }} </label>
                        <div class="col-sm-9">
                            <p class="form-text">
                                {{ filterPayload.updateAt | date: 'MM/dd/yyyy' : ' - ' }}
                            </p>
                        </div>
                    </div>
                </div>
            </form>

        </div>

        <!--------------- modal btns  --------->
        <div class="row d-flex modal-footer m-0">
            <button type="button" class="btn link-btn" (click)="cancelClick()"> {{ "COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
            <button type="submit" class="btn common-btn" (click)="applyFilter()" *ngIf="userData?.roleType !=='SUPER_ADMIN'"> {{ "COMMON_COMPONENT.UPDATE" | translate }} </button>
        </div>


    </div>
</div>