/*--------------------Angular related components---------------*/
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { Observable, timer, throwError } from "rxjs";
import { Router } from "@angular/router";
import { map, catchError, mergeMap } from "rxjs/operators";

/*-----------------App Providers---------------------*/
import { AppSettings } from "../app-settings-service/app-settings.service";
import { Constants } from "../app-settings-service/app-constant.service";
import { LocalStorageService } from "../local-storage-service/local-storage.service";
import { RestService } from "../rest-service/rest.service";
import { CommonService } from "../common-service/common-service";
import { HttpRequest, HttpHandler } from "@angular/common/http";

@Injectable()
export class PropertyManagerService {
    errorMsg: string;
    successMsg: string;

    constructor(
        private commonService: CommonService,
        private restService: RestService,
        public locstr: LocalStorageService,
        private constant: Constants,
        //private toaster: Toaster,
        private router: Router
    ) { }

    /**
     * Function to get Renewal data list
     */
    public getRenewalList( param ): Observable<any> {
        const path = AppSettings.RENEWAL_LIST_URL + "?size=" +
            param.pageSize +
            "&page=" +
            param.pageNumber;
        return this.restService.postCall( path, param, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /**
     * Function to get Delinquencies data list
     */
    public getDelinquenciesList( param ): Observable<any> {
        const path = AppSettings.DELINQUENCIES_LIST_URL + "?size=" +
            param.pageSize +
            "&page=" +
            param.pageNumber;
        return this.restService.postCall( path, param, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /**
     * Function to export Delinquencies table data
     */
    public exportDelenquencies( param, reportFormat,userId, isDashboard: boolean = false ): Observable<any> {
        const path = AppSettings.DELINQUENCIES_EXPORT_URL + "?reportFormat=" +
            reportFormat+
            "&userId=" +
            userId+
            "&isDashboard=" + isDashboard;
        return this.restService.postCall( path, param, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /**
     * Function to export Renewal table data
     */
    public exportRenewal( param, reportFormat,userId, isDashboard: boolean = false ): Observable<any> {
        const path = AppSettings.RENEWAL_EXPORT_URL + "?reportFormat=" +
            reportFormat+
            "&userId=" +
            userId+
            "&isDashboard=" + isDashboard;
        return this.restService.postCall( path, param, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }


    /**
     * Function to get Goals Percentage for PM
     */
    public getGoalsData(currentMonth): Observable<any> {
        const path = AppSettings.GOALS_DATA_URL + "?month=" + currentMonth;
        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function to get Eviction statuses for PM
     */
    public getEvictionStatusList(): Observable<any> {
        const path = AppSettings.EVICTION_STATUS_LIST_URL;

        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

     /**
     * Function to get Resident Acronym List
     */
    public getAcronymList(): Observable<any> {
        const path = AppSettings.ACRONYM_LIST_URL;
        return this.restService.postCall( path, null, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        ); 
    }

    /**
     * Function to get Owner's name list
     */
    public getOwnersNameList(): Observable<any> {
        const path = AppSettings.OWNERS_NAME_LIST_URL;
        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function to get action item for PM
     */
    public getActionItemList(): Observable<any> {
        const path = AppSettings.ACTION_ITEM_LIST_URL;

        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }
    /**
     * Function to get PM statuses for PM
     */
    public getPMStatusList(): Observable<any> {
        const path = AppSettings.PM_STATUS_LIST_URL;

        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }
   

    /**
     * Function to get PM EA Name list
     */
    public getPMEANameList(isReport): Observable<any> {
        var path;
        if(isReport){
            path = AppSettings.GET_PM_EA_NAME_LIST + "?onlyPm=" + isReport;
        } else {            
            path = AppSettings.GET_PM_EA_NAME_LIST;
        }
        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function to get PM EA Name list
     */
    public getPMEANameIdList(onlyPm): Observable<any> {     
   const path = AppSettings.GET_PM_EA_NAME_ID_LIST + "?onlyPm=" + onlyPm;
        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }


    /**
     * Function to get Renewal statuses for PM
     */
    public getRenewalStatusList(): Observable<any> {
        const path = AppSettings.RENEWAL_STATUS_LIST_URL;

        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function to get Units for PM
     */
    public getUnitList(): Observable<any> {
        const path = AppSettings.UNIT_LIST_URL;

        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function to update PM status and notes PM Role on Deliquency and Renewal List
     */
    public updateStatusNotes = (deliquenciesTenant) => {
        console.log("updateStatusNotes :: deliquenciesTenant :: ", deliquenciesTenant);
        const path = AppSettings.UPDATE_STATUS_NOTES_URL;
        return this.restService.putCall(path, deliquenciesTenant, false, 60000)
           .map(function (res) {
              if (typeof res == 'string') {
                 return JSON.parse(res);
              } else {
                 return res;
              }
           })
           .catch(this.restService.handleError);
     }

    public updateRenewalStatusNote = (renewalsTenant) => {
        console.log("updateRenewalStatusNotes :: renewalsTenant :: ", renewalsTenant);
        const path = AppSettings.UPDATE_RENEWALS_STATUS_NOTES_URL;
        return this.restService.putCall(path, renewalsTenant, false, 60000)
           .map(function (res) {
              if (typeof res == 'string') {
                 return JSON.parse(res);
              } else {
                 return res;
              }
           })
           .catch(this.restService.handleError);
    }

    /**
     * Function to get Renewal statuses(Custom renewal status -Renewed and Not renewed) for PM
     */
    public getCustomRenewalStatusList(): Observable<any> {
        const path = AppSettings.RENTDEX_RENEWAL_LIST_URL;

        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

     /**
     * Function to get tenent profile for PM
     */
    public getTenentProfileData(unitId): Observable<any> {
        const path = AppSettings.TENET_PROFILE_URL + "?unitId=" + unitId;
        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

     /**
     * Function to get tenent activity for PM
     */
    public getTenentActivityData(param): Observable<any> {
        const path = AppSettings.TENENT_ACTIVITY_URL + "?unitId=" + param.unitId +
        "&sortBy=" +
        param.sortBy+
        "&sortManner=" + param.sortManner;
        return this.restService.getCall( path, false, 60000 )
        .pipe( map( res => {
            return res;
        } ),
        catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }


    public addTenantActivity(payload): Observable<any> {
        const path = AppSettings.ADD_TENENT_ACTIVITY_URL;
        return this.restService.postCall( path, payload, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        ); 
    }
    public updateTenantActivity(payload): Observable<any> {
        const path = AppSettings.UPDATE_TENANT_ACTIVITY_URL + "?notesId=" + payload.notesId;
        return this.restService.postCall( path, payload, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        ); 
    }
}
