<div class="row page-wrap">
    <div class="container-fluid">
        
        <div class="row m-0">
            <div class="dashboard-tab-wrap container-fluid p-0">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs full-width">

                    <li class="search-wrap float-right">
                        <div class="form-group has-search common-search-box">
                            <span class="form-control-feedback"><i class="search-icon search-icon-cls"></i></span>
                            <input type="text" id="search-bar" class="form-control" spellcheck="false" autocomplete="false" name="searchUser"  placeholder="Search by Name/Address/Unit" pInputText 
                            [(ngModel)]="pipelinePayload.searchText" (input)="isSearchCall=true; getEvictionPipelineList($event)" />
                        </div>
                        <button class="btn btn-icon-bg" (click) ="openExportPopup()" ><i class="forward-icon filter-icon-cls" title="Export"></i></button>
                        <button class="btn btn-icon-bg" (click)="clickOnFilter($event)"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>
                    </li>

                </ul>

                <!-- Tab panes -->
                <div class="tab-content full-width">
                    <div id="tab-1" class="container-fluid tab-pane active">
                        <div class="card clearfix">

                            <p-table class="custom-table-cls" #dt [value]="pipelineList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 295px)'" [responsive]="true" (onLazyLoad)="customSort($event)" [lazy]="true">
                                
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th> {{ "PM_DASHBOARD.ADDRESS" | translate }} </th>

                                        <th pSortableColumn="name"> {{ "PM_DASHBOARD.NAME" | translate }}
                                            <p-sortIcon field="name"></p-sortIcon>
                                        </th>

                                        <th pSortableColumn="unit"> {{ "PM_DASHBOARD.UNIT" | translate }}
                                            <p-sortIcon field="unit"></p-sortIcon>
                                        </th>

                                        <th> {{ "EVICTION_PIPELINE.TYPE_OF_EVICTION" | translate }}
                                        </th>
                                        
                                        <th> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }} </th>

                                        <th pSortableColumn="evictionFiled">{{ "EVICTION_PIPELINE.EVICTION_FILED" | translate }}
                                            <p-sortIcon field="evictionFiled"></p-sortIcon>
                                        </th>

                                        <th pSortableColumn="initialCourtDate"> {{ "EVICTION_PIPELINE.INITIAL_COURT_DATE" | translate }}
                                            <p-sortIcon field="initialCourtDate"></p-sortIcon>
                                        </th>

                                        <th pSortableColumn="secondCourtDate"> {{ "EVICTION_PIPELINE.SECOND_COURT_DATE" | translate }}
                                            <p-sortIcon field="secondCourtDate"></p-sortIcon>
                                        </th>
                                        
                                        <th pSortableColumn="otherCourtDate"> {{ "EVICTION_PIPELINE.OTHER_COURT_DATE" | translate }}
                                            <p-sortIcon field="otherCourtDate"></p-sortIcon>
                                        </th>

                                        <th pSortableColumn="evictionStayDate"> {{ "EVICTION_PIPELINE.EVICTION_STAY_DATE" | translate }}
                                            <p-sortIcon field="evictionStayDate"></p-sortIcon>
                                        </th>

                                        <th pSortableColumn="sheriffOrdered"> {{ "EVICTION_PIPELINE.SHERIFF_ORDERED" | translate }}
                                            <p-sortIcon field="sheriffOrdered"></p-sortIcon>
                                        </th>

                                        <th> {{ "EVICTION_PIPELINE.POSSESSION_OF_UNIT" | translate }}
                                        </th>

                                        <th pSortableColumn="coppDate"> {{ "EVICTION_PIPELINE.COPP_DATE" | translate }}
                                            <p-sortIcon field="coppDate"></p-sortIcon>
                                        </th>

                                        <th> {{ "EVICTION_PIPELINE.DAYS_ONGOING" | translate }}
                                        </th>

                                        <th pSortableColumn="cost"> {{ "EVICTION_PIPELINE.COST" | translate }}
                                            <p-sortIcon field="cost"></p-sortIcon>
                                        </th>

                                        <th pSortableColumn="moneyJudgement"> {{ "EVICTION_PIPELINE.MONEY_JUDGEMENT" | translate }} 
                                            <p-sortIcon field="moneyJudgement"></p-sortIcon>
                                        </th>

                                        <th> {{ "EVICTION_PIPELINE.PAYMENT_PLAN_DETAILS" | translate }} </th>

                                        <th> {{ "EVICTION_PIPELINE.PROPERTY_MANAGER" | translate }} </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-pipeline>                                   
                                    
                                    <tr class="cusr-pointer" (click)="openEvictionDetails(pipeline)">
                                        <!-- <td>
                                        <p-tableCheckbox [value]="product "></p-tableCheckbox>
                                    </td> -->
                                        <td title="{{pipeline.address}}">{{pipeline.address? pipeline.address: '-'}}</td>
                                        <td title="{{pipeline.name}}" (click)="openTenantDetails(pipeline.unitId)" [ngClass]="!pipeline.name? 'rmLine': ''" class="hyperlink-cls pointer">{{pipeline.name? pipeline.name: '-'}}</td>
                                        <td title="{{pipeline.unit}}">{{pipeline.unit? pipeline.unit: '-'}}</td>
                                        <td title="{{pipeline.typeOfEviction  | replace : '_' : ' ' | titlecase }}">{{ pipeline.typeOfEviction ? (pipeline.typeOfEviction | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{pipeline.evictionStatus  | replace : '_' : ' ' | titlecase }}">{{ pipeline.evictionStatus ? (pipeline.evictionStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{pipeline.evictionFiled | date: 'MMM dd, yyyy' : ' - '}}">{{ pipeline.evictionFiled ? (pipeline.evictionFiled | date: 'MMM dd, yyyy' : ' - ') : '-' }}</td>
                                        <td title="{{pipeline.initialCourtDate | date: 'MMM dd, yyyy' : ' - '}}">{{ pipeline.initialCourtDate ? (pipeline.initialCourtDate | date: 'MMM dd, yyyy' : ' - ') : '-' }}</td>
                                        <td title="{{pipeline.secondCourtDate | date: 'MMM dd, yyyy' : ' - '}}">{{ pipeline.secondCourtDate ? (pipeline.secondCourtDate | date: 'MMM dd, yyyy' : ' - ') : '-' }}</td>
                                        <td title="{{pipeline.otherCourtDate | date: 'MMM dd, yyyy' : ' - '}}">{{ pipeline.otherCourtDate ? (pipeline.otherCourtDate | date: 'MMM dd, yyyy' : ' - ') : '-' }}</td>
                                        <td title="{{pipeline.evictionStayDate | date: 'MMM dd, yyyy' : ' - '}}">{{ pipeline.evictionStayDate ? (pipeline.evictionStayDate | date: 'MMM dd, yyyy' : ' - ') : '-' }}</td>
                                        <td title="{{pipeline.sheriffOrdered | date: 'MMM dd, yyyy' : ' - '}}">{{ pipeline.sheriffOrdered ? (pipeline.sheriffOrdered | date: 'MMM dd, yyyy' : ' - ') : '-' }}</td>
                                        <td title="{{pipeline.possessionOfUnit | date: 'MMM dd, yyyy' : ' - '}}">{{ pipeline.possessionOfUnit ? (pipeline.possessionOfUnit | date: 'MMM dd, yyyy' : ' - ') : '-' }}</td>
                                        <td title="{{pipeline.coppDate | date: 'MMM dd, yyyy' : ' - '}}">{{ pipeline.coppDate ? (pipeline.coppDate | date: 'MMM dd, yyyy' : ' - ') : '-' }}</td>
                                        <td title="{{pipeline.daysOnGoing}}">{{ pipeline.daysOnGoing? pipeline.daysOnGoing: '-' }}</td>
                                        <td title="{{pipeline.cost | currency:'USD':'symbol' :'1.2-2'}}">{{ pipeline.cost ? (pipeline.cost | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{pipeline.moneyJudgment | currency:'USD':'symbol' :'1.2-2'}}">{{ pipeline.moneyJudgment ? (pipeline.moneyJudgment | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{pipeline.paymentPlanDetails}}" class="txt-initial">{{pipeline.paymentPlanDetails? pipeline.paymentPlanDetails: '-'}}</td>
                                        <td title="{{pipeline.propertyManagerName}}" class="txt-initial">{{pipeline.propertyManagerName? pipeline.propertyManagerName: '-'}}</td>
                                    </tr>
                                   
                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="pipelineList.length == 0">
                                <div class="no-data-found" *ngIf="!loading1" text-center>
                                    <div class="no-found-txt" text-center> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                        <p-paginator styleClass="ui-paginator-bottom" [rows]="constants.defaultPageSize" #p [totalRecords]="pipelinePayload.totalRecords" (onLazyLoad)="pipelinepaginate($event)" (onPageChange)="pipelinepaginate($event)" class="list-paginator table-paginator"></p-paginator>
                    
                </div>
            </div>
        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>