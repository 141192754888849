import { Injectable, Injector } from '@angular/core';
import {
   HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { Observable } from 'rxjs/observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/do';
import { EMPTY } from 'rxjs';
import 'rxjs/add/operator/finally';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Constants } from '../app-settings-service/app-constant.service';
import { throwError } from 'rxjs';

import { RestService } from '../rest-service/rest.service';
import { AuthService } from '../auth-service/auth.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpNetworkInterceptor implements HttpInterceptor {
   private userAuthData;
   isRefreshingToken = false;
   tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);


   protected refreshTokenData: any = {
      grant_type: 'refresh_token',
      refresh_token: ''
   };

   constructor(
      private injector: Injector
   ) {
      console.log('intercept');
      this.getAccessTokenHeader();
   }

   addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
      return req.clone({ setHeaders: { Authorization: this.getAccessTokenHeader() } });
   }


   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
      console.log('intercept');
      // Skip for login api
      if (req.url.indexOf('oauth/token') > -1
         || req.url.indexOf('reset/password') > -1
         || req.url.indexOf('otp/requestOtp') > -1
         || req.url.indexOf('otp/verify') > -1
         || req.url.indexOf('otp/resendOtp') > -1
         || req.url.indexOf('assets/i18n') > -1
         || req.url.indexOf('user/signup') > -1) {
         return next.handle(req);
      } else {
         let accessToken;
         if (this.userAuthData) {
            accessToken = this.userAuthData.access_token;
         } else {
            this.userAuthData = this.getUserAuthData();
            if (this.userAuthData && this.userAuthData.access_token) {
               accessToken = this.userAuthData.access_token;
            }
         }

         if (accessToken) {
            return next.handle(this.addToken(req, accessToken))
               .pipe(catchError(error => {
                  if (error instanceof HttpErrorResponse) {
                     if (error.status === 401) {
                        return this.handle401Error(req, next);
                     } else {
                        const constants = this.injector.get(Constants);
                        const indexOfError = error.message.indexOf(constants.WEB_SERVICE_RESPONSE.HTTP_PARSE_FAIL);
                        if (indexOfError >= 0) {
                           return EMPTY;
                        } else {
                           return throwError(error);
                        }
                     }
                  } else {
                     return EMPTY;
                  }
               }));
         } else {
            return next.handle(req);
         }

      }
   }

   handle401Error(req: HttpRequest<any>, next: HttpHandler) {
      if (!this.isRefreshingToken) {
         this.isRefreshingToken = true;
         // Reset here so that the following requests wait until the token
         // comes back from the refreshToken call.
         this.tokenSubject.next(null);
         if (this.userAuthData) {
            this.refreshTokenData.refresh_token = this.userAuthData.refresh_token;
         } else {
            this.userAuthData = this.getUserAuthData();
            if (this.userAuthData && this.userAuthData.refresh_token) {
               this.refreshTokenData.refresh_token = this.userAuthData.refresh_token;
            }
         }
         const authenticationService = this.injector.get(AuthService);

         return authenticationService.getTokensFromRefreshToken(this.refreshTokenData.refresh_token)
            .switchMap((newAuthData: any) => {
               authenticationService.setCodeResponseToStorage(newAuthData);
               const accessToken = 'Bearer ' + newAuthData.access_token;
               const restService = this.injector.get(RestService);
               restService.addHeader('Authorization', accessToken);
               restService.setBearerAuthData(accessToken);

               if (newAuthData) {
                  this.tokenSubject.next(accessToken);
                  return next.handle(this.addToken(req, accessToken));
               }
               // If we don't get a new token, we are in trouble so logout.
               return EMPTY;
            })
            .catch(error => {
               // If there is an exception calling 'refreshToken', bad news so logout.
               return EMPTY;
            })
            .finally(() => {
               this.isRefreshingToken = false;
            });
      } else {

         return this.tokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(token => {
               return next.handle(this.addToken(req, token));
            })
         );

      }
   }

   /**
    * Function to get auth object from storage containing access token, refresh token, ect
    */
   private getUserAuthData = () => {
      const localstorageService = this.injector.get(LocalStorageService);

      if (localstorageService.getObj('tokenData')
         && localstorageService.getObj('tokenData') !== undefined
         && localstorageService.getObj('tokenData') !== null) {
         return localstorageService.getObj('tokenData');
      }

   }

   /**
    * Function to set header to for http request
    */
   private getAccessTokenHeader = () => {
      let authObj;
      this.userAuthData = this.getUserAuthData();
      if (this.userAuthData && this.userAuthData != undefined && this.userAuthData.access_token) {
         authObj = 'Bearer ' + this.userAuthData.access_token;
      }
      return authObj;
   }
}
