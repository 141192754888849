<div class="background-image-wrapper">

    <!-- Container -->
    <div class="form-container">

        <div class="">
            <!-- Logo -->
            <div>
                <img class="app-logo text-center" src="assets/images/rentdex-logo.png">
            </div>

            <!-- Heading -->
            <div class="text-center col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                <h2 class="form-title"> {{ "RESET_PASSWORD.RESET_PASSWORD" | translate }} </h2>
                <p class="form-subtitle"> {{ "RESET_PASSWORD.NEW_PASSWORD_TO_BE" | translate }} </p>
            </div>

            <!-- Form -->

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                <form class="common-form-cls" #resetPasswordForm="ngForm" (ngSubmit)="resetPasswordClick(resetPasswordForm)" novalidate>
                    <!---------------New password Field  --------->
                    <div class="form-group input-group mar-btm28">
                        <!-- <label></label> -->
                        <span><i class="password-icon icon-left input-icon"></i> </span>
                        <input type="{{inputType}}" [ngStyle]="{'border': (changePassword.errors && (changePassword.dirty || changePassword.touched || formSubmitted)) ? '1px solid red' : '0' }" placeholder="New Password" class="from-input pass-input" #changePassword="ngModel"
                            [(ngModel)]="passwordData.newPassword" id="new-password" onKeyDown="if(event.keyCode === 32) return false;" name="newPassword" minlength="6" maxlength="40" pInputText required>

                        <span (click)="hideShowPassword('newPassword')" [ngClass]="newPasswordCheck?'eye-open-icon icon-right input-icon':'eye-close-icon icon-right input-icon'"></span>
                        <div class="help-block text-left" *ngIf="changePassword.errors && (changePassword.dirty || changePassword.touched || formSubmitted)">
                            <small [hidden]="!changePassword.errors.required" class="p-invalid error-msg"> {{ "RESET_PASSWORD.ENTER_NEW_PASSWORD" | translate }} </small>
                            <small [hidden]="!changePassword.errors.minlength" class="p-invalid error-msg"> {{ "LOGIN.PASSWORD_LIMIT_IN_NO" | translate }} </small>
                            <!-- <small [hidden]="!changePassword.errors.pattern" class="p-invalid error-msg">{{ "LOGIN.PASSWORD_PATTEREN_VALIDATION" | translate }} </small> -->
                        </div>

                    </div>

                    <!---------------Confirm Password Field  --------->
                    <div class="form-group input-group mar-btm35">
                        <!-- <label></label> -->
                        <span><i class="password-icon icon-left input-icon"></i> </span>
                        <input type="{{confirmInputType}}" [ngStyle]="{'border': (confPassword.errors && (confPassword.dirty || confPassword.touched || formSubmitted)) ? '1px solid red' : '0' }" placeholder="Confirm Password" class="from-input pass-input" #confPassword="ngModel"
                            [(ngModel)]="passwordData.confirmPassword" id="confirm-password" onKeyDown="if(event.keyCode === 32) return false;" name="confirmPassword" minlength="6" maxlength="40" pInputText required>

                        <span (click)="hideShowPassword('confirmPassword')" [ngClass]="confirmPasswordCheck?'eye-open-icon icon-right input-icon':'eye-close-icon icon-right input-icon'"></span>
                        <div class="help-block text-left" *ngIf="confPassword.errors && (confPassword.dirty || confPassword.touched || formSubmitted)">
                            <small [hidden]="!confPassword.errors.required" class="p-invalid error-msg"> {{ "RESET_PASSWORD.ENTER_CONFIRM_PASSWORD" | translate }} </small>
                            <small [hidden]="!confPassword.errors.minlength" class="p-invalid error-msg"> {{ "LOGIN.PASSWORD_LIMIT_IN_NO" | translate }} </small>
                            <!-- <small [hidden]="!confPassword.errors.pattern" class="p-invalid error-msg">{{ "LOGIN.PASSWORD_PATTEREN_VALIDATION" | translate }} </small> -->
                        </div>
                        <div class="help-block text-left" *ngIf="resetPasswordForm.valid && passwordData.newPassword != passwordData.confirmPassword">
                            <small class="p-invalid error-msg" [hidden]="!changePassword.errors.required && !confPassword.errors.required"> New password and Confirm password not matched.  </small>
                        </div>
                    </div>
                    <!--------------- submit btn  --------->
                    <div>
                        <button type="submit" class="btn common-btn full-width"> {{ "RESET_PASSWORD.RESET" | translate }} </button>
                    </div>

                    <div>
                        <a [routerLink]="''" class="forgot-text text-center"> {{ "VERIFICATION_CODE.BACK_TO_LOGIN" | translate }} </a>
                    </div>
                </form>
                <!--------------- end of form  --------->
            </div>

        </div>
    </div>

    <p-toast class="toast-card" position="top-center"></p-toast>

</div>