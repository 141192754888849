import { Component, OnInit } from "@angular/core";
import { AppService } from './../../app/app.service';

import {
    CommonService,
    HeaderService,
    PropertyManagerService,
    SidebarService,
    LocalStorageService
} from "src/providers/providers";
import { LazyLoadEvent } from 'primeng/api';
import { Router } from "@angular/router";
import { ActivityLogComponent } from '../tenant-activity-log/tenant-activity-log.component';

@Component( {
    selector: "app-resident-profile",
    templateUrl: "./tenant-profile.component.html",
    styleUrls: ["./tenant-profile.component.scss"],
} )
export class TenantProfileComponent implements OnInit {
    
    tenantData: any;
    tenantProfileData: any
    tenantActivityList: any = [];
    filterData: any = {};
    public isUpdate: boolean;
    isTenantActivityNoData: boolean = false;
    public activityData : any = {
        unitId: '',
        sortBy: '',
        sortManner: ''
    }
    public userData;
    public breadcrum: any;
    
    public requestPayLoad: any = 
        {
            followUpDate: "",
            lastActionTaken: "",
            noteType: "TENANT_PROFILE",
            promiseToPay: "",
            unitId: "",
            tenantNotes: ""
        }
          
    constructor(
        public header: HeaderService,
        public sidebar: SidebarService,
        protected pmService: PropertyManagerService,
        protected commonService: CommonService,
        private appService: AppService,
        public localStr: LocalStorageService,
        private router: Router
    ) {         
        this.tenantData = this.router.getCurrentNavigation().extras;
        console.log("tenantProfile.>>>>>>", this.tenantData );
        this.userData = this.localStr.getObj('userData');
        console.log("tenantProfile.>>>>>>", this.tenantData );
        if(this.tenantData.skipLocationChange == false && this.userData.isLoggedInUser == true){
            this.tenantData = this.localStr.getObj ("TenantUnitId");
        }
        
        if(this.tenantData.replaceUrl){            
            this.tenantData = this.localStr.getObj ("TenantUnitId");            
        }
    }

    ngOnInit(): void {
        this.localStr.setObj ("TenantUnitId", this.tenantData);
        this.activityData.unitId = this.localStr.getObj ("TenantUnitId");

        this.getTenentProfile();
        this.getTenentActivity();
        var isSliderShow = this.localStr.getObj("isSliderShow");
        if(isSliderShow){
            this.sidebar.show();
            this.localStr.setObj("isSliderShow", true);
        } else {
            this.sidebar.hide();
            this.localStr.setObj("isSliderShow", false);
        }        
        this.header.show();

        this.breadcrum = this.localStr.getObj ("breadcrum");
        this.appService.setPreTitle( this.breadcrum );
        this.appService.setTitle( "Resident's Profile" );
        
        this.userData = this.localStr.getObj('userData');
    }


    /**
     * Function to get Tenent Profile
     *
     */
    public getTenentProfile() {
        this.commonService.showLoading();
        this.pmService.getTenentProfileData( this.tenantData ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log("response profile data::>>>>> ", res.data);                    
                    this.tenantProfileData = res.data;
                } else {
                    this.tenantProfileData = [];
                }
            },
            ( error ) => {
                this.commonService.hideLoading();
                this.tenantProfileData = [];
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    /**
     * Function to get Tenent Activity
     *
     */
    public getTenentActivity = () => {
        this.commonService.showLoading();
        console.log("this.tenant activity data check.....", this.activityData);
        
        this.pmService.getTenentActivityData( this.activityData ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log("response Tenent Activity data::>>>>> ", res);
                    this.tenantActivityList = res.data;
                    if (this.tenantActivityList && this.tenantActivityList.length == 0 ) {
                        this.isTenantActivityNoData = true;
                    } else {
                        this.isTenantActivityNoData = false;
                    }
                } else {
                    this.tenantActivityList = [];
                }
            },
            ( error ) => {
                this.commonService.hideLoading();
                this.tenantActivityList = [];
                this.isTenantActivityNoData = true;
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    /**
     * Function coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo( "Coming soon" );
    }

    /**
     * Function to get Add Tenent Activity API call
     *
     */
    public addActivityCheck = (data) => {
        data.noteType = "TENANT_PROFILE";
        data.unitId = this.tenantData;
        
        this.requestPayLoad = data;
        console.log("addActivity::>>>>>>this.requestPayLoad >>>> ", this.requestPayLoad);
        var value = new Date (this.requestPayLoad.promiseToPay);        

        var dateSet = value.getDate();
        var monthSet = value.getMonth()+1;
        var yearSet = value.getFullYear(); 

        var checkDate = yearSet + '-' + this.getMonthNumber(monthSet) + '-' + this.getMonthNumber(dateSet);
        this.requestPayLoad.promiseToPay = checkDate;
        
        console.log("this.requestPayLoad.promiseToPay >>>>> ",this.requestPayLoad.promiseToPay);

        this.commonService.showLoading();
        this.pmService.updateTenantActivity( this.requestPayLoad ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "addActivity: res:>>>>>", res );
                    this.commonService.clearToast();
                    this.commonService.showSuccess("Log updated Successfuly.");
                    this.getTenentActivity()

                }
            },
            ( error ) => {
                this.commonService.hideLoading();

                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        )
    }

    /**
     * Function for add tenant activity popup
     */
    public addActivity = (data ) => {
       
        if(this.userData.roleType !=='SUPER_ADMIN' && this.userData.roleType !=='SUB_ADMIN') {
            
        console.log("addActivity::>>>>>>data ", data);
            this.filterData = data;
            console.log("addActivity::>>>>>> this.filterData.isUpdate ", this.filterData);
            this.commonService.openConfirmModal( ActivityLogComponent, this.commonService.copy(this.filterData), ( data ) => {
                console.log("addActivity::>>>>>> ", data);
                if ( data.lastActionTaken || data.tenantNotes) {
                    this.addActivityCheck( data );
                }
            });
        }
        
    }

    public customSort( data: LazyLoadEvent, type ) {
        console.log( "sort", data );
            switch ( data.sortField ) {
                case "actionTaken":
                    this.activityData.sortBy = "ACTIONTAKEN";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.activityData.sortManner = "DESC";
                            break;
                        case -1:
                            this.activityData.sortManner = "ASC";
                            break;
                        default:
                            this.activityData.sortManner = "NONE";
                            break;

                    }
                    break;
                case "followUpDate":
                    this.activityData.sortBy = "FOLLOWUPDATE";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.activityData.sortManner = "DESC";
                            break;
                        case -1:
                            this.activityData.sortManner = "ASC";
                            break;
                        default:
                            this.activityData.sortManner = "NONE";
                            break;

                    }
                    break;
                case "promiseToPay":
                    this.activityData.sortBy = "PROMISETOPAY";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.activityData.sortManner = "DESC";
                            break;
                        case -1:
                            this.activityData.sortManner = "ASC";
                            break;
                        default:
                            this.activityData.sortManner = "NONE";
                            break;

                    }
                    break;                
                default:
                    this.activityData.sortBy = "";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.activityData.sortManner = "DESC";
                            break;
                        case -1:
                            this.activityData.sortManner = "ASC";
                            break;
                        default:
                            this.activityData.sortManner = "NONE";
                            break;

                    }
                    break;
        }
        this.getTenentActivity();
    }

    /** 
     * Function to get month 
     * **/
    public getMonthNumber = ( monthNumber?: number ) => {       
        var result = Number(monthNumber);
        if ( result < 10 ) {
            return "0" + result;
        } else {
            return result;
        }        
    }

    ngOnDestroy() {
        console.log('destroy is called');
        if(this.localStr.getObj("dataCheck")){
            var dataCheck = this.localStr.getObj("dataCheck");
            this.localStr.setObj("dataCheck", dataCheck);
        }
        this.localStr.getObj ("TenantUnitId");
        this.appService.setPreTitle( '' );
      }
}
