/*--------------------Angular related components---------------*/
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable, timer, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, mergeMap } from 'rxjs/operators';

/*-----------------App Providers---------------------*/
import { AppSettings } from '../app-settings-service/app-settings.service';
import { Constants } from '../app-settings-service/app-constant.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { RestService } from '../rest-service/rest.service';
import { CommonService } from '../common-service/common-service';
import { HttpRequest, HttpHandler } from '@angular/common/http';
//import { Toaster } from 'ngx-toast-notifications';

@Injectable()
export class AuthService {
    errorMsg: string;
    successMsg: string;

    constructor( private commonService: CommonService, private restService: RestService, public locstr: LocalStorageService,
        private constant: Constants,
        //private toaster: Toaster,
        private router: Router ) {

    }



    /**
     * Function for login an User
     * @param user data 
     */
    public login = ( userData ) => {
        //userData["grant_type"] = '79Sjs%26dJSvJytE@u';
        userData["grant_type"] = 'password';
        //  userData["client_id"] = "rentdex-client";

        let loginData = `username=${userData.email}&password=${userData.password}&fcmDeviceToken=${userData.fcmDeviceToken}&grant_type=${userData.grant_type}`;

        const path = AppSettings.LOGIN_OAUTH_URL;
        return this.restService.postLoginCall( path, loginData, true, 60000 )
            .map( function( res ) {
                if ( typeof res == 'string' ) {
                    return JSON.parse( res );
                } else {
                    return res;
                }
            } )
            .catch( this.restService.handleError );
    }
    
    public getTokensFromRefreshToken = (refreshToken) => {
        const path = AppSettings.LOGIN_OAUTH_URL;
        const payload = `refresh_token=${refreshToken}&grant_type=${'refresh_token'}`;
        return this.restService
           .postLoginCall(path, payload, true, null)
           .map((res) => {
              return res;
           })
           .catch(this.restService.handleOauthError);
     }
    
    /**
     *  Function to store details to storage regarding id token,access token
     */
    public setCodeResponseToStorage = (authResponse) => {
       const userData = JSON.parse(authResponse);
       const tokenData = {
          access_token: userData.access_token,
          refresh_token: userData.refresh_token
       };
       this.locstr.setObj('tokenData', tokenData);
    }

    /**
     * Function for OTP request by an User
     * @param user data 
     */
    public forgotPassword( userData ): Observable<any> {
        const path = AppSettings.FORGOT_PASSWORD_URL;
        return this.restService.postCall( path, userData, true, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function for email verification by an User
     * @param email data, transaction id
     */
    public verifyOTP( passwordData ) {
        const path = AppSettings.VERIFY_OTP_URL;
        return this.restService.postCall( path, passwordData, true, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function to perform reset password web-service integration
     * @param data
     */
    resendVerficationCode( data: any ): Observable<any> {
        const path = AppSettings.RESEND_OTP_URL
        return this.restService.postCall( path, data, true, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }
    
    /**
     * Function for Reset Password by an User
     * @param password data 
     */
    public resetPassword( passwordData ): Observable<any> {
        const path = AppSettings.RESET_PASSWORD_URL;
        return this.restService.postCall( path, passwordData, true, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function for logout an user
     */
    public logout( userData ): Observable<any> {
        const path = AppSettings.LOGOUT_URL;
        return this.restService.deleteCall( path, false, 60000, userData )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function to perform change password web-service integration
     * @param data
    */
    changePassword(passwordData:any): Observable<any> {
    const path = AppSettings.CHANGE_PASSWORD_URL;
    return this.restService.postCall(path, passwordData, null, 60000)     
    }

     /**
     * Function for update notification setting
     * 
     */
    public updateNotificationSetting = (notificationData) => {
        const path = AppSettings.UPDATE_NOTIFICATION_SETTING_URL;
        return this.restService.putCall(path, notificationData, false, 60000)
           .map(function (res) {
              if (typeof res == 'string') {
                 return JSON.parse(res);
              } else {
                 return res;
              }
           })
           .catch(this.restService.handleError);
     }

    /**
     * Function to get notification setting
     */
    public getNotificationSetting(  ): Observable<any> {
        const path = AppSettings.GET_NOTIFICATION_SETTING_URL;
        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function to get notification setting
     */
    public getNotificationList(param ): Observable<any> {
        const path = AppSettings.GET_NOTIFICATION_LIST_URL +"?size=" +
        param.pageSize +
        "&page=" +
        param.pageNumber + "&searchText="+ param.searchText
        + "&sortBy=" + param.sortBy + "&sortManner=" + param.sortManner;
        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

     /**
     * Function for update my profile
     * @param user data 
     */
    public updateMyProfile = (userData) => {
        const path = AppSettings.MY_PROFILE_UPDATE_URL;
        return this.restService.putCall(path, userData, false, 60000)
           .map(function (res) {
              if (typeof res == 'string') {
                 return JSON.parse(res);
              } else {
                 return res;
              }
           })
           .catch(this.restService.handleError);
     }


    /**
     * Function to perform common error handling
     * @param error
     */
    handleError = ( errorResponse: Response | any ) => {
        if ( ( errorResponse || errorResponse != null ) && errorResponse.name != 'TimeoutError' ) {
            console.log( "handleError", errorResponse.error.status );
            let error = errorResponse.error;
            if ( error && error.code == 401 ) {
                setTimeout(() => {
                }, 500 );
            } else {
                if ( error && error.message && ( error.code != 0 ) ) {
                    if ( error.message == this.constant.ERROR_NETWORK_UNAVAILABLE ) {
                        return Observable.throw( error );
                    } else {
                        console.log( 'errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr', error );
                        //this.toaster.open( { text: error.message, type: 'danger', position: 'top-center' } );
                        var err: any;
                        err = error;
                    }
                } else {
                    var errorMsg: string = this.constant.ERROR_MSG_UNABLE_TO_CONNECT;
                    if ( error && !error.message && ( error.statusCode == 0 ) ) {
                        errorMsg = this.constant.UNABLE_TO_CONNECT_SERVER_MSG;
                    }
                }
            }
        } else {
        }
        return Observable.throw( err );
    }
    
    /**
     * Function to send notification token
     */
    public sendToken = (userData) => {
        const path = AppSettings.NOTIFICATION_URL;
        return this.restService.postCall(path, userData, false, 60000)
           .map(function (res) {
              return res;
           })
           .catch(this.restService.handleError);
     }

}