<!-- Container -->
<div class="form-container filter-form-cls">
  <div class="">

     <!-- Heading -->
     <div class="row filter-heading m-0">
        <div class="col-6">
           <h2 class="form-title"> {{"COMMON_COMPONENT.FILTER" | translate }} </h2>
        </div>
        <div class="col-6">
           <button class="btn link-btn clear-btn ripple" (click)="clearFilter()"> {{"COMMON_COMPONENT.CLEAR_ALL" | translate }} </button>
        </div>
     </div>

     <div class="container custom-ver-tabs set-top-model">

        <!-- <div class="col nav-tabs-wrap"> -->
        <!-- Nav tabs -->
        <ul class="nav nav-tabs tabs-left sideways" id="myTab" role="tablist" >
           <li class="nav-item active" [ngClass]="{active: tab == 1}"><a (click)="tab=1;" class="nav-link active" href="#eviction-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 2}"><a (click)="tab=2;" class="nav-link" href="#type-eviction" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.TYPE_OF_EVICTION" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 3}"><a (click)="tab=3;" class="nav-link" href="#eviction-filed-v" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.EVICTION_FILED" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 4}"><a (click)="tab=4;" class="nav-link" href="#initial-court-date-v" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.INITIAL_COURT_DATE" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 5}"><a (click)="tab=5;" class="nav-link" href="#second-court-date-v" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.SECOND_COURT_DATE" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 6}"><a (click)="tab=6;" class="nav-link" href="#other-court-date-v" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.OTHER_COURT_DATE" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 7}"><a (click)="tab=7;" class="nav-link" href="#stay-date-v" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.EVICTION_STAY_DATE" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 8}"><a (click)="tab=8;" class="nav-link" href="#sheriff-ordered-v" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.SHERIFF_ORDERED" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 9}"><a (click)="tab=9;" class="nav-link" href="#possession-unit-v" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.POSSESSION_OF_UNIT" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 10}"><a (click)="tab=10;" class="nav-link" href="#copp-date-v" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.COPP_DATE" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 11}"><a (click)="tab=11;" class="nav-link" href="#days-ongoing-v" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.DAYS_ONGOING" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 12}"><a (click)="tab=12;" class="nav-link" href="#cost-v" data-toggle="tab" role="tab"> {{ "EVICTION_PIPELINE.COST" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 13}"><a (click)=" tab=13;" class="nav-link" href="#acronym-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RESIDENT_ACRONYM" | translate }} </a></li>
         </ul>
        <!-- </div> -->
        <!-- Tab panes -->

        <div class="tab-content set-scroll">

           <!----- Eviction Status ---->
           <div class="tab-pane" id="eviction-v" [ngClass]="{active: filterPayload}" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-dropdown class="form-dropdown" name="evictionFilter" [options]="evictionStatusList" [(ngModel)]="filterPayload.evictionStatus"></p-dropdown>
                    </div>
                 </div>
              </form>
           </div>

           <!----- Eviction Type ----->
           <div class="tab-pane" id="type-eviction" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-dropdown class="form-dropdown" name="typeOfEviction" [options]="evictionTypeList" [(ngModel)]="filterPayload.typeOfEviction"></p-dropdown>
                    </div>
                 </div>
              </form>
           </div>

           <!----- Eviction Filed ----->
           <div class="tab-pane" id="eviction-filed-v" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish"   [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.evictionFiledFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.evictionFiledTo" [disabled]="!filterPayload.evictionFiledFrom" [minDate]="filterPayload.evictionFiledFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <span class="p-invalid error-msg" *ngIf="!filterPayload.evictionFiledFrom">Please select from date first.</span>
                 </div>
              </form>
           </div>

           <!----- Initial Court Date ----->
           <div class="tab-pane" id="initial-court-date-v" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish"   [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.initialCourtDateFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.initialCourtDateTo" [disabled]="!filterPayload.initialCourtDateFrom" [minDate]="filterPayload.initialCourtDateFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <span class="p-invalid error-msg" *ngIf="!filterPayload.initialCourtDateFrom">Please select from date first.</span>
                 </div>
              </form>
           </div>

           <!----- Second Court Date ----->
           <div class="tab-pane" id="second-court-date-v" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish"   [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.secondCourtDateFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.secondCourtDateTo" [disabled]="!filterPayload.secondCourtDateFrom" [minDate]="filterPayload.secondCourtDateFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <span class="p-invalid error-msg" *ngIf="!filterPayload.secondCourtDateFrom">Please select from date first.</span>
                 </div>
              </form>
           </div>
           
           <!----- Other Court Date ----->
           <div class="tab-pane" id="other-court-date-v" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish"   [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.otherCourtDateFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.otherCourtDateTo" [disabled]="!filterPayload.otherCourtDateFrom" [minDate]="filterPayload.otherCourtDateFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <span class="p-invalid error-msg" *ngIf="!filterPayload.otherCourtDateFrom">Please select from date first.</span>
                 </div>
              </form>
           </div>

           <!----- Stay Court Date ----->
           <div class="tab-pane" id="stay-date-v" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish"   [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.evictionStayDateFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.evictionStayDateTo" [disabled]="!filterPayload.evictionStayDateFrom" [minDate]="filterPayload.evictionStayDateFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <span class="p-invalid error-msg" *ngIf="!filterPayload.evictionStayDateFrom">Please select from date first.</span>
                 </div>
              </form>
           </div>

           <!----- Sheriff Ordered Date ----->
           <div class="tab-pane" id="sheriff-ordered-v" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish"   [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.sheriffOrderedFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.sheriffOrderedTo" [disabled]="!filterPayload.sheriffOrderedFrom" [minDate]="filterPayload.sheriffOrderedFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <span class="p-invalid error-msg" *ngIf="!filterPayload.sheriffOrderedFrom">Please select from date first.</span>
                 </div>
              </form>
           </div>

           <!----- Possession Date ----->
           <div class="tab-pane" id="possession-unit-v" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish"   [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.possessionOfUnitFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.possessionOfUnitTo" [disabled]="!filterPayload.possessionOfUnitFrom" [minDate]="filterPayload.possessionOfUnitFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <span class="p-invalid error-msg" *ngIf="!filterPayload.possessionOfUnitFrom">Please select from date first.</span>
                 </div>
              </form>
           </div>

           <!----- COPP Date ----->
           <div class="tab-pane" id="copp-date-v" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish"   [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.coppDateFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.coppDateTO" [disabled]="!filterPayload.coppDateFrom" [minDate]="filterPayload.coppDateFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <span class="p-invalid error-msg" *ngIf="!filterPayload.coppDateFrom">Please select from date first.</span>
                 </div>
              </form>
           </div>

           <!----- Days Ongoing Date ----->
           <div class="tab-pane" id="days-ongoing-v" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish"   [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.daysOngoingFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <div class="col-sm-12 form-group">
                       <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.daysOngoingTo" [disabled]="!filterPayload.daysOngoingFrom" [minDate]="filterPayload.daysOngoingFrom" [monthNavigator]="true"
                       [yearNavigator]="true" [yearRange]="range"></p-calendar>
                    </div>
                    <span class="p-invalid error-msg" *ngIf="!filterPayload.daysOngoingFrom">Please select from date first.</span>
                 </div>
              </form>
           </div>

           <!----- Cost ----->
           <div class="tab-pane" id="cost-v" role="tabpanel">
              <form class="form-inline crud-form filter-form">
                 <div class="row">
                    <div class="col-sm-12 form-group">
                       <input type="number" id="from" name="from" class="form-control form-input set-wdt" min="0" [(ngModel)]="filterPayload.costFrom" placeholder="From" />
                    </div>
                    <div class="col-sm-12 form-group">
                       <input type="number" id="to" name="to" class="form-control form-input set-wdt" min="0" [(ngModel)]="filterPayload.costTo" placeholder="To" />
                    </div>
                 </div>
              </form>
           </div>

           <!----- Resident Acronym List ----->
           <div class="tab-pane" id="acronym-v" role="tabpanel">
            <form class="form-inline crud-form filter-form">
               <div class="row">
                  <div class="col-sm-12 form-group">
                     <p-dropdown class="form-dropdown" name="acronymFilter" [options]="acronymList" [(ngModel)]="filterPayload.acronym"></p-dropdown>
                  </div>
               </div>
            </form>
         </div>
        </div>

        <div class="clearfix"></div>
     </div>

     <!--------------- modal btns  --------->
     <div class="row d-flex modal-footer m-0">
        <button type="button" class="btn link-btn" (click)="cancelClick()"> {{"COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
        <button type="submit" class="btn common-btn" (click)="applyFilter()"> {{"COMMON_COMPONENT.APPLY_BUTTON" | translate }} </button>
     </div>
     
  </div>
</div>