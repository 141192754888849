import { Component, OnInit, ViewEncapsulation, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, MetaDataService, PropertyManagerService } from 'src/providers/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplacePipe } from 'src/providers/common-service/replace-pipe';
import { TitleCasePipe } from '@angular/common';
import * as _ from "lodash";
@Component( {
    selector: 'app-pm-delinquency-filter',
    templateUrl: './pm-delinquency-filter.component.html',
    styleUrls: ['./pm-delinquency-filter.component.scss'],

    encapsulation: ViewEncapsulation.None
} )
export class PMDelinquencyFilterComponent implements OnInit {

    public pageName: string;
    public firstButton: string;
    public secondButton: string;

    @Input() filterPayload: any;

    public pmNameItemList: any = [];
    public actionItemList: any = [];
    public acronymList: any = [];

    tab: any;


    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = ( this.currentYear + 99 );
    public minyear = ( this.currentYear - 99 );
    range: string = ( this.minyear ).toString() + ":" + ( this.maxyear ).toString();
    //public replaceChar= new ReplacePipe();
    constructor( private replaceChar: ReplacePipe, private toTitleCase: TitleCasePipe, private router: Router, private commonService: CommonService, public activeModal: NgbActiveModal,
        public metaDataService: MetaDataService,
        public propertyManagerService: PropertyManagerService) {
        let pageName = 'Filter';
        let firstButton = 'Cancel';
        let secondButton = 'Apply';
        console.log("filterData",this.filterPayload);
    }

    ngOnInit(): void {
        if(this.filterPayload.isHistoricalStats){
            this.tab = 9;
        } else {
            this.tab = 1;
        }
        
        console.log( "filterData :: ", this.filterPayload );
        this.getPMList();
        if ( this.filterPayload.totalUnitFrom == 0 ) {
            this.filterPayload.totalUnitFrom = null;
        }
        if ( this.filterPayload.totalUnitTo == 0 ) {
            this.filterPayload.totalUnitTo = null;
        }
        if ( this.filterPayload.totalOccupiedUnitFrom == 0 ) {
            this.filterPayload.totalOccupiedUnitFrom = null;
        }
        if ( this.filterPayload.totalOccupiedUnitTo == 0 ) {
            this.filterPayload.totalOccupiedUnitTo = null;
        }

        if ( this.filterPayload.totalResidentPaidRentTo == 0 ) {
            this.filterPayload.totalResidentPaidRentTo = null;
        }
        if ( this.filterPayload.totalResidentPaidRentFrom == 0 ) {
            this.filterPayload.totalResidentPaidRentFrom = null;
        }
        if ( this.filterPayload.totalSubsidizedPaidRentFrom == 0 ) {
            this.filterPayload.totalSubsidizedPaidRentFrom = null;
        }
        if ( this.filterPayload.totalSubsidizedPaidRentTo == 0 ) {
            this.filterPayload.totalSubsidizedPaidRentTo = null;
        }
        if ( this.filterPayload.totalCollectedFrom == 0 ) {
            this.filterPayload.totalCollectedFrom = null;
        }
        if ( this.filterPayload.totalCollectedTo == 0 ) {
            this.filterPayload.totalCollectedTo = null;
        }
        if ( this.filterPayload.totalResidentCollectedFrom == 0 ) {
            this.filterPayload.totalResidentCollectedFrom = null;
        }
        if ( this.filterPayload.totalResidentCollectedTo == 0 ) {
            this.filterPayload.totalResidentCollectedTo = null;
        } 
        if ( typeof this.filterPayload.pmName == 'object' ) {
            this.filterPayload.pmName = "";
        }
    }

  
     /**
       * Function to get PM list
       */
      private getPMList = () => {
        this.pmNameItemList = [];
        this.propertyManagerService.getPMEANameIdList(true).subscribe(
          (res) => {
            if (res.code == 200 || res.status == 200) {
              var pmEaNameIdList = res.data;
              for (let i = 0; i <= pmEaNameIdList.length; i++) {
                if(pmEaNameIdList[i]){
                    this.pmNameItemList.push({ label: pmEaNameIdList[i].name, value: pmEaNameIdList[i].name });
                }
            }
              console.log("pmEaNameItemList >>> ::::::: ", this.pmNameItemList);
              this.pmNameItemList = _.cloneDeep(this.pmNameItemList);
            }        
            this.commonService.hideLoading();
          },
          (error) => {
            this.pmNameItemList = [];
            if (error.message) {
              this.commonService.clearToast();
              this.commonService.showError(error.message);
            } else {
              this.commonService.clearToast();
              this.commonService.showError(error.error_description);
            }
            this.commonService.hideLoading();
          }
        );
      }


    /**
     * function on cancel Click
     */
    cancelClick = () => {
        this.activeModal.dismiss( '' );
    }
    /**
       * function on clear Click
       */
      clearFilter = () => {
        this.filterPayload.pmName = "";
        this.filterPayload.totalUnitFrom= null;
        this.filterPayload.totalUnitTo= null;
        this.filterPayload.totalOccupiedUnitFrom= null;
        this.filterPayload.totalOccupiedUnitTo= null;
        this.filterPayload.totalRentRollFrom= null;
        this.filterPayload.totalRentRollTo= null;
        this.filterPayload.totalResidentPaidRentFrom= null;
        this.filterPayload.totalResidentPaidRentTo= null;
        this.filterPayload.totalSubsidizedPaidRentFrom= null;
        this.filterPayload.totalSubsidizedPaidRentTo= null;
        this.filterPayload.totalCollectedFrom= null;
        this.filterPayload.totalCollectedTo= null;
        this.filterPayload.totalResidentCollectedFrom= null;
        this.filterPayload.totalResidentCollectedTo= null;
    }
    /**
     * function on cancel Click
     */
    applyFilter = () => {
        if ( typeof this.filterPayload.pmName === 'object' ) {
            this.filterPayload.pmName = "";
        }
        console.log( "filterData :: ", this.filterPayload,typeof this.filterPayload.pmName );
        this.activeModal.dismiss( this.filterPayload );
    }

 
    /**
     * Function coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo("Coming soon");    
    }

    /**
     * Function clear coming soon
     */
    public clearCommingSoon = () => {
        this.commonService.clearToast();   
    }

}
