/**
 * Renewals Functionality
 * Created By : #1116
 * Date : 14 January, 2021
 */


import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';

import { AppService } from './../../../app/app.service';

import {
    CommonService,
    HeaderService,
    PropertyManagerService,
    SidebarService,
    HighchartServiceService,
    LocalStorageService,
    Constants
} from "src/providers/providers";

import { LazyLoadEvent } from 'primeng/api';

import { ExportPopupComponent } from 'src/pages/export-popup/export-popup.component';
import { DashboardFilterComponent } from '../dashboard-filter/dashboard-filter.component';
import { ViewRenewalComponent } from '../view-renewal/view-renewal.component';
import { Router } from '@angular/router';
import { Paginator } from 'primeng/paginator';

@Component( {
    selector: 'app-renewals',
    templateUrl: './renewals.component.html',
    styleUrls: ['./renewals.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class RenewalsComponent implements OnInit {
    @Input()
    loading1: boolean = true;
    @ViewChild('p', {static: false}) paginator: Paginator;

    public userData: any;
    isDashboard: boolean = false;

    public renewalPayload: any = {
        evictionStatus: "",
        renewalStatus: "",
        rentFrom: 0,
        rentTo: 0,
        searchText: "",
        targetRentFrom: 0,
        targetRentTo: 0,
        totalAmountFrom: 0,
        totalAmountTo: 0,
        unitId: "",
        pageNumber: 0,
        pageSize: 25,
        sortBy: "",
        sortManner: "",
        leaseExpirationFrom: null,
        leaseExpirationTO: null,
        totalBalanceTo: 0,
        totalBalanceFrom: 0
    };

    public renewalsTenantList: any = [];
    isRenewalsNoData: boolean = false;
    renewalDefaultPage = 0;
    renewalDefaultSize = 10;

    filterData: any = {};
    public isSearchCall: boolean = false;
    public isRenewalList: boolean = true;

    constructor( public header: HeaderService,
        public sidebar: SidebarService, private appService: AppService,
        public localStr: LocalStorageService,
        protected commonService: CommonService,
        protected pmService: PropertyManagerService,
        private router: Router,
        public constants: Constants ) {
            this.renewalPayload.pageSize  = this.constants.defaultPageSize
         }

    ngOnInit(): void {
        var isSliderShow = this.localStr.getObj("isSliderShow");
        if(isSliderShow){
            this.sidebar.show();
            this.localStr.setObj("isSliderShow", true);
        } else {
            this.sidebar.hide();
            this.localStr.setObj("isSliderShow", false);
        }     
        this.header.show();

        this.localStr.setObj("breadcrum", "");
        this.appService.setTitle( 'Renewals' );
        this.userData = this.localStr.getObj( 'userData' );
    }

    /**
   * Function to get renewals list
   *
   */
    public getRenewalList($event) {

        if ( this.renewalPayload.sortBy == "" || this.renewalPayload.sortManner == "" ) {
            this.renewalPayload.sortBy = "";
            this.renewalPayload.sortManner = "";
        }

         // check data after visiting activity log
         if(this.localStr.getObj("beforeActivityData")){
            this.renewalPayload = this.localStr.getObj("beforeActivityData");
        }

        if ( !this.isSearchCall ) {
            this.commonService.showLoading();  // remove this when we use inplace loader
        }else if(this.isSearchCall){
            this.renewalPayload.pageNumber= 0;
        }

        this.commonService.showLoading(); // remove this when we use inplace loader 
        this.loading1 = true;
        this.pmService.getRenewalList( this.renewalPayload ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();

                if ( !this.isSearchCall ) {
                    this.commonService.hideLoading();
                }else if(this.isSearchCall){
                    this.paginator.changePageToFirst($event);
                }
                this.isSearchCall = false;

                if ( res.code == 200 || res.status == 200 ) {
                    this.renewalsTenantList = res.data.list;
                    for(let i=0; i<this.renewalsTenantList.length; i++){
                        if(this.renewalsTenantList[i].marketRent){
                            this.renewalsTenantList[i].marketRent = '$' + this.renewalsTenantList[i].marketRent;
                        }
                    }
                    if ( this.renewalsTenantList && this.renewalsTenantList.length == 0 ) {
                        this.isRenewalsNoData = true;
                    } else {
                        this.isRenewalsNoData = false;
                    }

                    this.renewalPayload.totalRecords = res.data.totalCount;
                    this.localStr.setObj("beforeActivityData", "");
                } else {
                    this.renewalsTenantList = [];
                }
                this.loading1 = false;
            },
            ( error ) => {
                this.commonService.hideLoading();

                if ( !this.isSearchCall ) {
                    this.commonService.hideLoading();
                }
                this.isSearchCall = false;

                this.renewalsTenantList = [];
                this.isRenewalsNoData = true;
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
                this.loading1 = false;
            }
        );
        this.commonService.hideLoading();
    }

    /**
     * Function for renewals list pagination
     */
    public renewalpaginate = ( event ) => {
        this.renewalDefaultPage = event.first / event.rows;
        this.renewalDefaultSize = event.rows;
        this.renewalsTenantList = [];
        this.renewalPayload.pageSize = this.constants.defaultPageSize;
        this.renewalPayload.pageNumber = this.renewalDefaultPage;
        this.getRenewalList(null);
    }

    /**
         *  Function for Export dialog 
         */
    openExportPopup() {
        this.commonService.openConfirmModal( ExportPopupComponent, this.filterData, ( data ) => {
            console.log( "log..data", this.filterData, data );
            if ( data.format ) {

                this.exportRenewal( data.format )
            }
        } );
    }

    /**
      * Function to  renewal export
      */
    public exportRenewal( reportFormat ) {
        this.commonService.showLoading();

        this.pmService.exportRenewal( this.renewalPayload, reportFormat, this.userData.id, this.isDashboard ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "res", res );

                    // window.open( res.data );
                    window.location.assign (res.data);
                }

            },
            ( error ) => {
                this.commonService.hideLoading();

                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    checkTypeOf = ( dataCheck ) => {
        if ( typeof dataCheck == 'object' ) {
            return dataCheck = "";
        } else {
            return dataCheck;
        }
    }

    /**
       * Function for open fiter popup
       */
    public clickOnFilter = ($event) => {
        this.filterData = this.renewalPayload;
        this.filterData.isRenewal = true;
        this.filterData.isRenewalList = this.isRenewalList;

        this.commonService.openConfirmModal( DashboardFilterComponent, this.filterData, ( data ) => {
            console.log( "filtrdata", data, data ?.leaseExpirationFrom ?.getTime() );


            this.renewalPayload.renewalStatus = this.checkTypeOf( this.renewalPayload.renewalStatus );
            this.renewalPayload.unitId = this.checkTypeOf( this.renewalPayload.unitId );


            if ( typeof this.renewalPayload.renewalStatus == 'object' ) {
                this.renewalPayload.renewalStatus = "";
            }
            if ( typeof this.renewalPayload.unitId == 'object' ) {
                this.renewalPayload.unitId = "";
            }

            if ( data ) {

                // this.renewalPayload.leaseExpirationFrom = data.leaseExpirationFrom ? data?.leaseExpirationFrom?.getTime() : null;
                // this.renewalPayload.leaseExpirationTO = data.leaseExpirationTO ? data?.leaseExpirationTO?.getTime() : null;
                // console.log( "renewalPayload", this.renewalPayload );
                this.renewalPayload.leaseExpirationFrom = data.leaseExpirationFrom;
                this.renewalPayload.leaseExpirationTO = data.leaseExpirationTO;
                this.renewalPayload.renewalStatus = data.renewalStatus;
                this.renewalPayload.rentFrom = data.rentFrom;
                this.renewalPayload.rentTo = data.rentTo;
                this.renewalPayload.subsidyFrom = data.subsidyFrom;
                this.renewalPayload.subsidyTo = data.subsidyTo;
                this.renewalPayload.targetRentFrom = data.targetRentFrom;
                this.renewalPayload.targetRentTo = data.targetRentTo;
                this.renewalPayload.unitId = data.unitId;
                this.renewalPayload.pageNumber = 0;
                this.paginator.changePageToFirst($event);
                this.getRenewalList(null)

            }

        } );
    }

    /**
       * Function to open Renewable details
       */
    public openRenewalDetails = ( renewalsTenant ) => {
        renewalsTenant.isRenewalList = this.isRenewalList;
        console.log( "renewalsTenant ::", renewalsTenant );
        let requestPayLoad;

        this.commonService.openConfirmModal( ViewRenewalComponent, this.commonService.copy( renewalsTenant ), ( data ) => {
            console.log( "renewalsTenant :: popup close ", data );
            if ( data ) {
                if(this.userData.roleType == 'EVICTION_ADMIN'){
                    requestPayLoad = {
                        "evictionStatus": data.evictionStatus,
                        "id": data.id,
                        "notes": data.renewableNotes
                    }

                    if((renewalsTenant.evictionStatus == requestPayLoad.evictionStatus) && (renewalsTenant.renewableNotes == requestPayLoad.notes)){
                        console.log("No channge");                   
                     } else {
                         this.updateRenewalStatus( requestPayLoad );
                     }
    
                    // if ( requestPayLoad.rentdexRenewalStatus || requestPayLoad.renewableNotes ) {
                    //     this.updateRenewalStatus( requestPayLoad );
                    // }
                } else {    
                requestPayLoad = {
                    "evictionStatus": data.evictionStatus,
                    "id": data.id,
                    "notes": data.renewableNotes,
                    "renewableStatus": data.status,
                    "rentdexRenewalStatus": data.rentdexRenewalStatus
                }

                if((renewalsTenant.rentdexRenewalStatus == requestPayLoad.rentdexRenewalStatus) && (renewalsTenant.renewableNotes == requestPayLoad.notes)){
                    console.log("No channge");                   
                 } else {
                     this.updateRenewalStatus( requestPayLoad );
                 }

                // if ( requestPayLoad.rentdexRenewalStatus || requestPayLoad.renewableNotes ) {
                //     this.updateRenewalStatus( requestPayLoad );
                // }
            }
        }
        } );
    }



    public updateRenewalStatus = ( requestPayLoad ) => {
        console.log( "updateRenewalStatus  :: renewalsTenant :: ", requestPayLoad );
        this.commonService.showLoading();

        requestPayLoad.rentdexRenewalStatus = this.checkTypeOf( requestPayLoad.rentdexRenewalStatus );
        requestPayLoad.evictionStatus = this.checkTypeOf( requestPayLoad.evictionStatus );

        if ( requestPayLoad) {

            this.pmService.updateRenewalStatusNote( requestPayLoad ).subscribe(
                ( res ) => {
                    this.commonService.hideLoading();
                    if ( res.code == 200 || res.status == 200 ) {
                        this.commonService.clearToast();
                        this.commonService.showSuccess( "Record updated successfully." );
                        this.getRenewalList(null);
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError( res.message );
                    }
                    this.commonService.hideLoading();
                    console.log( "updateRenewalStatus :: res :: ", res );
                },
                ( error ) => {
                    this.commonService.hideLoading();
                    if ( error && error.message ) {
                        this.commonService.clearToast();
                        this.commonService.showError( error.message );
                    } else if ( error && error.error_description ) {
                        this.commonService.clearToast();
                        this.commonService.showError( error.error_description );
                    }
                }
            );
        }
        this.commonService.hideLoading();
    }

    customSort( data: LazyLoadEvent ) {
        switch ( data.sortField ) {
            case "tenant":
                this.renewalPayload.sortBy = "NAME";
                switch ( data.sortOrder ) {
                    case 1:
                        this.renewalPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.renewalPayload.sortManner = "ASC";
                        break;
                    default:
                        this.renewalPayload.sortManner = "";
                        break;

                }
                break;
            case "unit":
                this.renewalPayload.sortBy = "UNIT";
                switch ( data.sortOrder ) {
                    case 1:
                        this.renewalPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.renewalPayload.sortManner = "ASC";
                        break;
                    default:
                        this.renewalPayload.sortManner = "";
                        break;

                }
                break;
            case "rent":
                this.renewalPayload.sortBy = "RENT";
                switch ( data.sortOrder ) {
                    case 1:
                        this.renewalPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.renewalPayload.sortManner = "ASC";
                        break;
                    default:
                        this.renewalPayload.sortManner = "";
                        break;

                }
                break;
            case "leaseTo":
                this.renewalPayload.sortBy = "LEASEEXPIRATION";
                switch ( data.sortOrder ) {
                    case 1:
                        this.renewalPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.renewalPayload.sortManner = "ASC";
                        break;
                    default:
                        this.renewalPayload.sortManner = "";
                        break;

                }
                break;
            default:
                this.renewalPayload.sortBy = "";
                switch ( data.sortOrder ) {
                    case 1:
                        this.renewalPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.renewalPayload.sortManner = "ASC";
                        break;
                    default:
                        this.renewalPayload.sortManner = "";
                        break;

                }
                break;



        }

        this.getRenewalList(null);

    }

     /** 
     * Function to open Tenant Details 
     */
    public openTenantDetails = ( tenantProfile ) => {
        console.log("renewalPayload ....", this.renewalPayload);
        this.localStr.setObj("beforeActivityData",this.renewalPayload);

        this.localStr.setObj("breadcrum", 'Renewals');

        this.router.navigate( ['/resident-profile'], tenantProfile );
    }

}
