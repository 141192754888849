<div class="background-image-wrapper">

    <!-- Container -->
    <div class="form-container change-form-cls">

        <div class="">

            <!-- Heading -->
            <div class="text-center col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <h2 class="form-title txt-capitalize">Change Password </h2>
            </div>
            <!-- Form -->

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <form class="common-form-cls" #changePasswordForm="ngForm" (ngSubmit)="submitChangePassword(changePasswordForm)" novalidate>
                    <!---------------Old password Field  --------->
                    <div class="form-group input-group">
                        <!-- <label></label> -->
                        <!-- <span><i class="password-icon icon-left input-icon"></i> </span> -->
                        <input type="text" autofocus="autofocus" style="display:none" />
                        <input type="{{oldInputType}}" [ngStyle]="{'border': (oldPassword.errors && (oldPassword.dirty || oldPassword.touched || formSubmitted)) ? '1px solid red' : '0' }" placeholder="Old Password" class="from-input pass-input" #oldPassword="ngModel" [(ngModel)]="passwordData.oldPassword"
                            id="old-password" name="oldPassword" minlength="6" maxlength="40" pInputText required>

                        <span (click)="hideShowPassword('oldPassword')" [ngClass]="oldPasswordCheck?'eye-open-icon icon-right input-icon':'eye-close-icon icon-right input-icon'"></span>
                        <div class="help-block text-left" *ngIf="oldPassword.errors && (oldPassword.dirty || oldPassword.touched || formSubmitted)">
                            <small [hidden]="!oldPassword.errors.required" class="p-invalid error-msg"> Please enter old password. </small>
                            <small [hidden]="!oldPassword.errors.minlength" class="p-invalid error-msg"> {{ "LOGIN.PASSWORD_LIMIT_IN_NO" | translate }} </small>
                            <!-- <small [hidden]="!oldPassword.errors.pattern" class="p-invalid error-msg">{{ "LOGIN.PASSWORD_PATTEREN_VALIDATION" | translate }} </small> -->
                        </div>

                    </div>

                    <!---------------New password Field  --------->
                    <div class="form-group input-group">
                        <!-- <label></label> -->
                        <!-- <span><i class="password-icon icon-left input-icon"></i> </span> -->
                        <input type="{{inputType}}" [ngStyle]="{'border': (newPassword.errors && (newPassword.dirty || newPassword.touched || formSubmitted)) ? '1px solid red' : '0' }" placeholder="New Password" class="from-input pass-input" #newPassword="ngModel" [(ngModel)]="passwordData.newPassword"
                            id="new-password" name="newPassword" minlength="6" maxlength="40" pInputText required>

                        <span (click)="hideShowPassword('newPassword')" [ngClass]="newPasswordCheck?'eye-open-icon icon-right input-icon':'eye-close-icon icon-right input-icon'"></span>
                        <div class="help-block text-left" *ngIf="newPassword.errors && (newPassword.dirty || newPassword.touched || formSubmitted)">
                            <small [hidden]="!newPassword.errors.required" class="p-invalid error-msg"> {{ "RESET_PASSWORD.ENTER_NEW_PASSWORD" | translate }} </small>
                            <small [hidden]="!newPassword.errors.minlength" class="p-invalid error-msg"> {{ "LOGIN.PASSWORD_LIMIT_IN_NO" | translate }} </small>
                            <!-- <small [hidden]="!newPassword.errors.pattern" class="p-invalid error-msg">{{ "LOGIN.PASSWORD_PATTEREN_VALIDATION" | translate }} </small> -->
                            <!-- pattern="(?=.*\d)(?=.*[a-z]).{6,9}" -->
                        </div>

                    </div>

                    <!---------------Confirm Password Field  --------->
                    <div class="form-group input-group">
                        <!-- <label></label> -->
                        <!-- <span><i class="password-icon icon-left input-icon"></i> </span> -->
                        <input type="{{confirmInputType}}" [ngStyle]="{'border': (confPassword.errors && (confPassword.dirty || confPassword.touched || formSubmitted)) ? '1px solid red' : '0' }" placeholder="Confirm Password" class="from-input pass-input" #confPassword="ngModel"
                            [(ngModel)]="passwordData.confirmPassword" id="confirm-password" name="confirmPassword" minlength="6" maxlength="40" pInputText required>

                        <span (click)="hideShowPassword('confirmPassword')" [ngClass]="confirmPasswordCheck?'eye-open-icon icon-right input-icon':'eye-close-icon icon-right input-icon'"></span>
                        <div class="help-block text-left" *ngIf="confPassword.errors && (confPassword.dirty || confPassword.touched || formSubmitted)">
                            <small [hidden]="!confPassword.errors.required" class="p-invalid error-msg"> Please confirm password. </small>
                            <small [hidden]="!confPassword.errors.minlength" class="p-invalid error-msg"> {{ "LOGIN.PASSWORD_LIMIT_IN_NO" | translate }} </small>
                            <!-- <small [hidden]="!confPassword.errors.pattern" class="p-invalid error-msg">{{ "LOGIN.PASSWORD_PATTEREN_VALIDATION" | translate }} </small> -->
                        </div>
                        <div class="help-block text-left" *ngIf="changePasswordForm.valid && passwordData.newPassword != passwordData.confirmPassword">
                            <small class="p-invalid error-msg" > New password and Confirm password not matched.  </small>
                        </div>
                    </div>
                    <!--------------- submit btn  --------->
                    <div class="row d-flex modal-footer">
                        <button type="button" class="btn link-btn cancel-btn-font" (click)="cancelClick()"> CANCEL </button>
                        <button type="submit" class="btn common-btn"> SUBMIT </button>
                    </div>


                </form>
                <!--------------- end of form  --------->
            </div>

        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>


</div>