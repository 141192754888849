import { Component, OnInit, ViewEncapsulation, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, LocalStorageService, MetaDataService } from 'src/providers/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplacePipe } from 'src/providers/common-service/replace-pipe';
import { TitleCasePipe } from '@angular/common';
import * as _ from "lodash";
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
    selector: 'app-view-delinquency',
    templateUrl: './view-delinquency.component.html',
    styleUrls: ['./view-delinquency.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ViewDelinquencyComponent implements OnInit {

    public pageName: string;
    public firstButton: string;
    public secondButton: string;
    public optionList: any;
    public actionItemList: any = [];
    public setMinDate: any;

    @Input() formData: any;
    public userData: any;
    public filterPayload: any = {
        subsidyFrom: 0,
        subsidyTo: 0,
        tenantAmountFrom: 0,
        tenantAmountTo: 0,
        totalAmountTo: 0,
        totalAmountFrom: 0,
        rentFrom: 0,
        rentTo: 0

    }
    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = ( this.currentYear + 99 );
    public minyear = ( this.currentYear );
    range: string = ( this.minyear ).toString() + ":" + ( this.maxyear ).toString();

    public pmStatusList: any = [];
    public evictionStatusList: any = [];
    public isMobile: boolean = false;
    deviceInfo = null;
    public isCheckPMStatus: boolean;
    constructor(private replaceChar: ReplacePipe, private toTitleCase: TitleCasePipe, private router: Router,
        private commonService: CommonService,
        public localStr: LocalStorageService,
        public activeModal: NgbActiveModal, private metaDataService: MetaDataService,
        private deviceService: DeviceDetectorService) {

        this.setMinDate = new Date();
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
        let pageName = 'Filter';
        let firstButton = 'Cancel';
        let secondButton = 'Apply';

    }

    ngOnInit(): void {
        this.userData = this.localStr.getObj('userData');
        console.log("filterData", this.formData);
        if(this.formData.promiseToPay){
            this.formData.promiseToPay = new Date(this.commonService.formatDate(this.formData.promiseToPay));
        }
        this.formData.delinquencyNotes = "";
        if (this.formData.delinquencyNotes == null) {
            this.formData.delinquencyNotes = this.formData.mostRecentNotes;
        }
        if(this.formData.pmStatus == "EVICTION" || this.formData.pmStatus == "eviction"){
            this.isCheckPMStatus = true;
        } else {
            this.isCheckPMStatus = false;
        }
        if (this.userData.roleType == 'EVICTION_ADMIN') {
            this.getEvictionStatusList();
            this.getActionItemList();
        } else {
            this.getPMStatusList();
            this.getActionItemList();
        }
    }

    /**
     * function on cancel Click
     */
    cancelClick = () => {
        this.activeModal.dismiss('');
    }
    /**
       * function on clear Click
       */
    clearFilter = () => {
        this.filterPayload = {
            subsidyFrom: 0,
            subsidyTo: 0,
            tenantAmountFrom: 0,
            tenantAmountTo: 0,
            totalAmountFrom: 0,
            totalAmountTo: 0,
            rentFrom: 0,
            rentTo: 0
        }
    }
    /**
     * function on cancel Click
     */
    applyFilter = () => {       
        console.log("this.formData ...", this.formData);
        this.activeModal.dismiss(this.formData);
    }

    /**
     * Function to get PM status
     */
    private getPMStatusList = () => {
        this.pmStatusList = [];

        this.metaDataService.getPMStatusList(
            (response) => {
                this.pmStatusList.push({ label: "Select", value: "" });
                response.map(status =>
                    this.pmStatusList.push(status)
                );

                if (!this.formData.pmStatus) {
                    this.formData.pmStatus = this.pmStatusList[0];
                }
                this.pmStatusList = _.cloneDeep(this.pmStatusList);
                this.commonService.hideLoading();
            },
            (reason) => {
                this.commonService.hideLoading();
                this.pmStatusList = reason;
            });
    }

    /**
     * Function to get PM status
     */
    private getEvictionStatusList = () => {
        this.evictionStatusList = [];

        this.metaDataService.getEvictionStatusList(
            (response) => {
                this.evictionStatusList.push({ label: "Select", value: "" });
                response.map(status =>
                    this.evictionStatusList.push(status)
                );

                if (!this.formData.evictionStatus) {
                    this.formData.evictionStatus = this.evictionStatusList[0];
                }
                this.evictionStatusList = _.cloneDeep(this.evictionStatusList);
                this.commonService.hideLoading();
            },
            (reason) => {
                this.commonService.hideLoading();
                this.evictionStatusList = reason;
            });
    }

    /**
     * Function to get action item
     */
    private getActionItemList = () => {
        this.actionItemList = [];
        this.metaDataService.getActionItemList(
            (response) => {
                this.commonService.hideLoading();
                this.actionItemList.push({ label: "Select", value: "" });

                response.map(status =>
                    this.actionItemList.push(status)
                );

                this.actionItemList = _.cloneDeep(this.actionItemList);
            },
            (reason) => {
                this.commonService.hideLoading();
                this.actionItemList = reason;
            });
    }

}
