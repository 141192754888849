<div class="row page-wrap">
    <div class="container-fluid">
        <div class="row m-0 pm-setting-panel-container">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
                <p-panel class="custom-panel-ui">                   
                    <!-- <hr class="panel-divider"> -->
                    <div class="panel-content">                       
                        <div class="p-formgroup-inline">                          
                            <form class="p-field-checkbox" #rentColledtedForm="ngForm" (ngSubmit)="goalsUpdate(rentColledtedForm)">
                                <p-header class="panel-header">
                                    <p class="noti-txt mar20"> {{ "GOALS.TENANT_RENT_COLLECTED" | translate }} </p>
                                </p-header>
                                <p class="noti-txt"> {{ "GOALS.CURRENT_GOAL" | translate }}: &nbsp; {{tenentRent}}% </p>
                                <div class="p-formgroup-inline">
                                    <label for="tenantGoal" class="default-chk-lbl font-bold"> {{ "GOALS.TENANT_RENT_GOALS" | translate }}: &nbsp; </label>
                                    <label class="goals-percentage"> {{goalsData.rentGoalPercentage}} %  </label>
                                    <!-- <input type="number" (keypress)="valueCheck(this)" maxlength="3" pattern="(^[1-9][0-9]?$|^100$)" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" class="form-control form-input" name="tenantRent" #tenantRent="ngModel" [(ngModel)]="goalsData.rentGoalPercentage" placeholder="Enter" /> -->
                                    <div  class="set-wdt30">
                                        <!-- <input type="number" class="form-control form-input" name="rentGoal" pInputText [ngModelOptions]="{standalone: true}" [(ngModel)]="goalsData.rentGoalPercentage" readonly/> -->
                                        <p-slider [(ngModel)]="goalsData.rentGoalPercentage" [ngModelOptions]="{standalone: true}"></p-slider>
                                    </div>
                                </div>
                                <hr class="panel-divider">
                                <p-header class="panel-header">
                                    <p class="noti-txt mar20"> {{ "GOALS.SUBSIDIZED_RENT_COLLECTED" | translate }} </p>
                                </p-header>
                                <div class="panel-content">
                                    <p class="noti-txt"> {{ "GOALS.CURRENT_GOAL" | translate }}: &nbsp; {{subsidyRent}}% </p>
                                    <div class="p-formgroup-inline">
                                        <div class="p-formgroup-inline">
                                            <label for="subsidizedGoal" class="default-chk-lbl font-bold"> {{ "GOALS.SUBSIDIZED_RENT_GOALS" | translate }}:  &nbsp; </label>
                                            <label class="goals-percentage"> {{goalsData.subsidyGoalPercentage}} %   </label>
                                            <!-- // <input type="number" maxlength="3" pattern="([0-9]|[1-9][0-9]|100)" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" class="form-control form-input" name="subsidizedRent" #subsidizedRent="ngModel" [(ngModel)]="goalsData.subsidyGoalPercentage" placeholder="Enter" /> -->
                                            <div class="set-wdt30">
                                                <!-- <input type="number" class="form-control form-input" name="subsidyGoal" pInputText [ngModelOptions]="{standalone: true}" [(ngModel)]="goalsData.subsidyGoalPercentage" /> -->
                                                <p-slider [(ngModel)]="goalsData.subsidyGoalPercentage" [ngModelOptions]="{standalone: true}"></p-slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn common-btn noti-btn" [disabled]= "goalsData.subsidyGoalPercentage == null || goalsData.rentGoalPercentage == null" > {{ "COMMON_COMPONENT.UPDATE" | translate }} </button>
                            </form>
                        </div>
                    </div>
                </p-panel>
            </div>
        </div>
    </div>
</div>