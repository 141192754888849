<!-- Container -->
<div class="form-container filter-form-cls">

    <div class="">

        <!-- Heading -->
        <div class="row filter-heading m-0">

            <div class="col-6">
                <h2 class="form-title"> {{"COMMON_COMPONENT.FILTER" | translate }} </h2>
            </div>

            <div class="col-6">
                <button class="btn link-btn clear-btn" (click)="clearFilter()"> {{"COMMON_COMPONENT.CLEAR_ALL" | translate }} </button>
            </div>
        </div>

        <div class="container custom-ver-tabs">
            <!-- <div class="col nav-tabs-wrap"> -->

            <!-- Nav tabs -->
            <ul class="nav nav-tabs tabs-left sideways" id="myTab1" role="tablist" *ngIf="filterPayload.isDeliquenceDashboard && !filterPayload.isDeliquence && !filterPayload.isRenewalDashboard && !filterPayload.isRenewal">
                <li class="nav-item active" [ngClass]="{active: tab == 1}"><a (click)=" tab=1; clearCommingSoon()" class="nav-link active" href="#total-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.TOTAL_BALANCE" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 2}"><a (click)=" tab=2; clearCommingSoon()" class="nav-link" href="#tenant-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.TENANT_BALANCE" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 6}"><a (click)=" tab=6; clearCommingSoon()" class="nav-link" href="#promise-to-pay-v" data-toggle="tab" role="tab"> {{ "ACTIVITY.PROMISE_TO_PAY" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 3}"><a (click)=" tab=3; clearCommingSoon()" class="nav-link" href="#action-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.ACTION_ITEM" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 4}"><a (click)=" tab=4; clearCommingSoon()" class="nav-link" href="#pm-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.PMS_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 5}"><a (click)=" tab=5; clearCommingSoon()" class="nav-link" href="#eviction-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 8}"><a (click)=" tab=8; clearCommingSoon()" class="nav-link" href="#acronym-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RESIDENT_ACRONYM" | translate }} </a></li>
            </ul>

            <ul class="nav nav-tabs tabs-left sideways" id="myTab" role="tablist" *ngIf="filterPayload.isDeliquence && !filterPayload.isRenewalDashboard && !filterPayload.isRenewal && !filterPayload.isDeliquenceDashboard">
                <li class="nav-item active" [ngClass]="{active: tab == 4}"><a (click)=" tab=4; clearCommingSoon()" class="nav-link active" href="#pm-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.PMS_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 5}"><a (click)=" tab=5; clearCommingSoon()" class="nav-link" href="#eviction-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 1}"><a (click)=" tab=1; clearCommingSoon()" class="nav-link" href="#total-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.TOTAL_BALANCE" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 2}"><a (click)=" tab=2; clearCommingSoon()" class="nav-link" href="#tenant-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.TENANT_BALANCE" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 7}"><a (click)=" tab=7; clearCommingSoon()" class="nav-link" href="#subsidy-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.SUBSIDY_BALANCE" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 3}"><a (click)=" tab=3; clearCommingSoon()" class="nav-link" href="#action-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.ACTION_ITEM" | translate }} </a></li>
                <!--<li class="nav-item" [ngClass]="{active: tab == 6}"><a (click)=" tab=6; commingSoon()" class="nav-link" href="#unit-v" data-toggle="tab" role="tab" *ngIf="filterPayload.isDeliquenceList"> {{ "PM_DASHBOARD.UNIT" | translate }} </a></li>-->
                <li class="nav-item" [ngClass]="{active: tab == 8}"><a (click)=" tab=8; clearCommingSoon()" class="nav-link" href="#acronym-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RESIDENT_ACRONYM" | translate }} </a></li>
            </ul>

            <ul class="nav nav-tabs tabs-left sideways" id="deliquencyTab1" role="tablist" *ngIf="filterPayload.isRenewalDashboard && !filterPayload.isRenewal && !filterPayload.isDeliquenceDashboard && !filterPayload.isDeliquence">
                <li class="nav-item active" [ngClass]="{active: tab == 8}"><a class="nav-link active" (click)=" tab=8; clearCommingSoon()" href="#rent-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RENT" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 9}"><a (click)=" tab=9; clearCommingSoon()" class="nav-link" href="#lease-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.LEASE_EXPIRATION" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 10}"><a (click)=" tab=10; clearCommingSoon()" class="nav-link" href="#renewal-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RENEWAL_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 11}"><a (click)=" tab=11; clearCommingSoon()" class="nav-link" href="#eviction-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 12}"><a (click)=" tab=12; clearCommingSoon()" class="nav-link" href="#subsidy-v" data-toggle="tab" role="tab" *ngIf="!filterPayload.isRenewalList"> {{ "PM_DASHBOARD.SUBSIDY" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 13}"><a (click)=" tab=13; clearCommingSoon()" class="nav-link" href="#target-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.TARGET_RENT" | translate }} </a></li>
                <!--<li class="nav-item" [ngClass]="{active: tab == 14}"><a (click)=" tab=14; clearCommingSoon()" class="nav-link" href="#unit-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.UNIT" | translate }} </a></li>
           <li class="nav-item" [ngClass]="{active: tab == 15}"><a (click)=" tab=15; commingSoon()" class="nav-link" href="#total-v" data-toggle="tab" role="tab" *ngIf="filterPayload.isRenewalList"> {{ "PM_DASHBOARD.TOTAL_BALANCE" | translate }} </a></li>-->
                <li class="nav-item" [ngClass]="{active: tab == 14}"><a (click)=" tab=14; clearCommingSoon()" class="nav-link" href="#acronym-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RESIDENT_ACRONYM" | translate }} </a></li>
            </ul>

            <ul class="nav nav-tabs tabs-left sideways" id="deliquencyTab" role="tablist" *ngIf="filterPayload.isRenewal && !filterPayload.isDeliquenceDashboard && !filterPayload.isDeliquence && !filterPayload.isRenewalDashboard">
                <li class="nav-item active" [ngClass]="{active: tab == 10}"><a (click)=" tab=10; clearCommingSoon()" class="nav-link active" href="#renewal-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RENEWAL_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 11}"><a (click)=" tab=11; clearCommingSoon()" class="nav-link" href="#eviction-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 9}"><a (click)=" tab=9; clearCommingSoon()" class="nav-link" href="#lease-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.LEASE_EXPIRATION" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 8}"><a class="nav-link" (click)=" tab=8; clearCommingSoon()" href="#rent-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RENT" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 12}"><a (click)=" tab=12; clearCommingSoon()" class="nav-link" href="#subsidy-v" data-toggle="tab" role="tab" *ngIf="!filterPayload.isRenewalList"> {{ "PM_DASHBOARD.SUBSIDY" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 13}"><a (click)=" tab=13; clearCommingSoon()" class="nav-link" href="#target-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.TARGET_RENT" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 14}"><a (click)=" tab=14; clearCommingSoon()" class="nav-link" href="#acronym-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RESIDENT_ACRONYM" | translate }} </a></li>
            </ul>
            <!-- </div> -->
            <!-- <div class="col tab-content-wrap"> -->

            <!-- Tab panes -->
            <div class="tab-content">

                <div class="tab-pane " [ngClass]="{active: filterPayload.isDeliquenceDashboard}" id="total-v" role="tabpanel">
                    <!-- Total balance content...  -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalAmountFrom" placeholder="From" appTwoDigitDecimaNumber/>
                            </div>
                            <div class="col-sm-12 form-group">
                                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalAmountTo" placeholder="To" appTwoDigitDecimaNumber/>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="tenant-v" role="tabpanel">
                    <!-- Tenant balance content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <input type="number" id="from" name="from" class="form-control form-input padd-left12" min="0" [(ngModel)]="filterPayload.tenantAmountFrom" placeholder="From" />
                            </div>
                            <div class="col-sm-12 form-group">
                                <input type="number" id="to" name="to" class="form-control form-input padd-left12" min="0" [(ngModel)]="filterPayload.tenantAmountTo" placeholder="To" />
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="promise-to-pay-v" role="tabpanel">
                    <!-- Promise To Pay  -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.promiseToPayFrom"
                                    [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range"></p-calendar>
                            </div>
                            <div class="col-sm-12 form-group">
                                <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.promiseToPayTo" [disabled]="!filterPayload.promiseToPayFrom"
                                    [minDate]="filterPayload.promiseToPayFrom" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range"></p-calendar>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="action-v" role="tabpanel">
                    <!-- Action items content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <p-dropdown class="form-dropdown" name="actionFilter" [options]="actionItemList" [(ngModel)]="filterPayload.lastActionTaken"></p-dropdown>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" [ngClass]="{active: filterPayload.isDeliquence}" id="pm-v" role="tabpanel">
                    <!-- PM's status content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <p-dropdown class="form-dropdown" name="pmFilter" [options]="pmStatusList" [(ngModel)]="filterPayload.pmStatus"></p-dropdown>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" [ngClass]="{active: filterPayload.isRenewalDashboard}" id="rent-v" role="tabpanel">
                    <!-- Rent content...  -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.rentFrom" placeholder="From" appTwoDigitDecimaNumber/>
                            </div>
                            <div class="col-sm-12 form-group">
                                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.rentTo" placeholder="To" appTwoDigitDecimaNumber/>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="lease-v" role="tabpanel">
                    <!-- Lease content...  -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #from="ngModel" [(ngModel)]="filterPayload.leaseExpirationFrom"
                                    [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range"></p-calendar>
                            </div>
                            <div class="col-sm-12 form-group">
                                <p-calendar id="dateTo" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel" [(ngModel)]="filterPayload.leaseExpirationTO" [disabled]="!filterPayload.leaseExpirationFrom"
                                    [minDate]="filterPayload.leaseExpirationFrom" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range"></p-calendar>
                            </div>
                            <span class="p-invalid error-msg" *ngIf="!filterPayload.leaseExpirationFrom">Please select from date first.</span>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="renewal-v" [ngClass]="{active: filterPayload.isRenewal}" role="tabpanel">
                    <!-- Renewal status content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <p-dropdown class="form-dropdown" name="renewalFilter" [options]="renewalStatusList" [(ngModel)]="filterPayload.rentdexRenewalStatus"></p-dropdown>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="subsidy-v" role="tabpanel">
                    <!-- Subsidy content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.subsidyFrom" placeholder="From" />
                            </div>
                            <div class="col-sm-12 form-group">
                                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.subsidyTo" placeholder="To" />
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="target-v" role="tabpanel">
                    <!-- Target rent content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.targetRentFrom" placeholder="From" />
                            </div>
                            <div class="col-sm-12 form-group">
                                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.targetRentTo" placeholder="To" />
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="eviction-v" role="tabpanel">
                    <!-- Eviction status content...  -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <p-dropdown class="form-dropdown" name="evictionFilter" [options]="evictionStatusList" [(ngModel)]="filterPayload.evictionStatus"></p-dropdown>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- Resident Acronym List -->
                <div class="tab-pane" id="acronym-v" role="tabpanel">
                    <!-- Action items content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <p-dropdown class="form-dropdown" name="acronymFilter" [options]="acronymList" [(ngModel)]="filterPayload.acronym"></p-dropdown>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="total-v" role="tabpanel">
                    <!-- Target rent content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                            <div class="col-sm-12 form-group">
                                <input type="number" id="from" name="from" class="form-control form-input" min="0" [disabled]="true" [(ngModel)]="filterPayload.totalBalanceFrom" placeholder="From" />
                            </div>
                            <div class="col-sm-12 form-group">
                                <input type="number" id="to" name="to" class="form-control form-input" min="0" [disabled]="true" [(ngModel)]="filterPayload.totalBalanceTo" placeholder="To" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <!-- </div> -->
            <div class="clearfix"></div>

        </div>

        <!--------------- modal btns  --------->
        <div class="row d-flex modal-footer m-0">
            <button type="button" class="btn link-btn" (click)="cancelClick()"> {{"COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
            <button type="submit" class="btn common-btn" (click)="applyFilter()"> {{"COMMON_COMPONENT.APPLY_BUTTON" | translate }} </button>
        </div>

    </div>
</div>