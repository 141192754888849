import { Component, OnInit } from '@angular/core';
import { HeaderService, SidebarService } from 'src/providers/providers';
import { LocationStrategy } from '@angular/common';

@Component( {
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
} )
export class AdminDashboardComponent implements OnInit {
    public pageTitle: string;

    constructor(public header: HeaderService, public sidebar: SidebarService,  private location: LocationStrategy ) { 
        this.pageTitle = "Dashboard";

        history.pushState(null, null, window.location.href);  
        this.location.onPopState(() => {
          history.pushState(null, null, window.location.href);
        });
    }

    ngOnInit(): void {
        this.sidebar.show();
        this.header.show();
    }
}
