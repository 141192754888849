<div class="row page-wrap">
    <div class="container-fluid">
        
        <div class="row m-0">
            <div class="dashboard-tab-wrap container-fluid p-0">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs full-width">

                    <li class="search-wrap float-right">
                        <div class="form-group has-search common-search-box">
                            <span class="form-control-feedback"><i class="search-icon search-icon-cls"></i></span>
                            <input type="text" id="search-bar" class="form-control" spellcheck="false" autocomplete="false" name="searchUser"  placeholder="Search by Name/Address/Unit" pInputText 
                            [(ngModel)]="renewalPayload.searchText" (input)="isSearchCall=true;getRenewalList($event);" />
                        </div>
                        <button class="btn btn-icon-bg" (click)="openExportPopup()"><i class="forward-icon filter-icon-cls" title="Export"></i></button>
                        <button class="btn btn-icon-bg" (click)="clickOnFilter($event)"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>
                    </li>

                </ul>

                <!-- Tab panes -->
                <div class="tab-content full-width">
                    <div id="tab-1" class="container-fluid tab-pane active">
                        <div class="card clearfix">

                            <p-table class="custom-table-cls" #dt [value]="renewalsTenantList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 295px)'" (onLazyLoad)="customSort($event)" [lazy]="true">
                                
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th> {{ "PM_DASHBOARD.ADDRESS" | translate }} </th>

                                        <th pSortableColumn="tenant"> {{ "PM_DASHBOARD.NAME" | translate }}
                                            <p-sortIcon field="tenant"></p-sortIcon>
                                        </th>

                                        <th pSortableColumn="unit"> {{ "PM_DASHBOARD.UNIT" | translate }}
                                            <p-sortIcon field="unit"></p-sortIcon>
                                        </th>

                                        <th> {{ "PM_DASHBOARD.RENEWAL_STATUS" | translate }} </th>
                                        
                                        <th> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }} </th>

                                        <!--<th pSortableColumn="totalBalance">Total Balance
                                            <p-sortIcon field="totalBalance"></p-sortIcon>
                                        </th>-->

                                        <th pSortableColumn="leaseTo"> {{ "PM_DASHBOARD.LEASE_EXPIRATION" | translate }}
                                            <p-sortIcon field="leaseTo"></p-sortIcon>
                                        </th>

                                        <th pSortableColumn="rent"> {{ "PM_DASHBOARD.RENT" | translate }}
                                            <p-sortIcon field="rent"></p-sortIcon>
                                        </th>
                                        
                                        <!--<th pSortableColumn="targetRent"> {{ "PM_DASHBOARD.TARGET_RENT" | translate }}
                                            <p-sortIcon field="targetRent"></p-sortIcon>
                                        </th>-->

                                        <th> {{ "PM_DASHBOARD.TARGET_RENT" | translate }} </th>

                                        <th> {{ "PM_DASHBOARD.MOST_RECENT_NOTES" | translate }} </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-renewalsTenant>                                   
                                    
                                    <tr class="cusr-pointer" (click)="openRenewalDetails(renewalsTenant)">
                                        <!-- <td>
                                        <p-tableCheckbox [value]="product "></p-tableCheckbox>
                                    </td> -->
                                        <td title="{{renewalsTenant.property}}">{{renewalsTenant.property? renewalsTenant.property: '-'}}</td>
                                        <td title="{{renewalsTenant.tenant}}" (click)="openTenantDetails(renewalsTenant.unitId)" [ngClass]="!renewalsTenant.tenant? 'rmLine': ''" class="hyperlink-cls">{{renewalsTenant.tenant? renewalsTenant.tenant: '-'}}</td>
                                        <td title="{{renewalsTenant.unit}}">{{renewalsTenant.unit? renewalsTenant.unit: '-'}}</td>
                                        <td title="{{renewalsTenant.rentdexRenewalStatus  | replace : '_' : ' ' | titlecase }}">{{ renewalsTenant.rentdexRenewalStatus ? (renewalsTenant.rentdexRenewalStatus  | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{renewalsTenant.evictionStatus | replace : '_' : ' ' | titlecase}}">{{ renewalsTenant.evictionStatus ? (renewalsTenant.evictionStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <!--<td title="{{renewalsTenant.rent | currency:'USD':'symbol' :'1.2-2'}}">{{renewalsTenant.rent | currency:'USD':'symbol' :'1.2-2'}}</td>-->
                                        <td title="{{renewalsTenant.leaseTo | date: 'MMM dd, yyyy' : ' - '}}">{{ renewalsTenant.leaseTo ? (renewalsTenant.leaseTo | date: 'MMM dd, yyyy' : '-') : '-' }}</td>
                                        <td title="{{renewalsTenant.rent | currency:'USD':'symbol' :'1.2-2'}}">{{ renewalsTenant.rent ? (renewalsTenant.rent | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{renewalsTenant.marketRent }}">{{ renewalsTenant.marketRent ? (renewalsTenant.marketRent) : '-' }}</td>
                                        <td title="{{renewalsTenant.renewableNotes}}" class="txt-initial">{{renewalsTenant.renewableNotes? renewalsTenant.renewableNotes: '-'}}</td>
                                    </tr>
                                   
                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="renewalsTenantList.length == 0">
                                <div class="no-data-found" *ngIf="!loading1" text-center>
                                    <div class="no-found-txt" text-center> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                            </div>
                        </div>
                        <p-paginator styleClass="ui-paginator-bottom" [rows]="25" #p (onLazyLoad)="renewalpaginate($event)" (onPageChange)="renewalpaginate($event)" [totalRecords]="renewalPayload.totalRecords" class="list-paginator table-paginator"></p-paginator>
                    
                </div>
            </div>
        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>