<!-- <app-header></app-header>
<app-sidebar></app-sidebar>
<router-outlet></router-outlet>

<app-footer></app-footer> -->



<div class="d-flex bg-img-wrapper" id="wrapper">

    <app-sidebar id="sidebar-wrapper"></app-sidebar>

    <div id="page-content-wrapper">
        <app-header (closeMenu)="toggleMenu($event)"></app-header>
        <div class="container-fluid page-container">
            <router-outlet></router-outlet>
            <ngx-spinner type="ball-spin-clockwise" size="medium" color="#fff" class="loader"></ngx-spinner>
            <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 1)">
                template="<img src='https://media.giphy.com/media/N256GFy1u6M6Y/giphy.gif' />"
            </ngx-spinner> -->
            <!-- <app-progress-spinner class="example-margin" [color]="primary" [mode]="indeterminate" [value]="50" [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="displayProgressSpinner">
            </app-progress-spinner> -->
        </div>
    </div>
    <div class="progress-spinner" mode="indeterminate" *ngIf="showLoader">
        <p-progressSpinner ></p-progressSpinner>
    </div>
    
    <p-toast *ngIf="!isMobile" class="toast-card toast-card-sucess" key="success1" position="top-center"></p-toast>
    <p-toast *ngIf="!isMobile" class="toast-card toast-card-warn" key="warn1" position="top-center"></p-toast>
    <p-toast *ngIf="!isMobile" class="toast-card toast-card-error" key="error1" position="top-center"></p-toast>
    <p-toast *ngIf="!isMobile" class="toast-card toast-card-info" key="info1" position="top-center"></p-toast>

    <p-toast *ngIf="isMobile" [style]="{marginTop: '150px'}" class="toast-card toast-card-sucess" key="success1" position="top-center"></p-toast>
    <p-toast *ngIf="isMobile" [style]="{marginTop: '150px'}" class="toast-card toast-card-warn" key="warn1" position="top-center"></p-toast>
    <p-toast *ngIf="isMobile" [style]="{marginTop: '150px'}" class="toast-card toast-card-error" key="error1" position="top-center"></p-toast>
    <p-toast *ngIf="isMobile" [style]="{marginTop: '150px'}" class="toast-card toast-card-info" key="info1" position="top-center"></p-toast>
</div>

<app-footer></app-footer>