/*--------------------Angular related components---------------*/
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable, timer, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError, mergeMap } from 'rxjs/operators';

/*-----------------App Providers---------------------*/
import { AppSettings } from '../app-settings-service/app-settings.service';
import { Constants } from '../app-settings-service/app-constant.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { RestService } from '../rest-service/rest.service';
import { CommonService } from '../common-service/common-service';
import { HttpRequest, HttpHandler } from '@angular/common/http';


@Injectable()
export class ManageUserService {
    errorMsg: string;
    successMsg: string;

    constructor( private commonService: CommonService, private restService: RestService, public locstr: LocalStorageService,
        private constant: Constants,
        //private toaster: Toaster,
        private router: Router ) {

    }

    
   
    /**
     * Function for Create User
     * @param user data 
     */
    public createUser = (userData) => {
       const path = AppSettings.CREATE_USER_URL;
       return this.restService.postCall(path, userData, true, 60000)
          .map(function (res) {
             if (typeof res == 'string') {
                return JSON.parse(res);
             } else {
                return res;
             }
          })
          .catch(this.restService.handleError);
    }
    /**
     * Function for Create User
     * @param user data 
     */
    public updateUser = (userData) => {
      const path = AppSettings.UPDATE_USER_URL;
      return this.restService.putCall(path, userData, false, 60000)
         .map(function (res) {
            if (typeof res == 'string') {
               return JSON.parse(res);
            } else {
               return res;
            }
         })
         .catch(this.restService.handleError);
   }

    
    /**
     * Function for delete an user
     */
    public deleteUser( userData ):  Observable<any> {
        const path = AppSettings.DELETE_USER_URL+"?userId="+userData.userId;
        return this.restService.deleteCall(path, false, 60000,null)
           .pipe(map(res => {
              return res;
           }),
              catchError(e => timer(500).pipe(mergeMap(t => this.restService.handleError(e)))));
    }
    
    /**
     * Function for  an user list
     */
    public getUserList(data, param):  Observable<any> {
      const path = AppSettings.USER_LIST_URL+"?size=" +
      param.pageSize +
      "&page=" +
      param.pageNumber;
      return this.restService.postCall(path, data, false, 60000)
         .pipe(map(res => {
            return res;
         }),
            catchError(e => timer(500).pipe(mergeMap(t => this.restService.handleError(e)))));
  }

  /**
     * Function for  an user grant access
     */
    public grantAccess(param):  Observable<any> {
      const path = AppSettings.USER_GRANT_ACCESS_URL+"?userId="+param.userId;
      return this.restService.getCall(path, false, 60000)
         .pipe(map(res => {
            return res;
         }),
            catchError(e => timer(500).pipe(mergeMap(t => this.restService.handleError(e)))));
  }

  /**
     * Function for  an user block
     */
    public blockUser(param):  Observable<any> {
      const path = AppSettings.USER_BLOCK_URL+"?userId="+param.userId;
      return this.restService.deleteCall(path, false, 60000,null)
         .pipe(map(res => {
            return res;
         }),
            catchError(e => timer(500).pipe(mergeMap(t => this.restService.handleError(e)))));
  }

  /**
     * Function for  an user unblock
     */
    public unBlockUser(param):  Observable<any> {
      const path = AppSettings.USER_UNBLOCK_URL+"?userId="+param.userId;
      return this.restService.deleteCall(path, false, 60000,null)
         .pipe(map(res => {
            return res;
         }),
            catchError(e => timer(500).pipe(mergeMap(t => this.restService.handleError(e)))));
  }

  /**
     * Function for  an user activate
     */
    public activateUser(param):  Observable<any> {
      const path = AppSettings.ACTIVATE_USER_URL+"?userId="+param.userId;
      return this.restService.getCall(path, false, 60000)
         .pipe(map(res => {
            return res;
         }),
            catchError(e => timer(500).pipe(mergeMap(t => this.restService.handleError(e)))));
  }

  /**
     * Function for  an user activate
     */
    public logOutUser(param):  Observable<any> {
      const path = AppSettings.LOGOUT_USER_URL+"?userId="+param.userId;
      return this.restService.getCall(path, false, 60000)
         .pipe(map(res => {
            return res;
         }),
            catchError(e => timer(500).pipe(mergeMap(t => this.restService.handleError(e)))));
  }

}