<div class="background-image-wrapper">

    <!-- Container -->
    <div class="form-container">

        <div class="">
            <!-- Logo -->
            <div>
                <img class="app-logo text-center" src="assets/images/rentdex-logo.png">
            </div>

            <!-- Heading -->
            <div class="text-center col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                <h2 class="form-title"> {{ "FORGOT_PASSWORD.FORGOT_PASSWORD" | translate }} </h2>
                <p class="form-subtitle mar-btm10"> {{ "FORGOT_PASSWORD.ENTER_YOUR_REGISTRER_EMAIL_ADDRESS_BELOW" | translate }} <br> {{ "FORGOT_PASSWORD.send_you_verification_code" | translate }} </p>
            </div>

            <!-- Form -->

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                <form class="common-form-cls" #forgotPasswordForm="ngForm" (ngSubmit)="forgotPasswordClick(forgotPasswordForm)" novalidate>
                    <!--------------- Email Field  --------->
                    <div class="p-field form-group input-group mar-btm28">
                        <!-- <label for="Email">Email</label> -->
                        <span><i class="email-icon icon-left input-icon"></i> </span>
                        <input id="Email" [ngStyle]="{'border': (loginEmail.errors && (loginEmail.dirty || loginEmail.touched || formSubmitted)) ? '1px solid red' : '0' }" type="Email" placeholder="Email id" class="from-input pass-input" pattern="^(?!\.)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,50}$"
                            [(ngModel)]="forgotData.email" name="email" #loginEmail="ngModel" aria-describedby="Email-help" pInputText required/>
                        <div class="text-left error" *ngIf="loginEmail.errors && (loginEmail.dirty || loginEmail.touched || formSubmitted)">
                            <small [hidden]="!loginEmail.errors.required" class="p-invalid error-msg"> {{ "LOGIN.EMAIL_REQUIRED" | translate }}
							</small>
                            <small [hidden]="!loginEmail.errors.pattern" class="p-invalid error-msg"> {{ "LOGIN.EMAIL_INVALID" | translate }} 
							</small>
                        </div>
                    </div>
                    <!--------------- submit btn  --------->
                    <div>
                        <button type="submit" class="btn common-btn full-width"> {{ "COMMON_COMPONENT.SUBMIT_BUTTON" | translate }} </button>
                    </div>

                    <div>
                        <a [routerLink]="''" class="forgot-text text-center"> {{ "VERIFICATION_CODE.BACK_TO_LOGIN" | translate }} </a>
                    </div>
                </form>
                <!--------------- end of form  --------->
            </div>

        </div>
    </div>

    <!-- <p-toast class="toast-card" position="top-center"></p-toast> -->
</div>