import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HeaderService, LocalStorageService, MetaDataService, StatsService, CommonService, SidebarService, TrendsService, PropertyManagerService } from 'src/providers/providers';
import * as _ from "lodash";
@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  public userData: any;
  public monthList = [];
  public statsPayload: any = {
    month: '',
    selectedMonthObject: '',
    selectedPmNameObject: '',
    pmName: '',
    pmId: ''
  };
  public delinquencystatsData: any = [];
  public renewalsStatsData: any = [];
  public pmEaNameItemList: any = [];

  constructor(public header: HeaderService,
    public sidebar: SidebarService,
    protected commonService: CommonService,
    public metaDataService: MetaDataService,
    public appService: AppService,
    public localStr: LocalStorageService,
    public trendsService: TrendsService,
    public propertyManagerService: PropertyManagerService,
    public statsService: StatsService) { }

  ngOnInit(): void {
    this.sidebar.show();
    this.header.show();
    this.userData = this.localStr.getObj('userData');
    console.log("this.userData  >>>>> " , this.userData);

    if(this.userData.roleType == "PROPERTY_MANAGER"){
      this.statsPayload.pmId = this.userData.id;
    }
    
    this.appService.setTitle('Statistics');
    this.monthList = this.trendsService.getAllMonthsName(12, true);
    this.statsPayload.month = this.monthList[0]["value"];

    if(this.userData.roleType == "SUPER_ADMIN" || this.userData.roleType == "SUB_ADMIN"){
      this.getPMIdList();
    }
    this.getStatsDelinquencyList();
    this.getStatsRenewalList();

  }

  /**
   * Function to get stats for selected month
   * @param eve 
   * @param month 
   */
  public getStatsSelectedMonth(eve, month) {
    this.statsPayload.month = eve.value;
    this.getStatsDelinquencyList();
    this.getStatsRenewalList();
  }

  /**
  * Function to get stats for PM
  * @param eve 
  * @param month 
  */
  getStatsSelectedPMName(eve, pmId) {
    this.statsPayload.pmId = eve.value;
    this.getStatsDelinquencyList();
    this.getStatsRenewalList();
  }

  /**
     * Function to get Pm id list
     * 
   */
  private getPMIdList = () => {
    this.pmEaNameItemList = [];
    this.propertyManagerService.getPMEANameIdList(false).subscribe(
      (res) => {
        if (res.code == 200 || res.status == 200) {
          this.pmEaNameItemList.push({ label: "All", value: "" });
          var pmEaNameIdList = res.data;
          for (let i = 0; i <= pmEaNameIdList.length; i++) {
            if(pmEaNameIdList[i]){
              this.pmEaNameItemList.push({ label: pmEaNameIdList[i].name, value: pmEaNameIdList[i].id });
            }
        }
          console.log("pmEaNameItemList >>> ::::::: ", this.pmEaNameItemList);         
          this.statsPayload.pmName = this.pmEaNameItemList[0];
          this.pmEaNameItemList = _.cloneDeep(this.pmEaNameItemList);
        }        
        this.commonService.hideLoading();
      },
      (error) => {
        this.pmEaNameItemList = [];
        if (error.message) {
          this.commonService.clearToast();
          this.commonService.showError(error.message);
        } else {
          this.commonService.clearToast();
          this.commonService.showError(error.error_description);
        }
        this.commonService.hideLoading();
      }
    );
  }


  /**
     * Function to get stats Delinquency list
     *
   */
  public getStatsDelinquencyList() {
    console.log("this.statsPayload: >>>>>>> ", this.statsPayload);

    this.commonService.showLoading(); // remove this when we use inplace loader      
    this.statsService.getStatsDelinquenciesList(this.statsPayload).subscribe(
      (res) => {
        this.commonService.hideLoading();
        if (res.code == 200 || res.status == 200) {
          this.delinquencystatsData = res.data;
          console.log(" this.statsData....", this.delinquencystatsData);
          this.sortBy('label', 'delinquency');

        }
      },
      (error) => {
        this.commonService.hideLoading();
        this.delinquencystatsData = [];
        if (error.message) {
          this.commonService.clearToast();
          this.commonService.showError(error.message);
        } else {
          this.commonService.clearToast();
          this.commonService.showError(error.error_description);
        }
      }
    );
  }

  /**
       * Function to get stats renewals list
       *
     */
  public getStatsRenewalList() {
    console.log("this.statsPayload", this.statsPayload);

    this.commonService.showLoading(); // remove this when we use inplace loader      
    this.statsService.getStatsRenewalList(this.statsPayload).subscribe(
      (res) => {
        this.commonService.hideLoading();
        if (res.code == 200 || res.status == 200) {
          this.renewalsStatsData = res.data;
          this.sortBy('label', 'renewals');
        }
      },
      (error) => {
        this.commonService.hideLoading();
        this.renewalsStatsData = [];
        if (error.message) {
          this.commonService.clearToast();
          this.commonService.showError(error.message);
        } else {
          this.commonService.clearToast();
          this.commonService.showError(error.error_description);
        }
      }
    );
    // this.commonService.hideLoading();
  }

 /**
       * Function to get sorted stats data
       *
     */
  
  sortBy(field: string, type) {
    if (type == 'delinquency') {
      this.delinquencystatsData.sort((a: any, b: any) => {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      this.delinquencystatsData = this.delinquencystatsData;
    } else if (type == 'renewals') {
      this.renewalsStatsData.sort((a: any, b: any) => {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      this.renewalsStatsData = this.renewalsStatsData;
    }
  }

}
