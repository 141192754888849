import { Component, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, AuthService } from 'src/providers/providers';
// import { Toaster } from 'ngx-toast-notifications';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-export',
  templateUrl: './export-popup.component.html',
  styleUrls: ['./export-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportPopupComponent implements OnInit {

  formSubmitted: boolean = false;
 // @Output() callback: EventEmitter<any> = new EventEmitter();

  public format:any = "EXCEL"

  constructor(private router: Router, private commonService:CommonService, private authService: AuthService,
    public activeModal: NgbActiveModal,) { }
  
  ngOnInit(): void {
  }
  
  /**
   * Function coming soon
   */
  public commingSoon = () => {
    this.commonService.clearToast();
    this.commonService.showInfo("Coming soon");    
}

  /**
   * function on cancel Click
   */
  cancelClick =() => {
    
    this.activeModal.dismiss( {}  );
  }

  okClick =() => {
    
    console.log("format",this.format);
    if(this.format == 'PDF') {
        this.commingSoon();
    } else {
        this.activeModal.dismiss( {format:this.format}  );    
    }
    //this.callback.emit({format:this.format})
    
  }

}
