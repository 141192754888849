import { Component, OnInit, ViewEncapsulation, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, AuthService } from 'src/providers/providers';
// import { Toaster } from 'ngx-toast-notifications';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {

  formSubmitted: boolean = false;
  @Output() callback;

  passwordData = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  }

  paramData = {
    oldPassword: "",
    newPassword: ""
  }

  oldInputType: string = 'password';
  inputType: string = 'password';
  confirmInputType: string = 'password';

  oldPasswordCheck: boolean;
  newPasswordCheck: boolean;
  confirmPasswordCheck: boolean;

  constructor(private router: Router, private commonService:CommonService, private authService: AuthService,
    public activeModal: NgbActiveModal,) { }
  
  ngOnInit(): void {
  }

  submitChangePassword(form: NgForm) {
    this.formSubmitted = true;   
      if (form.valid) {
        if(this.passwordData.oldPassword != this.passwordData.newPassword){      
        if ( this.passwordData.newPassword == this.passwordData.confirmPassword) {
        this.commonService.showLoading();
        this.paramData.oldPassword = this.passwordData.oldPassword;
        this.paramData.newPassword = this.passwordData.newPassword;
        this.authService.changePassword(this.paramData)
          .subscribe(
            response => {
              this.commonService.hideLoading();
              this.formSubmitted = false;
              let res = response;
              if (res.code == 200) {                
                form.reset();
                this.commonService.clearToast();
                this.commonService.showSuccess(response.message);
                this.activeModal.dismiss( 'Cancel click',  );
                // this.toaster.open({ text: response.message, type: 'success' });
              }
            },
            error => {
              this.commonService.hideLoading();
              console.log("error...", error.error.message);
              if ( error.error.message ) {
                this.commonService.clearToast();
                this.commonService.showError(error.error.message);
            } else if ( error.message ) {
                this.commonService.clearToast();
                this.commonService.showError(error.message);
            } else {
                this.commonService.clearToast();
                this.commonService.showError(error.error_description);
            }
            });
          } else {
            this.commonService.clearToast();
      this.commonService.showError("New password and confirm password does not match.");
            // this.toaster.open({ text: "New password and confirm password does not match.", type: 'danger' });
          }
        } else {
          this.commonService.clearToast();
      this.commonService.showError("New password and old password should not be the same.");
          // this.toaster.open({ text: "New password and old password should not be the same.", type: 'danger' });      
        }
      } 
  }

  /**
   * function on cancel Click
   */
  cancelClick =() => {
    //this.router.navigate(['/settings']);
    this.activeModal.dismiss( 'Cancel click',  );
  }
  
   /**
    * Function for hide/show password
    * */
   hideShowPassword = (type) => {
    if (type == 'oldPassword') {
      this.commonService.hideShowPassword(this.oldInputType, this.oldPasswordCheck, (showPassword, inputType) => {
          this.oldInputType = inputType;
          this.oldPasswordCheck = showPassword;
      });
  } else if (type == 'newPassword') {
      this.commonService.hideShowPassword(this.inputType, this.newPasswordCheck, (showPassword, inputType) => {
          this.inputType = inputType;
          this.newPasswordCheck = showPassword;
      });
  } else {
      this.commonService.hideShowPassword(this.confirmInputType, this.confirmPasswordCheck, (showPassword, inputType) => {
          this.confirmInputType = inputType;
          this.confirmPasswordCheck = showPassword;
      });
  }
  }

}
