<div class="row page-wrap">
    <div class="container-fluid">

            <div class="row m-0">
                <div class="col-12 col-sm-12 dashboard-card-cls">
                    <div class="card dashboard-card clearfix">
                        <div class="row m-0 profile-user-info">
                            <div class="col-sm-6"><div class="profile-user-details clearfix">
                                <p class="profile-user-details-label">
                                    {{ "MY_PROFILE.PHONE_NO" | translate }}: <span class="profile-user-details-value">{{userData?.phoneNumber ? userData?.phoneNumber: ' '}} </span>
                                </p>
                            </div>
                            <div class="profile-user-details clearfix">
                                <p class="profile-user-details-label">
                                    {{ "MY_PROFILE.NAME" | translate }}: <span class="profile-user-details-value">{{userData?.firstName ? userData?.firstName: ' '}} {{userData?.lastName ? userData?.lastName: ' '}} </span>
                                </p>
                            </div>
                            <!-- <div class="profile-user-details clearfix">
                                <p class="profile-user-details-label">
                                    {{ "MY_PROFILE.GENDER" | translate }}: <span class="profile-user-details-value">{{userData?.gender ? userData?.gender: ' '}} </span>
                                </p>
                            </div>
                            <div class="profile-user-details clearfix" *ngIf="userData.roleType=='SUB_ADMIN'">
                                <p class="profile-user-details-label">
                                    {{ "MY_PROFILE.DOB" | translate }}: <span class="profile-user-details-value">{{userData?.dob ? (userData?.dob | date: 'MM/dd/yyyy') : ' '}} </span>
                                </p>
                            </div> -->
                            <div class="profile-user-details clearfix" *ngIf="userData.roleType=='SUB_ADMIN'">
                                <p class="profile-user-details-label">
                                    {{ "CREATE_USER.TEAM_MEMBERS" | translate }}: <span class="profile-user-details-value"> {{userData?.teamMemberList ? userData.teamMemberList  : ' - '}} </span>
                                </p> 
                            </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="profile-user-details clearfix">
                                    <p class="profile-user-details-label">
                                        {{ "MY_PROFILE.EMAIL" | translate }}: <span class="profile-user-details-value">{{userData?.email ? userData?.email: ' '}} </span>
                                    </p>
                                </div>
                                <div class="profile-user-details clearfix">
                                    <p class="profile-user-details-label">
                                        {{ "MY_PROFILE.ROLE" | translate }}: <span class="profile-user-details-value">{{userData?.roleType ? (userData?.roleType | replace : '_' : ' ' | titlecase) : ' '}} </span>
                                    </p> 
                                </div>
                                
                                <div class="profile-user-details clearfix" *ngIf="userData.roleType=='SUB_ADMIN'">
                                    <p class="profile-user-details-label">
                                        {{ "USER_LIST.USER_ACCESS" | translate }}: <span class="profile-user-details-value">{{access ? (access ) : ' - '}} </span>
                                    </p> 
                                </div>                                
                                <!-- <div class="profile-user-details clearfix" *ngIf="userData.roleType!='SUB_ADMIN'">
                                    <p class="profile-user-details-label">
                                        {{ "MY_PROFILE.DOB" | translate }}: <span class="profile-user-details-value">{{userData?.dob ? (userData?.dob | date: 'MM/dd/yyyy') : ' '}} </span>
                                    </p>
                                </div> -->
                            </div>
                            <div class="col-sm-12">
                                <div class="profile-user-details clearfix">
                                    <p class="profile-user-details-label">
                                        {{ "MY_PROFILE.ADDRESS" | translate }}: <span class="profile-user-details-value">{{userData?.address ? userData?.address : ' '}} </span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-sm-12 mar-top10">
                                <button class="btn common-btn small-btn" (click)="goToEditProfile()">{{ "MY_PROFILE.EDIT_PROFILE" | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>