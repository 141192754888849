<div class="row page-wrap pt-0">
    
    <div class="full-width" *ngIf="isMobile">

        <div class="row m-0">

            <div class="custom-tabs-wrap container-fluid p-0">
                <div class="col-xs-12 col-sm-12 p-0">
                  <div class="tabbable">
                    <ul class="nav nav-tabs">
                      <li><a href="#tab1" data-toggle="tab" class="active"><span class="bullet"></span> Miscellaneous</a>
                      </li>
                      <li><a href="#tab2" data-toggle="tab"><span></span> Kitchen</a>
                      </li>
                      <li><a href="#tab3" data-toggle="tab"><span></span> Bathroom</a>
                      </li>
                      <li><a href="#tab4" data-toggle="tab"><span></span> Move in ready</a>
                      </li>
                      <li><a href="#tab5" data-toggle="tab"><span></span> Extras</a>
                      </li>
                    </ul>
                    <div class="tab-content">
                        <!-- Miscellaneous content start  -->
                        <div class="tab-pane active" id="tab1">
                            <ul class="tab-content-list">
                                <li>
                                    <h3 class="sub-heading">Trash out</h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Notes" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading">Change front locks to WPD locks </h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Notes" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading">Change back locks to WPD locks</h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Notes" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading">New mailbox lock</h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Notes" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading">Interior knobs to silver </h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Notes" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading">Replace interior doors </h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Lorem Ipsum is simply dummy text of the printing" />
                                        </div>
                                    </div>
                                </li> 
                                <li>
                                    <h3 class="sub-heading">Repair interior doors </h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Lorem Ipsum is simply dummy text of the printing" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading">Replace closet doors </h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="It is a long established fact that a reader will be
                                            distracted" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading">Repair closet doors </h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="1" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="2 closets off the hinges and missing Knobs. " />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading">Final cleaning </h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="It is a long established fact that a reader will be
                                            distracted" />
                                        </div>
                                    </div>
                                </li> 
                              </ul>
                                
                            <div class="row m-0">
                                <div class="col-sm-12">
                                    <div class="float-left">&nbsp;</div> 
                                    <div class="float-right">
                                        <button type="button" class="btn link-btn">Cancel</button> 
                                        <button type="button" class="btn common-btn small-btn ml-2">Save & Next</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Kitchen content start  -->
                        <div class="tab-pane" id="tab2">
                            <ul class="tab-content-list">
                                <li>
                                    <h3 class="sub-heading"><span></span> Trash out</h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Lorem Ipsum is simply dummy text of the printing" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading"><span></span> Change front locks to WPD locks </h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Lorem Ipsum is simply dummy text of the printing" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading"><span></span> Change back locks to WPD locks</h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Lorem Ipsum is simply dummy text of the printing" />
                                        </div>
                                    </div>
                                </li>  
                              </ul>
                              <div class="row m-0">
                                    <div class="col-sm-12">
                                        <div class="float-left">&nbsp;</div> 
                                        <div class="float-right">
                                            <button type="button" class="btn link-btn">Cancel</button> 
                                            <button type="button" class="btn common-btn small-btn ml-2">Save & Next</button>
                                        </div>
                                    </div>
                              </div>
                        </div>
                        <!-- Bathroom content start  -->
                        <div class="tab-pane" id="tab3">
                            <ul class="tab-content-list">
                                <li>
                                    <h3 class="sub-heading"><span></span> Trash out</h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Notes" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading"><span></span> Change front locks to WPD locks </h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Notes" />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 class="sub-heading"><span></span> Change back locks to WPD locks</h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Notes" />
                                        </div>
                                    </div>
                                </li>  
                              </ul>
                              <div class="row m-0">
                                <div class="col-sm-12">
                                    <div class="float-left">&nbsp;</div> 
                                    <div class="float-right">
                                        <button type="button" class="btn link-btn">Cancel</button> 
                                        <button type="button" class="btn common-btn small-btn ml-2">Save & Next</button>
                                    </div>
                                </div>
                              </div>
                        </div>
                        <!-- Move ready content start  -->
                        <div class="tab-pane" id="tab4">
                            <ul class="tab-content-list">
                                <li>
                                    <h3 class="sub-heading">Remove hooks/nails in walls/ protruding objects</h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="1" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Lorem Ipsum is simply dummy text of the printing" />
                                        </div>
                                    </div>
                                </li>
                                <div>
                                    <h2 class="main-heading">Clean entire unit: </h2>
                                    <ul class="tab-content-list">
                                        <li>
                                            <h3 class="sub-heading"><span></span> Rear porch</h3>
                                            <div class="inline-form-row row m-0">
                                                <div class="form-group col-3 col-sm-3 pl-0">
                                                    <label for="inputFirst"></label> 
                                                    <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="1" />
                                                </div> 
                                                <div class="form-group col-9 col-sm-9 p-0">
                                                    <label for="inputSecond"></label> 
                                                    <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="It is a long established fact that a reader will be
                                                    distracted" />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h3 class="sub-heading"><span></span> Floors </h3>
                                            <div class="inline-form-row row m-0">
                                                <div class="form-group col-3 col-sm-3 pl-0">
                                                    <label for="inputFirst"></label> 
                                                    <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="1" />
                                                </div> 
                                                <div class="form-group col-9 col-sm-9 p-0">
                                                    <label for="inputSecond"></label> 
                                                    <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Phasellus ullamcorper justo a velit bibendum." />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h3 class="sub-heading"><span></span> Appliances</h3>
                                            <div class="inline-form-row row m-0">
                                                <div class="form-group col-3 col-sm-3 pl-0">
                                                    <label for="inputFirst"></label> 
                                                    <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="1" />
                                                </div> 
                                                <div class="form-group col-9 col-sm-9 p-0">
                                                    <label for="inputSecond"></label> 
                                                    <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Nunc vel odio nec turpis cursus elementum." />
                                                </div>
                                            </div>
                                        </li> 
                                        <li>
                                            <h3 class="sub-heading"><span></span> Windows</h3>
                                            <div class="inline-form-row row m-0">
                                                <div class="form-group col-3 col-sm-3 pl-0">
                                                    <label for="inputFirst"></label> 
                                                    <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="1" />
                                                </div> 
                                                <div class="form-group col-9 col-sm-9 p-0">
                                                    <label for="inputSecond"></label> 
                                                    <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Sed pretium sem et ligula ultrices" />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h3 class="sub-heading"><span></span> Ceiling fans </h3>
                                            <div class="inline-form-row row m-0">
                                                <div class="form-group col-3 col-sm-3 pl-0">
                                                    <label for="inputFirst"></label> 
                                                    <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="1" />
                                                </div> 
                                                <div class="form-group col-9 col-sm-9 p-0">
                                                    <label for="inputSecond"></label> 
                                                    <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Proin ac odio in ligula dictum efficitur nec nec." />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h3 class="sub-heading"><span></span> Bathrooms</h3>
                                            <div class="inline-form-row row m-0">
                                                <div class="form-group col-3 col-sm-3 pl-0">
                                                    <label for="inputFirst"></label> 
                                                    <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="1" />
                                                </div> 
                                                <div class="form-group col-9 col-sm-9 p-0">
                                                    <label for="inputSecond"></label> 
                                                    <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Cras molestie arcu et lorem semper," />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h3 class="sub-heading"><span></span> Faces of cabinets and vanities </h3>
                                            <div class="inline-form-row row m-0">
                                                <div class="form-group col-3 col-sm-3 pl-0">
                                                    <label for="inputFirst"></label> 
                                                    <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="1" />
                                                </div> 
                                                <div class="form-group col-9 col-sm-9 p-0">
                                                    <label for="inputSecond"></label> 
                                                    <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Aenean eu ligula id velit sodales efficitur." />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <h3 class="sub-heading"><span></span> Insides of cabinets and drawers</h3>
                                            <div class="inline-form-row row m-0">
                                                <div class="form-group col-3 col-sm-3 pl-0">
                                                    <label for="inputFirst"></label> 
                                                    <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="1" />
                                                </div> 
                                                <div class="form-group col-9 col-sm-9 p-0">
                                                    <label for="inputSecond"></label> 
                                                    <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Aenean feugiat tellus tempor cursus commodo." />
                                                </div>
                                            </div>
                                        </li> 
                                      </ul>
                                    </div>
                                <li>
                                    <h3 class="sub-heading">Gas is off, needs turned on </h3>
                                    <div class="inline-form-row row m-0">
                                        <div class="form-group col-3 col-sm-3 pl-0">
                                            <label for="inputFirst"></label> 
                                            <input id="inputFirst" type="text" class="tab-form-control tab-form-text" value="No" />
                                        </div> 
                                        <div class="form-group col-9 col-sm-9 p-0">
                                            <label for="inputSecond"></label> 
                                            <input id="inputSecond" type="text" class="tab-form-control tab-form-text" value="Contrary to popular belief" />
                                        </div>
                                    </div>
                                </li>  
                              </ul>
                              <div class="row m-0">
                                    <div class="col-sm-12">
                                        <div class="float-left">&nbsp;</div> 
                                        <div class="float-right">
                                            <button type="button" class="btn link-btn">Cancel</button> 
                                            <button type="button" class="btn common-btn small-btn ml-2">Save & Next</button>
                                        </div>
                                    </div>
                              </div>
                        </div>
                        <!-- Extras content start  -->
                        <div class="tab-pane" id="tab5">
                            <div class="container">
                                <h3 class="sub-heading">Comment </h3>
                                <div class="inline-form-row row m-0">
                                    <div class="form-group col-sm-12 p-0">
                                        <textarea rows="7" cols="50" class="tab-form-control tab-form-text textarea-ctrl">
                                            At w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.
                                        </textarea>
                                    </div> 
                                </div>
                            </div>
                               
                              <div class="row m-0">
                                <div class="col-sm-12">
                                    <div class="float-left">&nbsp;</div> 
                                    <div class="float-right">
                                        <button type="button" class="btn link-btn">Cancel</button> 
                                        <button type="button" class="btn common-btn small-btn ml-2">Save & Next</button>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>

        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>