import { Component, Input, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, RouterLinkActive } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService, Broadcaster, CommonService, LocalStorageService, SidebarService } from 'src/providers/providers';
// import { Toaster } from 'ngx-toast-notifications';
import { MessageService } from 'primeng/api';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    //encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

    public adminDashboard: boolean = true;
    public pmDashboard: boolean = true;
    public goals: boolean = false;
    public isMobile: boolean = false;
    deviceInfo = null;
    hideSideNav: boolean = false;
    public checkValueData: boolean;
    public password = "";
    @Input() userData: any;
    clicked: boolean = false;
    step: any;
    userAccessSet: any;
    public isUserManagment: boolean = false;
    public isActivity: boolean = false;
    public isreport: boolean = false;
    public isGoals: boolean = false;
    public isStats: boolean = false;

    constructor(protected commonService: CommonService, private authService: AuthService, public toastMessage: MessageService, private router: Router, private locstr: LocalStorageService, private renderer: Renderer2,
        private broadcaster: Broadcaster, public sidebar: SidebarService,
        private deviceService: DeviceDetectorService) {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
     }

    ngOnInit(): void {
        this.step = 'step1';
        this.routeChangeDetector();
        this.userData = []
        this.userData = this.locstr.getObj('userData');
        this.broadcaster.on<any>('logout')
            .subscribe(message => {
                this.renderer.removeClass(document.body, 'menu-open');
            });
            this.sidebar.isUpdate.subscribe(
            (data) => {
                this.userData = []
                this.userData = this.locstr.getObj('userData');
            });
            this.broadcaster.on<any>('subAdmin')
            .subscribe(message => {
                this.userData = []
                this.userData = this.locstr.getObj('userData');
                console.log("this.userData>>>>>", this.userData);
            });
        this.sidebar.isUpdate.subscribe(
            (data) => {
                this.userData = []
                this.userData = this.locstr.getObj('userData');
                if(this.userData){
                if (this.userData.roleType == "SUB_ADMIN") {
                    this.userAccessSet = this.locstr.getObj("userAccessSet");
                    this.isUserManagment = false;
                    this.isActivity = false;
                    this.isreport = false;
                    this.isGoals = false;
                    this.isStats = false;
                    for ( let i = 0; i < this.userData.userAccessList.length; i++ ) {
                        if ( this.userData.userAccessList[i] == 'USER_MANAGEMENT' ) {
                            this.isUserManagment = true;
                        }
                        if ( this.userData.userAccessList[i] == 'ACTIVITY' ) {
                            this.isActivity = true;                   
                        }
                        if ( this.userData.userAccessList[i] == 'REPORTS' ) {
                            this.isreport = true;
                        }                       
                        if ( this.userData.userAccessList[i] == 'GOALS' ) {
                            this.isGoals = true;
                        }
                        if ( this.userData.userAccessList[i] == 'STATS' ) {
                            this.isStats = true;
                        }
                    }
                  }
                }
            }
        );
        

        

    }

    routeChangeDetector() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                console.log("event['url'] ", event['url']);
                if (this.userData?.roleType == 'PROPERTY_MANAGER') {
                    if ((event['url'] === '/property-manager-dashboard')) {
                        this.pmDashboard = true;
                    } else {
                        this.pmDashboard = false;
                    }
                } else {
                    if ((event['url'] === '/admin-dashboard')) {
                        this.adminDashboard = true;
                    } else {
                        this.adminDashboard = false;
                    }
                    if ((event['url'] === '/goals')) {
                        this.goals = true;
                    } else {
                        this.goals = false;
                    }
                }
            }
            if(event['url'] != undefined && event['url'] != '/'){
                this.locstr.setObj('currUrl',event['url']);
            }
        });
    }

    closeSidebar = () => {
        this.broadcaster.broadcast('menu-close', false);
        this.renderer.removeClass(document.body, 'menu-open');
    }

    toggleSideNav(): void {
        this.hideSideNav = !this.hideSideNav;
    }
    toggleMenu() {
        console.log("toggle1");
        // this.closeMenu.emit(true);
        if (this.sidebar.visible) {
            this.sidebar.hide()
            console.log("Slider hide");
            this.locstr.setObj("isSliderShow", false);
        } else {
            this.sidebar.show()
            console.log("Slider show");
            this.locstr.setObj("isSliderShow", true);
        }

        $("#wrapper").toggleClass("toggled");
    }

    /**
    * Function Coming soon
    */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo("Coming soon");

        // this.toaster.open({ text: "Coming soon", type: 'info', position: 'top-center' })
    }

    logoutClick = () => {
        //this.clicked = false;
        //this.clicked = !this.clicked
        let titleText = "Rentdex";
        let messageText = "Are you sure you want to logout?";
        let yesText = "YES";
        let noText = "NO";
        this.commonService.showConfirm(titleText, messageText, noText, yesText, () => {
        }, () => {
            console.log("enter");
            this.commonService.showLoading();
            this.authService.logout(this.userData).subscribe(
                res => {
                    this.commonService.hideLoading();
                    this.step = 'step1';
                    if (res.code == 200 || res.status == 200) {
                        this.userData = this.locstr.getObj('userData');
                        this.password = this.locstr.getObj("password");
                        this.checkValueData = this.locstr.getObj("checkValue");
                        var deviceTokenId =  this.locstr.getObj("deviceTokenId");
                        window.localStorage.clear(); //try this to clear all local storage
                        this.userData.isLoggedInUser = false; 
                        this.locstr.setObj('userData', this.userData);
                        this.locstr.setObj("password", this.password);
                        this.locstr.setObj("checkValue", this.checkValueData);
                        this.locstr.setObj("isSliderShow", true);
                        this.locstr.setObj("deviceTokenId", deviceTokenId);
                        this.isUserManagment = false;
                        this.isActivity = false;
                        this.isreport = false;
                        this.isGoals = false;
                        this.isStats = false;

                        this.router.navigate([''], res);
                    }
                },
                error => {
                    this.commonService.hideLoading();
                    console.log("HeaderComponent :: logoutClick :: error :: ", error);
                    if (error.message) {
                        this.commonService.clearToast();
                        this.commonService.showError(error.message);
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError(error.error_description);
                    }
                }
            );
        }, () => {
        });
    }
}
