/**
 * common modal functionality
 * Created By : #1223
 * Date : 10 Dec, 2019
 */
/***************************** Angular component *****************************/
import { Component, OnInit, Input, Output ,ViewEncapsulation, HostListener} from '@angular/core';
import { NgClass } from '@angular/common';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from "@angular/forms";

/***************************** Provider *****************************/
// import { CommonService } from 'src/providers/common-service/common-service';
import { EventEmitter } from 'events';
import { LocalStorageService } from 'src/providers/providers';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {
  formSubmitted: boolean;
  inputData:any = "";
  @Input() title;
  @Input() message;
  @Output() okCall;
  @Output() cancelCall;
  @Output() crossBtnCall;
  @Input() pageName;
  @Input() firstButton;
  @Input() secondButton;
  @Input() selectedObj; 
  @Input() formData;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
   this.cancel();
  }
  
  constructor( public activeModal: NgbActiveModal, 
    public localStr: LocalStorageService) {
      console.log('constructor called');
  }

  /**
   * Function on page load
   * 
  */
  ngOnInit() {
    if(this.selectedObj) {
      this.inputData = this.selectedObj;
    }
    console.log("formData", this.formData);
  }
    
  /**
   * Function for cancel
   * 
  */
  cancel() {
    if(this.localStr.getObj('valueCheckI') == 'Out'){
      this.localStr.setObj('valueCheckI', 'All');
    }
      this.activeModal.dismiss( 'Cancel click',  );
      this.cancelCall();
  }

      
  /**
   * Function on ok click
   * 
  */
  ok(form: NgForm) {
    this.formSubmitted = true;
    console.log("form", form.valid);
    if(form.valid) {
      var amodel = this.activeModal;
      this.activeModal.close( 'Ok click' );
      this.okCall(this.inputData);
      console.log("inputData ==>", this.inputData);
    }      
  }
  
  crossBtnClick() {
      this.activeModal.dismiss( 'Cross click' );
     // this.crossBtnCall();
  }

}
