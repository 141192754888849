import { Component, OnInit, ViewEncapsulation, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, MetaDataService } from 'src/providers/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplacePipe } from 'src/providers/common-service/replace-pipe';
import { TitleCasePipe } from '@angular/common';
import * as _ from "lodash";
@Component( {
    selector: 'app-client-delinquency-filter',
    templateUrl: './client-delinquency-filter.component.html',
    styleUrls: ['./client-delinquency-filter.component.scss'],

    encapsulation: ViewEncapsulation.None
} )
export class ClientDelinquencyFilterComponent implements OnInit {

    public pageName: string;
    public firstButton: string;
    public secondButton: string;


    @Input() filterPayload: any;

    public buildingOwnerList: any = [];

    tab: any;


    constructor( private replaceChar: ReplacePipe, private toTitleCase: TitleCasePipe, private router: Router, private commonService: CommonService, public activeModal: NgbActiveModal,
        public metaDataService: MetaDataService) {
        let pageName = 'Filter';
        let firstButton = 'Cancel';
        let secondButton = 'Apply';
        console.log("filterData",this.filterPayload);
    }

    ngOnInit(): void {
        if(this.filterPayload.isClientHistoricalStats){
            this.tab = 10;
        } else {
            this.tab = 1;
        }
        console.log( "filterData :: ", this.filterPayload );
        this.getOwnerList();
        if ( this.filterPayload.totalUnitFrom == 0 ) {
            this.filterPayload.totalUnitFrom = null;
        }
        if ( this.filterPayload.totalUnitTo == 0 ) {
            this.filterPayload.totalUnitTo = null;
        }
        if ( this.filterPayload.totalOccupiedUnitFrom == 0 ) {
            this.filterPayload.totalOccupiedUnitFrom = null;
        }
        if ( this.filterPayload.totalOccupiedUnitTo == 0 ) {
            this.filterPayload.totalOccupiedUnitTo = null;
        }

        if ( this.filterPayload.totalResidentPaidRentTo == 0 ) {
            this.filterPayload.totalResidentPaidRentTo = null;
        }
        if ( this.filterPayload.totalResidentPaidRentFrom == 0 ) {
            this.filterPayload.totalResidentPaidRentFrom = null;
        }
        if ( this.filterPayload.totalSubsidizedRentFrom == 0 ) {
            this.filterPayload.totalSubsidizedRentFrom = null;
        }
        if ( this.filterPayload.totalSubsidizedRentTo == 0 ) {
            this.filterPayload.totalSubsidizedRentTo = null;
        }
        if ( this.filterPayload.totalCollectedFrom == 0 ) {
            this.filterPayload.totalCollectedFrom = null;
        }
        if ( this.filterPayload.totalCollectedTo == 0 ) {
            this.filterPayload.totalCollectedTo = null;
        }
        if ( this.filterPayload.residentPaidRentCollectedFrom == 0 ) {
            this.filterPayload.residentPaidRentCollectedFrom = null;
        }
        if ( this.filterPayload.residentPaidRentCollectedTo == 0 ) {
            this.filterPayload.residentPaidRentCollectedTo = null;
        }
        if ( this.filterPayload.subsidizedRentCollectedFrom == 0 ) {
            this.filterPayload.subsidizedRentCollectedFrom = null;
        }
        if ( this.filterPayload.subsidizedRentCollectedTo == 0 ) {
            this.filterPayload.subsidizedRentCollectedTo = null;
        }
        if ( typeof this.filterPayload.buildingOwner === 'object' ) {
            this.filterPayload.buildingOwner = "";
        }
        // console.log( "filterData :: ", this.filterPayload,typeof this.filterPayload.buildingOwner );
    }

    

    /**
       * Function to get owner list
       */
      private getOwnerList = () => {
        this.buildingOwnerList = [];
        this.metaDataService.getOwnersNameList(( response ) => {

            this.buildingOwnerList.push( { label: "Select", value: "" } );
            response.map( status =>
                this.buildingOwnerList.push( status )
            );

            console.log("this.buildingOwnerList>>>>", this.buildingOwnerList);
            
            if ( this.filterPayload.buildingOwner == null ) {
                this.filterPayload.buildingOwner = this.buildingOwnerList[0].value;
                console.log("this.filterPayload.buildingOwner :: >>>", this.filterPayload.buildingOwner);
                
            }
            this.buildingOwnerList = _.cloneDeep( this.buildingOwnerList );
            this.commonService.hideLoading();
        },
            ( reason ) => {
                this.commonService.hideLoading();
                this.buildingOwnerList = reason;
            }
        );
    }


    /**
     * function on cancel Click
     */
    cancelClick = () => {
        this.activeModal.dismiss( '' );
    }
    /**
       * function on clear Click
       */
      clearFilter = () => {
        this.filterPayload.buildingOwner = "";
        this.filterPayload.totalUnitFrom= null;
        this.filterPayload.totalUnitTo= null;
        this.filterPayload.totalOccupiedUnitFrom= null;
        this.filterPayload.totalOccupiedUnitTo= null;
        this.filterPayload.totalRentRollFrom= null;
        this.filterPayload.totalRentRollTo= null;
        this.filterPayload.totalResidentPaidRentFrom= null;
        this.filterPayload.totalResidentPaidRentTo= null;
        this.filterPayload.totalSubsidizedRentFrom= null;
        this.filterPayload.totalSubsidizedRentTo= null;
        this.filterPayload.totalCollectedFrom= null;
        this.filterPayload.totalCollectedTo= null;
        this.filterPayload.residentPaidRentCollectedFrom=null;
        this.filterPayload.residentPaidRentCollectedTo=null;
        this.filterPayload.subsidizedRentCollectedFrom=null;
        this.filterPayload.subsidizedRentCollectedTo=null;
        

    }
    /**
     * function on cancel Click
     */
    applyFilter = () => {
        if ( typeof this.filterPayload.buildingOwner === 'object' ) {
            this.filterPayload.buildingOwner = "";
        }
        console.log( "filterData :: ", this.filterPayload,typeof this.filterPayload.buildingOwner );
        this.activeModal.dismiss( this.filterPayload );
    }   

    /**
     * Function coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo("Coming soon");    
    }

    /**
     * Function clear coming soon
     */
    public clearCommingSoon = () => {
        this.commonService.clearToast();   
    }

}
