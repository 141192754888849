
<div class="row page-wrap">
    <div class="container-fluid">
        
        <div class="row m-0">
            <div class="container-fluid p-0">

                <div class="card clearfix">

                        <p-table class="custom-table-cls" #dt [value]="notificationList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 295px)'" (onLazyLoad)="customSort($event)" [responsive]="true" [lazy]="true">
                            <!-- [loading]="loading"  Add this for Inplace loader-->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="notificationDate"> {{ "NOTIFICATION.DATE_AND_TIME"| translate }}
                                        <p-sortIcon field="notificationDate"></p-sortIcon>
                                    </th>
                                                                            
                                    <th> {{ "NOTIFICATION.NOTIFICATION_DETAILS"| translate }} </th>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-notificationList>
                                <tr class="cusr-pointer">
                                    
                                    <td title="{{notificationList.createdAt | date: 'MMM dd, yyyy hh:mm a' : ' '}}">{{ notificationList.createdAt ? (notificationList.createdAt | date: 'MMM dd, yyyy hh:mm a' : ' ') : '-' }}</td>
                                    <td title="{{ notificationList.message }}">{{ notificationList.message? notificationList.message: '-' }}</td>

                                </tr>                 
                            </ng-template>
                        </p-table>
                        <div class="control-label" *ngIf="notificationList.length == 0">
                            <div class="no-data-found" *ngIf="!loading" text-center>
                                <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND"| translate }} </div>
                            </div>
                        </div>
                    </div>
                    <p-paginator styleClass="ui-paginator-bottom" [rows]="25" (onLazyLoad)="notificationpaginate($event)" (onPageChange)="notificationpaginate($event)" [totalRecords]=" notificationPayload.totalRecords" class="list-paginator table-paginator"></p-paginator>
                </div>
                    
        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>