import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginDTO } from '../../../models/login-dto';
import { AuthService, Broadcaster, CommonService, HeaderService, LocalStorageService, SidebarService } from 'src/providers/providers';

// import { Toaster } from 'ngx-toast-notifications';
// import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import * as _ from "lodash";
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocationStrategy } from '@angular/common';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['../auth.scss']
})
export class LoginComponent implements OnInit {

    public userDetails: LoginDTO = {};
    passwordCheck: boolean;
    checkValue: boolean = false;
    tokenData: any;
    userData: any;
    public userAccessSet: {
        "isUserManagment": false,
        "isActivity": false,
        "isreport": false,
        "isGoals": false
    };
    inputType: string = "password";
    public showPassword: boolean = false;
    formSubmitted: boolean;
    email: string;
    password: string;
    public isMobile: boolean = false;
    public access: any = []
    deviceInfo = null;
    constructor(private locstr: LocalStorageService, private authService: AuthService, private router: Router, private commonService: CommonService,
        private translate: TranslateService, private appComponent: AppComponent,
        private location: LocationStrategy, private broadcaster: Broadcaster,
        public header: HeaderService, public sidebar: SidebarService, private deviceService: DeviceDetectorService) {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();

        this.checkValue = this.locstr.getObj('checkValue');
        console.log("checkValue...", this.checkValue);
        this.tokenData = this.locstr.getObj('tokenData');
        this.userData = this.locstr.getObj('userData');
        this.password = this.locstr.getObj("password");

        if (this.checkValue && (this.userData != undefined && this.userData.isLoggedInUser == false)) {
            if (this.userData) {
                this.checkValue = true;
                $('#customCheck').prop('checked', true);
                this.userData.password = this.password;
                this.userDetails.email = this.userData.email;
                this.userDetails.email = _.cloneDeep(this.userData.email);
                this.userDetails.password = _.cloneDeep(this.password);
            }
            } else if(this.userData != undefined && this.userData.isLoggedInUser == true){
                this.commonService.checkIsLoggedInUser();
            } else {
                this.checkValue = false;
            $('#customCheck').prop('checked', false);
                this.userDetails.password = "";
                this.userDetails.email = "";
            }
        console.log("hash>>>", window.location.hash);

        // if (this.isMobile) {
            history.pushState(null, null, window.location.href);
            this.location.onPopState(() => {
                history.pushState(null, null, window.location.href);
            });
        // }

    }

    ngOnInit(): void {
        this.commonService.hideLoading();
        this.commonService.clearToast();
        this.sidebar.hide();
        this.header.hide();
    }

    /**
     * Function to executed on press of login button
     */
    public processLoginRequest = (form: NgForm) => {
        if(this.locstr.getObj("deviceTokenId")){
            this.userDetails.fcmDeviceToken = this.locstr.getObj("deviceTokenId");
            console.log("this.userDetails.fcmDeviceToken ...", this.userDetails.fcmDeviceToken);
        } else {
            this.userDetails.fcmDeviceToken = "";
        }
        
        this.formSubmitted = true;
        this.userDetails.isMobile = this.isMobile
        console.log("valid", form.valid)
        if (form.valid) {
            this.commonService.showLoading();
            this.authService.login(this.userDetails).subscribe(
                res => {
                    if (res.code == 200 || res.status == 200) {
                        this.commonService.hideLoading();
                        this.locstr.setObj("password", this.userDetails.password);
                        this.checkValue = $("#customCheck").prop("checked");
                        console.log("checkValue...", this.checkValue);
                        this.locstr.setObj("checkValue", this.checkValue);
                        this.locstr.setObj("isSliderShow", true);
                                                
                        if (res.data.roleType == "SUPER_ADMIN") {
                            if (this.isMobile) {
                                this.commonService.showError("Mobile view is not supported for admin.");
                                return;
                            } else {
                                this.router.navigate(['/admin-dashboard'], res);
                            }
                        } else if (res.data.roleType == "SUB_ADMIN") {
                            if (this.isMobile) {
                                this.commonService.showError("Mobile view is not supported for admin.");
                                return;
                            } else {
                                this.router.navigate(['/admin-dashboard'], res);
                            }
                        } else if (res.data.roleType == "EVICTION_ADMIN") {
                            if (this.isMobile) {
                                this.commonService.showError("Mobile view is not supported for admin.");
                                return;
                            } else {
                                this.router.navigate(['/eviction-pipeline'], res);
                            }
                        } else if (res.data.roleType == "PROPERTY_MANAGER") {
                            this.router.navigate(['/property-manager-dashboard'], res);
                        } else if (res.data.roleType == "SUB_ADMIN") {
                            /***** Sub admin update ******************/
                            // this.locstr.setObj("isUserManagment", false);
                            // this.locstr.setObj("isActivity", false);
                            // this.locstr.setObj("isreport", false);
                            // this.locstr.setObj("isGoals", false);
                            // this.locstr.setObj("isStats", false);
                            

                            this.broadcaster.broadcast('subAdmin', true);
                            if (res.data.userAccessList) {
                                if (res.data.userAccessList[0] == 'USER_MANAGEMENT') {
                                    this.router.navigate(['/admin-dashboard'], res);
                                }
                                if (res.data.userAccessList[0] == 'ACTIVITY') {
                                    this.router.navigate(['/activities'], res);
                                }
                                if (res.data.userAccessList[0] == 'REPORTS') {
                                    this.router.navigate(['/admin-dashboard'], res);
                                }
                                if (res.data.userAccessList[0] == 'GOALS') {
                                    this.router.navigate(['/goals'], res);
                                }
                                if (res.data.userAccessList[0] == 'STATS') {
                                    this.router.navigate(['/stats'], res);
                                }
                            }
                        }
                        if (this.isMobile) {
                            console.log("New mobile build version: 1.0.0");

                        }
                        this.locstr.setObj('tokenData', { access_token: res.access_token, refresh_token: res.refresh_token });
                        
                        res.data['isLoggedInUser'] = true;
                        
                        this.locstr.setObj('userData', res.data);
                        
                      //Call notification API
                        this.sendToken();
                        
                        console.log("LoginComponent :: processLoginRequest :: res :: ", res);
                        this.commonService.clearToast();
                        this.commonService.showSuccess('Login successful');
                        // this.toaster.open( { text: "Login successful", type: 'success', position: 'top-center' } );
                    }
                },
                error => {
                    this.commonService.hideLoading();
                    console.log("LoginComponent :: processLoginRequest :: error :: ", error);
                    if (error.message) {
                        this.commonService.clearToast();
                        this.commonService.showError(error.message);
                        // this.toaster.open({ text: error.message, type: 'danger', position: 'top-center' });
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError(error.error_description);
                        // this.toaster.open({ text: error.error_description, type: 'danger', position: 'top-center' });
                    }

                }
            );
        }
        //this.commonService.hideLoading();
    }

    /**
   * Function for hide/show password
   * 
  */
    hideShowPassword = () => {
        this.commonService.hideShowPassword(this.inputType, this.passwordCheck, (showPassword, inputType) => {
            this.inputType = inputType;
            this.passwordCheck = showPassword;
        });
    }
    
    /**
     * Function to Send a Notification token
   */
   public sendToken = ( ) => {
        this.authService.sendToken( this.userDetails ).subscribe(
            ( res ) => {
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "create user :: res :: ", res );                    
                }
            },
            ( error ) => {                
            }
        );

        
   }

}
