<div class="row page-wrap">
    <div class="container-fluid">

            <div class="row m-0">
                <div class="col-12 col-sm-12 dashboard-card-cls">
                    <div class="card dashboard-card clearfix">
                        <form class="form-inline crud-form" #profileForm="ngForm">
                            <div class="d-flex flex-row form-row">
                                <div class="col-sm-6 col-12 d-flex form-gr">
                                    <div class="form-group">
                                        <label for="phone"> {{ "EDIT_PROFILE.PHONE_NO" | translate }}: </label>
                                        <input type="number" id="phone" class="form-control form-input" onKeyPress="if(this.value.length==10) return false;" [disabled]="true" placeholder="Phone Number" #phoneNumber="ngModel" [(ngModel)]="userToView.phoneNumber" name="phone"
                                        pattern="^((\\+91-?)|0)?[0-9]{10}$" aria-describedby="phone number" pInputText /> 
                                        <div class="text-left error" *ngIf="phoneNumber.errors && (phoneNumber.dirty || phoneNumber.touched || formSubmitted)">
                                            <small [hidden]="!phoneNumber.errors.pattern" class="p-invalid error-msg"> {{ "CREATE_USER.PLEASE_ENTER_10_DIGIT_PHONE_NO" | translate }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12 d-flex form-gr">
                                    <div class="form-group">
                                        <label for="email">{{ "EDIT_PROFILE.EMAIL" | translate }}:</label>
                                        <input id="email" type="text" placeholder="Email" class="form-control form-input" #userEmail="ngModel" [disabled]="true" [(ngModel)]="userToView.email" id="formEmail" pattern="^(?!\.)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,256}$" name="email"
                                            aria-describedby="Email-help" pInputText required />
                                        <div class="text-left error" *ngIf="userEmail.errors && (userEmail.dirty || userEmail.touched || formSubmitted)">
                                            <small [hidden]="!userEmail.errors.required" class="p-invalid error-msg"> {{ "CREATE_USER.PLEASE_ENTER_EMAIL_ADDRESS" | translate }}
                                            </small>
                                            <small [hidden]="!userEmail.errors.pattern" class="p-invalid error-msg"> {{ "LOGIN.EMAIL_INVALID" | translate }}
                                            </small>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            <div class="d-flex flex-row form-row">
                                <div class="col-sm-6 col-12 d-flex form-gr">
                                    <div class="form-group">
                                        <label for="firstName"> {{ "EDIT_PROFILE.FIRST_NAME" | translate }}: </label>
                                        <input type="text" id="fname" [ngStyle]="{'border': (firstName.errors && (firstName.dirty || firstName.touched || formSubmitted)) ? '1px solid red' : '0' }" class="form-control form-input" placeholder="First Name" #firstName="ngModel" [(ngModel)]="userToView.firstName"
                                            name="fname" aria-describedby="first name" maxlength="50" pInputText required />
                                        <div class="error" *ngIf="firstName.errors && (firstName.dirty || firstName.touched || formSubmitted)">
                                            <small [hidden]="!firstName.errors.required" class="p-invalid error-msg"> {{ "CREATE_USER.PLEASE_ENTER_FIRST_NAME" | translate }} </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-12 d-flex form-gr">
                                    <div class="form-group">
                                        <label for="lastName"> {{ "EDIT_PROFILE.LAST_NAME" | translate }}:</label>
                                        <input type="text" id="lname" [ngStyle]="{'border': (lastName.errors && (lastName.dirty || lastName.touched || formSubmitted)) ? '1px solid red' : '0' }" class="form-control form-input" placeholder="Last Name" #lastName="ngModel" [(ngModel)]="userToView.lastName"
                                            name="lname" aria-describedby="last name" maxlength="50" pInputText required/>
                                        <div class="error" *ngIf="lastName.errors && (lastName.dirty || lastName.touched || formSubmitted)">
                                            <small [hidden]="!lastName.errors.required" class="p-invalid error-msg"> {{ "CREATE_USER.PLEASE_ENTER_LAST_NAME" | translate }} </small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex flex-row form-row">
                                <div class="col-sm-6 col-12 d-flex form-gr">
                                    <div class="form-group">
                                        <label for="role">{{ "EDIT_PROFILE.ROLE" | translate }}:</label>
                                        <p-dropdown class="form-dropdown" [options]="roleList" name="role" #roleType="ngModel" [(ngModel)]="userToView.roleType" [disabled]="true"></p-dropdown>
                                      
                                    </div>
                                </div>
                                <!-- <div class="col-sm-6 col-12 d-flex form-gr">
                                    <div class="form-group">
                                        <label for="gender">{{ "EDIT_PROFILE.GENDER" | translate }}:</label>
                                        <p-dropdown class="form-dropdown" [options]="genderList" [(ngModel)]="userToView.gender" name="selectedGender" #gender="ngModel"  (onChange)="getSelectedGender($event)"></p-dropdown>
                                    </div>
                                </div>-->
                                <div class="col-sm-6 col-12 d-flex form-gr" *ngIf="userToView.roleType=='SUB_ADMIN'">
                                    <div class="form-group">
                                        <label for="access"> {{ "CREATE_USER.TEAM_MEMBERS" | translate }}: </label>
                                        <p-multiSelect class="form-dropdown form-multiselect" [defaultLabel]="'Please Select (Optional)'" [options]="pmNameItemList" name="teamMemberList" #teamMemberList="ngModel" [(ngModel)]="userToView.teamMemberList" (onChange)="getSelectedTeamMember($event)"></p-multiSelect>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex flex-row form-row">
                                                               
                                <div class="col-sm-6 col-12 d-flex form-gr" *ngIf="userToView.roleType=='SUB_ADMIN'">
                                    <div class="form-group">
                                        <label for="access"> {{ "CREATE_USER.ACCESS" | translate }}: </label>
                                        <p-multiSelect class="form-dropdown form-multiselect" [defaultLabel]="'Please Select Access'" [options]="accessList" name="access" #userAccessList="ngModel" [(ngModel)]="userToView.userAccessList " (onChange)="getSelectedAccess($event)"></p-multiSelect>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="d-flex flex-row form-row">
                                <div class="col-sm-6 col-12 d-flex form-gr">
                                    <div class="form-group">
                                        <label for="dob">{{ "CREATE_USER.DOB" | translate }}</label>
                                        <p-calendar id="dateOf" [touchUI]="true" name="dob" placeholder="DOB (Optional)" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [maxDate]="maxDateValue"  [readonlyInput]="true" [showIcon]="true" #dob="ngModel" [(ngModel)]="userToView.dob" [monthNavigator]="true"
                                            [yearNavigator]="true" [yearRange]="range" ></p-calendar>

                                    </div>
                                </div>
                            </div> -->

                                <div class="d-flex flex-row form-row">
                                    <div class="col-12 d-flex form-gr">
                                        <div class="form-group">
                                            <label for="address">{{ "EDIT_PROFILE.ADDRESS" | translate }}:</label>
                                            <input type="text" id="address " class="form-control form-input" placeholder="Address (Optional)" #address="ngModel" [(ngModel)]="userToView.address" name="address" aria-describedby="address" pInputText />

                                        </div>
                                    </div>
                                </div>

                                
                                <div class="col-sm-12 p-0 mar-top15">
                                    <button class="btn outline-btn" (click)="cancelClick()">{{ "COMMON_COMPONENT.CANCEL_BUTTON" | translate }}</button>
                                    <button class="btn common-btn small-btn" (click)="submitProfile(profileForm)">{{ "COMMON_COMPONENT.SUBMIT_BUTTON" | translate }}</button>
                                </div>
                        </form>
                        
                    </div>
                </div>
            </div>

    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>