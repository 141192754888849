
<!-- Container -->
<div class="form-container filter-form-cls">

    <div class="">

        <!-- Heading -->
        <div class="row filter-heading m-0">
            <div class="col-6">
              <h2 class="form-title"> {{"COMMON_COMPONENT.FILTER" | translate }} </h2>
            </div>
            <div class="col-6">
              <button class="btn link-btn clear-btn" (click)="clearFilter()"> {{"COMMON_COMPONENT.CLEAR_ALL" | translate }} </button>
            </div>
        </div>


        <div class="container custom-ver-tabs">
            <!-- <div class="col nav-tabs-wrap"> -->
              <!-- Nav tabs -->
              <ul class="nav nav-tabs tabs-left sideways" id="myTab" role="tablist" *ngIf="filterPayload.isDeliquence">
                <li class="nav-item active" [ngClass]="{active: tab == 1}"><a (click)=" tab=1; clearCommingSoon()" class="nav-link active" href="#pme-v" data-toggle="tab" role="tab"> {{ "ACTIVITY.PM_EA_NAME" | translate }} </a></li>
                <!-- <li class="nav-item" [ngClass]="{active: tab == 2}"><a (click)=" tab=2; commingSoon()" class="nav-link" href="#ownerName-v" data-toggle="tab" role="tab"> {{ "ACTIVITY.OWNERS_NAME" | translate }} </a></li> -->
                <li class="nav-item" [ngClass]="{active: tab == 3}"><a (click)=" tab=3; clearCommingSoon()" class="nav-link" href="#action-v" data-toggle="tab" role="tab"> {{ "ACTIVITY.ACTION_TAKEN" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 4}"><a (click)=" tab=4; clearCommingSoon()" class="nav-link" href="#follow-v" data-toggle="tab" role="tab"> {{ "ACTIVITY.FOLLOW_UP_DATE" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 5}"><a (click)=" tab=5; clearCommingSoon()" class="nav-link" href="#promise-v" data-toggle="tab" role="tab"> {{ "ACTIVITY.PROMISE_TO_PAY" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 6}"><a (click)=" tab=6; clearCommingSoon()" class="nav-link" href="#update-v" data-toggle="tab" role="tab"> {{ "ACTIVITY.UPDATE_AT" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 7}"><a (click)=" tab=7; clearCommingSoon()" class="nav-link" href="#acronym-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RESIDENT_ACRONYM" | translate }} </a></li>                
              </ul>
            <!-- </div> -->

            <!-- <div class="col tab-content-wrap"> -->
              <!-- Tab panes -->
                <div class="tab-content">
                  <div class="tab-pane" id="pme-v" role="tabpanel" [ngClass]="{active: filterPayload.isDeliquence}">
                    <!-- PM/EA content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">
                            <p-dropdown class="form-dropdown" name="pmFilter" placeholder="Select" [options]="pmEaNameItemList" [(ngModel)]="filterPayload.pmName"></p-dropdown>
                          </div>
                        </div>
                    </form>
                  </div> 
                  <div class="tab-pane" id="ownerName-v" role="tabpanel">
                    <!-- Owner's Name content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">

                            <!--  Remove  [disabled]="true"  to active this field -->
                            <p-dropdown [disabled]="true" class="form-dropdown" placeholder="Select" name="ownersNameFilter" [options]="ownersNameList" [(ngModel)]="filterPayload.ownersName"></p-dropdown>
                          
                          </div>
                        </div>
                    </form>
                  </div>
                  <div class="tab-pane" id="action-v" role="tabpanel">
                      <!-- Action taken content... -->
                      <form class="form-inline crud-form filter-form">
                          <div class="row">
                            <div class="col-sm-12 form-group">
                              <p-dropdown class="form-dropdown" placeholder="Select" name="actionFilter" [options]="actionItemList" [(ngModel)]="filterPayload.lastActionTaken"></p-dropdown>
                            </div>
                          </div>
                      </form>
                  </div>

                  <div class="tab-pane" id="follow-v" role="tabpanel">
                    <!-- Follow up date content...  -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">
                            <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #from="ngModel"  [(ngModel)]="filterPayload.followUpDateFrom" [monthNavigator]="true"
                            [yearNavigator]="true" [yearRange]="range"></p-calendar>
                          </div>
                          <div class="col-sm-12 form-group">
                            <p-calendar id="dateto" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel"  [(ngModel)]="filterPayload.followUpDateTo" [disabled]="!filterPayload.followUpDateFrom" [minDate]="filterPayload.followUpDateFrom" [monthNavigator]="true"
                            [yearNavigator]="true" [yearRange]="range"></p-calendar>
                          </div>
                          <span class="p-invalid error-msg" *ngIf="!filterPayload.followUpDateFrom">Please select from date first.</span>
                        </div>
                    </form>
                </div>
                
                <div class="tab-pane" id="promise-v" role="tabpanel">
                    <!-- Promise to pay content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">
                            <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #from="ngModel"  [(ngModel)]="filterPayload.promiseToPayFrom" [monthNavigator]="true"
                            [yearNavigator]="true" [yearRange]="range"></p-calendar>
                          </div>
                          <div class="col-sm-12 form-group">
                            <p-calendar id="dateto" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel"  [(ngModel)]="filterPayload.promiseToPayTo" [disabled]="!filterPayload.promiseToPayFrom" [minDate]="filterPayload.promiseToPayFrom" [monthNavigator]="true"
                            [yearNavigator]="true" [yearRange]="range"></p-calendar>
                          </div>
                          <span class="p-invalid error-msg" *ngIf="!filterPayload.promiseToPayFrom">Please select from date first.</span>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="update-v" role="tabpanel">
                    <!-- Updated at content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">
                            <p-calendar id="dateOf" [touchUI]="true" name="from" placeholder="From" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #from="ngModel"  [(ngModel)]="filterPayload.updatedAtFrom" [monthNavigator]="true"
                            [yearNavigator]="true" [yearRange]="range"></p-calendar>
                        </div>
                        <div class="col-sm-12 form-group">
                          <p-calendar id="dateto" [touchUI]="true" name="to" placeholder="To" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #to="ngModel"  [(ngModel)]="filterPayload.updatedAtTo" [disabled]="!filterPayload.updatedAtFrom" [minDate]="filterPayload.updatedAtFrom" [monthNavigator]="true"
                            [yearNavigator]="true" [yearRange]="range"></p-calendar>
                        </div>
                        <span class="p-invalid error-msg" *ngIf="!filterPayload.updatedAtFrom">Please select from date first.</span>
                        </div>
                    </form>
                </div>

                <!-- Resident Acronym List --->
                <div class="tab-pane" id="acronym-v" role="tabpanel">
                  <!-- Action items content... -->
                  <form class="form-inline crud-form filter-form">
                     <div class="row">
                        <div class="col-sm-12 form-group">
                           <p-dropdown class="form-dropdown" name="acronymFilter" [options]="acronymList" [(ngModel)]="filterPayload.acronym"></p-dropdown>
                        </div>
                     </div>
                  </form>
               </div>

                   
              </div>
            <!-- </div> -->

            <div class="clearfix"></div>

        </div>

        <!--------------- modal btns  --------->
        <div class="row d-flex modal-footer m-0">
          <button type="button" class="btn link-btn" (click)="cancelClick()"> {{"COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
          <button type="submit" class="btn common-btn" (click)="applyFilter()"> {{"COMMON_COMPONENT.APPLY_BUTTON" | translate }} </button>
        </div>


    </div>
  </div>


