import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from "@angular/core";

import { AppService } from './../../../app/app.service';

import {
    CommonService,
    HeaderService,
    SidebarService,
    RentRollService,
    LocalStorageService
} from "src/providers/providers";
import { LazyLoadEvent } from 'primeng/api';
import { ExportPopupComponent } from 'src/pages/export-popup/export-popup.component';
import { RentRollFilterComponent } from "./rent-roll-filter/rent-roll-filter.component";
import { Router } from '@angular/router';
import { Paginator } from "primeng/paginator";
import { Constants } from '../../../providers/app-settings-service/app-constant.service';

@Component( {
    selector: "rent-roll-list",
    templateUrl: "./rent-roll.component.html",
    styleUrls: ["./rent-roll.component.scss"],
    encapsulation: ViewEncapsulation.None
} )
export class RentRollComponent implements OnInit {
    @Input()
    loading: boolean = true;
    @ViewChild('p', {static: false}) paginator: Paginator;

    filterData: any = {};

    public isSearchCall: boolean = false
    pageNumber: number = 0;

    rentRollListDefaultPage = 0;
    rentRollListDefaultSize = 25;

    // Today's table data
    public rentRollList: any = [];
    isRentRollNoData: boolean = false;

    public isRentRoll: boolean = true;
    public isRentRollList: boolean = true;

    public rentRollPayload: any = {
        "pageNumber": 0,
        "pageSize": 25,
        "evictionStatus": "",
        "pendingRentFrom": 0,
        "pendingRentTo": 0,
        "pmStatus": "",
        "renewalStatus": "",
        "searchText": "",
        "sortBy": "",
        "sortManner": "",
        "subsidyFrom": 0,
        "subsidyTo": 0,
        "tenantBalanceFrom": 0,
        "tenantBalanceTo": 0,
        "totalRentFrom": 0,
        "totalRentTo": 0,
        "unit": ""
    };

    public userData: any;

    constructor(
        public header: HeaderService,
        public sidebar: SidebarService,
        protected commonService: CommonService,
        private rentRollService: RentRollService,
        private appService: AppService,
        public localStr: LocalStorageService,
        private router: Router,
        public constants : Constants
    ) {
        this.rentRollPayload.pageSize = this.constants.defaultPageSize;
     }

    ngOnInit(): void {
        //Get Rent Roll list data
        this.getRentRollList(null);

        var isSliderShow = this.localStr.getObj("isSliderShow");
        if(isSliderShow){
            this.sidebar.show();
            this.localStr.setObj("isSliderShow", true);
        } else {
            this.sidebar.hide();
            this.localStr.setObj("isSliderShow", false);
        }
        this.header.show();

        this.localStr.setObj("breadcrum", "");
        this.appService.setTitle( 'Rent Roll' );
        this.userData = this.localStr.getObj( 'userData' );
    }

    /**
     * Function for Delinquencies list pagination
     */
    public rentRollpaginate = ( event ) => {
        let currentPage: number;
        this.rentRollListDefaultPage = event.first / event.rows;
        this.rentRollListDefaultSize = event.rows;
        this.rentRollList = [];
        this.rentRollPayload.pageSize = this.constants.defaultPageSize;
        this.rentRollPayload.pageNumber = this.rentRollListDefaultPage;
        this.getRentRollList(null);
    }

    /**
     * Function to get deliquencies list
     */
    public getRentRollList($event) {

        if ( this.rentRollPayload.sortBy == "" || this.rentRollPayload.sortManner == "" ) {
            this.rentRollPayload.sortBy = "";
            this.rentRollPayload.sortManner = "";
        }

        // check data after visiting activity log
        if(this.localStr.getObj("beforeActivityData")){
            this.rentRollPayload = this.localStr.getObj("beforeActivityData");
        }

        if ( !this.isSearchCall ) {
            this.commonService.showLoading();  // remove this when we use inplace loader
        }else if(this.isSearchCall){
            this.rentRollPayload.pageNumber= 0;
        }
        this.loading = true;

        this.rentRollService.getRentRollList( this.rentRollPayload ).subscribe(
            ( res ) => {
                if ( !this.isSearchCall ) {
                    this.commonService.hideLoading();
                }else if(this.isSearchCall){
                    this.paginator.changePageToFirst($event);
                }
                this.isSearchCall = false
                if ( res.code == 200 || res.status == 200 ) {
                    this.rentRollList = res.data.list;
                    //this.rentRollList = res.data;
                    if ( this.rentRollList && this.rentRollList.length == 0 ) {
                        this.isRentRollNoData = false;
                    } else {
                        // Added condition for page 1  - show no data found
                        if ( this.rentRollPayload.pageNumber == 0 ) {
                            this.isRentRollNoData = true;
                        }
                    }

                    this.rentRollPayload.totalRecords = res.data.totalCount;
                    this.localStr.setObj("beforeActivityData", "");
                } else {
                    this.rentRollList = [];
                }

                this.loading = false;
            },
            ( error ) => {
                if ( !this.isSearchCall ) {
                    this.commonService.hideLoading();
                }
                this.isSearchCall = false
                this.rentRollList = [];
                this.isRentRollNoData = true;
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
                this.loading = false;
            }
        );
        this.commonService.hideLoading();
    }

    /**
     * Function to Rent Roll data export
     */
    public exportRentRoll( reportFormat ) {
        this.commonService.showLoading();

        this.rentRollService.exportRentRoll( this.rentRollPayload, reportFormat, this.userData.id ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "exportRentRoll :: res", res );
                    //window.open( res.data );
                    window.location.assign (res.data);
                }

            },
            ( error ) => {
                this.commonService.hideLoading();

                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    public loadData = ( event ) => {
        this.pageNumber = this.pageNumber + 1;
        this.rentRollPayload.page = this.pageNumber;
        this.getRentRollList(null);
    }

    checkTypeOf = ( dataCheck ) => {
        if ( typeof dataCheck == 'object' ) {
            return dataCheck = "";
        } else {
            return dataCheck;
        }
    }

    /**
     * Function for open fiter popup
     */
    public clickOnFilter = ($event) => {
        this.filterData = this.rentRollPayload;
        this.filterData.isRentRoll = this.isRentRoll;
        this.filterData.isRentRollList = this.isRentRollList;

        this.commonService.openConfirmModal( RentRollFilterComponent, this.filterData, ( data ) => {
            console.log( "RentRollComponent :: clickOnFilter :: filterdata", data );

            this.rentRollPayload.pmStatus = this.checkTypeOf( this.rentRollPayload.pmStatus );
            this.rentRollPayload.evictionStatus = this.checkTypeOf( this.rentRollPayload.evictionStatus );
            this.rentRollPayload.renewalStatus = this.checkTypeOf( this.rentRollPayload.renewalStatus );

            if ( data ) {
                if ( this.isRentRoll && this.isRentRollList ) {
                    this.rentRollPayload.evictionStatus = data.evictionStatus;
                    this.rentRollPayload.pendingRentFrom = data.pendingRentFrom;
                    this.rentRollPayload.pendingRentTo = data.pendingRentTo;
                    this.rentRollPayload.pmStatus = data.pmStatus;
                    this.rentRollPayload.renewalStatus = data.renewalStatus;
                    this.rentRollPayload.subsidyFrom = data.subsidyFrom;
                    this.rentRollPayload.subsidyTo = data.subsidyTo;
                    this.rentRollPayload.tenantBalanceFrom = data.tenantBalanceFrom;
                    this.rentRollPayload.tenantBalanceTo = data.tenantBalanceTo;
                    this.rentRollPayload.totalRentFrom = data.totalRentFrom;
                    this.rentRollPayload.totalRentTo = data.totalRentTo;
                    this.rentRollPayload.unit = data.unit;
                    this.paginator.changePageToFirst($event);
                    this.getRentRollList(null);
                }
            }

        } );
    }

    customSort( data: LazyLoadEvent, type ) {
        console.log( "sort", data );

        switch ( data.sortField ) {
            case "name":
                this.rentRollPayload.sortBy = "NAME";
                switch ( data.sortOrder ) {
                    case 1:
                        this.rentRollPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.rentRollPayload.sortManner = "ASC";
                        break;
                    default:
                        this.rentRollPayload.sortManner = "";
                        break;

                }
                break;
            case "unit":
                this.rentRollPayload.sortBy = "UNIT";
                switch ( data.sortOrder ) {
                    case 1:
                        this.rentRollPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.rentRollPayload.sortManner = "ASC";
                        break;
                    default:
                        this.rentRollPayload.sortManner = "";
                        break;

                }
                break;
            case "totalRent":
                this.rentRollPayload.sortBy = "TOTALBALANCE";
                switch ( data.sortOrder ) {
                    case 1:
                        this.rentRollPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.rentRollPayload.sortManner = "ASC";
                        break;
                    default:
                        this.rentRollPayload.sortManner = "";
                        break;

                }
                break;
            case "tenantBalance":
                this.rentRollPayload.sortBy = "TENANTBALANCE";
                switch ( data.sortOrder ) {
                    case 1:
                        this.rentRollPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.rentRollPayload.sortManner = "ASC";
                        break;
                    default:
                        this.rentRollPayload.sortManner = "";
                        break;

                }
                break;
            case "subsidyAmount":
                this.rentRollPayload.sortBy = "SUBSIDY";
                switch ( data.sortOrder ) {
                    case 1:
                        this.rentRollPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.rentRollPayload.sortManner = "ASC";
                        break;
                    default:
                        this.rentRollPayload.sortManner = "";
                        break;

                }
                break;
            case "pendingRent":
                this.rentRollPayload.sortBy = "PENDINGBALANCE";
                switch ( data.sortOrder ) {
                    case 1:
                        this.rentRollPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.rentRollPayload.sortManner = "ASC";
                        break;
                    default:
                        this.rentRollPayload.sortManner = "";
                        break;

                }
                break;
            default:
                this.rentRollPayload.sortBy = "";
                switch ( data.sortOrder ) {
                    case 1:
                        this.rentRollPayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.rentRollPayload.sortManner = "ASC";
                        break;
                    default:
                        this.rentRollPayload.sortManner = "";
                        break;

                }
                break;
        }

        this.getRentRollList(null);
    }


    /**
     *  Function for Export dialog 
     */
    openExportPopup() {
        this.commonService.openConfirmModal( ExportPopupComponent, this.filterData, ( data ) => {
            console.log( "log..data", this.filterData, data );
            if ( data.format ) {

                if ( this.isRentRoll ) {

                    this.exportRentRoll( data.format );
                }
            }
        } );
    }

     /** 
     * Function to open Tenant Details 
     */
    public openTenantDetails = ( tenantProfile ) => {
        console.log("renewalPayload ....", this.rentRollPayload);
        this.localStr.setObj("beforeActivityData",this.rentRollPayload);

        this.localStr.setObj("breadcrum", 'Rent Roll');

        this.router.navigate( ['/resident-profile'], tenantProfile );
    }
}
