/** 
 * To add providers
 * Created By : #1116
 */

import { RestService } from './rest-service/rest.service';
import { LocalStorageService } from './local-storage-service/local-storage.service';
import { AuthService } from './auth-service/auth.service';
import { AppSettings } from './app-settings-service/app-settings.service';
import { Constants } from './app-settings-service/app-constant.service';
import { CommonService } from './common-service/common-service';
import { ManageUserService } from './manage-user-service/manage-user.service';
import { Broadcaster } from './broadcast-service/broadcast.service';
import { HeaderService } from './common-service/header-service';
import { SidebarService } from './common-service/sidebar.service';
import { HttpNetworkInterceptor } from './network-inteceptor/network.inteceptor';

/*-------------------- Project Manager related services ---------------*/
import { PropertyManagerService } from './property-manager-service/property-manager.service';
import { TrendsService } from './trends-service/trends.service';
import { MetaDataService } from './metadata-service/metadata-service';
import { RentRollService } from './property-manager-service/rent-roll.service';
import {StatsService } from './stats-service/stats-service';
import { ReportsService } from './reports-service/reports-service';

/*-------------------- Highchart related services---------------*/
import { HighchartServiceService } from './highchart-service/highchart-service.service';

/*-------------------- PrimeNg related services ---------------*/
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

/*-------------------- Admin related services ---------------*/
import { AdminService } from './admin-service/admin-service';

/*-------------------- Eviction Admin related services ---------------*/
import { EvictionAdminService } from './eviction-service/eviction-service';

export {
    RestService,
    AppSettings,
    LocalStorageService,
    AuthService,
    ManageUserService,
    PropertyManagerService,
    Constants,
    CommonService,
    Broadcaster,
    HeaderService,
    SidebarService,
    HttpNetworkInterceptor,
    ConfirmationService,
    HighchartServiceService,
    MessageService,
    TrendsService,
    MetaDataService,
    AdminService,
    RentRollService,
    StatsService,
    EvictionAdminService,
    ReportsService
};
