/**
 * user list functionality
 * Created By : #1224
 * Date : 27 November, 2020
 */
/***************************** Angular component *****************************/
import { Component, OnInit, OnDestroy, ViewChild, HostListener, ElementRef } from "@angular/core";
import { NgForm } from "@angular/forms";

import { Router } from "@angular/router";
import { UserDTO } from "src/models/user-dto";
import { ManageUserService } from "src/providers/manage-user-service/manage-user.service";
import { ConfirmationService, Constants } from "src/providers/providers";
import { Message } from 'primeng/api';
import {
    CommonService,
    HeaderService,
    LocalStorageService,
    SidebarService,
} from "src/providers/providers";
import * as _ from "lodash";

import { AppService } from './../../../app/app.service';

@Component({
    selector: "user-list",
    templateUrl: "./user-list.component.html",
    styleUrls: ["./user-list.component.scss"],
    providers: [ConfirmationService]
})
export class UserListComponent implements OnInit, OnDestroy {
    public isDirty: boolean = false;
    public isUpdate: boolean = false;
    public updateId = "";
    first = 0;
    rows = 10;
    isNoData: boolean = false;
    public access: any = []
    public minyear = 1950;
    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = (this.currentYear - 18);
    range: string = (this.minyear).toString() + ":" + (this.maxyear).toString();

    show: boolean = false;
    msgs: Message[] = [];
    public userList: any = [];
    public isAllUser: boolean = true;
    public isPropertyManager: boolean = false;
    public isEvictionAdmin: boolean = false;
    public isSubAdmin: boolean = false;
    //UserDTO;
    public genderList: any;
    public accessList: any;
    public roleList: any;
    public selectedUser: any = [];
    public allUserChecked: boolean = false;
    public param: any = {
        pageNumber: 0,
        pageSize: 10,
        totalRecords: 0,
    };

    public userData: any = {
        isBlocked: null,
        roleType: "",
        searchText: ""
    }
    public isSearchCall: boolean = false
    public maxDateValue: Date = new Date(
        new Date().getFullYear() - 18,
        new Date().getMonth(),
        new Date().getDate());

    public isDetailView: boolean = true;
    public isBlockCall: boolean = false;
    public isCreate: boolean = false;
    public isCreateClick: boolean = false
    public codeList: any;
    public userToView: UserDTO = {};
    formSubmitted: boolean;
    public roleCheck: boolean = false;
    public accessCheck: boolean = false;
    public userName: any;
    public logedInUser: any;
    defaultPage = 0;
    defaultSize = 2

    constructor(
        private locstr: LocalStorageService,
        public header: HeaderService,
        public sidebar: SidebarService,
        private router: Router,
        protected userService: ManageUserService,
        protected commonService: CommonService,
        private confirmationService: ConfirmationService,
        public elementRef: ElementRef,
        private appService: AppService,
        public constants: Constants
    ) {
        var extras = this.router.getCurrentNavigation().extras;
        this.param.pageSize = this.constants.pageSize;
    }

    /**
     * Function on page load
     *
     */
    ngOnInit() {
        this.logedInUser = this.locstr.getObj("userData");

        this.genderList = [
            { label: "Select Gender (Optional)", value: null },
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
            { label: "Other", value: "Other" },
        ];
        this.roleList = [
            { label: "Select Role", value: null },
            { label: "Sub Admin", value: "SUB_ADMIN" },
            { label: "Property Manager", value: "PROPERTY_MANAGER" },
            { label: "Eviction Admin", value: "EVICTION_ADMIN" },
        ];
        this.accessList = [
            { label: "User Management", value: "USER_MANAGEMENT" },
            { label: "Activity", value: "ACTIVITY" },
            { label: "Reports", value: "REPORTS" },
            { label: "Goals", value: "GOALS" },
            { label: "Stats", value: "STATS" }
        ];

        this.getUserList();
        this.sidebar.show();
        this.header.show();

        this.appService.setTitle('Users');
    }

    // outSideClick = (e) => {
    //     document.getElementById('outer-container').onclick = function(e) {
    //         if(e.target != document.getElementById('content-area')) {
    //             console.log('You clicked outside');
    //         } else {
    //             console.log('You clicked inside');
    //         }
    //       }
    // }



    public getSelectedGender(eve) {
        console.log("eb", eve, this.userToView.gender);
    }
    public getSelectedAccess(eve) {
        console.log("eb", eve, this.userToView.userAccessList);
        if (this.userToView.userAccessList) {
            this.accessCheck = false;
        }
    }
    public getSelectedRole(eve) {
        console.log("eb", eve, this.userToView.roleType);
        if (this.userToView.roleType != null) {
            this.roleCheck = false;
            this.accessCheck = true
        }
    }
    /**
     * Remove conditional class only for login page
     * */
    ngOnDestroy() { }

    /**
      * Function for pagination
      */
    public paginate = (event) => {
        let currentPage: number;
        this.defaultPage = event.first / event.rows;
        this.defaultSize = event.rows;
        this.userToView.userAccessList = [];
        this.param.pageSize = this.constants.pageSize;
        this.param.pageNumber = this.defaultPage;
        this.getUserList();
    }

    /**
     * Function to get user list
     *
     */
    public getUserList() {
        console.log("param..", this.param);
        if (this.userData.searchText != "" && this.userData.roleType != "") {
            this.param.pageNumber = 0;
        }


        console.log("check userData..", this.userData);

        if (!this.isSearchCall) {
            this.commonService.showLoading();
        }

        this.userService.getUserList(this.userData, this.param).subscribe(
            (res) => {
                if (!this.isSearchCall) {
                    this.commonService.hideLoading();
                }
                this.isSearchCall = false
                if (res.code == 200 || res.status == 200) {
                    this.userList = res.data.list;
					
						// for ( let i = 0; i < this.userList.length; i++ ) {
						// 	if(this.userList[i].dob != null){
                        //     this.userList[i].dob = this.commonService.formatDate(this.userList[i].dob);
						// 	}
						// }

                    if (this.isBlockCall) {
                        this.isBlockCall = false;
                        console.log("1");

                        for (let i = 0; i < this.userList.length; i++) {
                            if (this.userToView.id == this.userList[i].id) {
                                this.userToView = _.cloneDeep(this.userList[i]);
                            }
                        }
                    } else {
                        if (this.isUpdate) {
                            console.log("2");
                            this.isUpdate = false
                            for (let i = 0; i < this.userList.length; i++) {
                                if (this.updateId == this.userList[i].id) {
                                    this.userToView = _.cloneDeep(this.userList[i]);
                                }
                            }
                        } else {
                            console.log("3");

                            this.userToView = _.cloneDeep(this.userList[0]);
                        }
                        this.processUser(this.userToView);
                        this.param.totalRecords = res.data.totalCount;
                        console.log("userList :: res :: ", res);
                    }
                }
            },
            (error) => {
                if (!this.isSearchCall) {
                    this.commonService.hideLoading();
                }
                this.isSearchCall = false
                console.log("user list :: error :: ", error);
                if (error.message) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.message);
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError(error.error_description);
                }
            }
        );
        // this.commonService.hideLoading();
    }

    protected processUser(userToView) {
        if (userToView) {
            this.isNoData = false;
            // userToView.dob = new Date(userToView.dob);
            if (userToView.userAccessList) {
                //var access :any=[] //= userToView.userAccessList
                this.access = []
                for (let i = 0; i < userToView.userAccessList.length; i++) {
                    if (userToView.userAccessList[i] == 'USER_MANAGEMENT') {
                        this.access.push('User Management')
                    }
                    if (userToView.userAccessList[i] == 'ACTIVITY') {
                        this.access.push('Activity')
                    }
                    if (userToView.userAccessList[i] == 'REPORTS') {
                        this.access.push('Reports')
                    }
                    if (userToView.userAccessList[i] == 'GOALS') {
                        this.access.push('Goals')
                    }
                    if (userToView.userAccessList[i] == 'STATS') {
                        this.access.push('Stats')
                    }
                }

                this.access = _.cloneDeep(this.access);

            }
            if (userToView.firstName || userToView.lastName) {
                this.userName =
                    userToView.firstName + " " + userToView.lastName;
            }

        } else {
            this.isNoData = true;

        }
    }
    /**
     * Functionto add filter
     */
    public FilterDataShow = (data) => {
        if (data == "") {
            this.isAllUser = true;
            this.show = false;
        } else {
            this.isAllUser = false;
        }
        if (data == "PROPERTY_MANAGER") {
            this.isPropertyManager = true;
            this.show = false;
        } else {
            this.isPropertyManager = false;
        }
        if (data == "EVICTION_ADMIN") {
            this.isEvictionAdmin = true;
            this.show = false;
        } else {
            this.isEvictionAdmin = false;
        }
        if (data == "SUB_ADMIN") {
            this.isSubAdmin = true;
            this.show = false;
        } else {
            this.isSubAdmin = false;
        }

        console.log("data..", data);
        this.userData.roleType = data;
        this.getUserList();
    }

    /**
     * Functionto for pagination
     */
    next() {
        console.log("eneter to prev");

        this.first = this.first + this.rows;
        this.param.pageNumber == this.param.pageNumber
        this.param.pageSize = this.param.pageSize,
            this.getUserList()
    }

    /**
     * Function to create/update user
     */
    public submitUser = (form: NgForm) => {
        this.formSubmitted = true;
        console.log("valid", form.valid);
        if (form.valid) {
            this.commonService.showLoading();
            console.log("valid", form.valid);

            if (this.isCreate) {
                this.userService.createUser(this.userToView).subscribe(
                    (res) => {
                        this.commonService.hideLoading();
                        if (res.code == 200 || res.status == 200) {
                            console.log("create user :: res :: ", res);
                            this.isCreate = false;
                            // this.isDetailView = true;
                            this.getUserList();
                            this.cancelClick()
                            this.commonService.clearToast();
                            this.commonService.showSuccess(res.message);

                            //suc( { text: res.message, type: 'success', position: 'top-center' } );
                        }
                    },
                    (error) => {
                        this.commonService.hideLoading();
                        console.log("create user: ", error);
                        if (error.message) {
                            this.commonService.clearToast();
                            this.commonService.showError(error.message);
                            //this.toaster.open( { text: error.message, type: 'danger', position: 'top-center' } );
                        } else {
                            this.commonService.clearToast();
                            this.commonService.showError(error.error_description);
                            //this.toaster.open( { text: error.error_description, type: 'danger', position: 'top-center' } );
                        }

                    }
                );
            } else {
                this.updateId = this.userToView.id;
                this.userService.updateUser(this.userToView).subscribe(
                    (res) => {
                        this.commonService.hideLoading();
                        if (res.code == 200 || res.status == 200) {
                            console.log("update user :: res :: ", res);
                            this.isCreate = false;
                            // this.isDetailView = true;
                            this.isUpdate = true;
                            this.getUserList();
                            this.cancelClick()
                            this.commonService.clearToast();
                            this.commonService.showSuccess(res.message);
                            //this.toaster.open( { text: res.message, type: 'success', position: 'top-center' } );
                        }
                    },
                    (error) => {
                        this.commonService.hideLoading();
                        console.log("update user: ", error);
                        if (error.message) {
                            this.commonService.clearToast();
                            this.commonService.showError(error.message);
                            //this.toaster.open( { text: error.message, type: 'danger', position: 'top-center' } );
                        } else {
                            this.commonService.clearToast();
                            this.commonService.showError(error.error_description);
                            //this.toaster.open( { text: error.error_description, type: 'danger', position: 'top-center' } );
                        }
                    }
                );
            }
        } else if (
            this.userToView.roleType == null ||
            this.userToView.roleType == ""
        ) {
            this.roleCheck = true;
            this.accessCheck = true
        }
    };

    /**
     * Function to block/ user
     */
    public blockMultipleUser = () => {
        console.log("this.selectedUser..", this.selectedUser);

        let titleText = "Block User";
        let messageText = "Are you sure you want to blocked?";
        let yesText = "YES";
        let noText = "NO";
        this.commonService.showConfirm(titleText, messageText, noText, yesText, () => {
        }, () => {
            this.commonService.showLoading();
            this.userService.blockUser({ userId: this.selectedUser }).subscribe(
                (res) => {
                    this.commonService.hideLoading();
                    if (res.code == 200 || res.status == 200) {
                        console.log("block user :: res :: ", res);
                        this.isCreate = false;
                        this.isDetailView = true;
                        this.selectedUser = [];
                        this.isBlockCall = true;
                        this.getUserList();
                        this.commonService.clearToast();
                        this.commonService.showSuccess(res.message);
                        //this.toaster.open( { text: res.message, type: 'success', position: 'top-center' } );
                    }
                },
                (error) => {
                    this.commonService.hideLoading();
                    console.log("block user: ", error);
                    if (error.message) {
                        this.commonService.clearToast();
                        this.commonService.showError(error.message);
                        //this.toaster.open( { text: error.message, type: 'danger', position: 'top-center' } );
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError(error.error_description);
                        //this.toaster.open( { text: error.error_description, type: 'danger', position: 'top-center' } );
                    }
                }
            );
        }, () => {
        });
    };

    /**
     * Function to delete user
     */
    public deleteClick = () => {
        let titleText = "Delete User";
        let messageText = "Are you sure you want to delete  " + this.userToView.firstName + " " + this.userToView.lastName + "?";
        let yesText = "DELETE";
        let noText = "CANCEL";
        this.commonService.showConfirm(titleText, messageText, noText, yesText, () => {
        }, () => {
            this.commonService.showLoading();

            this.userService.deleteUser({ userId: this.userToView.id }).subscribe(
                (res) => {
                    this.commonService.hideLoading();
                    if (res.code == 200 || res.status == 200) {
                        console.log("delete user :: res :: ", res);
                        this.commonService.clearToast();
                        this.commonService.showSuccess(res.message);
                        //this.toaster.open( { text: res.message, type: 'success', position: 'top-center' } );
                        this.getUserList();
                    }
                },
                (error) => {
                    this.commonService.hideLoading();
                    console.log("delete user: ", error);
                    if (error.message) {
                        this.commonService.clearToast();
                        this.commonService.showError(error.message);
                        // this.toaster.open( { text: error.message, type: 'danger', position: 'top-center' } );
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError(error.error_description);
                        //this.toaster.open( { text: error.error_description, type: 'danger', position: 'top-center' } );
                    }
                }
            );
        }, () => {
        });
    };

    /**
     * Function to block/unblock user
     */
    public blockUnblockUser = (block) => {
        if (block) {
            console.log("this.selectedUser..", this.userToView);

            let titleText = "Block User";
            let messageText = "Are you sure you want to block " + this.userToView.firstName + " " + this.userToView.lastName + "?";
            let yesText = "BLOCK";
            let noText = "CANCEL";
            this.commonService.showConfirm(titleText, messageText, noText, yesText, () => {
            }, () => {
                this.commonService.showLoading();
                console.log("block", block);

                this.userService.blockUser({ userId: [this.userToView.id] }).subscribe(
                    (res) => {
                        this.commonService.hideLoading();
                        if (res.code == 200 || res.status == 200) {
                            console.log("block user :: res :: ", res);
                            this.isCreate = false;
                            this.isDetailView = true;
                            this.isBlockCall = true
                            this.getUserList();
                            this.commonService.clearToast();
                            this.commonService.showSuccess(res.message);
                        }
                    },
                    (error) => {
                        this.commonService.hideLoading();
                        console.log("block user: ", error);
                        if (error.message) {
                            this.commonService.clearToast();
                            this.commonService.showError(error.message);
                            //this.toaster.open( { text: error.message, type: 'danger', position: 'top-center' } );
                        } else {
                            this.commonService.clearToast();
                            this.commonService.showError(error.error_description);
                            //this.toaster.open( { text: error.error_description, type: 'danger', position: 'top-center' } );
                        }
                    }
                );
            }, () => {
            });

        } else {
            let titleText = "Unblock User";
            let messageText = "Are you sure you want to unblock " + this.userToView.firstName + " " + this.userToView.lastName + "?";
            let yesText = "UNBLOCK";
            let noText = "CANCEL";
            this.commonService.showConfirm(titleText, messageText, noText, yesText, () => {
            }, () => {
                this.userService.unBlockUser({ userId: this.userToView.id }).subscribe(
                    (res) => {
                        this.commonService.hideLoading();
                        if (res.code == 200 || res.status == 200) {
                            console.log("unBlockUser user :: res :: ", res);
                            this.isCreate = false;
                            this.isDetailView = true;
                            this.isBlockCall = true
                            this.getUserList();
                            this.commonService.clearToast();
                            this.commonService.showSuccess(res.message);
                        }
                    },
                    (error) => {
                        this.commonService.hideLoading();
                        console.log("unBlockUser user: ", error);
                        if (error.message) {
                            this.commonService.clearToast();
                            this.commonService.showError(error.message);
                            //this.toaster.open( { text: error.message, type: 'danger', position: 'top-center' } );
                        } else {
                            this.commonService.clearToast();
                            this.commonService.showError(error.error_description);
                            //this.toaster.open( { text: error.error_description, type: 'danger', position: 'top-center' } );
                        }
                    }
                );
            }, () => {
            });
        }
    };

    /**
     * Function to select user
     * */
    selectUserToViewEdit(user) {
        this.isDetailView = true;
        this.isCreate = false
        //if ( !this.isCreate ) {
        this.userToView = _.cloneDeep(user);
        this.processUser(this.userToView);
        //}
    }

    /**
     * Function to handle edit user
     * */
    editClick() {
        this.isDetailView = false;
        // if (this.userToView.dob) {
        //     let date: Date = new Date(this.userToView.dob);
        //     this.userToView.dob = new Date(date.getFullYear(),
        //         date.getMonth(),
        //         date.getDate());
        // } else {
        //     let date: Date = new Date();
        //     this.userToView.dob = new Date(
        //         date.getFullYear() - 18,
        //         date.getMonth(),
        //         date.getDate()
        //     );
        // }
        if (this.userToView.roleType != null) {
            this.roleCheck = false;
        }
        if (this.userToView.userAccessList && this.userToView.roleType == "SUB_ADMIN") {
            this.accessCheck = false;
        }
        //this.userToView.role = {id:this.userToView.roleType}
        //this.userToView.gender = {id:this.userToView.gender}
    }
    /**
     * Function to handle create user
     * */
    createClick() {
        this.isDetailView = false;
        this.isCreate = true;
        // if(!this.isFirstCreateClick){
        //  this.isFirstCreateClick = true
        // }else {
        //     this.isFirstCreateClick = false
        // }
        this.userToView = {
        };
    }
    /**
     * Function to handle edit user
     * */
    cancelClick() {
        if (this.isCreate) {
            this.isDetailView = true;
            this.isCreate = false;
            this.userToView = _.cloneDeep(this.userList[0]);
            this.processUser(this.userToView);
        } else {
            this.isDetailView = true;
            this.isCreate = false;
            this.userToView = _.cloneDeep(this.userList.find(x => x.id === this.userToView.id));
            console.log("user refresh", this.userToView);

            this.processUser(this.userToView);
        }
    }
    /**
     * Function to single select user
     * */
    selectUser(checkValue, user) {
        if (checkValue) {
            if (!user.isBlocked && user != this.logedInUser.id) {
                this.selectedUser.push(user.id);
            }
        } else {
            for (let i = 0; i < this.selectedUser.length; i++) {
                if (this.selectedUser[i] == user.id) {
                    this.selectedUser.splice(i, 1);
                    this.allUserChecked = false;
                }
            }
        }
        // this.selectedRows = [];
        console.log(
            " this.selectedUser",
            this.selectedUser,
            checkValue,
            this.userList
        );
    }
    /**
     * Function to select all user
     * */
    selectAllUser(checkValue) {
        if (checkValue) {
            //this.selectedUser = _.cloneDeep( this.userList);
            this.allUserChecked = true;
            for (let i = 0; i < this.userList.length; i++) {
                if (
                    !this.userList[i].isBlocked &&
                    this.userList[i].id != this.logedInUser.id
                ) {
                    this.userList[i].checked = true;
                    this.selectedUser.push(this.userList[i].id);
                }
            }
        } else {
            this.selectedUser = [];
            this.allUserChecked = false;
            for (let i = 0; i < this.userList.length; i++) {
                this.userList[i].checked = false;
            }
        }
        console.log(
            " this.selectedUser",
            this.selectedUser,
            checkValue,
            this.userList
        );
    }
    /**
     * Function to pagination user list
     * */
    // paginate(event) {
    //   //event.first = Index of the first record
    //   //event.rows = Number of rows to display in new page
    //   //event.page = Index of the new page
    //   //event.pageCount = Total number of pages
    // }
    /************ confirm modal function - added by 1206 *****/
    /**
     * Function to Block user confirmation popup
     * */
    blockConfimationPopup() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to block this user(s)?',
            header: 'Block User(s)',
            accept: () => {
                this.blockUnblockUser(true);
                this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'Blocked User(s) Successfully.' }];

            },
            reject: () => {
                this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'Cancelled Block user(s)' }];
            }
        });
    }
    blockMultiUserConfimationPopup() {
        this.confirmationService.confirm({
            message: 'Are you sure you want to block this user(s)?',
            header: 'Block User(s)',
            accept: () => {
                this.blockMultipleUser();
                this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'Blocked User(s) Successfully.' }];

            },
            reject: () => {
                this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'Cancelled Block user(s)' }];
            }
        });
    }
    // confirmationFunctionCall() {
    //     this.confirmPopup.deleteCommonConfimationPopup();
    // }

    public backFromCreate(data) {
        this.cancelClick()
        this.getUserList()

    }
    /**
     * Function Coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo("Coming soon");
        // this.toaster.open( { text: "Coming soon", type: 'info', position: 'top-center' } )
    }

    /* toggle between hiding and showing the dropdown content */
    openFilterDropdown() {
        this.isCreate = false;
        this.isDetailView = true
        this.userToView = _.cloneDeep(this.userList[0]);
        document.getElementById("myDropdown").classList.toggle("show");
        //$('[data-toggle="dropdown"]').dropdown();
        this.show = !this.show;
    }
}
