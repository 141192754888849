/**
 * Eviction Pipeline Functionality
 * Created By : #1116
 * Date : 2 Feb, 2021
 */

import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { LazyLoadEvent } from 'primeng/api';
import { Router } from '@angular/router';

import { AppService } from './../../../app/app.service';

import { PipelineFilterComponent } from '../pipeline-filter/pipeline-filter.component';
import { EvictionPipelineDetailsComponent } from '../eviction-pipeline-details/eviction-pipeline-details.component';
import { ExportPopupComponent } from 'src/pages/export-popup/export-popup.component';

import {
    HeaderService,
    SidebarService,
    LocalStorageService,
    CommonService,
    Constants,
    EvictionAdminService
} from "src/providers/providers";
import * as moment from 'moment';
import 'moment-timezone';


@Component( {
    selector: 'app-eviction-pipeline',
    templateUrl: './eviction-pipeline.component.html',
    styleUrls: ['./eviction-pipeline.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class EvictionPipelineComponent implements OnInit {
    @Input()
    loading1: boolean = true;
    @ViewChild( 'p', { static: false } ) paginator: Paginator;

    public userData: any;

    public pipelinePayload: any = {
        "coppDateFrom": "",
        "coppDateTO": "",
        "costFrom": 0,
        "costTo": 0,
        "daysOngoingFrom": 0,
        "daysOngoingTo": 0,
        "evictionFiledFrom": "",
        "evictionFiledTo": "",
        "evictionStatus": "",
        "evictionStayDateFrom": "",
        "evictionStayDateTo": "",
        "evictionType": "",
        "initialCourtDateFrom": "",
        "initialCourtDateTo": "",
        "otherCourtDateFrom": "",
        "otherCourtDateTo": "",
        "possessionOfUnitFrom": "",
        "possessionOfUnitTo": "",
        "searchText": "",
        "secondCourtDateFrom": "",
        "secondCourtDateTo": "",
        "sheriffOrderedFrom": "",
        "sheriffOrderedTo": "",
        "sortBy": "",
        "sortManner": "",
        "pageNumber": 0,
        "pageSize": 25
    };

    public pipelineList: any = [];
    isRenewalsNoData: boolean = false;
    pipelineDefaultPage = 0;
    pipelineDefaultSize = 25;

    filterData: any = {};
    public isSearchCall: boolean = false;
    public isPipelineList: boolean = true;

    constructor( public header: HeaderService,
        protected commonService: CommonService,
        public sidebar: SidebarService, private appService: AppService,
        private router: Router,
        public localStr: LocalStorageService, public constants: Constants, private evictionAdminService: EvictionAdminService ) {
        this.pipelinePayload.pageSize = this.constants.defaultPageSize;
    }

    ngOnInit(): void {
        this.sidebar.show();
        this.header.show();

        this.localStr.setObj("breadcrum", "");
        this.appService.setTitle( 'Eviction Pipeline' );
        this.userData = this.localStr.getObj( 'userData' );

        this.getEvictionPipelineList( null );
    }

    /**
      * Function for open filter pop-up and get updated results
      */
    public clickOnFilter = ( $event ) => {
        this.filterData = this.pipelinePayload;
        this.filterData.isPipelineList = this.isPipelineList;

        this.commonService.openConfirmModal( PipelineFilterComponent, this.filterData, ( data ) => {

            this.pipelinePayload.typeOfEviction = this.checkTypeOf( this.pipelinePayload.typeOfEviction );
            this.pipelinePayload.evictionStatus = this.checkTypeOf( this.pipelinePayload.evictionStatus );

            if ( data ) {
                if ( this.isPipelineList ) {
                    this.pipelinePayload.evictionStatus = data.evictionStatus;
                    this.pipelinePayload.evictionType = data.typeOfEviction;
                    this.pipelinePayload.coppDateFrom = data.coppDateFrom;
                    this.pipelinePayload.coppDateTO = data.coppDateTO;
                    this.pipelinePayload.costFrom = data.costFrom;
                    this.pipelinePayload.costTo = data.costTo;
                    this.pipelinePayload.daysOngoingFrom = data.daysOngoingFrom;
                    this.pipelinePayload.daysOngoingTo = data.daysOngoingTo;
                    this.pipelinePayload.evictionFiledFrom = data.evictionFiledFrom;
                    this.pipelinePayload.evictionFiledTo = data.evictionFiledTo;
                    this.pipelinePayload.evictionStayDateFrom = data.evictionStayDateFrom;
                    this.pipelinePayload.evictionStayDateTo = data.evictionStayDateTo;
                    this.pipelinePayload.initialCourtDateFrom = data.initialCourtDateFrom;
                    this.pipelinePayload.otherCourtDateFrom = data.otherCourtDateFrom;
                    this.pipelinePayload.otherCourtDateTo = data.otherCourtDateTo;
                    this.pipelinePayload.possessionOfUnitFrom = data.possessionOfUnitFrom;
                    this.pipelinePayload.searchText = data.searchText;
                    this.pipelinePayload.secondCourtDateFrom = data.secondCourtDateFrom;
                    this.pipelinePayload.secondCourtDateTo = data.secondCourtDateTo;
                    this.pipelinePayload.sheriffOrderedFrom = data.sheriffOrderedFrom;
                    this.pipelinePayload.sheriffOrderedTo = data.sheriffOrderedTo;

                    this.paginator.changePageToFirst( $event );
                    this.getEvictionPipelineList( null );
                }
            }

        } );
    }

    /**
      * Function to open Eviction pipeline details
      */
    public openEvictionDetails = ( evDetails ) => {
        console.log("evDetails >>>",evDetails);
        
        let requestPayload;
        this.commonService.openConfirmModal( EvictionPipelineDetailsComponent, this.commonService.copy( evDetails ), ( data ) => {
            console.log( "openEvictionDetails :: data :: ", data );
            if ( data ) {
                requestPayload = {
                    "address": data.address ? data.address : "",
                    "coppDate": data.coppDate ? data.coppDate : "",
                    "cost": data.cost ? data.cost : "",
                    "entityId": "",
                    "entityType": "",
                    "evictionFiled": data.evictionFiled ? data.evictionFiled : "",
                    "evictionStatus": data.evictionStatus ? data.evictionStatus : "",
                    "evictionStayDate": data.evictionStayDate ? data.evictionStayDate : "",
                    "id": data.id ? data.id : "",
                    "initialCourtDate": data.initialCourtDate ? data.initialCourtDate : "",
                    "moneyJudgment": data.moneyJudgment ? data.moneyJudgment : "",
                    "name": data.name ? data.name : "",
                    "otherCourtDate": data.otherCourtDate ? data.otherCourtDate : "",
                    "paymentPlanDetails": data.paymentPlanDetails ? data.paymentPlanDetails : "",
                    // "pmId": data.propertyManagerName ? data.propertyManagerName: "",
                    "possessionOfUnit": data.possessionOfUnit ? data.possessionOfUnit : "",
                    "secondCourtDate": data.secondCourtDate ? data.secondCourtDate : "",
                    "sheriffOrdered": data.sheriffOrdered ? data.sheriffOrdered : "",
                    "typeOfEviction": data.typeOfEviction ? data.typeOfEviction : "",
                    "unit": data.unit ? data.unit : ""
                }
                console.log("requestPayload >>>>", requestPayload);
                
                this.updateEvictionPipelineData( requestPayload );
            }
        } );
    }

    /**
      * Function to get Eviction Pipeline list
      *
      */
    public getEvictionPipelineList( $event ) {

        if ( this.pipelinePayload.sortBy == "" || this.pipelinePayload.sortManner == "" ) {
            this.pipelinePayload.sortBy = "";
            this.pipelinePayload.sortManner = "";
        }
        
        // check data after visiting activity log
        if(this.localStr.getObj("beforeActivityData")){
            this.pipelinePayload = this.localStr.getObj("beforeActivityData");
        }

        if ( !this.isSearchCall ) {
            this.commonService.showLoading();  // remove this when we use inplace loader
        } else if ( this.isSearchCall ) {
            this.pipelinePayload.pageNumber = 0;
        }

        this.commonService.showLoading(); // remove this when we use inplace loader 
        this.loading1 = true;
        this.evictionAdminService.getEvictionPipelineList( this.pipelinePayload ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();

                if ( !this.isSearchCall ) {
                    this.commonService.hideLoading();
                } else if ( this.isSearchCall ) {
                    this.paginator.changePageToFirst( $event );
                }
                this.isSearchCall = false;

                if ( res.code == 200 || res.status == 200 ) {
                    this.pipelineList = res.data.list;

                    this.pipelinePayload.totalRecords = res.data.totalCount;
                    this.localStr.setObj("beforeActivityData", "");
                } else {
                    this.pipelineList = [];
                }
                this.loading1 = false;
            },
            ( error ) => {
                this.commonService.hideLoading();

                if ( !this.isSearchCall ) {
                    this.commonService.hideLoading();
                }
                this.isSearchCall = false;

                this.pipelineList = [];
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
                this.loading1 = false;
            }
        );
        this.commonService.hideLoading();
    }

    /**
     * Function for eviction pipeline list pagination
     */
    public pipelinepaginate = ( event ) => {
        this.pipelineDefaultPage = event.first / event.rows;
        this.pipelineDefaultSize = event.rows;
        this.pipelineList = [];
        this.pipelinePayload.pageSize = this.constants.defaultPageSize;
        this.pipelinePayload.pageNumber = this.pipelineDefaultPage;
        this.getEvictionPipelineList( null );
    }

    /**
     * Function to sort eviction pipeline list 
     */
    customSort( data: LazyLoadEvent ) {

        switch ( data.sortField ) {
            case "name":
                this.pipelinePayload.sortBy = "NAME";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            case "unit":
                this.pipelinePayload.sortBy = "UNIT";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            case "evictionFiled":
                this.pipelinePayload.sortBy = "EVICTIONFILED";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            case "initialCourtDate":
                this.pipelinePayload.sortBy = "INITIALCOURTDATE";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            case "secondCourtDate":
                this.pipelinePayload.sortBy = "SECONDCOURTDATE";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            case "otherCourtDate":
                this.pipelinePayload.sortBy = "OTHERCOURTDATE";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            case "evictionStayDate":
                this.pipelinePayload.sortBy = "EVICTIONSTAYDATE";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            case "sheriffOrdered":
                this.pipelinePayload.sortBy = "SHERIFFORDERED";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            case "moneyJudgement":
                this.pipelinePayload.sortBy = "MONEYJUDGMENT";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            case "coppDate":
                this.pipelinePayload.sortBy = "COPPDATE";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            case "cost":
                this.pipelinePayload.sortBy = "COST";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
            default:
                this.pipelinePayload.sortBy = "";
                switch ( data.sortOrder ) {
                    case 1:
                        this.pipelinePayload.sortManner = "DESC";
                        break;
                    case -1:
                        this.pipelinePayload.sortManner = "ASC";
                        break;
                    default:
                        this.pipelinePayload.sortManner = "";
                        break;

                }
                break;
        }

        this.getEvictionPipelineList( null );
    }

    checkTypeOf = ( dataCheck ) => {
        if ( typeof dataCheck == 'object' ) {
            return dataCheck = "";
        } else {
            return dataCheck;
        }
    }

    /**
     * Function to update Eviction Pipeline data
     */
    public updateEvictionPipelineData = ( requestPayLoad ) => {
        console.log( "updateEvictionPipelineData  :: requestPayLoad :: ", requestPayLoad );
        this.commonService.showLoading();

        requestPayLoad.evictionStatus = this.checkTypeOf( requestPayLoad.evictionStatus );

        if ( requestPayLoad ) {

            this.evictionAdminService.updatePipelineData( requestPayLoad ).subscribe(
                ( res ) => {
                    this.commonService.hideLoading();
                    if ( res.code == 200 || res.status == 200 ) {
                        this.commonService.clearToast();
                        this.commonService.showSuccess( "Record updated successfully." );
                        this.getEvictionPipelineList( null );
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError( res.message );
                    }
                    this.commonService.hideLoading();
                    console.log( "updateEvictionPipelineData :: res :: ", res );
                },
                ( error ) => {
                    this.commonService.hideLoading();
                    if ( error && error.message ) {
                        this.commonService.clearToast();
                        this.commonService.showError( error.message );
                    } else if ( error && error.error_description ) {
                        this.commonService.clearToast();
                        this.commonService.showError( error.error_description );
                    }
                }
            );
        }
        this.commonService.hideLoading();
    }

    /** 
     * Function to get Ongoing Days 
     */
    getOngoingDays( dateSent ) {
        let currentDate = new Date();

        if ( dateSent ) {
            dateSent = new Date( dateSent );
         return Math.floor(( Date.UTC( currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() ) - Date.UTC( dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate() ) ) / ( 1000 * 60 * 60 * 24 ) );
        }
    }

    /** 
     * Function to open Tenant Details 
     */
    public openTenantDetails = ( tenantProfile ) => {
        console.log("tenentProfile >>>> ", tenantProfile);
        console.log("pipelinePayload ....", this.pipelinePayload);
        this.localStr.setObj("beforeActivityData",this.pipelinePayload);

        this.localStr.setObj("breadcrum", 'Eviction Pipeline');

        if(tenantProfile){
            this.router.navigate( ['/resident-profile'], tenantProfile );
        } else {
            tenantProfile = "no_data"
            this.router.navigate( ['/resident-profile'], tenantProfile );
        }
    }

    /**
     *  Function for Export dialog 
     */
    openExportPopup() {
        this.commonService.openConfirmModal( ExportPopupComponent, this.filterData, ( data ) => {
            if ( data.format ) {
                this.exportPipeline( data.format )
            }
        } );
    }

    /**
      * Function to eviction pipeline data export
      */
    public exportPipeline( reportFormat ) {
        this.commonService.showLoading();

        // var d = new Date();
        // var s = d.toString();
        // var timeZone = s.match(".*(\\((.*)\\))")[2];
        // var getTimeZone = /\((.*)\)/.exec(new Date().toString())[1]
        // console.log("getTimeZone....", getTimeZone);

        var timeZone = moment.tz.guess();
        var time = new Date();
        var timeZoneOffset = time.getTimezoneOffset();
        var getTimeZone = moment.tz.zone(timeZone).abbr(timeZoneOffset);

        console.log("getTimeZone....", getTimeZone);        

        this.evictionAdminService.exportPipelineData( this.pipelinePayload, reportFormat, this.userData.id, getTimeZone ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "res", res );

                    // window.open( res.data );
                    window.location.assign( res.data );
                }

            },
            ( error ) => {
                this.commonService.hideLoading();

                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

}
