/**
 * Constants module
 * Contains constants and strings
 */

import { Injectable } from '@angular/core';

@Injectable()
export class Constants {
    
    ERROR_NETWORK_UNAVAILABLE: string;
    ERROR_MSG_UNABLE_TO_CONNECT: string;
    UNABLE_TO_CONNECT_SERVER_MSG: string;
    public WEB_SERVICE_RESPONSE: any;
    public defaultPageSize: 25;
    public pageSize: 10;

    static CURRENT_ENVIRONMENT: string = 'uat';
    
    constructor() { 
        this.defaultPageSize = 25;
        this.pageSize = 10;    
        this.ERROR_NETWORK_UNAVAILABLE = 'Please check internet connection and try again.';
        this.ERROR_MSG_UNABLE_TO_CONNECT = 'Unable to connect with server. Please try again.';
        this.UNABLE_TO_CONNECT_SERVER_MSG = "Unable to connect to server, please try again.";
        
        this.WEB_SERVICE_RESPONSE = {
            EXPIRED_JWT: 'Expired JWT',
            HTTP_PARSE_FAIL: 'Http failure during parsing for'
         };
    }
}