/**
 * email-verification functionality
 * Created By : #1223
 * Date : 25 November, 2020
 */
/***************************** Angular component *****************************/
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService, CommonService } from 'src/providers/providers';
import { Router } from '@angular/router';
// import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['../auth.scss']
})

export class EmailVerificationComponent implements OnInit, OnDestroy {
  verifyAllData = {
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  }
  verifyData = {
    otp: '',
    transactionId: ''
  }
  otpData = {
    transactionId: '',
    email: ''
  };
  formSubmitted: boolean;
  constructor(private authService: AuthService, private router: Router, private commonService: CommonService) {
    var extras = this.router.getCurrentNavigation().extras;
    this.otpData.transactionId = extras && extras.state && extras.state.transactionId ? extras.state.transactionId : "";
    this.verifyData.transactionId = extras && extras.state && extras.state.transactionId ? extras.state.transactionId : "";
    this.otpData.email = extras && extras.state && extras.state.email ? extras.state.email : "";
  }

  /**
   * Function on page load
   * 
  */
  ngOnInit() {
    this.formSubmitted = false;
  }

  keytab(event, seq) {
    let element = event.srcElement.nextElementSibling; // get the sibling element
    console.log(event);
    this.verifyData.otp = this.verifyAllData.otp1 + this.verifyAllData.otp2 + this.verifyAllData.otp3 + this.verifyAllData.otp4 + this.verifyAllData.otp5 + this.verifyAllData.otp6;

    var key = event.keyCode || event.charCode
    if (key == 8 || key == 46) {
      var forms = document.forms || [];
      if (seq > 1) {
        document.getElementById("otp" + (seq - 1)).focus();
      }

    } else if (element == null)  // check if its null
      return;
    else
      element.focus();   // focus if not null
  }

  /**
   * Function on email verification
   * 
  */
  emailVerification = (form: NgForm) => {
    this.formSubmitted = true;
    if (form.valid) {
      console.log("this.verifyData.otp..");
      this.commonService.showLoading();
      this.formSubmitted = false;
      this.authService.verifyOTP(this.verifyData).subscribe(
        res => {
          if (res) {
            this.commonService.hideLoading();
            console.log("EmailVerificationComponent :: emailVerification :: res :: ", res);
            var stateData = {
              state: this.otpData
            }
            this.router.navigate(['/reset-password'], stateData);
          }
        },
        error => {
          this.commonService.hideLoading();
          console.log("EmailVerificationComponent :: emailVerification :: error :: ", error);
          if (error.message) {
            this.commonService.clearToast();
            this.commonService.showError(error.message);
          } else {
            this.commonService.clearToast();
            this.commonService.showError(error.error_description);
          }
        });
    }
  }

  /**
   * Function to redirect for resend otp
   * @param: form
   */
  resendOtp = () => {
    this.formSubmitted = false;
    this.commonService.showLoading();
    this.authService.resendVerficationCode(this.otpData).subscribe(
      res => {
        console.log("response.......", res);
        if (res.code == 200) {
          this.commonService.hideLoading();
          console.log("EmailVerificationComponent :: resendOtp :: res :: ", res);
          this.commonService.clearToast();
          this.commonService.showSuccess("Code resent.");
          // this.toaster.open({ text: "Code resent.", type: 'success', position: 'top-center' });
        }
      },
      error => {
        this.commonService.hideLoading();
        console.log("EmailVerificationComponent :: resendOtp :: error :: ", error);
        if (error.message) {
          this.commonService.clearToast();
          this.commonService.showError(error.message);
        } else {
          this.commonService.clearToast();
          this.commonService.showError(error.error_description);
        }
      }
    );
  }

  /**
   * Remove conditional class only for login page
   * */
  ngOnDestroy() {
  }
}
