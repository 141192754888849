import { Component, OnInit, ViewChild, HostListener, Input, } from "@angular/core";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { AppService } from './../../app/app.service';

import {
    CommonService,
    HeaderService,
    SidebarService,
    LocalStorageService,
    AuthService,
    PropertyManagerService
} from "src/providers/providers";
import { UserDTO } from "src/models/user-dto";
import * as _ from "lodash";

@Component( {
    selector: "app-edit-profile",
    templateUrl: "./edit-profile.component.html",
    styleUrls: ["./edit-profile.component.scss"],
} )
export class EditProfileComponent implements OnInit {

    @ViewChild("profileForm")
    profileForm:NgForm;
    @Input() isCreate: boolean ;

    public minyear = 1950;
    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = ( this.currentYear - 18 );
    range: string = ( this.minyear ).toString() + ":" + ( this.maxyear ).toString();

    public userData: any;
	public userDob: any;					
    public genderList: any;
    public roleList: any;
    public userName: any;
    public logedInUser: any;
    public accessCheck: boolean = false;
    public roleCheck: boolean = false;
    public accessList: any;
    formSubmitted: boolean;
    public pmNameItemList: any = [];
    public breadcrum: any;

    public maxDateValue: Date = new Date(
        new Date().getFullYear() - 18,
        new Date().getMonth(),
        new Date().getDate());

    @Input() userToView: UserDTO = {};

    constructor(
        public header: HeaderService,
        public sidebar: SidebarService,
        protected commonService: CommonService,
        private appService: AppService,
        public localStr: LocalStorageService,
        public router: Router,
        public authService : AuthService,
        public propertyManagerService: PropertyManagerService
    ) { }

    ngOnInit(): void {

        this.userToView = this.localStr.getObj( "userData" );
        // this.userDob  = this.userToView.dob;
        // if(this.userDob.length > 24){
        //     this.userToView.dob = new Date( this.commonService.formatDate(this.userToView.dob));
        // }else{
        //     this.userToView.dob = new Date(this.userToView.dob);
        // }
		
        this.genderList = [
            { label: "Select Gender (Optional)", value: null },
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
            { label: "Other", value: "Other" },
        ];
        this.roleList = [
            { label: "Select Role", value: null },
            { label: "Sub Admin", value: "SUB_ADMIN" },
            { label: "Property Manager", value: "PROPERTY_MANAGER" },
            { label: "Eviction Admin", value: "EVICTION_ADMIN" },
        ];
        this.getPMList();
        this.accessList = [
            { label: "User Management", value: "USER_MANAGEMENT", disabled: true },
            { label: "Activity", value: "ACTIVITY", disabled: true },
            { label: "Reports", value: "REPORTS", disabled: true },
            { label: "Goals", value: "GOALS", disabled: true },
            { label: "Stats", value: "STATS", disabled: true }
        ];

        this.sidebar.show();
        this.header.show();

        this.breadcrum = this.localStr.getObj ("breadcrum");
        this.appService.setPreTitle( this.breadcrum );
        this.appService.setTitle( 'Edit Profile' );
        this.userData = this.localStr.getObj( 'userData' );
    }


    /**
     * Function coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo( "Coming soon" );
    }

    /**
       * Function to get PM list
       */
      private getPMList = () => {
        this.pmNameItemList = [];
        this.propertyManagerService.getPMEANameIdList(true).subscribe(
          (res) => {
            if (res.code == 200 || res.status == 200) {
              var pmEaNameIdList = res.data;
              for (let i = 0; i <= pmEaNameIdList.length; i++) {
                if(pmEaNameIdList[i]){
                  this.pmNameItemList.push({ label: pmEaNameIdList[i].name, value: pmEaNameIdList[i].id });
                }
            }
              console.log("pmEaNameItemList >>> ::::::: ", this.pmNameItemList);
              this.pmNameItemList = _.cloneDeep(this.pmNameItemList);
            }        
            this.commonService.hideLoading();
          },
          (error) => {
            this.pmNameItemList = [];
            if (error.message) {
              this.commonService.clearToast();
              this.commonService.showError(error.message);
            } else {
              this.commonService.clearToast();
              this.commonService.showError(error.error_description);
            }
            this.commonService.hideLoading();
          }
        );
      }


    /**
     * Function to Edit Profile
    */
   public cancelClick = () => {
        this.router.navigate(['/my-profile']);
    }

    public getSelectedGender( eve ) {
        console.log( "eb", eve, this.userToView.gender );
    }

    public getSelectedAccess( eve ) {
        console.log( "eb", eve, this.userToView.userAccessList );
        if ( this.userToView.userAccessList ) {
            this.accessCheck = false;
        }
    }

    /**
       * Function to select team member
       */
      public getSelectedTeamMember( eve ) {
        console.log( "eb", eve, this.userToView.teamMemberList );
    }

    public getSelectedRole( eve ) {
        console.log( "eb", eve, this.userToView.roleType );
        if ( this.userToView.roleType != null ) {
            this.roleCheck = false;
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
        // Your logic on beforeunload
        let titleText = "Rentdex";
            let messageText = "Are you sure you want to leave?";
            let yesText = "YES";
            let noText = "NO";
    }


    /**
     * Remove conditional class only for login page
     * */
    ngOnDestroy() { 
        if(!this.profileForm.submitted && this.profileForm.dirty){
        let titleText = "Rentdex";
        let messageText = "Are you sure you want to leave?";
        let yesText = "YES";
        let noText = "NO";
         }
   
    }

   /**
     * Function to Edit Profile
   */
   public submitProfile = (form: NgForm ) => {
        this.formSubmitted = true;
        console.log( "form", form.valid );
        if ( form.valid ) {
            this.commonService.showLoading();
            console.log( "valid", this.userToView );

        this.authService.updateMyProfile( this.userToView ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "create user :: res :: ", res );
                    this.localStr.setObj( "userData", this.userToView );
                    console.log( "after valid form data", this.userToView );
                    this.cancelClick();
                    this.commonService.clearToast();
                    this.commonService.showSuccess( res.message);
                    
                }
            },
            ( error ) => {
                this.commonService.hideLoading();
                console.log( "create user: ", error );
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.message);
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError(error.error_description);
                }
            }
        );

        }
   }


}
