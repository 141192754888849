<!-- Container -->
<div class="form-container filter-form-cls">

    <!-- Heading -->
    <div class="row filter-heading m-0">
        <div class="col-12">
            <h2 class="form-title popup-title"> {{ "PM_DASHBOARD.TODAYS_DELINQUENT_DETAILS" | translate }} </h2>
        </div>
    </div>

    <div class="modal-body">
        <form class="form-inline crud-form-cls view-form">
            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.ADDRESS" | translate
                            }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.propertyAddress }}</p>
                            <!-- <input type="text" class="form-control" readonly 
                              [(ngModel)]="formData.propertyAddress" [ngModelOptions]="{standalone: true}"/>  -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.NAME" | translate }}:
                        </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.name }}</p>
                            <!-- <input type="text" class="form-control" readonly 
                              [(ngModel)]="formData.name" [ngModelOptions]="{standalone: true}"/> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row form-row-cls">
                  <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                      <div class="form-group row">
                          <label for="inputFirstname" class="col-4 control-label">Unit: </label>
                          <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.unit }}</p>
                            //<input type="text" class="form-control" readonly 
                            //[(ngModel)]="formData.unit" [ngModelOptions]="{standalone: true}"/>
                          </div>
                      </div>
                  </div>
                </div> -->
            <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType != 'EVICTION_ADMIN'">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.PM_STATUS" | translate
                            }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-dropdown class="form-dropdown" [options]="pmStatusList" [(ngModel)]="formData.pmStatus" [disabled]="isCheckPMStatus" name="pmstatus" #pmStatus="ngModel"></p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType != 'EVICTION_ADMIN'">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "ACTIVITY.PROMISE_TO_PAY" | translate
                            }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-calendar id="promiseToPay" [touchUI]="true" name="promiseToPay" placeholder="{{ 'ACTIVITY.PROMISE_TO_PAY' | translate}}" class="form-dropdown custom-calendar-ui evi-calendar" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true"
                                [minDate]="setMinDate" #promiseToPay="ngModel" [(ngModel)]="formData.promiseToPay" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range"></p-calendar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType != 'EVICTION_ADMIN'">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.EVICTION_STATUS" |
                            translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.evictionStatus | replace : '_' : ' ' | titlecase }}</p>
                            <!-- <p class="form-text">{{ formData.evictionStatus }}</p>
                            <input type="text" class="form-control" readonly 
                              [(ngModel)]="formData.evictionStatus" [ngModelOptions]="{standalone: true}"/> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType == 'EVICTION_ADMIN'">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.PM_STATUS" | translate
                            }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.pmStatus | replace : '_' : ' ' | titlecase }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType == 'EVICTION_ADMIN'">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.EVICTION_STATUS" |
                            translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-dropdown class="form-dropdown" [options]="evictionStatusList" [(ngModel)]="formData.evictionStatus" name="evictionStatus" #evictionStatus="ngModel">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls" *ngIf="!isMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.TOTAL_BALANCE" |
                            translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">
                                <span class="inline-value" *ngIf="formData.amountReceivable">$</span> {{ formData.amountReceivable }}
                            </p>
                            <!-- <span class="inline-value" *ngIf="formData.amountReceivable">$</span>
                              <input type="text" class="form-control" readonly 
                              [(ngModel)]="formData.amountReceivable" [ngModelOptions]="{standalone: true}"/> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls" *ngIf="!isMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.TENANT_BALANCE" |
                            translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">
                                <span class="inline-value" *ngIf="formData.tenantBalance">$</span> {{ formData.tenantBalance }}
                            </p>
                            <!-- <span class="inline-value" *ngIf="formData.tenantBalance">$</span>
                            <input type="text" class="form-control" readonly 
                            [(ngModel)]="formData.tenantBalance" [ngModelOptions]="{standalone: true}"/> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls" *ngIf="!isMobile && formData.isDeliquenceList">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label">Subsidy Balance: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">
                                <span class="inline-value">$</span> {{ formData.delinquentSubsidyAmount }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType != 'EVICTION_ADMIN'">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.ACTION_ITEM" |
                            translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-dropdown class="form-dropdown" [options]="actionItemList" [(ngModel)]="formData.lastActionTaken" name="selectedFrom1" #lastActionTaken="ngModel">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType == 'EVICTION_ADMIN'">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.ACTION_ITEM" |
                            translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.lastActionTaken | replace : '_' : ' ' | titlecase }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.MOST_RECENT_NOTES" |
                            translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <textarea rows="4" cols="30" maxlength="255" [(ngModel)]="formData.delinquencyNotes" [ngModelOptions]="{standalone: true}">
                              </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="clearfix"></div>
    </div>

    <!--------------- modal btns  --------->
    <div class="row d-flex modal-footer m-0">
        <button type="button" class="btn link-btn" (click)="cancelClick()"> {{ "COMMON_COMPONENT.CANCEL_BUTTON" |
            translate }} </button>
        <button type="submit" class="btn common-btn" (click)="applyFilter()"> {{ "COMMON_COMPONENT.UPDATE" | translate
            }} </button>
    </div>
</div>