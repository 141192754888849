<!-- Container -->
<div class="form-container filter-form-cls">

    <div class="">

        <!-- Heading -->
        <div class="row filter-heading m-0">

            <div class="col-6">
                <h2 class="form-title"> {{"TREND.MANAGE_PROPERTY_GROUP" | translate }} </h2>
            </div>

            <!-- <div class="col-6">
                <button class="btn link-btn clear-btn" (click)="clearFilter()"> {{"COMMON_COMPONENT.CLEAR_ALL" | translate }} </button>
            </div> -->
        </div>

        <div class="container custom-ver-tab">

            <div class="row-set form-row-cls">
                <div class="form-group row-set set-mar">
                    <label for="inputFirstname" class="col-5 control-label txt-rgt"> {{ "TREND.PROPERTY_GROUP" | translate }}: </label>
                    <div class="col-sm-7 pl-0">
                        <p-dropdown class="form-dropdown" name="access" [defaultLabel]="'Select groups'" [options]="groupList" #propertyGroupName="ngModel" [(ngModel)]="propertyGroupData.propertyGroupName" (onChange)="getSelectedGroup($event)">
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <!-- Tab panes -->
            <!-- <div class="tab-content">
                <div class="col-sm-6 col-12 d-flex form-gr">
                    <div class="form-group set-mar">
                        <p-multiSelect class="form-dropdown form-multiselect" [defaultLabel]="'Select groups'" [options]="groupList" #propertyGroupName="ngModel" [(ngModel)]="propertyGroupData.propertyGroupName" (onChange)="getSelectedGroup($event)" name="access">
                        </p-multiSelect>
                        <p-dropdown class="form-dropdown" [defaultLabel]="'Select groups'" [options]="groupList" #propertyGroupName="ngModel" [(ngModel)]="propertyGroupData.propertyGroupName" (onChange)="getSelectedGroup($event)" name="access">
                        </p-dropdown>

                    </div>
                </div>
            </div> -->

            <!-- </div> -->
            <div class="clearfix"></div>

        </div>

        <!--------------- modal btns  --------->
        <div class="row d-flex modal-footer m-0">
            <button type="button" class="btn link-btn" (click)="cancelClick()"> {{"COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
            <button type="submit" class="btn common-btn" (click)="applyFilter()"> {{"COMMON_COMPONENT.APPLY_BUTTON" | translate }} </button>
        </div>

    </div>
</div>