import { Component, OnInit } from '@angular/core';
import { LoginDTO } from 'src/models/login-dto';
import { AppSettings } from 'src/providers/providers';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component( {
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
} )
export class FooterComponent implements OnInit {

    public currentYear;
    public version;
    public isMobile :boolean = false;
    deviceInfo = null;

    constructor(
        private deviceService: DeviceDetectorService) {        
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
     }

    ngOnInit(): void {
        let d = new Date();
        let n = d.getFullYear();
        this.currentYear = n; //Set current year for copyright statement  
        this.version = AppSettings.buildVersion ;        
    }

}
