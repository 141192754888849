/**
 * user create functionality
 * Created By : #1224
 * Date : 17 December 2020
 */
/***************************** Angular component *****************************/
import { Component, OnInit, OnDestroy, ViewChild, HostListener,ElementRef, Input, EventEmitter, Output } from "@angular/core";
import { NgForm } from "@angular/forms";

import { Router } from "@angular/router";
import { UserDTO } from "src/models/user-dto";
import { ManageUserService } from "src/providers/manage-user-service/manage-user.service";
import { ConfirmationService, AuthService, PropertyManagerService } from "src/providers/providers";
import { Message } from 'primeng/api';
import {
    CommonService,
    HeaderService,
    LocalStorageService,
    SidebarService,
} from "src/providers/providers";
import * as _ from "lodash";
import { Observable } from 'rxjs';

@Component( {
    selector: "create-user",
    templateUrl: "./create-user.component.html",
    styleUrls: ["./create-user.component.scss"],
    providers: [ConfirmationService]
} )
export class CreateUserComponent implements OnInit, OnDestroy {
    public isDirty: boolean = false;
    public isUpdate: boolean = false;
    public updateId = "";
    @ViewChild("userForm")
    userForm:NgForm
    first = 0;
    rows = 10;
    isNoData: boolean = false;
    public access :any=[]
    public minyear = 1950;
    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = ( this.currentYear - 18 );
    range: string = ( this.minyear ).toString() + ":" + ( this.maxyear ).toString();

    show: boolean = false;
    msgs: Message[] = [];
    // public userList: any = [];
    // public isAllUser: boolean = true;
    // public isPropertyManager: boolean = false;
    // public isEvictionAdmin: boolean = false;
    // public isSubAdmin: boolean = false;
    //UserDTO;
    public genderList: any;
    public accessList: any;
    public roleList: any;
    // public selectedUser: any = [];
    // public allUserChecked: boolean = false;
    // public param: any = {
    //     pageNumber: 0,
    //     pageSize: 10,
    //     totalRecords: 0,
    // };

@Input() userData: any = {
        isBlocked: null,
        roleType: "",
        searchText: ""
    }
    public checkValueData: boolean;
    public password = "";

    public maxDateValue: Date = new Date(
        new Date().getFullYear() - 18,
        new Date().getMonth(),
        new Date().getDate());
    
    // public isDetailView: boolean = true;
    // public isBlockCall:boolean =false;
    @Input() isCreate: boolean ;
    public isCreateClick :boolean = false
    public codeList: any;
    @Input() userToView: UserDTO = {};
    formSubmitted: boolean;
    public pmNameItemList: any = [];
    public roleCheck: boolean = false;
    public accessCheck: boolean = false;
    public pmNameCheck: boolean = false;
    public userName: any;
    public logedInUser: any;
    defaultPage = 0;
    defaultSize = 2
    @Output() back = new EventEmitter();
    constructor(
        private locstr: LocalStorageService,
        public header: HeaderService,
        public sidebar: SidebarService,
        private router: Router,
        protected userService: ManageUserService,
        protected commonService: CommonService,
        private confirmationService: ConfirmationService,
        public elementRef: ElementRef,
        public authService: AuthService,
        public propertyManagerService: PropertyManagerService
    ) {
        //var extras = this.router.getCurrentNavigation().extras;
    }

    /**
     * Function on page load
     *
     */
    ngOnInit() {
        this.logedInUser = this.locstr.getObj( "userData" );

        this.genderList = [
            { label: "Select Gender (Optional)", value: null },
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
            { label: "Other", value: "Other" },
        ];
        this.roleList = [
            { label: "Select Role", value: null },
            { label: "Sub Admin", value: "SUB_ADMIN" },
            { label: "Property Manager", value: "PROPERTY_MANAGER" },
            { label: "Eviction Admin", value: "EVICTION_ADMIN" },
        ];
        this.getPMList();
        this.accessList = [
            { label: "User Management", value: "USER_MANAGEMENT" },
            { label: "Activity", value: "ACTIVITY" },
            { label: "Reports", value: "REPORTS" },
            { label: "Goals", value: "GOALS" },
            { label: "Stats", value: "STATS" }
        ];

        var isSliderShow = this.locstr.getObj("isSliderShow");
        if(isSliderShow){
            this.sidebar.show();
            this.locstr.setObj("isSliderShow", true);
        } else {
            this.sidebar.hide();
            this.locstr.setObj("isSliderShow", false);
        }
        this.header.show();
    }

   
    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
        // Your logic on beforeunload
        let titleText = "Rentdex";
            let messageText = "Are you sure you want to leave?";
            let yesText = "YES";
            let noText = "NO";
        
            //     this.commonService.showConfirm( titleText, messageText, noText, yesText, () => {
            // }, () => {
            
            // }, () => {
            //     window.event.preventDefault();
                
            // } );
    
        
    }
    backToView() {    
        this.back.emit();
    }

    /**
       * Function to get PM list
       */
     private getPMList = () => {
        this.pmNameItemList = [];
        this.propertyManagerService.getPMEANameIdList(true).subscribe(
          (res) => {
            if (res.code == 200 || res.status == 200) {
              var pmEaNameIdList = res.data;
              for (let i = 0; i <= pmEaNameIdList.length; i++) {
                if(pmEaNameIdList[i]){
                    // if(pmEaNameIdList[i].assigned == true){
                    //     this.pmNameItemList.push({ label: pmEaNameIdList[i].name, value: pmEaNameIdList[i].id }); // disable: true
                    // } else {
                  this.pmNameItemList.push({ label: pmEaNameIdList[i].name, value: pmEaNameIdList[i].id });
                  for(let j=0; j<this.userToView.teamMemberList.length; j++){
                    if (this.userToView.teamMemberList[j] == pmEaNameIdList[i].name){
                        this.userToView.teamMemberList[j] = pmEaNameIdList[i].id;
                      }
                  }                  
                }
            }
            // }
              console.log("pmEaNameItemList >>> ::::::: ", this.pmNameItemList);
              this.pmNameItemList = _.cloneDeep(this.pmNameItemList);
            }        
            this.commonService.hideLoading();
          },
          (error) => {
            this.pmNameItemList = [];
            if (error.message) {
              this.commonService.clearToast();
              this.commonService.showError(error.message);
            } else {
              this.commonService.clearToast();
              this.commonService.showError(error.error_description);
            }
            this.commonService.hideLoading();
          }
        );
      }

    /**
     * Function to select gender
     */
    public getSelectedGender( eve ) {
        console.log( "eb", eve, this.userToView.gender );
    }

     /**
       * Function to select user access
       */
    public getSelectedAccess( eve ) {
        console.log( "eb", eve, this.userToView.userAccessList );
        if ( this.userToView.userAccessList != "" ) {
            this.accessCheck = false;
        } else {            
            this.accessCheck = true;
        }
    }

    /**
       * Function to select team member
       */
    public getSelectedTeamMember( eve ) {
        console.log( "eb", eve, this.userToView.teamMemberList );
    }

     /**
       * Function to select role
       */
    public getSelectedRole( eve ) {
        console.log( "eb", eve, this.userToView.roleType );
        if ( this.userToView.roleType != null ) {
            this.userToView.userAccessList = []
            this.roleCheck = false;
            this.accessCheck = true;
            // this.pmNameCheck = true;
        }
    }

    /**
     * Remove conditional class only for login page
     * */
    ngOnDestroy() { 
        if(!this.userForm.submitted && this.userForm.dirty){
            let titleText = "Rentdex";
            let messageText = "Are you sure you want to leave?";
            let yesText = "YES";
            let noText = "NO";       
        }   
    }

    
    /**
     * Function to create/update user
     */
    public submitUser = ( form: NgForm ) => {
        this.formSubmitted = true;
        console.log( "valid", form.valid );
        if ( form.valid ) {
            this.commonService.showLoading();
            console.log( "valid", form.valid );

            if ( this.isCreate ) {
                this.userService.createUser( this.userToView ).subscribe(
                    ( res ) => {
                        this.commonService.hideLoading();
                        if ( res.code == 200 || res.status == 200 ) {
                            console.log( "create user :: res :: ", res );                            
                            //this.getUserList();
                            this.backToView()
                            //this.cancelClick()
                            this.commonService.clearToast();
                            this.commonService.showSuccess( res.message);                            
                        }
                    },
                    ( error ) => {
                        this.commonService.hideLoading();
                        console.log( "create user: ", error );
                        if ( error.message ) {
                            this.commonService.clearToast();
                            this.commonService.showError(error.message);
                        } else {
                            this.commonService.clearToast();
                            this.commonService.showError(error.error_description);
                        }
                    }
                );
            } else {
                this.updateId = this.userToView.id;
                this.userService.updateUser( this.userToView ).subscribe(
                    ( res ) => {
                        this.commonService.hideLoading();
                        if ( res.code == 200 || res.status == 200 ) {
                            console.log( "update user :: res :: ", res );
                            /***** Sub admin update ******************/
                            console.log("this.logedInUser >>>>", this.logedInUser);
                            if(this.logedInUser.roleType == "SUB_ADMIN"){
                                if(this.logedInUser.id ==  this.updateId){
                                    console.log("this.logedInUser.userAccessList.length >>>", this.logedInUser.userAccessList.length);
                                    console.log("this.userToView.userAccessList.length >>>", this.userToView.userAccessList.length);
                                    console.log("this.logedInUser.teamMemberList.length >>",this.logedInUser.teamMemberList.length);
                                    console.log("this.userToView.teamMemberList.length >>>", this.userToView.teamMemberList.length);
                                    if(this.logedInUser.userAccessList.length === this.userToView.userAccessList.length){
                                        for(let i=0; i<this.logedInUser.userAccessList.length; i++){
                                            if(this.logedInUser.userAccessList[i] == this.userToView.userAccessList[i]){
                                                console.log("user is not edited access list");
                                            }
                                        }
                                        for(let i=0; i<this.logedInUser.teamMemberList.length; i++){
                                            if(this.logedInUser.teamMemberList[i] == this.userToView.teamMemberList[i]){
                                                console.log("user is not edited team Member list", this.logedInUser.teamMemberList[i]);
                                            }
                                        }
                                    } else {
                                        console.log("user edited access list");
                                        this.logoutClick();
                                    }
                                }                                
                            }                           
                            this.isUpdate = true;
                            //this.getUserList();
                            this.backToView()
                            //this.cancelClick()
                            this.commonService.clearToast();
                            this.commonService.showSuccess( res.message);
                        }
                    },
                    ( error ) => {
                        this.commonService.hideLoading();
                        console.log( "update user: ", error );
                        if ( error.message ) {
                            this.commonService.clearToast();
                            this.commonService.showError(error.message);
                        } else {
                            this.commonService.clearToast();
                            this.commonService.showError(error.error_description);
                        }
                    }
                );
            }
        } else if (
            this.userToView.roleType == null ||
            this.userToView.roleType == ""
        ) {
            this.roleCheck = true;
        this.accessCheck = true
        }
    };

    /**
     * Function to logout from application
     */
    logoutClick = () => {
        this.commonService.clearToast();       
        console.log("enter");
        this.commonService.showLoading();
        this.authService.logout( this.userData ).subscribe(
            res => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    this.userData = this.locstr.getObj('userData');
                    this.password = this.locstr.getObj("password");
                    this.checkValueData = this.locstr.getObj("checkValue");
                    var deviceTokenId =  this.locstr.getObj("deviceTokenId");
                    window.localStorage.clear(); //try this to clear all local storage
                    this.userData.isLoggedInUser = false; 
                    this.locstr.setObj('userData', this.userData);
                    this.locstr.setObj("password", this.password);
                    this.locstr.setObj("checkValue", this.checkValueData);
                    this.locstr.setObj("isSliderShow", true);
                    this.locstr.setObj("deviceTokenId", deviceTokenId);
                    this.commonService.clearToast(); 
                    this.commonService.showSuccess( "Profile updated successfully. Please login again.");
                    this.router.navigate([''], res);                    
                }
            },
            error => {
                this.commonService.hideLoading();
                console.log( "HeaderComponent :: logoutClick :: error :: ", error );
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.message);
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError(error.error_description);
                }
            }
        );          
    }
    
    /**
     * Function Coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
      this.commonService.showInfo("Coming soon");
       // this.toaster.open( { text: "Coming soon", type: 'info', position: 'top-center' } )
    }

}
