import { Component, OnInit } from '@angular/core';
import { SidebarService, HeaderService, CommonService, AuthService } from 'src/providers/providers';
import { NgForm } from '@angular/forms';
import { GoalsService } from 'src/providers/goals-service/goals.service';
import { AppService } from 'src/app/app.service';
import {SliderModule} from 'primeng/slider';

@Component({
  selector: 'app-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.scss']
})
export class GoalComponent implements OnInit {
  public pageTitle: string;
  public formSubmitted;
  public tenentRent = 0; 
  public subsidyRent = 0;

  public tenentRentPost = 0;
  public goalsData = {
      "actualRent": 0,
      "actualRentPercentage": 0,
      "actualSubsidy": 0,
      "actualSubsidyPercentage": 0,
      "rentGoalPercentage": 0,
      "subsidyGoalPercentage": 0
    };

  constructor(public header: HeaderService, public sidebar: SidebarService, public commonService: CommonService, public goalsService: GoalsService,
    private appService: AppService) { 
    this.pageTitle = "Goals";
    
    this.goalsPercentage();
  }
  checked1: boolean = false;
  checked2: boolean = true;
  cities2: any[] = [];

  ngOnInit(): void {
    this.sidebar.show();
    this.header.show();
    this.appService.setTitle( 'Goals' );

  }


  public goalsPercentage = () => { 
        this.commonService.showLoading();
        this.goalsService.goalsRentPercentage().subscribe(
            res => {
                if ( res.code == 200 || res.status == 200 ) {
                    this.commonService.hideLoading();
                    console.log( "userPercentage :: userPercentage :: res :: ", res );
                    this.goalsData = res.data;
                    this.tenentRent = this.goalsData.rentGoalPercentage;
                    this.subsidyRent = this.goalsData.subsidyGoalPercentage;                 
                    this.commonService.clearToast();
                }
            },
            error => {
                this.commonService.hideLoading();
                console.log( "userPercentage :: userPercentage :: error :: ", error );
                if(error.message) {
                  this.commonService.clearToast();
                  this.commonService.showError(error.message);
                  } else {
                    this.commonService.clearToast();
                    this.commonService.showError(error.error_description);
              }                  
            }
        );
  }

  valueCheck(el){
    console.log("event..", el);     
    if(el.goalsData.rentGoalPercentage > 100){
      el.goalsData.rentGoalPercentage = null;      
   return el.goalsData.rentGoalPercentage;
    }
  }

  /**
     * Function to update the tenant Rent
     */
    public goalsUpdate = ( form: NgForm ) => {
      console.log("goalsUpdate..", form);
      console.log("this.tenantRentValue..", form);

      if(form.controls.tenantRent){
        this.goalsData.subsidyGoalPercentage = this.subsidyRent;               
      }
      if(form.controls.subsidizedRent){
        this.goalsData.rentGoalPercentage = this.tenentRent;               
      }
      
      this.formSubmitted = true;
      console.log("valid",form.valid)
      if ( form.valid ) {
          this.commonService.showLoading();
          this.goalsService.updateGoals(this.goalsData).subscribe(
              res => {
                  if ( res.code == 200 || res.status == 200 ) {
                      this.commonService.hideLoading();
                      console.log( "GoalComponent :: tenantRentRequest :: res :: ", res );
                      this.goalsData = res.data;                      
                      this.tenentRent = this.goalsData.rentGoalPercentage;
                      this.subsidyRent = this.goalsData.subsidyGoalPercentage;
                      // if (this.goalsData.rentGoalPercentage == 0){
                      //   this.goalsData.rentGoalPercentage = null;
                      // };
                      // if (this.goalsData.subsidyGoalPercentage == 0){
                      //   this.goalsData.subsidyGoalPercentage = null;
                      // };
                      this.commonService.clearToast();
                      this.commonService.showSuccess("Goals updated successfully.");
                  }
              },
              error => {
                  this.commonService.hideLoading();
                  console.log( "GoalComponent :: tenantRentRequest :: error :: ", error );
                  if(error.message) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.message);
                    } else {
                      this.commonService.clearToast();
                      this.commonService.showError(error.error_description);
                }                  
              }
          );
      } 
  }
}
