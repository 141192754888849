<div class="background-image-wrapper">

    <!-- Container -->
    <div class="form-container">

        <div class="">
            <!-- Logo -->
            <div>
                <img class="app-logo text-center" src="assets/images/rentdex-logo.png">
            </div>

            <!-- Heading -->
            <div class="text-center col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                <h2 class="form-title"> {{ "LOGIN.WELCOME_TO_RENTDEX" | translate }} </h2>
                <p class="form-subtitle"> {{ "LOGIN.PLEASE_ENTER_YOUR_LOGIN_DETAILS_BELOW" | translate }} </p>
            </div>

            <!-- Form -->

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                <form class="common-form-cls" #loginForm="ngForm" (ngSubmit)="processLoginRequest(loginForm)">
                    <!--------------- Email Field  --------->
                    <div class="p-field form-group input-group mar-btm28">
                        <span><i class="email-icon icon-left input-icon"></i> </span>
                        <input id="form-email" [ngStyle]="{'border': (loginEmail.errors && (loginEmail.dirty || loginEmail.touched || formSubmitted)) ? '1px solid red' : '0' }" type="Email" placeholder="Email id" class="from-input pass-input ng-pristine ng-invalid submitted"
                            name="email" #loginEmail="ngModel" [(ngModel)]="userDetails.email" pattern="^(?!\.)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,256}$" aria-describedby="Email-help" pInputText required/>
                        <div class="text-left error" *ngIf="loginEmail.errors && (loginEmail.dirty || loginEmail.touched || formSubmitted)">
                            <small [hidden]="!loginEmail.errors.required" class="p-invalid error-msg"> {{ "LOGIN.EMAIL_REQUIRED" | translate }}
                            </small>
                            <small [hidden]="!loginEmail.errors.pattern" class="p-invalid error-msg"> {{ "LOGIN.EMAIL_INVALID" | translate }}
                            </small>
                        </div>

                    </div>

                    <!--------------- password Field  --------->
                    <div class="form-group input-group">
                        <!-- <label></label> -->
                        <span><i class="password-icon icon-left input-icon"></i> </span>
                        <input id="form-password" [ngStyle]="{'border': (loginPassword.errors && (loginPassword.dirty || loginPassword.touched || formSubmitted)) ? '1px solid red' : '0' }" type="{{inputType}}" placeholder="Password" class="from-input pass-input ng-pristine ng-invalid submitted"
                            #loginPassword="ngModel" [(ngModel)]="userDetails.password" onKeyDown="if(event.keyCode === 32) return false;" name="password" minlength="6" maxlength="40" pInputText required>
                        <span (click)="hideShowPassword()" [ngClass]="passwordCheck?'eye-open-icon icon-right input-icon':'eye-close-icon icon-right input-icon'"></span>
                        <div class="text-left error" *ngIf="loginPassword.errors && (loginPassword.dirty || loginPassword.touched || formSubmitted)">
                            <small [hidden]="!loginPassword.errors.required" class="p-invalid error-msg"> {{ "LOGIN.PASSWORD_REQUIRED" | translate }} </small>
                            <small [hidden]="!loginPassword.errors.minlength" class="p-invalid error-msg">{{ "LOGIN.PASSWORD_LIMIT_IN_NO" | translate }} </small>
                        </div>

                    </div>
                    <!--------------- password Field  --------->
                    <div class="custom-control custom-checkbox checkbox-wrap">
                        <input type="checkbox" class="custom-control-input checkbox-cls" id="customCheck" [checked]="checkValue" name="example1">
                        <label class="custom-control-label checkmark-text" for="customCheck"><span>{{ "LOGIN.KEEP_ME_LOGGED_IN" | translate }}</span></label>
                    </div>
                    <!--------------- submit btn  --------->
                    <div>
                        <button class="btn common-btn full-width">{{ "LOGIN.LOGIN" | translate }}</button>
                    </div>

                    <div>
                        <a [routerLink]="'/forgot-password'" class="forgot-text text-center">{{ "LOGIN.IS_FORGOT_PASSWORD" | translate }}</a>
                    </div>

                </form>
                <!--------------- end of form  --------->
            </div>

        </div>
    </div>

    <p-toast class="toast-card" position="top-center"></p-toast>

</div>