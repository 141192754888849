
<!-- Container -->
<div class="form-container filter-form-cls">

    <div class="">

        <!-- Heading -->
        <div class="row filter-heading m-0">
            <div class="col-6">
              <h2 class="form-title"> {{"COMMON_COMPONENT.FILTER" | translate }} </h2>
            </div>
            <div class="col-6">
              <button class="btn link-btn clear-btn" (click)="clearFilter()"> {{"COMMON_COMPONENT.CLEAR_ALL" | translate }} </button>
            </div>
        </div>


        <div class="container custom-ver-tabs">
            <!-- <div class="col nav-tabs-wrap"> -->
              <!-- Nav tabs -->
              <ul class="nav nav-tabs tabs-left sideways" id="myTab" role="tablist" >
                <li class="nav-item active" [ngClass]="{active: tab == 2}"><a (click)="tab=2;  clearCommingSoon()" class="nav-link active" href="#pm-v" data-toggle="tab" role="tab"> {{ "RENT_ROLL_FILTER.PMS_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 3}"><a (click)="tab=3;  clearCommingSoon()" class="nav-link" href="#renewal-v" data-toggle="tab" role="tab"> {{ "RENT_ROLL_FILTER.RENEWAL_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 4}"><a (click)="tab=4; clearCommingSoon()" class="nav-link" href="#eviction-v" data-toggle="tab" role="tab"> {{ "RENT_ROLL_FILTER.EVICTION_STATUS" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 5}"><a (click)="tab=5;  clearCommingSoon()" class="nav-link" href="#total-v" data-toggle="tab" role="tab"> {{ "RENT_ROLL_FILTER.TOTAL_RENT" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 6}"><a (click)="tab=6;  clearCommingSoon()" class="nav-link" href="#tenant-v" data-toggle="tab" role="tab"> {{ "RENT_ROLL_FILTER.TENANT_BALANCE" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 7}"><a (click)="tab=7; clearCommingSoon()" class="nav-link" href="#subsidy-v" data-toggle="tab" role="tab"> {{ "RENT_ROLL_FILTER.SUBSIDY_RENT" | translate }} </a></li>
                <li class="nav-item" [ngClass]="{active: tab == 8}"><a (click)="tab=8;  clearCommingSoon()" class="nav-link" href="#pending-v" data-toggle="tab" role="tab"> {{ "RENT_ROLL_FILTER.PENDING_RENT" | translate }} </a></li>   
                <li class="nav-item" [ngClass]="{active: tab == 9}"><a (click)=" tab=9; clearCommingSoon()" class="nav-link" href="#acronym-v" data-toggle="tab" role="tab"> {{ "PM_DASHBOARD.RESIDENT_ACRONYM" | translate }} </a></li>
              </ul>
            <!-- </div> -->

            <!-- <div class="col tab-content-wrap"> -->
              <!-- Tab panes -->
                <div class="tab-content">
                  <div class="tab-pane" id="pm-v" [ngClass]="{active: filterPayload}" role="tabpanel">
                      <!-- PM's status content... -->
                      <form class="form-inline crud-form filter-form">
                          <div class="row">
                            <div class="col-sm-12 form-group">
                              <p-dropdown class="form-dropdown" name="pmFilter" [options]="pmStatusList" [(ngModel)]="filterPayload.pmStatus"></p-dropdown>
                            </div>
                          </div>
                      </form>
                  </div>

                  <div class="tab-pane" id="renewal-v" role="tabpanel">
                    <!-- Renewal status content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">
                            <p-dropdown class="form-dropdown" name="renewalFilter" [options]="renewalStatusList" [(ngModel)]="filterPayload.renewalStatus"></p-dropdown>
                          </div>
                        </div>
                    </form>
                  </div>

                  <div class="tab-pane" id="eviction-v" role="tabpanel">
                    <!-- Eviction status content...  -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">
                            <p-dropdown class="form-dropdown" name="evictionFilter" [options]="evictionStatusList" [(ngModel)]="filterPayload.evictionStatus"></p-dropdown>
                          </div>
                        </div>
                    </form>
                  </div>

                  <div class="tab-pane" id="total-v" role="tabpanel">
                    <!-- Total rent content...  -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">
                              <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalRentFrom" placeholder="From" 
                              appTwoDigitDecimaNumber/>
                          </div>
                          <div class="col-sm-12 form-group">
                            <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalRentTo" placeholder="To" 
                            appTwoDigitDecimaNumber/>
                          </div>
                        </div>
                    </form>
                </div>
                
                <div class="tab-pane" id="tenant-v" role="tabpanel">
                    <!-- Tenant balance content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">
                              <input type="number" id="from" name="from" class="form-control form-input padd-left12" min="0" [(ngModel)]="filterPayload.tenantBalanceFrom" placeholder="From" 
                              appTwoDigitDecimaNumber/>
                          </div>
                          <div class="col-sm-12 form-group">
                            <input type="number" id="to" name="to" class="form-control form-input padd-left12" min="0" [(ngModel)]="filterPayload.tenantBalanceTo" placeholder="To" 
                            appTwoDigitDecimaNumber/>
                          </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="subsidy-v" role="tabpanel">
                    <!-- Subsidy content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">
                            <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.subsidyFrom" placeholder="From" 
                            appTwoDigitDecimaNumber/>
                        </div>
                        <div class="col-sm-12 form-group">
                          <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.subsidyTo" placeholder="To" 
                          appTwoDigitDecimaNumber/>
                        </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane" id="pending-v" role="tabpanel">
                    <!-- Pending rent content... -->
                    <form class="form-inline crud-form filter-form">
                        <div class="row">
                          <div class="col-sm-12 form-group">
                            <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.pendingRentFrom" placeholder="From" 
                            appTwoDigitDecimaNumber/>
                        </div>
                        <div class="col-sm-12 form-group">
                          <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.pendingRentTo" placeholder="To" 
                          appTwoDigitDecimaNumber/>
                        </div>
                        </div>
                    </form>
                </div>
                
                <!-- Resident Acronym List --->
                <div class="tab-pane" id="acronym-v" role="tabpanel">
                  <!-- Action items content... -->
                  <form class="form-inline crud-form filter-form">
                     <div class="row">
                        <div class="col-sm-12 form-group">
                           <p-dropdown class="form-dropdown" name="acronymFilter" [options]="acronymList" [(ngModel)]="filterPayload.acronym"></p-dropdown>
                        </div>
                     </div>
                  </form>
               </div>

                   
              </div>
            <!-- </div> -->

            <div class="clearfix"></div>

        </div>

        <!--------------- modal btns  --------->
        <div class="row d-flex modal-footer m-0">
          <button type="button" class="btn link-btn" (click)="cancelClick()"> {{"COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
          <button type="submit" class="btn common-btn" (click)="applyFilter()"> {{"COMMON_COMPONENT.APPLY_BUTTON" | translate }} </button>
        </div>


    </div>
  </div>


