import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";

import { CommonService, MetaDataService } from 'src/providers/providers';

@Component( {
    selector: 'app-pipeline-filter',
    templateUrl: './pipeline-filter.component.html',
    styleUrls: ['./pipeline-filter.component.scss'],

    encapsulation: ViewEncapsulation.None
} )
export class PipelineFilterComponent implements OnInit {

    @Input() filterPayload: any;
    public evictionStatusList: any = [];
    public acronymList: any = [];

    tab: any;

    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = ( this.currentYear + 99 );
    public minyear = ( this.currentYear - 99 );
    range: string = ( this.minyear ).toString() + ":" + ( this.maxyear ).toString();

    public evictionTypeList: any = [];

    constructor( private commonService: CommonService, public activeModal: NgbActiveModal,
        public metaDataService: MetaDataService ) { }

    ngOnInit(): void {
        this.getAcronymList();
        this.getEvictionStatusList();
        this.tab = 1;
        this.evictionTypeList = [
            { label: "Select", value: null },
            { label: "No Deals", value: "NO_DEALS" },
            { label: "Pay Balance", value: "PAY_BALANCE" },
            { label: "Payment Plan", value: "PAYMENT_PLAN" }
        ];
    }

    /**
       * function on cancel Click
       */
    cancelClick = () => {
        this.activeModal.dismiss( '' );
    }

    applyFilter = () => {
        if ( this.filterPayload.costTo == null ) {
            this.filterPayload.costTo = 0;
        }

        if ( this.filterPayload.costFrom == null ) {
            this.filterPayload.costFrom = 0;
        }

        if ( typeof this.filterPayload.typeOfEviction == 'object' ) {
            this.filterPayload.typeOfEviction = "";
        }

        if ( typeof this.filterPayload.evictionStatus == 'object' ) {
            this.filterPayload.evictionStatus = "";
        }

        if ( typeof this.filterPayload.acronym == 'object' ) {
            this.filterPayload.acronym = "";
        }


        this.activeModal.dismiss( this.filterPayload );
    }

    clearFilter = () => {
        this.filterPayload.coppDateFrom = "";
        this.filterPayload.coppDateTO = "";
        this.filterPayload.costFrom = null;
        this.filterPayload.costTo = null;
        this.filterPayload.daysOngoingFrom = "";
        this.filterPayload.daysOngoingTo = "";
        this.filterPayload.evictionFiledFrom = "";
        this.filterPayload.evictionFiledTo = "";
        this.filterPayload.evictionStatus = "";
        this.filterPayload.evictionStayDateFrom = "";
        this.filterPayload.evictionStayDateTo = "";
        this.filterPayload.evictionType = "";
        this.filterPayload.typeOfEviction = "";
        this.filterPayload.initialCourtDateFrom = "";
        this.filterPayload.initialCourtDateTo = "";
        this.filterPayload.otherCourtDateFrom = "";
        this.filterPayload.otherCourtDateTo = "";
        this.filterPayload.possessionOfUnitFrom = "";
        this.filterPayload.possessionOfUnitTo = "";
        this.filterPayload.searchText = "";
        this.filterPayload.secondCourtDateFrom = "";
        this.filterPayload.secondCourtDateTo = "";
        this.filterPayload.sheriffOrderedFrom = "";
        this.filterPayload.sheriffOrderedTo = "";
        this.filterPayload.acronym = "";
    }

    /**
         * Function to get eviction status
         */
    private getEvictionStatusList = () => {
        this.evictionStatusList = [];
        this.metaDataService.getEvictionStatusList(
            ( response ) => {
                this.commonService.hideLoading();
                this.evictionStatusList.push( { label: "Select", value: "" } );

                response.map( status =>
                    this.evictionStatusList.push( status )
                );

                this.evictionStatusList = _.cloneDeep( this.evictionStatusList );
                this.commonService.hideLoading();
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.evictionStatusList = reason;
                this.commonService.hideLoading();
            } );
    }

    /**
       * Function to get Resident Acronym List
       */
    private getAcronymList = () => {
        this.acronymList = [];
        this.metaDataService.getAcronymList(
            ( response ) => {
                this.commonService.hideLoading();
                this.acronymList.push( { label: "Select", value: "" } );

                response.map( status =>
                    this.acronymList.push( status )
                );

                if ( !this.filterPayload.acronym ) {
                    // this.acronymList.splice(1, 1);
                    this.filterPayload.acronym = this.acronymList[0];
                }

                this.acronymList = _.cloneDeep( this.acronymList );
                this.commonService.hideLoading();
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.acronymList = reason;
                this.commonService.hideLoading();
            } );
    }

}
