/**
 * View Activites Functionality taken by all PM/EA 
 * Created By : #1116
 * Date : 15 January, 2021
 */

import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { AppService } from './../../../app/app.service';
import { LazyLoadEvent } from 'primeng/api';

import {
    CommonService,
    HeaderService,
    SidebarService,
    LocalStorageService,
    AdminService,
    Constants
} from "src/providers/providers";

import { ExportPopupComponent } from 'src/pages/export-popup/export-popup.component';
import { ActivityFilterComponent } from '../activity-filter/activity-filter.component';
import { Paginator } from 'primeng/paginator';

@Component( {
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class ActivityComponent implements OnInit {

    @Input() loading: boolean = true;
    @ViewChild('p', {static: false}) paginator: Paginator;
    public userData: any;

    filterData: any = {};
    public isDeliquence: boolean = true;

    public isSearchCall: boolean = false;

    /*public activityPayload: any = {
        pageNumber: 0,
        pageSize: 25
    };*/

    public activityFilterPayload: any = {
        actionTaken: "",
        searchText: "",
        followUpDateFrom: null,
        followUpDateTo: null,
        pmName: "",
        promiseToPayFrom: null,
        promiseToPayTo: null,
        sortBy: "",
        sortManner: "",
        tenantName: "",
        updatedAtFrom: null,
        updatedAtTo: null,
        pageNumber: 0,
        pageSize: 10
    };

    // Today's table data
    public activitiesList: any = [];
    isActivitiesNoData: boolean = false;

    activitiesDefaultPage = 0;
    activitiesDefaultSize = 25;

    constructor( public header: HeaderService,
        public sidebar: SidebarService,
        private appService: AppService,
        public localStr: LocalStorageService,
        protected commonService: CommonService,
        private adminService: AdminService,
        private router: Router,
        public constants : Constants ) {
            this.activityFilterPayload.pageSize = this.constants.defaultPageSize;
         }

    ngOnInit(): void {
        var isSliderShow = this.localStr.getObj("isSliderShow");
        if(isSliderShow){
            this.sidebar.show();
            this.localStr.setObj("isSliderShow", true);
        } else {
            this.sidebar.hide();
            this.localStr.setObj("isSliderShow", false);
        }
        this.header.show();

        this.localStr.setObj("breadcrum", "");
        this.appService.setTitle( 'Activities' );
        this.userData = this.localStr.getObj( 'userData' );

        //Get Activites Data
        this.getActivitiesList(null);
    }


    /**
       * Function to get activity list
       */
    public getActivitiesList($event) {
        if ( !this.isSearchCall ) {
            this.commonService.showLoading();  // remove this when we use inplace loader
        }else if(this.isSearchCall){
            this.activityFilterPayload.pageNumber= 0;
        }

        // check data after visiting activity log
        if(this.localStr.getObj("beforeActivityData")){
            this.activityFilterPayload = this.localStr.getObj("beforeActivityData");
        }

        this.loading = true;

        this.adminService.getActivitesList( this.activityFilterPayload ).subscribe(
            ( res ) => {
                if ( !this.isSearchCall ) {
                    this.commonService.hideLoading();
                }else if(this.isSearchCall){
                    this.paginator.changePageToFirst($event);
                }
                this.isSearchCall = false
                if ( res.code == 200 || res.status == 200 ) {
                    this.activitiesList = res.data.list;
                    if ( this.activitiesList && this.activitiesList.length == 0 ) {
                        this.isActivitiesNoData = false;
                    } else {
                        // Added condition for page 1  - show no data found
                        if ( this.activityFilterPayload.pageNumber == 0 ) {
                            this.isActivitiesNoData = true;
                        }
                    }

                    this.activityFilterPayload.totalRecords = res.data.totalCount;
                    this.localStr.setObj("beforeActivityData", "");
                } else {
                    this.activitiesList = [];
                }

                this.loading = false;
            },
            ( error ) => {
                if ( !this.isSearchCall ) {
                    this.commonService.hideLoading();
                }
                this.isSearchCall = false
                this.activitiesList = [];
                this.isActivitiesNoData = true;
                if ( error && error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else if ( error && error.error_description ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
                this.loading = false;
            }
        );
    }

    /**
       * Function for Delinquencies list pagination
       */
    public activitiespaginate = ( event ) => {
        let currentPage: number;
        this.activitiesDefaultPage = event.first / event.rows;
        this.activitiesDefaultSize = event.rows;
        this.activitiesList = [];
        this.activityFilterPayload.pageSize = this.constants.defaultPageSize;
        this.activityFilterPayload.pageNumber = this.activitiesDefaultPage;
        this.getActivitiesList(null);
    }

    /**
      * Function to export activities records
      */
    public exportActivities( reportFormat ) {
        //this.commonService.showLoading();

        this.adminService.exportActivities( this.activityFilterPayload, reportFormat, this.userData.id ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "res", res );
                    //window.open( res.data );
                    window.location.assign (res.data);
                    //this.commonService.clearToast();
                    //this.commonService.showSuccess(res.message);
                }
  
            },
            ( error ) => {
                this.commonService.hideLoading();
  
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    /**
       *  Function for Export dialog 
       */
    openExportPopup() {
        this.commonService.openConfirmModal( ExportPopupComponent, this.filterData, ( data ) => {
            console.log( "log..data", this.filterData, data );
            if ( data.format ) {
                this.exportActivities( data.format );
            }
        } );
    }

    /**
     * Function for open fiter popup
     */
    public clickOnFilter = ($event) => {
        this.filterData.isDeliquence = this.isDeliquence;

        this.commonService.openConfirmModal( ActivityFilterComponent, this.filterData, ( data ) => {
            console.log( "filtrdata", data );
            if ( data ) {
                this.activityFilterPayload.actionTaken = data.lastActionTaken;
                this.activityFilterPayload.acronym = data.acronym;
                this.activityFilterPayload.followUpDateFrom = data.followUpDateFrom;
                this.activityFilterPayload.followUpDateTo = data.followUpDateTo;
                this.activityFilterPayload.pmName = data.pmName;
                this.activityFilterPayload.promiseToPayFrom = data.promiseToPayFrom;
                this.activityFilterPayload.promiseToPayTo = data.promiseToPayTo;
                this.activityFilterPayload.updatedAtFrom = data.updatedAtFrom;
                this.activityFilterPayload.updatedAtTo = data.updatedAtTo;
                this.paginator.changePageToFirst($event);
                this.getActivitiesList(null)
            } 
        } );
    }

        customSort( data: LazyLoadEvent, type ) {
            console.log( "sort", data );
                switch ( data.sortField ) {
                    case "pmName":
                        this.activityFilterPayload.sortBy = "PMNAME";
                        switch ( data.sortOrder ) {
                            case 1:
                                this.activityFilterPayload.sortManner = "DESC";
                                break;
                            case -1:
                                this.activityFilterPayload.sortManner = "ASC";
                                break;
                            default:
                                this.activityFilterPayload.sortManner = "";
                                break;    
                        }
                        break;
                    case "residentName":
                        this.activityFilterPayload.sortBy = "TENANTNAME";
                        switch ( data.sortOrder ) {
                            case 1:
                                this.activityFilterPayload.sortManner = "DESC";
                                break;
                            case -1:
                                this.activityFilterPayload.sortManner = "ASC";
                                break;
                            default:
                                this.activityFilterPayload.sortManner = "";
                                break;    
                        }
                    break;
                    case "address":
                        this.activityFilterPayload.sortBy = "ADDRESS";
                        switch ( data.sortOrder ) {
                            case 1:
                                this.activityFilterPayload.sortManner = "DESC";
                                break;
                            case -1:
                                this.activityFilterPayload.sortManner = "ASC";
                                break;
                            default:
                                this.activityFilterPayload.sortManner = "";
                                break;    
                        }
                    break;
                    case "ownersName":
                        this.activityFilterPayload.sortBy = "OWNERSNAME";
                        switch ( data.sortOrder ) {
                            case 1:
                                this.activityFilterPayload.sortManner = "DESC";
                                break;
                            case -1:
                                this.activityFilterPayload.sortManner = "ASC";
                                break;
                            default:
                                this.activityFilterPayload.sortManner = "";
                                break;    
                        }
                        break;
                    case "actionItem":
                        this.activityFilterPayload.sortBy = "ACTIONTAKEN";
                        switch ( data.sortOrder ) {
                            case 1:
                                this.activityFilterPayload.sortManner = "DESC";
                                break;
                            case -1:
                                this.activityFilterPayload.sortManner = "ASC";
                                break;
                            default:
                                this.activityFilterPayload.sortManner = "";
                                break;    
                        }
                        break;
                    case "followUpDate":
                        this.activityFilterPayload.sortBy = "FOLLOWUPDATE";
                        switch ( data.sortOrder ) {
                            case 1:
                                this.activityFilterPayload.sortManner = "DESC";
                                break;
                            case -1:
                                this.activityFilterPayload.sortManner = "ASC";
                                break;
                            default:
                                this.activityFilterPayload.sortManner = "";
                                break;    
                        }
                        break;
                    case "promiseToPay":
                        this.activityFilterPayload.sortBy = "PROMISETOPAY";
                        switch ( data.sortOrder ) {
                            case 1:
                                this.activityFilterPayload.sortManner = "DESC";
                                break;
                            case -1:
                                this.activityFilterPayload.sortManner = "ASC";
                                break;
                            default:
                                this.activityFilterPayload.sortManner = "";
                                break;    
                        }
                        break;
                    case "updatedAt":
                        this.activityFilterPayload.sortBy = "UPDATEDAT";
                        switch ( data.sortOrder ) {
                            case 1:
                                this.activityFilterPayload.sortManner = "DESC";
                                break;
                            case -1:
                                this.activityFilterPayload.sortManner = "ASC";
                                break;
                            default:
                                this.activityFilterPayload.sortManner = "";
                                break;    
                        }
                        break;
                    default:
                        this.activityFilterPayload.sortBy = "";
                        switch ( data.sortOrder ) {
                            case 1:
                                this.activityFilterPayload.sortManner = "DESC";
                                break;
                            case -1:
                                this.activityFilterPayload.sortManner = "ASC";
                                break;
                            default:
                                this.activityFilterPayload.sortManner = "";
                                break;    
                        }
                        break;
                }
                this.getActivitiesList(null);
    }

     /** 
     * Function to open Tenant Details 
     */
    public openTenantDetails = ( tenantProfile ) => {
        console.log("activityFilterPayload ....", this.activityFilterPayload);
        this.localStr.setObj("beforeActivityData",this.activityFilterPayload);
        
        this.localStr.setObj("breadcrum", 'Activities');
        
        this.router.navigate( ['/resident-profile'], tenantProfile );
    }

}
