import { Component, EventEmitter, OnInit, Output, Renderer2, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { Broadcaster, HeaderService, LocalStorageService, AuthService, CommonService } from 'src/providers/providers';
// import { Toaster } from 'ngx-toast-notifications';
// import { Router } from '@angular/router';
import { AppService } from './../../app/app.service';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component( {
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class HeaderComponent implements OnInit {
    @Output() closeMenu = new EventEmitter();
    isMenuOpen: boolean = false;
    public isCollapsed: boolean = false;
    public checkValueData: boolean;
    public password = "";
    @Input() userData:any;
    title:String;
    preTitle: any;
    public isMobile :boolean = false;
    deviceInfo = null;
    constructor( private locstr: LocalStorageService,private renderer: Renderer2, private broadcaster: Broadcaster, private router: Router,
        public header: HeaderService, private authService: AuthService, private commonService:CommonService,
        private appService: AppService,
        private deviceService: DeviceDetectorService) { 
            this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();
            
        }

    ngOnInit(): void {
        this.broadcaster.on<any>( 'menu-close' )
            .subscribe( message => {
                this.isMenuOpen = message;
            } );
            this.header.isUpdate.subscribe(
                (data) => {
                    this.userData = this.locstr.getObj('userData');
                }
              );

              this.appService.getTitle().subscribe(appTitle => this.title = appTitle);
              this.appService.getPreTitle().subscribe(appTitle => this.preTitle = appTitle);
              
              
    }

    //Toggle Click Function
    toggleMenu() {
            this.closeMenu.emit(true);
    }

    
    /**
     * 
     */
    pageRedirection = () => {
        console.log("this.preTitle...", this.preTitle);
        

        switch ( this.preTitle ) {
            case 'Activities':
                this.router.navigate(['/activities']);
            break;
            case 'Dashboard':
                this.router.navigate(['/property-manager-dashboard']);
            break;
            case 'Delinquencies':
                this.router.navigate(['/delinquencies']);
            break;
            case "Renewals":
                this.router.navigate(['/renewals']);
            break;
            case 'Rent Roll':
                this.router.navigate(['/rent-roll']);
            break;
            case 'Eviction Pipeline':
                this.router.navigate(['/eviction-pipeline']);
            break;
            case 'My Profile':
                this.router.navigate(['/my-profile']);
            break;
        }
    }


    toggleNavbar() {
        this.isCollapsed = !this.isCollapsed;
    }

    /**
     * Function to redirect to settings page
     */
    goToSettings =()=> {
        this.router.navigate(['/settings']);
    }

    /**
     * Function to redirect to my profile page
     */
    goToMyProfile = () => {
        this.router.navigate(['/my-profile']);
    }

    /**
     * Function to redirect to Feedback page
     */
    goToFeedback = () => {
        this.router.navigate(['/feedback']);
    }

    /**
     * Function to logout from application
     */
    logoutClick = () => {
        let titleText = "Rentdex";
        let messageText = "Are you sure you want to logout?";
        let yesText = "YES";
        let noText = "NO";
        this.commonService.showConfirm(titleText, messageText, noText, yesText, () => {
        }, () => {       
            console.log("enter");
            this.commonService.showLoading();
            this.authService.logout( this.userData ).subscribe(
                res => {
                    this.commonService.hideLoading();
                    if ( res.code == 200 || res.status == 200 ) {
                        this.userData = this.locstr.getObj('userData');
                        this.password = this.locstr.getObj("password");
                        this.checkValueData = this.locstr.getObj("checkValue");
                        var deviceTokenId =  this.locstr.getObj("deviceTokenId");
                        var OneSignal = window['OneSignal'] || [];
                        var that = this;
                        OneSignal.push(function () {
                            var isSubscribedCheck;
                            console.log("OneSignal The user's subscription state is before:", OneSignal);
                            // Occurs when the user's subscription changes to a new value.
                            OneSignal.on('subscriptionChange', function (isSubscribed) {
                              console.log("OneSignal The user's subscription state is now:", isSubscribed);
                              isSubscribedCheck = isSubscribed;
                              if(isSubscribedCheck){
                                OneSignal.setSubscription(false);
                                isSubscribed = false;
                              }
                              console.log("isSubscribed >>", isSubscribed);
                              
                              
                            });
                        });
                        window.localStorage.clear(); //try this to clear all local storage
                        
                        this.userData.isLoggedInUser = false; 
                        this.locstr.setObj('userData', this.userData);
                        this.locstr.setObj("password", this.password);
                        this.locstr.setObj("checkValue", this.checkValueData);
                        this.locstr.setObj("isSliderShow", true);
                        this.locstr.setObj("deviceTokenId", deviceTokenId);
                        this.router.navigate([''], res);
                }
                },
                error => {
                    this.commonService.hideLoading();
                    console.log( "HeaderComponent :: logoutClick :: error :: ", error );
                    if ( error.message ) {
                        this.commonService.clearToast();
                        this.commonService.showError(error.message);
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError(error.error_description);
                    }
                }
            );
        }, () => {
        });        
    }

   /**
   * Function Coming soon
   */
  public commingSoon = () => {
    this.commonService.clearToast();
    this.commonService.showInfo("Coming soon");
    // this.toaster.open({ text: "Coming soon", type: 'info', position: 'top-center' })
  }

}
