/**
 * View Notifications Functionality taken by all PM/EA 
 * Created By : #1060
 * Date : 20 January, 2021
 */

import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { AppService } from './../../app/app.service';
import { LazyLoadEvent } from 'primeng/api';

import {
    CommonService,
    HeaderService,
    SidebarService,
    LocalStorageService,
    AdminService,
    Constants,
    AuthService
} from "src/providers/providers";
import { AppComponent } from 'src/app/app.component';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent implements OnInit {

    @Input() loading: boolean = true;

    public userData: any;


    public notificationPayload: any = {
        pageNumber: 0,
        pageSize: 25,
        searchText: "",
        sortBy: "",
        sortManner: ""
    };

    // Today's table data
    public notificationList: any = [];
    isActivitiesNoData: boolean = false;

    activitiesDefaultPage = 0;
    activitiesDefaultSize = this.constants.defaultPageSize;

    constructor(public header: HeaderService,
        public sidebar: SidebarService, private appService: AppService,
        public localStr: LocalStorageService,
        protected commonService: CommonService,
        private authService: AuthService,
        public constants: Constants) {
            this.notificationPayload.pageSize = this.constants.defaultPageSize;
         }

    ngOnInit(): void {
        this.sidebar.show();
        this.header.show();

        this.appService.setTitle('Notifications');
        this.userData = this.localStr.getObj('userData');

        this.getNotificationsList();
    }

    customSort( data: LazyLoadEvent ) {
        console.log( "sort", data );
            switch ( data.sortField ) {
                case "notificationDate":
                    this.notificationPayload.sortBy = "notificationDate";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.notificationPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.notificationPayload.sortManner = "ASC";
                            break;
                        default:
                            this.notificationPayload.sortManner = "";
                    break;    
                    }
                    break;
                    default:
                        this.notificationPayload.sortBy = "";
                        switch ( data.sortOrder ) {
                            case 1:
                                this.notificationPayload.sortManner = "DESC";
                                break;
                            case -1:
                                this.notificationPayload.sortManner = "ASC";
                                break;
                            default:
                                this.notificationPayload.sortManner = "";
                                break;    
                        }
                    break;

                }

                this.getNotificationsList();
            }


    /**
       * Function for Delinquencies list pagination
       */
      public notificationpaginate = (event) => {
        let currentPage: number;
        this.activitiesDefaultPage = event.first / event.rows;
        this.activitiesDefaultSize = event.rows;
        this.notificationList = [];
        this.notificationPayload.pageSize = this.constants.defaultPageSize;
        this.notificationPayload.pageNumber = this.activitiesDefaultPage;
        this.getNotificationsList();
    }

    /**
       * Function to get deliquencies list
       */
    public getNotificationsList() {
        console.log("this.notificationPayload ::::",this.notificationPayload);
        
        this.commonService.showLoading();  // remove this when we use inplace loader
        this.loading = true;
        this.authService.getNotificationList(this.notificationPayload).subscribe(
            (res) => {
                this.commonService.hideLoading();
                if (res.code == 200 || res.status == 200) {
                    console.log("Response >>>>", res);
                    this.notificationList = res.data.list;
                    this.notificationPayload.totalRecords = res.data.totalCount;
                } else {
                    this.notificationList = [];
                }
                this.loading = false;
            },
            (error) => {
                this.commonService.hideLoading();
                this.notificationList = [];
                this.isActivitiesNoData = true;
                if (error && error.message) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.message);
                } else if (error && error.error_description) {
                    this.commonService.clearToast();
                    this.commonService.showError(error.error_description);
                }
                this.loading = false;
            }
        );
    }

    

}
