<div *ngIf="header?.visible" class="page-header">
    <nav class="navbar navbar-expand-lg navbar-expand-md navbar-light top-navbar">
        <!-- <div class="menu-toggle-cls"><button class="btn btn-link" id="menu-toggle" (click)="toggleMenu()" [attr.aria-expanded]="!isCollapsed"><i class="toggle-icon" title="Collapse/Expand Navigation Panel"></i></button> <span>{{title}}</span></div> -->
        <div class="menu-toggle-cls" >
            <button class="btn btn-link" id="menu-toggle" (click)="toggleMenu()" [attr.aria-expanded]="!isCollapsed"><i class="toggle-icon" title="Collapse/Expand Navigation Panel" preTitle="Collapse/Expand Navigation Panel"></i></button> 
            
            <!-- <a id= 'pageCheck' class="pointer" routerLinkActive="active" > </a> -->
            <button class="btn pre-title" id="pageCheck" *ngIf='preTitle' (click)="pageRedirection()"> {{preTitle}} </button> 
            <img class="back-button-cls" *ngIf='preTitle' src="assets/images/back.svg">
            <span>{{title}}</span>
        </div>
        
       <!-- <div class="menu-toggle-cls" *ngIf="userData.roleType=='SUPER_ADMIN'"><button class="btn btn-link" id="menu-toggle" (click)="toggleMenu()" [attr.aria-expanded]="!isCollapsed"><i class="toggle-icon" title="Collapse/Expand Navigation Panel"></i></button> <span>User Management </span></div>-->
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="!isMobile">
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle rprofile-cls" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="username-text">{{userData?.firstName}} {{userData?.lastName}} <br />
            <span class="role-text">{{userData?.roleType | replace : '_' : ' ' | titlecase}}</span></span>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <div class="mobileOnly">
                            <a class="nav-link user-info">
                                <span class="username-text">{{userData?.firstName}} {{userData?.lastName}} <br />
                                <span class="role-text">{{userData?.roleType | replace : '_' : ' ' | titlecase}}</span></span>
                            </a>
                        </div>
                        <a class="dropdown-item pointer" (click)="goToMyProfile()" routerLinkActive="active"> {{ "HEADER.MY_PROFILE" | translate }} </a>
                        <a class="dropdown-item pointer" [routerLink]="'/settings'" routerLinkActive="active"> {{ "HEADER.SETTING" | translate }} </a>
                        <a class="dropdown-item pointer" *ngIf="userData.roleType=='PROPERTY_MANAGER'" [routerLink]="'/notifications'" routerLinkActive="active"> {{ "HEADER.NOTIFICATION" | translate }} </a>
                        <!-- <div class="dropdown-divider"></div> -->
                        <a class="dropdown-item set-logout pointer" (click)="logoutClick()"> {{ "HEADER.LOGOUT" | translate }} </a>
                    </div>
                    
                </li>
            </ul> 

        </div>

    </nav>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>