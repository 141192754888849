import { Component, OnInit, ViewEncapsulation, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, MetaDataService, PropertyManagerService } from 'src/providers/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplacePipe } from 'src/providers/common-service/replace-pipe';
import { TitleCasePipe } from '@angular/common';
import * as _ from "lodash";
@Component( {
    selector: 'app-pm-stats-filter',
    templateUrl: './pm-stats-filter.component.html',
    styleUrls: ['./pm-stats-filter.component.scss'],

    encapsulation: ViewEncapsulation.None
} )
export class PMStatsFilterComponent implements OnInit {

    public pageName: string;
    public firstButton: string;
    public secondButton: string;

    @Input() filterPayload: any;

    public pmNameItemList: any = [];
    public actionItemList: any = [];
    public acronymList: any = [];

    tab: any;


    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = ( this.currentYear + 99 );
    public minyear = ( this.currentYear - 99 );
    range: string = ( this.minyear ).toString() + ":" + ( this.maxyear ).toString();
    //public replaceChar= new ReplacePipe();
    constructor( private replaceChar: ReplacePipe, private toTitleCase: TitleCasePipe, private router: Router, private commonService: CommonService, public activeModal: NgbActiveModal,
        public metaDataService: MetaDataService,
        public propertyManagerService: PropertyManagerService) {
        let pageName = 'Filter';
        let firstButton = 'Cancel';
        let secondButton = 'Apply';
        console.log("filterData",this.filterPayload);
    }

    ngOnInit(): void {
        this.tab = 1;
        console.log( "filterData :: ", this.filterPayload );
        this.getPMList();
        if ( this.filterPayload.totalNumberOfResidentsUpForRenewalFrom == 0 ) {
            this.filterPayload.totalNumberOfResidentsUpForRenewalFrom = null;
        }
        if ( this.filterPayload.totalNumberOfResidentsUpForRenewalTo == 0 ) {
            this.filterPayload.totalNumberOfResidentsUpForRenewalTo = null;
        }
        if ( this.filterPayload.hashOfResidentsRenewedForMonthFrom == 0 ) {
            this.filterPayload.hashOfResidentsRenewedForMonthFrom = null;
        }
        if ( this.filterPayload.hashOfResidentsRenewedForMonthTo == 0 ) {
            this.filterPayload.hashOfResidentsRenewedForMonthTo = null;
        }

        if ( this.filterPayload.repaymentPlansCraftedFrom == 0 ) {
            this.filterPayload.repaymentPlansCraftedFrom = null;
        }
        if ( this.filterPayload.repaymentPlansCraftedTo == 0 ) {
            this.filterPayload.repaymentPlansCraftedTo = null;
        }
        if ( this.filterPayload.hashOfTenantsWithBalanceOf15ResidentPaidFrom == 0 ) {
            this.filterPayload.hashOfTenantsWithBalanceOf15ResidentPaidFrom = null;
        }
        if ( this.filterPayload.hashOfTenantsWithBalanceOf15ResidentPaidTo == 0 ) {
            this.filterPayload.hashOfTenantsWithBalanceOf15ResidentPaidTo = null;
        }
        if ( this.filterPayload.hashResidentInEvictionStatusFrom == 0 ) {
            this.filterPayload.hashResidentInEvictionStatusFrom = null;
        }
        if ( this.filterPayload.hashResidentInEvictionStatusTo == 0 ) {
            this.filterPayload.hashResidentInEvictionStatusTo = null;
        }
        if ( this.filterPayload.hashOfResidentInEvictionOnPaymentPlansFrom == 0 ) {
            this.filterPayload.hashOfResidentInEvictionOnPaymentPlansFrom = null;
        }
        if ( this.filterPayload.hashOfResidentInEvictionOnPaymentPlansTo == 0 ) {
            this.filterPayload.hashOfResidentInEvictionOnPaymentPlansTo = null;
        }
        if ( typeof this.filterPayload.pmNameStats == 'object' ) {
            this.filterPayload.pmNameStats = "";
        }
    }

     /**
       * Function to get PM list
       */
      private getPMList = () => {
        this.pmNameItemList = [];
        this.propertyManagerService.getPMEANameIdList(true).subscribe(
          (res) => {
            if (res.code == 200 || res.status == 200) {
              var pmEaNameIdList = res.data;
              for (let i = 0; i <= pmEaNameIdList.length; i++) {
                if(pmEaNameIdList[i]){
                    this.pmNameItemList.push({ label: pmEaNameIdList[i].name, value: pmEaNameIdList[i].name });
                }
            }
              console.log("pmEaNameItemList >>> ::::::: ", this.pmNameItemList);
              this.pmNameItemList = _.cloneDeep(this.pmNameItemList);
            }        
            this.commonService.hideLoading();
          },
          (error) => {
            this.pmNameItemList = [];
            if (error.message) {
              this.commonService.clearToast();
              this.commonService.showError(error.message);
            } else {
              this.commonService.clearToast();
              this.commonService.showError(error.error_description);
            }
            this.commonService.hideLoading();
          }
        );
      }


    /**
     * function on cancel Click
     */
    cancelClick = () => {
        this.activeModal.dismiss( '' );
    }
    /**
       * function on clear Click
       */
      clearFilter = () => {
        this.filterPayload.pmNameStats = "";        
        this.filterPayload.totalNumberOfResidentsUpForRenewalFrom= null;
        this.filterPayload.totalNumberOfResidentsUpForRenewalTo= null;
        this.filterPayload.hashOfResidentsRenewedForMonthFrom= null;
        this.filterPayload.hashOfResidentsRenewedForMonthTo= null;
        this.filterPayload.repaymentPlansCraftedFrom= null;
        this.filterPayload.repaymentPlansCraftedTo= null;
        this.filterPayload.hashOfTenantsWithBalanceOf15ResidentPaidFrom= null;
        this.filterPayload.hashOfTenantsWithBalanceOf15ResidentPaidTo= null;
        this.filterPayload.hashResidentInEvictionStatusFrom= null;
        this.filterPayload.hashResidentInEvictionStatusTo= null;
        this.filterPayload.hashOfResidentInEvictionOnPaymentPlansFrom= null;
        this.filterPayload.hashOfResidentInEvictionOnPaymentPlansTo= null;
    }
    /**
     * function on cancel Click
     */
    applyFilter = () => {
        if ( typeof this.filterPayload.pmNameStats === 'object' ) {
            this.filterPayload.pmNameStats = "";
        }
        console.log( "filterData :: ", this.filterPayload,typeof this.filterPayload.pmNameStats );
        this.activeModal.dismiss( this.filterPayload );
    }

 
    /**
     * Function coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo("Coming soon");    
    }

    /**
     * Function clear coming soon
     */
    public clearCommingSoon = () => {
        this.commonService.clearToast();   
    }

}
