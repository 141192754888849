<!-- Container -->
<div class="form-container filter-form-cls">

  <div class="">

    <!-- Heading -->
    <div class="row filter-heading m-0">
      <div class="col-6">
        <h2 class="form-title"> {{"COMMON_COMPONENT.FILTER" | translate }} </h2>
      </div>
      <div class="col-6">
        <button class="btn link-btn clear-btn" (click)="clearFilter()"> {{"COMMON_COMPONENT.CLEAR_ALL" | translate }}
        </button>
      </div>
    </div>


    <div class="container custom-ver-tabs">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs tabs-left sideways" id="myTab" role="tablist" *ngIf="!this.filterPayload.isPMStatsHistoricalStats">
        <li class="nav-item active" [ngClass]="{active: tab == 1}"><a (click)=" tab=1;" class="nav-link active" href="#pm-v" data-toggle="tab" role="tab"> {{ "REPORTS.PROPERTY_MANAGER" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 2 || this.filterPayload.isPMStatsHistoricalStats}"><a (click)=" tab=2;" [ngClass]="{active: this.filterPayload.isPMStatsHistoricalStats}" class="nav-link" href="#total-number-of-residents-up-for-renewal-v" data-toggle="tab" role="tab"> {{ "REPORTS.TOTAL_HASH_OF_RESIDENTS_UP_FOR_RENEWALS" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 3}"><a (click)=" tab=3;" class="nav-link" href="#hash-of-residents-renewed-for-this-month-v" data-toggle="tab" role="tab"> {{ "REPORTS.HASH_OF_RESIDENTS_RENEWED_FOR_MONTH" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 4}"><a (click)=" tab=4;" class="nav-link" href="#repayment-plans-crafted-v" data-toggle="tab" role="tab"> {{ "REPORTS.REPAYMENT_PLANS_CRAFTED" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 5}"><a (click)=" tab=5;" class="nav-link" href="#hash-of-tenants-with-balance-of-1_5-resident-paid-v" data-toggle="tab" role="tab"> {{ "REPORTS.HASH_OF_RESIDENT_WITH_BALANCE_1_5x_RESIDENT_PAID" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 6}"><a (click)=" tab=6;" class="nav-link" href="#hash-resident-in-eviction-status-v" data-toggle="tab" role="tab"> {{ "REPORTS.RESIDENT_IN_EVICTION_STATUS" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 7}"><a (click)=" tab=7;" class="nav-link" href="#hash-of-resident-in-eviction-on-payment-plans-v" data-toggle="tab" role="tab"> {{ "REPORTS.RESIDENT_IN_EVICTION_OF_PAYMENT_PLAN" | translate }} </a> </li>
      </ul>

      <ul class="nav nav-tabs tabs-left sideways" id="myTab" role="tablist" *ngIf="this.filterPayload.isPMStatsHistoricalStats">
        <li class="nav-item" [ngClass]="{active: tab == 1}"><a (click)=" tab=1;" class="nav-link active" href="#total-number-of-residents-up-for-renewal-v" data-toggle="tab" role="tab"> {{ "REPORTS.TOTAL_HASH_OF_RESIDENTS_UP_FOR_RENEWALS" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 2}"><a (click)=" tab=2;" class="nav-link" href="#hash-of-residents-renewed-for-this-month-v" data-toggle="tab" role="tab"> {{ "REPORTS.HASH_OF_RESIDENTS_RENEWED_FOR_MONTH" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 3}"><a (click)=" tab=3;" class="nav-link" href="#repayment-plans-crafted-v" data-toggle="tab" role="tab"> {{ "REPORTS.REPAYMENT_PLANS_CRAFTED" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 4}"><a (click)=" tab=4;" class="nav-link" href="#hash-of-tenants-with-balance-of-1_5-resident-paid-v" data-toggle="tab" role="tab"> {{ "REPORTS.HASH_OF_RESIDENT_WITH_BALANCE_1_5x_RESIDENT_PAID" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 5}"><a (click)=" tab=5;" class="nav-link" href="#hash-resident-in-eviction-status-v" data-toggle="tab" role="tab"> {{ "REPORTS.RESIDENT_IN_EVICTION_STATUS" | translate }} </a></li>
        <li class="nav-item" [ngClass]="{active: tab == 6}"><a (click)=" tab=6;" class="nav-link" href="#hash-of-resident-in-eviction-on-payment-plans-v" data-toggle="tab" role="tab"> {{ "REPORTS.RESIDENT_IN_EVICTION_OF_PAYMENT_PLAN" | translate }} </a> </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div class="tab-pane" id="pm-v" *ngIf="!this.filterPayload.isPMStatsHistoricalStats" [ngClass]="{active: filterPayload}" role="tabpanel">
          <!-- PM content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <p-dropdown class="form-dropdown" name="pmFilter" placeholder="Select" [options]="pmNameItemList" [(ngModel)]="filterPayload.pmNameStats"></p-dropdown>
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="total-number-of-residents-up-for-renewal-v" role="tabpanel" [ngClass]="{active: this.filterPayload.isPMStatsHistoricalStats}">
          <!-- total unit content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalNumberOfResidentsUpForRenewalFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.totalNumberOfResidentsUpForRenewalTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="hash-of-residents-renewed-for-this-month-v" role="tabpanel">
          <!-- total occupied unit content...  -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.hashOfResidentsRenewedForMonthFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.hashOfResidentsRenewedForMonthTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="repayment-plans-crafted-v" role="tabpanel">
          <!-- total rent roll content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.repaymentPlansCraftedFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.repaymentPlansCraftedTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="hash-of-tenants-with-balance-of-1_5-resident-paid-v" role="tabpanel">
          <!-- total resident paid rent content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.hashOfTenantsWithBalanceOf15ResidentPaidFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.hashOfTenantsWithBalanceOf15ResidentPaidTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="hash-resident-in-eviction-status-v" role="tabpanel">
          <!-- total subsidized paid rent content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.hashResidentInEvictionStatusFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.hashResidentInEvictionStatusTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

        <div class="tab-pane" id="hash-of-resident-in-eviction-on-payment-plans-v" role="tabpanel">
          <!-- total collected content... -->
          <form class="form-inline crud-form filter-form">
            <div class="row">
              <div class="col-sm-12 form-group">
                <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="filterPayload.hashOfResidentInEvictionOnPaymentPlansFrom" placeholder="From" />
              </div>
              <div class="col-sm-12 form-group">
                <input type="number" id="to" name="to" class="form-control form-input" min="0" [(ngModel)]="filterPayload.hashOfResidentInEvictionOnPaymentPlansTo" placeholder="To" />
              </div>
            </div>
          </form>
        </div>

      </div>

      <div class="clearfix"></div>

    </div>

    <!--------------- modal btns  --------->
    <div class="row d-flex modal-footer m-0">
      <button type="button" class="btn link-btn" (click)="cancelClick()">
        {{"COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
      <button type="submit" class="btn common-btn" (click)="applyFilter()">
        {{"COMMON_COMPONENT.APPLY_BUTTON" | translate }} </button>
    </div>


  </div>
</div>