/**
 * Rent Roll Related Services
 * Created By : #1116
 * Date : 18 January, 2021
 */


import { Injectable } from '@angular/core';
import { AppSettings, RestService } from '../providers';
import { Observable, timer, throwError } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";

@Injectable( {
    providedIn: 'root'
} )
export class RentRollService {

    constructor( private restService: RestService ) { }

    /**
      * Function to get Rent Roll data list
      */
    public getRentRollList( param ): Observable<any> {
        const path = AppSettings.RENT_ROLL_URL + "?size=" +
            param.pageSize +
            "&page=" +
            param.pageNumber;
        return this.restService.postCall( path, param, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /**
     * Function to export Rent Roll table data
     */
    public exportRentRoll( param, reportFormat,userId ): Observable<any> {
        const path = AppSettings.RENT_ROLL_EXPORT_URL + "?reportFormat=" +
            reportFormat+
            "&userId=" +
            userId;
        return this.restService.postCall( path, param, false, 180000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

}
