<div class="background-image-wrapper">

    <!-- Container -->
    <div class="form-container">

        <div class="">
            <!-- Logo -->
            <div>
                <img class="app-logo text-center" src="assets/images/rentdex-logo.png">
            </div>

            <!-- Heading -->
            <div class="text-center col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                <h2 class="form-title"> {{ "VERIFICATION_CODE.VERIFICATION_CODE" | translate }} </h2>
                <p class="form-subtitle mar-btm10"> {{ "VERIFICATION_CODE.PLEASE_CHECK_EMAIL_AND_VERIFICATION_CODE" | translate }} <br> {{ "VERIFICATION_CODE.ENTER_BELOW" | translate }}</p>
            </div>

            <!-- Form -->

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 p-0">
                <form class="common-form-cls" #otpVerificationForm="ngForm" (ngSubmit)="emailVerification(otpVerificationForm)" novalidate>
                    <!--------------- Email Field  --------->
                    <div class="p-field form-group input-group">
                        <!-- <input type="text" class="from-input pass-input" id="form-email" maxlength="6" pattern="\d{6}" [(ngModel)]="verifyData.otp" name="email" #loginEmail="ngModel" autocapitalize="off " aria-describedby="Email-help" pInputText required> -->
                        <input class="otp-text" id="otp1" name="otp1" (keyup)="keytab($event,1)" type="text" #loginEmail1="ngModel" [(ngModel)]="verifyAllData.otp1" maxlength="1" pattern="\d{1}" pInputText required>
                        <input class="otp-text" id="otp2" name="otp2" (keyup)="keytab($event,2)" type="text" #loginEmail2="ngModel" [(ngModel)]="verifyAllData.otp2" maxlength="1" pattern="\d{1}" pInputText required>
                        <input class="otp-text" id="otp3" name="otp3" (keyup)="keytab($event,3)" type="text" #loginEmail3="ngModel" [(ngModel)]="verifyAllData.otp3" maxlength="1" pattern="\d{1}" pInputText required>
                        <input class="otp-text" id="otp4" name="otp4" (keyup)="keytab($event,4)" type="text" #loginEmail4="ngModel" [(ngModel)]="verifyAllData.otp4" maxlength="1" pattern="\d{1}" pInputText required>
                        <input class="otp-text" id="otp5" name="otp5" (keyup)="keytab($event,5)" type="text" #loginEmail5="ngModel" [(ngModel)]="verifyAllData.otp5" maxlength="1" pattern="\d{1}" pInputText required>
                        <input class="otp-text" id="otp6" name="otp6" (keyup)="keytab($event,6)" type="text" #loginEmail6="ngModel" [(ngModel)]="verifyAllData.otp6" maxlength="1" pattern="\d{1}" pInputText required>

                        <div class="text-left" *ngIf="(otpVerificationForm.dirty || otpVerificationForm.touched || formSubmitted)">
                            <small *ngIf="(verifyData.otp.length == 0 )" class="p-invalid error-msg"> {{ "VERIFICATION_CODE.PLEASE_ENTER_OTP" | translate }}
                        </small>
                            <small *ngIf="(verifyData.otp.length < 6  && verifyData.otp.length  > 0)" class="p-invalid error-msg"> {{ "VERIFICATION_CODE.PLEASE_VALID_ENTER_OTP" | translate }}
                        </small>
                        </div>
                    </div>

                    <small (click)="resendOtp()" class=" pointer resend-cls"> {{ "VERIFICATION_CODE.RESEND_VERIFICATION_CODE" | translate }} </small>

                    <!--------------- submit btn  --------->
                    <div>
                        <button type="submit " class="btn common-btn full-width "> {{ "COMMON_COMPONENT.SUBMIT_BUTTON" | translate }} </button>
                    </div>

                    <div>
                        <a [routerLink]="''" class="forgot-text text-center"> {{ "VERIFICATION_CODE.BACK_TO_LOGIN" | translate }} </a>
                    </div>

                </form>
                <!--------------- end of form  --------->
            </div>
        </div>
    </div>

    <p-toast class="toast-card" position="top-center"></p-toast>

</div>