import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/index';

@Injectable()
export class AppService {
  private title = new BehaviorSubject<String>('Dashboard');
  private title$ = this.title.asObservable();

  private preTitle = new BehaviorSubject<String>('');
  private preTitle$ = this.preTitle.asObservable();

  constructor() {}

  setTitle(title: String) {
    this.title.next(title);
  }
  setPreTitle(preTitle: String) {
    this.preTitle.next(preTitle);
  }
  
  getTitle(): Observable<String> {
    return this.title$;
  }

  getPreTitle(): Observable<String> {
    return this.preTitle$;    
  }


}