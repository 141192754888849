<div class="row page-wrap">
    <div class="container-fluid">
        


        <div class="row m-0">
            <div class="dashboard-tab-wrap container-fluid p-0">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs full-width">
                    <!-- <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#tab-1" (click)="getDelinquenciesList(); isDeliquence=true">Today’s Delinquent Tenants</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tab-2" (click)="getRenewalList(); isDeliquence=false">Today’s Renewable Tenants</a>
                    </li> -->
                    <li class="search-wrap float-right">
                        <div class="form-group has-search common-search-box">
                            <span class="form-control-feedback"><i class="search-icon search-icon-cls"></i></span>
                            <input type="text" id="search-bar" class="form-control" spellcheck="false" autocomplete="false" name="searchUser" placeholder="Search by Name/Address/Unit" pInputText 
                            [(ngModel)]="rentRollPayload.searchText" (input)="isSearchCall=true;getRentRollList($event);" />
                        </div>
                        <button class="btn btn-icon-bg" (click)="openExportPopup()"><i class="forward-icon filter-icon-cls" title="Export"></i></button>
                        <button class="btn btn-icon-bg" (click)="clickOnFilter($event)"><i class="filter-icon filter-icon-cls" title="Filter"></i></button>
                    </li>

                </ul>

                <!-- Tab panes -->
                <div class="tab-content full-width">
                    <div id="tab-1" class="container-fluid tab-pane active">
                        <div class="card clearfix">

                            <p-table class="custom-table-cls" #dt [value]="rentRollList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 295px)'" [responsive]="true" (onLazyLoad)="customSort($event,'DELINQUENCY')" [lazy]="true">
                                <!-- [loading]="loading"  Add this for Inplace loader-->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th style="width: 3rem">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th> -->
                                        <th> {{ "RENT_ROLL.ADDRESS" | translate }} </th>
                                        <th pSortableColumn="name"> {{ "RENT_ROLL.NAME" | translate }}
                                            <p-sortIcon field="name"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="unit"> {{ "RENT_ROLL.UNIT" | translate }}
                                            <p-sortIcon field="unit"></p-sortIcon>

                                        </th>
                                        <th> {{ "RENT_ROLL.RENEWAL_STATUS" | translate }} </th>
                                        <th> {{ "RENT_ROLL.PM_STATUS" | translate }} </th>
                                        <th> {{ "RENT_ROLL.EVICTION_STATUS" | translate }} </th> 
                                        <th pSortableColumn="totalRent"> {{ "RENT_ROLL.TOTAL_RENT" | translate }}
                                            <p-sortIcon field="totalRent"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="tenantBalance"> {{ "RENT_ROLL.TENANT_BALANCE" | translate }}
                                            <p-sortIcon field="tenantBalance"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="subsidyAmount"> {{ "RENT_ROLL.SUBSIDY_RENT" | translate }}
                                            <p-sortIcon field="subsidyAmount"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="pendingRent"> {{ "RENT_ROLL.PENDING_RENT" | translate }}
                                            <p-sortIcon field="pendingRent"></p-sortIcon>
                                        </th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rentRoll>
                                    <tr>
                                        <!-- <td>
                                        <p-tableCheckbox [value]="product"></p-tableCheckbox>
                                    </td> -->
                                        <td title="{{rentRoll.address}}">{{rentRoll.address? rentRoll.address: '-'}}</td>
                                        <td title="{{rentRoll.name}}" (click)="openTenantDetails(rentRoll.unitId)" [ngClass]="!rentRoll.name? 'rmLine': ''" class="hyperlink-cls pointer">{{rentRoll.name? rentRoll.name: '-'}}</td>
                                        <td title="{{rentRoll.unit}}">{{rentRoll.unit? rentRoll.unit: '-'}}</td>
                                        <td title="{{rentRoll.renewalStatus | replace : '_' : ' ' | titlecase}}">{{ rentRoll.renewalStatus ? (rentRoll.renewalStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{rentRoll.pmStatus | replace : '_' : ' ' | titlecase}}">{{ rentRoll.pmStatus ? (rentRoll.pmStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{rentRoll.evictionStatus | replace : '_' : ' ' | titlecase }}">{{ rentRoll.evictionStatus ? (rentRoll.evictionStatus | replace : '_' : ' ' | titlecase) : '-' }}</td>
                                        <td title="{{rentRoll.totalRent | currency:'USD':'symbol' :'1.2-2'}}">{{ rentRoll.totalRent ? (rentRoll.totalRent | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{rentRoll.tenantRent | currency:'USD':'symbol' :'1.2-2'}}">{{ rentRoll.tenantRent ? (rentRoll.tenantRent | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{rentRoll.subsidyRent | currency:'USD':'symbol' :'1.2-2'}}">{{ rentRoll.subsidyRent ? (rentRoll.subsidyRent | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>
                                        <td title="{{rentRoll.pendingRent | currency:'USD':'symbol' :'1.2-2'}}">{{ rentRoll.pendingRent ? (rentRoll.pendingRent | currency:'USD':'symbol' :'1.2-2') : '-' }}</td>

                                    </tr>                 
                                </ng-template>
                            </p-table>
                            <div class="control-label" *ngIf="rentRollList && rentRollList.length == 0">
                                <div class="no-data-found" *ngIf="!loading" text-center>
                                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                                </div>
                          </div>
                        </div>
                        <p-paginator styleClass="ui-paginator-bottom" [rows]="25" #p  (onLazyLoad)="rentRollpaginate($event)" (onPageChange)="rentRollpaginate($event)" [totalRecords]="rentRollPayload.totalRecords" class="list-paginator table-paginator"></p-paginator>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>