import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from "@angular/core";
import * as HighCharts from 'highcharts';
import { DashboardFilterComponent } from '../dashboard-filter/dashboard-filter.component';
import { AppService } from './../../../app/app.service';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';

import {
    CommonService,
    HeaderService,
    PropertyManagerService,
    SidebarService,
    HighchartServiceService,
    LocalStorageService,
    Constants,
    AuthService
} from "src/providers/providers";
import { LazyLoadEvent } from 'primeng/api';
import { ExportPopupComponent } from 'src/pages/export-popup/export-popup.component';
import { ViewRenewalComponent } from '../view-renewal/view-renewal.component';
import { ViewDelinquencyComponent } from './../view-delinquency/view-delinquency.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TenantProfileComponent } from './../../tenant-profile/tenant-profile.component';
import { Paginator } from "primeng/paginator";

@Component( {
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    encapsulation: ViewEncapsulation.None
} )
export class DashboardComponent implements OnInit {
    @Input()
    loading1: boolean = true;
    @Input()
    loading2: boolean = true;
    @ViewChild('p', {static: false}) paginator: Paginator;
    isDashboard: boolean = true;
    isGoalData: boolean;
    filterData: any = {};

    //Goals Data Bind Parameter
    public dashboardGoalsPieChartData = {
        'rentGoalPercentage': 0,
        'subsidyGoalPercentage': 0,
        'actualRentPercentage': 0,
        'actualSubsidyPercentage': 0,
        'actualRent': 0,
        'actualSubsidy': 0
    };

    pageNumber: number = 0;

    renewalDefaultPage = 0;
    renewalDefaultSize = 10;

    delinquenciesDefaultPage = 0;
    delinquenciesDefaultSize = 10;
    public checkValueData: boolean;
    public password = "";

    // Today's table data
    public deliquenciesTenantList: any = [];
    isDeliquenciesNoData: boolean = false;
    public renewalsTenantList: any = [];
    isRenewalsNoData: boolean = false;
    public isDeliquence: boolean = true;
    public renewalPayload: any = {
        evictionStatus: "",
        renewalStatus: "",
        rentFrom: 0,
        rentTo: 0,
        searchText: "",
        targetRentFrom: 0,
        targetRentTo: 0,
        totalAmountFrom: 0,
        totalAmountTo: 0,
        unitId: "",
        pageNumber: 0,
        pageSize: 10,
        sortBy: "",
        sortManner: "",
        leaseExpirationFrom: null,
        leaseExpirationTO: null
    };

    public deliquenciesPayload: any = {
        lastActionTaken: "",
        evictionStatus: "",
        promiseToPayFrom: null,
        promiseToPayTo: null,
        pmStatus: "",
        subsidyFrom: 0,
        subsidyTo: 0,
        searchText: "",
        tenantAmountFrom: 0,
        tenantAmountTo: 0,
        totalAmountFrom: 0,
        totalAmountTo: 0,
        unitId: "",
        propertyId: "",
        pageNumber: 0,
        pageSize: 10,
        sortBy: "",
        sortManner: ""
    };
    public userData: any;
public isMobile :boolean = false;
public valueCheckI: any = 'All';
deviceInfo = null;


    constructor(
        public header: HeaderService,
        public sidebar: SidebarService,
        protected pmService: PropertyManagerService,
        protected commonService: CommonService,
        private highchartServiceService: HighchartServiceService,
        private appService: AppService,
        public localStr: LocalStorageService,
        private location: LocationStrategy,
        private router: Router,
        private deviceService: DeviceDetectorService,
        public constants: Constants,
        public authService: AuthService
    ) {
        this.deliquenciesPayload.pageSize = this.constants.pageSize;
        this.renewalPayload.pageSize = this.constants.pageSize;
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isMobile = this.deviceService.isMobile();

    }
    
    ngOnInit(): void {
        //Get Delequency list data
        this.getDelinquenciesList();
        if(this.isMobile){
            this.sidebar.hide();           
        } else {
            var isSliderShow = this.localStr.getObj("isSliderShow");
            if(isSliderShow){
                this.sidebar.show();
                this.localStr.setObj("isSliderShow", true);
            } else {
                this.sidebar.hide();
                this.localStr.setObj("isSliderShow", false);
            }
        }
        this.header.show();
        this.appService.setTitle( 'Dashboard' );

        //Get Goals Data
        this.getGoalsPercentageData();
        
        this.localStr.setObj("breadcrum", "");
        this.userData = this.localStr.getObj( 'userData' );

        
        if(!this.isMobile){
            history.pushState( null, null, window.location.href );
            this.location.onPopState(() => {
                history.pushState( null, null, window.location.href );
            } );
        } else { 
           
            history.pushState( null, null, window.location.href );
            this.location.onPopState(() => {
                console.log("hash>>>2", window.location.hash);
                if(window.location.hash == "#/property-manager-dashboard") {
                    if(this.localStr.getObj('valueCheckI')){
                        this.valueCheckI = this.localStr.getObj('valueCheckI');
                    } else {
                        this.valueCheckI = 'All';
                    }

                    console.log("this.valueCheckI 111>>>", this.valueCheckI);
                    if(this.valueCheckI == 'All'){
                        this.localStr.setObj('valueCheckI', 'Out');
                        this.logoutClick();
                    }
                } else if(window.location.hash == "#/") {
                    console.log("login >>> ");
                    this.router.navigate(['/'], { replaceUrl: true })
                }
            } );
        }
    }


    /**
     * Function to logout from application
     */
    logoutClick = () => {
            console.log("this.valueCheckI 2222>>>", this.valueCheckI);
            
        let titleText = "Rentdex";
        let messageText = "Are you sure you want to logout?";
        let yesText = "YES";
        let noText = "NO";
        this.commonService.showConfirm(titleText, messageText, noText, yesText, () => {
            console.log("Pop open 1");               
        }, () => {
            console.log("enter");
            this.commonService.showLoading();
            this.authService.logout( this.userData ).subscribe(
                res => {
                    this.commonService.hideLoading();
                    if ( res.code == 200 || res.status == 200 ) {
                        this.userData = this.localStr.getObj('userData');
                        this.password = this.localStr.getObj("password");
                        this.checkValueData = this.localStr.getObj("checkValue");
                        var deviceTokenId =  this.localStr.getObj("deviceTokenId");
                        window.localStorage.clear(); //try this to clear all local storage
                        this.userData.isLoggedInUser = false; 
                        this.localStr.setObj('userData', this.userData);
                        this.localStr.setObj("password", this.password);
                        this.localStr.setObj("checkValue", this.checkValueData);
                        this.localStr.setObj("isSliderShow", true);
                        this.localStr.setObj("deviceTokenId", deviceTokenId);
                        this.router.navigate([''], res);
                    }
                },
                error => {
                    this.commonService.hideLoading();
                    console.log( "HeaderComponent :: logoutClick :: error :: ", error );
                    if ( error.message ) {
                        this.commonService.clearToast();
                        this.commonService.showError(error.message);
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError(error.error_description);
                    }
                }
            );
        }, () => {
        });
    }

    /**
     * Function to get Pie Chart data
     **/
    private setRentCollectedData = ( divElement, pieChartData, isActualRentCollectionFlag ) => {
        HighCharts.chart( divElement, this.initializePMDashboardPieChartConfiguration( pieChartData, isActualRentCollectionFlag ) );
    }

    /** 
     * TODO - Dynamic change for parameters - Code refactor
     * Function to get Pie chart configuration for PM Dashboard Rent Collected 
     */
    private initializePMDashboardPieChartConfiguration = ( pieChartData, isActualRentCollectionFlag ) => {
        let pieChartConfig;
        let seriesData = [];
        let seriesColors = this.highchartServiceService.getDefaultPMDashboardPieChartColors();
        let filledColor;
        if ( isActualRentCollectionFlag ) {
            filledColor = seriesColors[1];
        } else {
            filledColor = seriesColors[2];
        }

        seriesData = [{
            y: pieChartData,
            color: filledColor
        },
        {
            y: 100 - pieChartData,
            color: seriesColors[0]
        }
        ]
        pieChartConfig = this.highchartServiceService.initializePMDashboardPieChartConfiguration( seriesData );
        return pieChartConfig;
    }

    /**
   * Function for renewals list pagination
   */
    public renewalpaginate = ( event ) => {
        this.renewalDefaultPage = event.first / event.rows;
        this.renewalDefaultSize = event.rows;
        this.renewalsTenantList = [];
        this.renewalPayload.pageSize = this.constants.pageSize;
        this.renewalPayload.pageNumber = this.renewalDefaultPage;
        this.getRenewalList();
    }


    /**
     * Function to get renewals list
     *
     */
    public getRenewalList() {
        if(this.isMobile){
            this.renewalPayload.pageSize = this.constants.defaultPageSize;
        }
        if ( this.renewalPayload.sortBy == "" || this.renewalPayload.sortManner == "" ) {
            this.renewalPayload.sortBy = "";
            this.renewalPayload.sortManner = "";
        }

        if(this.localStr.getObj("dataCheck") == 'renewals' && this.localStr.getObj("beforeActivityData")){
            this.renewalPayload = this.localStr.getObj("beforeActivityData");
        }

        this.commonService.showLoading(); // remove this when we use inplace loader 
        this.loading2 = true;
        this.pmService.getRenewalList( this.renewalPayload ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    this.renewalsTenantList = res.data.list;
                    for(let i=0; i < this.renewalsTenantList.length; i++){
                        if(this.renewalsTenantList[i].marketRent){
                            this.renewalsTenantList[i].marketRent = '$' + this.renewalsTenantList[i].marketRent;
                        }
                    }
                    if ( this.renewalsTenantList && this.renewalsTenantList.length == 0 ) {
                        this.isRenewalsNoData = true;
                    } else {
                        this.isRenewalsNoData = false;
                    }

                    this.renewalPayload.totalRecords = res.data.totalCount;
                    this.localStr.setObj("beforeActivityData", "");
                } else {
                    this.renewalsTenantList = [];
                }
                this.loading2 = false;
            },
            ( error ) => {
                this.commonService.hideLoading();
                this.renewalsTenantList = [];
                this.isRenewalsNoData = true;
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
                this.loading2 = false;
            }
        );
        this.commonService.hideLoading();
    }


    /**
     * Function for Delinquencies list pagination
     */
    public delinquenciespaginate = ( event ) => {
        let currentPage: number;
        this.delinquenciesDefaultPage = event.first / event.rows;
        this.delinquenciesDefaultSize = event.rows;
        this.deliquenciesTenantList = [];
        this.deliquenciesPayload.pageSize = this.constants.pageSize;
        this.deliquenciesPayload.pageNumber = this.delinquenciesDefaultPage;
        this.getDelinquenciesList();
    }

    /**
     * Function to get deliquencies list
     */
    public getDelinquenciesList() {
        console.log("this.deliquenciesPayload....", this.deliquenciesPayload);
        
        if(this.isMobile){
            this.deliquenciesPayload.pageSize = this.constants.defaultPageSize;
        }

        if ( this.deliquenciesPayload.sortBy == "" || this.deliquenciesPayload.sortManner == "" ) {
            this.deliquenciesPayload.sortBy = "";
            this.deliquenciesPayload.sortManner = "";
        }

        // check data after visiting activity log
        if(this.localStr.getObj("beforeActivityData")){
            this.deliquenciesPayload = this.localStr.getObj("beforeActivityData");
        }

        // if(this.isGoalData != true){
        //     this.commonService.showLoading();   // Add this when we use inplace loader
        // }
        this.commonService.showLoading(); // remove this when we use inplace loader
        this.loading1 = true;
        this.pmService.getDelinquenciesList( this.deliquenciesPayload ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    this.deliquenciesTenantList = res.data.list;
                    if ( this.deliquenciesTenantList && this.deliquenciesTenantList.length == 0 ) {
                        this.isDeliquenciesNoData = true;
                    } else {
                        this.isDeliquenciesNoData = false;
                    }

                    this.deliquenciesPayload.totalRecords = res.data.totalCount;
                    this.localStr.setObj("beforeActivityData", "");

                } else {
                    this.deliquenciesTenantList = [];
                }
                this.loading1 = false;
            },
            ( error ) => {
                this.commonService.hideLoading();
                this.deliquenciesTenantList = [];
                this.isDeliquenciesNoData = true;
                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
                this.loading1 = false;
            }
        );
        this.commonService.hideLoading();
    }

    /**
     * Function to  deliquencies export
     */
    public exportDelinquencies( reportFormat ) {
        this.commonService.showLoading();

        this.pmService.exportDelenquencies( this.deliquenciesPayload, reportFormat, this.userData.id, this.isDashboard ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "res", res );
                    // window.open( res.data )
                    window.location.assign (res.data);
                    //this.commonService.clearToast();
                    //this.commonService.showSuccess(res.message);
                }

            },
            ( error ) => {
                this.commonService.hideLoading();

                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    /**
     * Function to  deliquencies export
     */
    public exportRenewal( reportFormat ) {
        this.commonService.showLoading();

        this.pmService.exportRenewal( this.renewalPayload, reportFormat, this.userData.id, this.isDashboard ).subscribe(
            ( res ) => {
                this.commonService.hideLoading();
                if ( res.code == 200 || res.status == 200 ) {
                    console.log( "res", res );

                    //this.commonService.clearToast();
                    // window.open( res.data )
                    window.location.assign (res.data);
                    //this.commonService.showSuccess(res.message);
                }

            },
            ( error ) => {
                this.commonService.hideLoading();

                if ( error.message ) {
                    this.commonService.clearToast();
                    this.commonService.showError( error.message );
                } else {
                    this.commonService.clearToast();
                    this.commonService.showError( error.error_description );
                }
            }
        );
    }

    public loadData = ( event ) => {
        this.pageNumber = this.pageNumber + 1;
        this.deliquenciesPayload.page = this.pageNumber;
        this.getDelinquenciesList();
    }

    /**
     * Function coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo( "Coming soon" );
    }

    checkTypeOf = ( dataCheck ) => {
        if ( typeof dataCheck == 'object' ) {
            return dataCheck = "";
        } else {
            return dataCheck;
        }
    }

    /**
     * Function for open fiter popup
     */
    public clickOnFilter = ($event) => {
        console.log( "click ---------------------------------------------------", this.isDeliquence, this.filterData );
        this.filterData = {};
        if ( this.isDeliquence ) {

            this.filterData = this.deliquenciesPayload;
            this.filterData.isDeliquenceDashboard = this.isDeliquence;
            this.commonService.openConfirmModal( DashboardFilterComponent, this.filterData, ( data ) => {

                this.deliquenciesPayload.pmStatus = this.checkTypeOf( this.deliquenciesPayload.pmStatus );
                this.deliquenciesPayload.evictionStatus = this.checkTypeOf( this.deliquenciesPayload.evictionStatus );
                this.deliquenciesPayload.lastActionTaken = this.checkTypeOf( this.deliquenciesPayload.lastActionTaken );


                if ( data ) {
                    this.deliquenciesPayload.acronym = data.acronym;
                    this.deliquenciesPayload.evictionStatus = data.evictionStatus;
                    this.deliquenciesPayload.lastActionTaken = data.lastActionTaken;
                    this.deliquenciesPayload.pmStatus = data.pmStatus;
                    this.deliquenciesPayload.promiseToPayFrom = data.promiseToPayFrom;
                    this.deliquenciesPayload.promiseToPayTo = data.promiseToPayTo;
                    this.deliquenciesPayload.tenantAmountFrom = data.tenantAmountFrom;
                    this.deliquenciesPayload.tenantAmountTo = data.tenantAmountTo;
                    this.deliquenciesPayload.totalAmountFrom = data.totalAmountFrom;
                    this.deliquenciesPayload.totalAmountTo = data.totalAmountTo;
                    this.deliquenciesPayload.pageNumber = 0;
                    this.paginator.changePageToFirst($event);
                    this.getDelinquenciesList()
                }

            } );

        } else {
            this.filterData = this.renewalPayload
            this.filterData.isRenewalDashboard = true;
            this.commonService.openConfirmModal( DashboardFilterComponent, this.filterData, ( data ) => {
                console.log( "filtrdata", data, data ?.leaseExpirationFrom ?.getTime() );


                this.renewalPayload.renewalStatus = this.checkTypeOf( this.renewalPayload.renewalStatus );
                this.renewalPayload.unitId = this.checkTypeOf( this.renewalPayload.unitId );


                if ( typeof this.renewalPayload.renewalStatus == 'object' ) {
                    this.renewalPayload.renewalStatus = "";
                }
                if ( typeof this.renewalPayload.unitId == 'object' ) {
                    this.renewalPayload.unitId = "";
                }

                if ( data ) {

                    // this.renewalPayload.leaseExpirationFrom = data.leaseExpirationFrom ? data?.leaseExpirationFrom?.getTime() : null;
                    // this.renewalPayload.leaseExpirationTO = data.leaseExpirationTO ? data?.leaseExpirationTO?.getTime() : null;
                    // console.log( "renewalPayload", this.renewalPayload );
                    this.renewalPayload.acronym = data.acronym;
                    this.renewalPayload.leaseExpirationFrom = data.leaseExpirationFrom;
                    this.renewalPayload.leaseExpirationTO = data.leaseExpirationTO;
                    this.renewalPayload.renewalStatus = data.renewalStatus;
                    this.renewalPayload.rentFrom = data.rentFrom;
                    this.renewalPayload.rentTo = data.rentTo;
                    this.renewalPayload.subsidyFrom = data.subsidyFrom;
                    this.renewalPayload.subsidyTo = data.subsidyTo;
                    this.renewalPayload.targetRentFrom = data.targetRentFrom;
                    this.renewalPayload.targetRentTo = data.targetRentTo;
                    this.renewalPayload.unitId = data.unitId;
                    this.renewalPayload.pageNumber = 0;
                    this.paginator.changePageToFirst($event);
                    this.getRenewalList()

                }

            } );

        }

    }

    customSort( data: LazyLoadEvent, type ) {
        console.log( "sort", data );
        if ( type == "DELINQUENCY" && data ) {
            switch ( data.sortField ) {
                case "name":
                    this.deliquenciesPayload.sortBy = "NAME";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;
                case "amountReceivable":
                    this.deliquenciesPayload.sortBy = "TOTALBALANCE";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;
                case "tenantBalance":
                    this.deliquenciesPayload.sortBy = "TENANTBALANCE";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;
                case "actionItem":
                    this.deliquenciesPayload.sortBy = "ACTIONTAKEN";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;
                default:
                    this.deliquenciesPayload.sortBy = "";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.deliquenciesPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.deliquenciesPayload.sortManner = "ASC";
                            break;
                        default:
                            this.deliquenciesPayload.sortManner = "";
                            break;

                    }
                    break;



            }

            this.getDelinquenciesList();
        } else {
            switch ( data.sortField ) {
                case "tenant":
                    this.renewalPayload.sortBy = "NAME";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.renewalPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.renewalPayload.sortManner = "ASC";
                            break;
                        default:
                            this.renewalPayload.sortManner = "";
                            break;

                    }
                    break;
                case "unit":
                    this.renewalPayload.sortBy = "UNIT";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.renewalPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.renewalPayload.sortManner = "ASC";
                            break;
                        default:
                            this.renewalPayload.sortManner = "";
                            break;

                    }
                    break;
                case "rent":
                    this.renewalPayload.sortBy = "RENT";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.renewalPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.renewalPayload.sortManner = "ASC";
                            break;
                        default:
                            this.renewalPayload.sortManner = "";
                            break;

                    }
                    break;
                case "leaseTo":
                    this.renewalPayload.sortBy = "LEASEEXPIRATION";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.renewalPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.renewalPayload.sortManner = "ASC";
                            break;
                        default:
                            this.renewalPayload.sortManner = "";
                            break;

                    }
                    break;
                default:
                    this.renewalPayload.sortBy = "";
                    switch ( data.sortOrder ) {
                        case 1:
                            this.renewalPayload.sortManner = "DESC";
                            break;
                        case -1:
                            this.renewalPayload.sortManner = "ASC";
                            break;
                        default:
                            this.renewalPayload.sortManner = "";
                            break;

                    }
                    break;



            }

            this.getRenewalList();
        }

    }


    /**
     *  Function for Export dialog 
     */
    openExportPopup() {
        this.commonService.openConfirmModal( ExportPopupComponent, this.filterData, ( data ) => {
            console.log( "log..data", this.filterData, data );
            if ( data.format ) {

                if ( this.isDeliquence ) {

                    this.exportDelinquencies( data.format );
                } else {
                    this.exportRenewal( data.format )
                }
            }
        } );
    }

    /**
     * Function to get Goals Percentage data
     */
    private getGoalsPercentageData = () => {
        this.commonService.showLoading();
        const date = new Date();
        var currentMonth = (date.getMonth() + 1);
        console.log("month:", currentMonth);
        
        this.pmService.getGoalsData(currentMonth).subscribe(
            ( res ) => {
                this.commonService.hideLoading();

                if ( res.code == 200 || res.status == 200 ) {
                    this.dashboardGoalsPieChartData = res.data;
                    this.isGoalData = true;
                    if ( Object.keys( this.dashboardGoalsPieChartData ).length ) {
                        Object.keys( this.dashboardGoalsPieChartData ).forEach( key => {
                            this.dashboardGoalsPieChartData[key] = Math.round( this.dashboardGoalsPieChartData[key] );
                        } );
                    }

                    //Tenant Rent Collected Set Pie Chart Configuration
                    this.setRentCollectedData( 'd-pie-1', this.dashboardGoalsPieChartData.actualRentPercentage, true );
                    this.setRentCollectedData( 'd-pie-2', this.dashboardGoalsPieChartData.rentGoalPercentage, false );

                    //Subsidized Rent Collected Set Pie Chart Configuration
                    this.setRentCollectedData( 'd-pie-3', this.dashboardGoalsPieChartData.actualSubsidyPercentage, true );
                    this.setRentCollectedData( 'd-pie-4', this.dashboardGoalsPieChartData.subsidyGoalPercentage, false );
                }
            },
            ( error ) => {
                this.commonService.hideLoading();
                this.isGoalData = true;
            }
        );
    }

    /**
     * Function to open Delinquency details
     */
    public openDelinquencyDetails = ( deliquenciesTenant ) => {
        let requestPayLoad;
        console.log( "deliquenciesTenant", deliquenciesTenant );

        this.commonService.openConfirmModal( ViewDelinquencyComponent, this.commonService.copy( deliquenciesTenant ), ( data ) => {

            if ( data ) {
                requestPayLoad = {
                    "delinquencyNotes": data.delinquencyNotes,
                    "id": data.id,
                    "pmStatus": data.pmStatus,
                    "actionItem": data.lastActionTaken,
                    "isMobile": this.isMobile,
                    "promiseToPay": data.promiseToPay
                }
                console.log( "requestPayLoad", requestPayLoad );

                if((deliquenciesTenant.pmStatus == requestPayLoad.pmStatus) && (deliquenciesTenant.lastActionTaken == requestPayLoad.actionItem) && (deliquenciesTenant.mostRecentNotes == requestPayLoad.delinquencyNotes) && 
                (deliquenciesTenant.promiseToPay == requestPayLoad.promiseToPay)){
                    console.log("No channge");                   
                 } else {
                     this.updateStatus( requestPayLoad );
                 }
                
                // if((deliquenciesTenant.pmStatus !="" && requestPayLoad.pmStatus == "") || (deliquenciesTenant.lastActionTaken !="" && requestPayLoad.actionItem == "")){
                //     console.log("No channge");                   
                //  } else {
                //      this.updateStatus( requestPayLoad );
                //  }
                
            }
        } );
    }


    public updateStatus = ( deliquenciesTenant ) => {
        console.log( "updateStatus :: deliquenciesTenant :: ", deliquenciesTenant );
        this.commonService.showLoading();

        deliquenciesTenant.pmStatus = this.checkTypeOf( deliquenciesTenant.pmStatus );
        deliquenciesTenant.actionItem = this.checkTypeOf( deliquenciesTenant.actionItem );


        if ( deliquenciesTenant) {
            let requestPayLoad = {
                "delinquencyNotes": deliquenciesTenant.delinquencyNotes,
                "id": deliquenciesTenant.id,
                "pmStatus": deliquenciesTenant.pmStatus,
                "actionItem": deliquenciesTenant.actionItem,
                "isMobile": this.isMobile,
                "promiseToPay": deliquenciesTenant.promiseToPay
            }
            this.pmService.updateStatusNotes( requestPayLoad ).subscribe(
                ( res ) => {
                    this.commonService.hideLoading();
                    if ( res.code == 200 || res.status == 200 ) {
                        this.commonService.clearToast();
                        this.commonService.showSuccess( "Record updated successfully." );
                        this.getDelinquenciesList();
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError( res.message );
                    }
                    console.log( "updateStatus :: res :: ", res );
                },
                ( error ) => {
                    this.commonService.hideLoading();
                    if ( error.message ) {
                        this.commonService.clearToast();
                        this.commonService.showError( error.message );
                    }
                }
            );
        }
        this.commonService.hideLoading();
    }

    /**
     * Function to open Renewable details
     */
    public openRenewalDetails = ( renewalsTenant ) => {
        console.log( "renewalsTenant ::", renewalsTenant );
        let requestPayLoad;

        this.commonService.openConfirmModal( ViewRenewalComponent, this.commonService.copy( renewalsTenant ), ( data ) => {
            console.log( "renewalsTenant :: popup close ", data );
            if ( data) {
                requestPayLoad = {
                    "evictionStatus": data.evictionStatus,
                    "id": data.id,
                    "notes": data.renewableNotes,
                    "renewableStatus": data.status,
                    "rentdexRenewalStatus": data.rentdexRenewalStatus,
                    "isMobile": this.isMobile
                }

                if((renewalsTenant.rentdexRenewalStatus == requestPayLoad.rentdexRenewalStatus) && (renewalsTenant.renewableNotes == requestPayLoad.notes)){
                    console.log("No channge");                   
                 } else {
                     this.updateRenewalStatus( requestPayLoad );
                 }

                // if ( requestPayLoad.rentdexRenewalStatus || requestPayLoad.renewableNotes ) {
                //     this.updateRenewalStatus( requestPayLoad );
                // }
            }
        } );
    }



    public updateRenewalStatus = ( requestPayLoad ) => {
        console.log( "updateRenewalStatus  :: renewalsTenant :: ", requestPayLoad );
        this.commonService.showLoading();

        requestPayLoad.rentdexRenewalStatus = this.checkTypeOf( requestPayLoad.rentdexRenewalStatus );
        requestPayLoad.evictionStatus = this.checkTypeOf( requestPayLoad.evictionStatus );

        if ( requestPayLoad || ( requestPayLoad && requestPayLoad.rentdexRenewalStatus ) || ( requestPayLoad && requestPayLoad.renewableNotes ) ) {

            this.pmService.updateRenewalStatusNote( requestPayLoad ).subscribe(
                ( res ) => {
                    this.commonService.hideLoading();
                    if ( res.code == 200 || res.status == 200 ) {
                        this.commonService.clearToast();
                        this.commonService.showSuccess( "Record updated successfully." );
                        this.getRenewalList();
                    } else {
                        this.commonService.clearToast();
                        this.commonService.showError( res.message );
                    }
                    this.commonService.hideLoading();
                    console.log( "updateRenewalStatus :: res :: ", res );
                },
                ( error ) => {
                    this.commonService.hideLoading();
                    if ( error.message ) {
                        this.commonService.clearToast();
                        this.commonService.showError( error.message );
                    }
                }
            );
        }
        this.commonService.hideLoading();
    }


    /** 
     * Function to open Tenant Details 
     */
    public openTenantDetails = ( tenantProfile, dataCheck ) => {
        console.log("tenantProfile.....", tenantProfile);
        
        console.log("dataCheck ...", dataCheck);
        this.localStr.setObj("dataCheck", dataCheck);

        if(dataCheck == 'deliquencies'){
            console.log("this.deliquenciesPayload ....", this.deliquenciesPayload);
            this.localStr.setObj("beforeActivityData",this.deliquenciesPayload);
        } 
        else if(dataCheck == 'renewals'){
            console.log("renewalPayload ....", this.renewalPayload);
            this.localStr.setObj("beforeActivityData",this.renewalPayload);
        }

        this.localStr.setObj("breadcrum", 'Dashboard');
        
        this.router.navigate( ['/resident-profile'], tenantProfile );
    }

}
