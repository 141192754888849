
<!-- Container -->
<div class="form-container filter-form-cls">

        <!-- Heading -->
        <div class="row filter-heading m-0">
            <div class="col-12">
              <h2 class="form-title popup-title"> {{ "PM_DASHBOARD.TODAYS_RENEWABLE_DETAILS" | translate }} </h2>
            </div>
        </div>

        <div class="modal-body">
            <form class="form-inline crud-form-cls view-form">
                <div class="row form-row-cls">
                    <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                        <div class="form-group row">
                            <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.ADDRESS" | translate }}: </label>
                            <div class="col-sm-8 pl-0">
                                <p class="form-text">{{ formData.property }}</p>
                              <!-- <input type="text" class="form-control" readonly 
                              [(ngModel)]="formData.property" [ngModelOptions]="{standalone: true}"/>  -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-row-cls">
                    <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                        <div class="form-group row">
                            <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.NAME" | translate }}: </label>
                            <div class="col-sm-8 pl-0">
                              <p class="form-text">{{ formData.tenant }}</p>
                              <!-- <input type="text" class="form-control" readonly 
                              [(ngModel)]="formData.tenant" [ngModelOptions]="{standalone: true}"/> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-row-cls"  *ngIf="!isMobile">
                  <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                      <div class="form-group row">
                          <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.UNIT" | translate }}: </label>
                          <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.unit }}</p>
                            <!-- <input type="text" class="form-control" readonly 
                            [(ngModel)]="formData.unit" [ngModelOptions]="{standalone: true}"/> -->
                          </div>
                      </div>
                  </div>
                </div>
                <div class="row form-row-cls" *ngIf="!isMobile">
                    <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                        <div class="form-group row">
                            <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.RENT" | translate }}: </label>
                            <div class="col-sm-8 pl-0">
                                <p class="form-text">
                                    <span class="inline-value" *ngIf="formData.rent || formData.rent == 0">$</span> 
                                    {{ formData.rent }}
                                </p>
                                <!-- <span class="inline-value" *ngIf="formData.rent || formData.rent == 0">$</span>
                              <input type="text" class="form-control" readonly 
                              [(ngModel)]="formData.rent" [ngModelOptions]="{standalone: true}"/> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-row-cls" *ngIf="!isMobile">
                    <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                        <div class="form-group row">
                            <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.LEASE_EXPIRATION" | translate }}: </label>
                            <div class="col-sm-8 pl-0">
                                <p class="form-text">{{ formData.leaseTo | date: 'MMM dd, yyyy' : ' - '}}</p> 
                                 <!-- <input type="text" class="form-control" readonly 
                                [(ngModel)]="formData.leaseTo" [ngModelOptions]="{standalone: true}"/> -->
                            </div>
                        </div>
                      </div>
                  </div>
                  <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType != 'EVICTION_ADMIN'">
                    <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                        <div class="form-group row">
                            <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.RENEWAL_STATUS" | translate }}: </label>
                            <div class="col-sm-8 pl-0">
                                <p-dropdown class="form-dropdown"  [options]="renewalStatusList" [(ngModel)]="formData.rentdexRenewalStatus" name="rentdexStatus" #status="ngModel" [disabled]="isCheckRenewalStatus"></p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType != 'EVICTION_ADMIN'">
                  <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                      <div class="form-group row">
                          <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }}: </label>
                          <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.evictionStatus | replace : '_' : ' ' | titlecase }}</p>
                            <!-- <input type="text" class="form-control" readonly 
                              [(ngModel)]="formData.evictionStatus" [ngModelOptions]="{standalone: true}"/> -->
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType == 'EVICTION_ADMIN'">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.RENEWAL_STATUS" | translate
                            }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.rentdexRenewalStatus | replace : '_' : ' ' | titlecase }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row form-row-cls" *ngIf="!isMobile && userData.roleType == 'EVICTION_ADMIN'">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.EVICTION_STATUS" |
                            translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-dropdown class="form-dropdown" [options]="evictionStatusList"
                                [(ngModel)]="formData.evictionStatus" name="evictionStatus" #evictionStatus="ngModel">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
              <div class="row form-row-cls" *ngIf="!isMobile && !formData.isRenewalList">
                  <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                      <div class="form-group row">
                          <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.SUBSIDY" | translate }}: </label>
                          <div class="col-sm-8 pl-0">
                              <p class="form-text">
                                <span class="inline-value" *ngIf="formData.subsidy || formData.subsidy == 0">$</span> 
                                {{ formData.subsidy }}
                              </p>
                              <!-- <span class="inline-value" *ngIf="formData.subsidy || formData.subsidy == 0">$</span>
                              <input type="text" class="form-control" readonly 
                              [(ngModel)]="formData.subsidy" [ngModelOptions]="{standalone: true}"/> -->
                          </div>
                      </div>
                  </div>
              </div>
              
              <div class="row form-row-cls" *ngIf="!isMobile">
                  <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                      <div class="form-group row">
                          <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.TARGET_RENT" | translate }}: </label>
                          <div class="col-sm-8 pl-0">
                            <p class="form-text">
                                <span class="inline-value" *ngIf="formData.targetRent">$</span> 
                                {{ formData.targetRent }}
                            </p>
                              <!-- <span class="inline-value" *ngIf="formData.targetRent">$</span>
                              <input type="text" class="form-control" readonly 
                              [(ngModel)]="formData.targetRent" [ngModelOptions]="{standalone: true}"/> -->
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row form-row-cls">
                  <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                      <div class="form-group row">
                          <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.MOST_RECENT_NOTES" | translate }}: </label>
                          <div class="col-sm-8 pl-0">
                              <textarea rows="4" cols="30" maxlength="255" [(ngModel)]="formData.renewableNotes" [ngModelOptions]="{standalone: true}">                                
                              </textarea>
                          </div>
                      </div>
                  </div>
              </div>
            </form>

            <div class="clearfix"></div>
        </div>

        <!--------------- modal btns  --------->
        <div class="row d-flex modal-footer m-0">
          <button type="button" class="btn link-btn" (click)="cancelClick()"> {{ "COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
          <button type="submit" class="btn common-btn" (click)="applyFilter()"> {{ "COMMON_COMPONENT.UPDATE" | translate }} </button>
        </div>


  </div>


