<!-- Container -->
<div class="form-container filter-form-cls">

    <!-- Heading -->
    <div class="row filter-heading m-0">
        <div class="col-12">
          <h2 class="form-title popup-title"> {{ "EVICTION_PIPELINE.DETAILS" | translate }} </h2>
        </div>
    </div>

    <div class="modal-body">
        <form class="form-inline crud-form-cls view-form">

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.ADDRESS" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.address }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.NAME" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                          <p class="form-text">{{ formData.name }}</p>                          
                        </div>
                    </div>
                </div>
            </div>

            <div class="row form-row-cls">
              <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                  <div class="form-group row">
                      <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.UNIT" | translate }}: </label>
                      <div class="col-sm-8 pl-0">
                        <p class="form-text">{{ formData.unit }}</p>                        
                      </div>
                  </div>
              </div>
            </div>

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.TYPE_OF_EVICTION" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-dropdown class="form-dropdown evi-dropdown" name="typeOfEviction" [options]="evictionTypeList" [(ngModel)]="formData.typeOfEviction"></p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "PM_DASHBOARD.EVICTION_STATUS" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-dropdown class="form-dropdown evi-dropdown" name="evictionStatus" [options]="evictionStatusList" [(ngModel)]="formData.evictionStatus"></p-dropdown>
                        </div>
                    </div>
                </div>
            </div> 
            
            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.EVICTION_FILED" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-calendar id="evictionFiled" [touchUI]="true" name="evictionFiled" placeholder="{{ 'EVICTION_PIPELINE.EVICTION_FILED' | translate}}" class="form-calendar custom-calendar-ui evi-calendar" dateFormat="mm/dd/yy" [maxDate]="evictionMaxDate" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #evictionFiled="ngModel" [(ngModel)]="formData.evictionFiled" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range" ></p-calendar>
                        </div>
                    </div>
                </div>
            </div> 
            
            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.INITIAL_COURT_DATE" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-calendar id="initialCourtDate" [touchUI]="true" name="initialCourtDate" placeholder="{{ 'EVICTION_PIPELINE.INITIAL_COURT_DATE' | translate}}" class="form-calendar custom-calendar-ui evi-calendar" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #initialCourtDate="ngModel" [(ngModel)]="formData.initialCourtDate" (ngModelChange)="getInitalCourtDate($event)" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range" ></p-calendar>
                        </div>
                    </div>
                </div>
            </div>  

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.SECOND_COURT_DATE" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-calendar id="secondCourtDate" [touchUI]="true" name="secondCourtDate" placeholder="{{ 'EVICTION_PIPELINE.SECOND_COURT_DATE' | translate}}" class="form-calendar custom-calendar-ui evi-calendar" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #secondCourtDate="ngModel" [(ngModel)]="formData.secondCourtDate" (ngModelChange)="getSecondCourtDate($event)" [minDate]="minDateValue" [disabled]="!formData.initialCourtDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range" ></p-calendar>
                        </div>
                    </div>
                </div>
            </div>  

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.OTHER_COURT_DATE" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-calendar id="otherCourtDate" [touchUI]="true" name="otherCourtDate" placeholder="{{ 'EVICTION_PIPELINE.OTHER_COURT_DATE' | translate}}" class="form-calendar custom-calendar-ui evi-calendar" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #otherCourtDate="ngModel" [(ngModel)]="formData.otherCourtDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range" [minDate]="minOtherDateValue" [disabled]="!formData.secondCourtDate"></p-calendar>
                        </div>
                    </div>
                </div>
            </div>  

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.EVICTION_STAY_DATE" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-calendar id="evictionStayDate" [touchUI]="true" name="evictionStayDate" placeholder="{{ 'EVICTION_PIPELINE.EVICTION_STAY_DATE' | translate}}" class="form-calendar custom-calendar-ui evi-calendar" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #evictionStayDate="ngModel" [(ngModel)]="formData.evictionStayDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range" ></p-calendar>
                        </div>
                    </div>
                </div>
            </div> 
            
            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.SHERIFF_ORDERED" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-calendar id="sheriffOrdered" [touchUI]="true" name="sheriffOrdered" placeholder="{{ 'EVICTION_PIPELINE.SHERIFF_ORDERED' | translate}}" class="form-calendar custom-calendar-ui evi-calendar" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #sheriffOrdered="ngModel" [(ngModel)]="formData.sheriffOrdered" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range" ></p-calendar>
                        </div>
                    </div>
                </div>
            </div> 

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.POSSESSION_OF_UNIT" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-calendar id="possessionOfUnit" [touchUI]="true" name="possessionOfUnit" placeholder="{{ 'EVICTION_PIPELINE.POSSESSION_OF_UNIT' | translate}}" class="form-calendar custom-calendar-ui evi-calendar" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #possessionOfUnit="ngModel" [(ngModel)]="formData.possessionOfUnit" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range" ></p-calendar>
                        </div>
                    </div>
                </div>
            </div> 

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.COPP_DATE" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p-calendar id="coppDate" [touchUI]="true" name="coppDate" placeholder="{{ 'EVICTION_PIPELINE.COPP_DATE' | translate}}" class="form-calendar custom-calendar-ui evi-calendar" dateFormat="mm/dd/yy" inputId="spanish" [readonlyInput]="true" [showIcon]="true" #coppDate="ngModel" [(ngModel)]="formData.coppDate" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="range" ></p-calendar>
                        </div>
                    </div>
                </div>
            </div> 

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.DAYS_ONGOING" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">{{formData.daysOnGoing}}</p>
                        </div>
                    </div>
                </div>
            </div> 

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.COST" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <input type="number" id="from" name="from" class="form-control form-input" min="0" [(ngModel)]="formData.cost" placeholder="{{ 'EVICTION_PIPELINE.COST' | translate}}" 
                       appTwoDigitDecimaNumber/>
                        </div>
                    </div>
                </div>
            </div> 

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.MONEY_JUDGEMENT" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <input type="number" id="money" name="money" min="0" class="form-control form-input" [(ngModel)]="formData.moneyJudgment" placeholder="{{ 'EVICTION_PIPELINE.MONEY_JUDGEMENT' | translate }}" appTwoDigitDecimaNumber/>
                            <!-- <textarea rows="2" cols="30" maxlength="255" [(ngModel)]="formData.moneyJudgment" [ngModelOptions]="{standalone: true}">                                
                            </textarea> -->
                        </div>
                    </div>
                </div>
            </div> 

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.PAYMENT_PLAN_DETAILS" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <input type="text" id="plan" name="plan" class="form-control form-input" [(ngModel)]="formData.paymentPlanDetails" placeholder="{{ 'EVICTION_PIPELINE.PAYMENT_PLAN_DETAILS' | translate }}" />
                            <!-- <textarea rows="2" cols="30" maxlength="255" [(ngModel)]="formData.paymentPlanDetails" [ngModelOptions]="{standalone: true}">                                
                            </textarea> -->
                        </div>
                    </div>
                </div>
            </div> 

            <div class="row form-row-cls">
                <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                    <div class="form-group row">
                        <label for="inputFirstname" class="col-4 control-label"> {{ "EVICTION_PIPELINE.PROPERTY_MANAGER" | translate }}: </label>
                        <div class="col-sm-8 pl-0">
                            <p class="form-text">{{ formData.propertyManagerName }}</p> 
                        </div>
                    </div>
                </div>
            </div> 
        
        </form>

        <div class="clearfix"></div>
    </div>

    <!--------------- modal btns  --------->
    <div class="row d-flex modal-footer m-0">
      <button type="button" class="btn link-btn" (click)="cancelClick()"> {{ "COMMON_COMPONENT.CANCEL_BUTTON" | translate }} </button>
      <button type="submit" class="btn common-btn" (click)="applyFilter()"> {{ "COMMON_COMPONENT.UPDATE" | translate }} </button>
    </div>


</div>


