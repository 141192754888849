/*
 * Rest Service Provider
 * Created By #1116
 */

/*-------------------- Default components---------------*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/observable/throw';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/observable';
import { AppSettings } from '../app-settings-service/app-settings.service';
import { Constants } from '../app-settings-service/app-constant.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { delay } from 'rxjs/internal/operators';

import { DeviceDTO } from '../../models/device-dto';
import { Router } from '@angular/router';

@Injectable()
export class RestService {
   private headerObj = {
      'content-type': 'application/json',
      'Accept-Language': 'en',
   };
   private basicAuthData = 'Basic ' + btoa('rentdex-client:tp*k3m1H&s');
   private bearerAuthData: string = null;
   private isUnauthPopUpOpen = false;
   public errorNetwork = {
      message: 'Please check your internet connection and try again.',
      status: 0,
      data: {
         message: 'Please check your internet connection and try again.',
      }
   };
   protected deviceDetails: DeviceDTO = {};

   constructor(
      private http: HttpClient, private locstr: LocalStorageService, private constants: Constants,
      private router: Router) {
   }

   public getCall = (path: string, skipAuth: boolean, timeout: any): Observable<any> => {
      if (this.isNetworkAvailable()) {
         const url = AppSettings.BASE_URL + path;
         const responseTypeIndicator = 'json';
         timeout = timeout || 1000000;
         skipAuth = skipAuth || false;
         if (skipAuth) {
            this.addHeader('authorization', this.basicAuthData);
         } else {
            this.addHeader('authorization', 'Bearer ' + this.getBearerAuthData());
         }
        
         
         this.headerObj['content-type'] = 'application/json';
         const httpOptions = {
            headers: new HttpHeaders(this.headerObj),
         };
         return this.http.get<any>(url, httpOptions).timeout(timeout);
      } else {
         return throwError(this.errorNetwork);
      }
   }
   
   public getCall2 = (path: string, skipAuth: boolean, timeout: any): Observable<any> => {
      return this.http.get<any>(path).pipe(delay(1000));
   }
   
   public postCall2 = (path: string, data: any, skipAuth: boolean, timeout: any): Observable<any> => {
      return this.http.get<any>(path).pipe(delay(1000));
   }

   public postCall = (path: string, data: any, skipAuth: boolean, timeout: any): Observable<any> => {
      if (this.isNetworkAvailable()) {
         const url = AppSettings.BASE_URL + path;
         const responseTypeIndicator = 'json';
         timeout = timeout || 1000000;
         data = data || {};
         skipAuth = skipAuth || false;

         if (skipAuth) {
            this.addHeader('authorization', this.basicAuthData);
         } else {
            this.addHeader('authorization', this.getBearerAuthData());
         }
         this.headerObj['content-type'] = 'application/json';

         // Set device details as HTTP request header
         this.setDeviceDetailsAsReqHeader();

         const httpOptions = {
            headers: new HttpHeaders(this.headerObj),
            responseType: responseTypeIndicator as any
         };

         const payload = JSON.stringify(data);
         return this.http.post<any>(url, payload, httpOptions).timeout(timeout);
      } else {
         return throwError(this.errorNetwork);
      }
   }

   public putCall = (path: string, data: any, skipAuth: boolean, timeout: any): Observable<any> => {
      if (this.isNetworkAvailable()) {
         const url = AppSettings.BASE_URL + path;
         data = data || {};
         timeout = timeout || 40000;
         skipAuth = skipAuth || false;

         if (skipAuth) {
            this.addHeader('authorization', this.basicAuthData);
         } else {
            this.addHeader('authorization', 'Bearer ' + this.getBearerAuthData());
         }
         this.headerObj['content-type'] = 'application/json';
         // Set device details as HTTP request header
         this.setDeviceDetailsAsReqHeader();

         const httpOptions = {
            headers: new HttpHeaders(this.headerObj)
         };

         const payload = JSON.stringify(data);
         return this.http.put<any>(url, payload, httpOptions);
      } else {
         return throwError(this.errorNetwork);
      }
   }

   public postLoginCall = (path: string, data: any, skipAuth: boolean, timeout: any): Observable<any> => {
      if (this.isNetworkAvailable()) {
         const url = AppSettings.BASE_URL + path;
         const responseTypeIndicator: any = 'application/x-www-form-urlencoded';
         timeout = timeout || 1000000;
         data = data || {};
         skipAuth = skipAuth || false;

         if (skipAuth) {
            this.addHeader('authorization', this.basicAuthData);
         } else {
            this.addHeader('authorization','Bearer ' + this.bearerAuthData);
         }
         this.headerObj['content-type'] = 'application/x-www-form-urlencoded';

         // Set device details as HTTP request header
         this.setDeviceDetailsAsReqHeader();

         const httpOptions = {
            headers: new HttpHeaders(this.headerObj),
            responseType: responseTypeIndicator as any
         };
         const payload = data;
         return this.http.post<any>(url, payload, httpOptions);
      } else {
         return throwError(this.errorNetwork);
      }
   }

   /**
    * Function to perform delete call
    * @param: data
    * @param: skipAuth - To skip auth token or not
    * @param: path - base url
    * @param: timeout
    */
   deleteCall(path: any, skipAuth: any, timeout: any, data: any): Observable<any> {
      if (this.isNetworkAvailable()) {
         const url = AppSettings.BASE_URL + path;
         data = data || {};
         timeout = timeout || 20000;
         const responseTypeIndicator = 'json';
         skipAuth = skipAuth || false;
        
         if (skipAuth) {
            this.addHeader('authorization', this.basicAuthData);
         } else {
            this.addHeader('authorization', 'Bearer ' +this.getBearerAuthData());
         }
         this.headerObj['content-type'] = 'application/json';
         // Set device details as HTTP request header
         this.setDeviceDetailsAsReqHeader();

         const httpOptions = {
            headers: new HttpHeaders(this.headerObj),
            responseType: responseTypeIndicator as any,
            body: data
         };

         return this.http.delete<any>(url, httpOptions);
      } else {
         return throwError(this.errorNetwork);
      }
   }

   externalCall(url: any, params: any, options: any, responseTypeIndicator: any = 'application/text'): Observable<any> {
      return this.http.get<any>(url, { responseType: responseTypeIndicator } as any);
   }

   getHeaders() {
      return this.headerObj;
   }

   appendHeader(key, value) {
      this.headerObj[key] = value;
   }

   addHeader(key, value) {
      this.deleteHeader(key);
      this.appendHeader(key, value);
   }

   deleteHeader(key) {
      delete this.headerObj[key];
   }

   setBearerAuthData = (data) => {
      this.bearerAuthData = data;
   }

   getBearerAuthData = () => {
      if (this.locstr.getObj('tokenData')) {
         const token = this.locstr.getObj('tokenData');
         this.bearerAuthData = token.access_token;
      }
      return this.bearerAuthData;
   }


   /**
    * Function to perform common error handling
    * @param: error
    */
   public handleError = (error: Response | any) => {
      console.log('handleError error--------->', error);
      if (error.status == 401) {
         const errorJson = error.error;
         console.log('errorJson--------->401', errorJson);
         if (!this.isUnauthPopUpOpen) {
            this.isUnauthPopUpOpen = true;
            setTimeout(() => {
               // this.events.publish( 'user:logout', true );
            }, 500);
            //this.commonService.presentToast('Please login again.');
         }
         var refreshToken
         refreshToken = this.locstr.getObj('tokenData').refresh_token;
         this.getTokensFromRefreshToken(refreshToken);
         // this.tokenExpiredHandle(error);
         return throwError(errorJson);
      } else if (error.status == 400) {
         const errorJson = error.error;
         console.log('errorJson--------->400', error);
         if (typeof (errorJson) === 'string' && errorJson.length > 0) {
            const errorJson = JSON.parse(error.error);
            return throwError(errorJson);
         } else {
            return throwError(errorJson);
         }
      } else if (error.status == 502) {
         const errorJson = '{"error_description":"Oops! Something went wrong. Please try again later."}';
         console.log('errorJson--------->502', error);
         if (typeof (errorJson) === 'string' && errorJson.length > 0) {
            const errorJson = JSON.parse(error.error);
            return throwError(errorJson);
         } else {
            return throwError(errorJson);
         }
      }else if (error.status == 0) {
         const errorJson = error;
         return throwError(error);
      } else {
         if (typeof (error.error) === 'string' && error.error.length > 0) {
            const errorJson = JSON.parse(error.error);
            return throwError(errorJson);
         } else {
            return throwError(error);
         }
      }
   }

   public getTokensFromRefreshToken = (refreshToken) => {
      const path = AppSettings.LOGIN_OAUTH_URL;
      const payload = `refresh_token=${refreshToken}&grant_type=${'refresh_token'}`;
      return this
         .postLoginCall(path, payload, true, null)
         .map((res) => {
            return res;
         })
         .catch(this.handleOauthError);
   }

   /**
    * Function to set device details as request header
    */
   private setDeviceDetailsAsReqHeader = () => {
      this.setDeviceDetails();
      let deviceDTO = this.getDeviceDetails();
      console.log("RestService :: setDeviceDetailsAsReqHeader :: deviceDTO", deviceDTO);

      if (Object.keys(deviceDTO).length) {
         Object.keys(deviceDTO).forEach(key => {
            console.log("\n" + key + ": " + deviceDTO[key]);
            this.addHeader(key, deviceDTO[key]);
         });
      }
   }
   
   isNetworkAvailable() {
       return navigator.onLine;
   }
   
   /**
    * Function to set device details
    */
   public setDeviceDetails = (): any => { 
      // this.deviceDetails.deviceId = '12345';
       this.deviceDetails.deviceType = 'WEB';
      // this.deviceDetails.deviceName = 'Web';
      // this.deviceDetails.appVersion = '1.0.0';
   }
   
   /**
    * Function to get device details
    */
   public getDeviceDetails = (): any => {  
       return this.deviceDetails;
   }
   
   /**
    * Function to perform common error handling for Oauth
    */
   public handleOauthError = (error: Response | any) => {
      const errorStr = this.constants.WEB_SERVICE_RESPONSE.EXPIRED_JWT;
      if (error._body && error._body.indexOf(errorStr) > 0) {
      }
      setTimeout(() => {
         //TODO handle user log out event
      }, 500);
      console.log("Token expire Error >>>>> ", error);
      this.tokenExpiredHandle();
      return throwError(error);
   }

   tokenExpiredHandle = () => {
         var userData = this.locstr.getObj('userData');
         var deviceTokenId = this.locstr.getObj('deviceTokenId');
         var password = this.locstr.getObj('password');
         var checkValue = this.locstr.getObj('checkValue');
         window.localStorage.clear();
         userData.isLoggedInUser = false;
         this.locstr.setObj('userData', userData);
         this.locstr.setObj('password', password);
         this.locstr.setObj('deviceTokenId', deviceTokenId)
         this.locstr.setObj('checkValue', checkValue);
         this.locstr.setObj('currUrl', '');
         this.router.navigate([''],userData);
   }
}
