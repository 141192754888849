import { Component, OnInit, ViewEncapsulation, Output, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService, MetaDataService } from 'src/providers/providers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReplacePipe } from 'src/providers/common-service/replace-pipe';
import { TitleCasePipe } from '@angular/common';
import * as _ from "lodash";
@Component( {
    selector: 'app-activity-filter',
    templateUrl: './activity-filter.component.html',
    styleUrls: ['./activity-filter.component.scss'],

    encapsulation: ViewEncapsulation.None
} )
export class ActivityFilterComponent implements OnInit {

    public pageName: string;
    public firstButton: string;
    public secondButton: string;

    @Input() filterPayload: any;

    public pmEaNameItemList: any = [];
    public ownersNameList: any = [];
    public actionItemList: any = [];
    public acronymList: any = [];

    tab: any;


    public date = new Date();
    public currentYear = this.date.getFullYear();
    public maxyear = ( this.currentYear + 99 );
    public minyear = ( this.currentYear - 99 );
    range: string = ( this.minyear ).toString() + ":" + ( this.maxyear ).toString();
    //public replaceChar= new ReplacePipe();
    constructor( private replaceChar: ReplacePipe, private toTitleCase: TitleCasePipe, private router: Router, private commonService: CommonService, public activeModal: NgbActiveModal,
        public metaDataService: MetaDataService) {
        let pageName = 'Filter';
        let firstButton = 'Cancel';
        let secondButton = 'Apply';
        //console.log("filterData",this.formData);
    }

    ngOnInit(): void {
        this.tab = 1;
        console.log( "filterData :: ", this.filterPayload );
        // this.getOwnersNameList();    //  enable when bind owner's name list
        this.getActionItemList();
        this.getPMList();
        this.getAcronymList();   
    }

    /**
      * Function to get Owner's name list
      */
     public getOwnersNameList = () => {
        this.ownersNameList = [];
        this.metaDataService.getOwnersNameList(
            ( response ) => {
                this.commonService.hideLoading();
                this.ownersNameList.push( { label: "Select", value: "" } );

                response.map( status =>
                    this.ownersNameList.push( status )
                );
                console.log("this.ownersNameList>>>>", this.ownersNameList);

                this.ownersNameList = _.cloneDeep( this.ownersNameList );
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.ownersNameList = reason;
            } );
    }

    /**
      * Function to get action item
      */
     private getActionItemList = () => {
        this.actionItemList = [];
        this.metaDataService.getActionItemList(
            ( response ) => {
                this.commonService.hideLoading();
                this.actionItemList.push( { label: "Select", value: "" } );

                response.map( status =>
                    this.actionItemList.push( status )
                );
                console.log("this.actionItemList>>>>", this.actionItemList);

                this.actionItemList = _.cloneDeep( this.actionItemList );
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.actionItemList = reason;
            } );
    }


    /**
       * Function to get unit list
       */
      private getPMList = () => {
        this.pmEaNameItemList = [];
        this.metaDataService.getPMList(true, ( response ) => {

            this.pmEaNameItemList.push( { label: "Select", value: "" } );
            response.map( status =>
                this.pmEaNameItemList.push( status )
            );

            console.log("this.pmEaNameItemList>>>>", this.pmEaNameItemList);
            
            // if ( !this.filterPayload.pmName ) {
            //     this.filterPayload.pmName = this.pmEaNameItemList[0];
            //     console.log("this.filterPayload.pmName>>>", this.filterPayload.pmName);
                
            // }
            this.pmEaNameItemList = _.cloneDeep( this.pmEaNameItemList );
            this.commonService.hideLoading();
        },
            ( reason ) => {
                this.commonService.hideLoading();
                this.pmEaNameItemList = reason;
            }
        );
    }


    /**
     * function on cancel Click
     */
    cancelClick = () => {
        this.activeModal.dismiss( '' );
    }
    /**
       * function on clear Click
       */
      clearFilter = () => {
        this.filterPayload.lastActionTaken = "";
        this.filterPayload.actionTaken= "";
        this.filterPayload.searchText= "";
        this.filterPayload.followUpDateFrom= null;
        this.filterPayload.followUpDateTo= null;
        this.filterPayload.pmName= "";
        this.filterPayload.promiseToPayFrom= null;
        this.filterPayload.promiseToPayTo= null;
        this.filterPayload.sortBy= "";
        this.filterPayload.sortManner= "";
        this.filterPayload.tenantName= "";
        this.filterPayload.updatedAtFrom= null;
        this.filterPayload.updatedAtTo= null;
        this.filterPayload.acronym = "";
    }
    /**
     * function on cancel Click
     */
    applyFilter = () => {
        if ( typeof this.filterPayload.acronym == 'object' ) {
            this.filterPayload.acronym = "";
        }
        this.activeModal.dismiss( this.filterPayload );
    }

    /**
       * Function to get Resident Acronym List
       */
      private getAcronymList = () => {
        this.acronymList = [];
        this.metaDataService.getAcronymList(
            ( response ) => {
                this.commonService.hideLoading();
                this.acronymList.push( { label: "Select", value: "" } );

                response.map( status =>
                    this.acronymList.push( status )
                );

                if ( !this.filterPayload.acronym ) {
                    // this.acronymList.splice(1, 1);
                    this.filterPayload.acronym = this.acronymList[0];
                }

                this.acronymList = _.cloneDeep( this.acronymList );
                this.commonService.hideLoading();
            },
            ( reason ) => {
                this.commonService.hideLoading();
                this.acronymList = reason;
                this.commonService.hideLoading();
            } );
    }

    /**
     * Function coming soon
     */
    public commingSoon = () => {
        this.commonService.clearToast();
        this.commonService.showInfo("Coming soon");    
    }

    /**
     * Function clear coming soon
     */
    public clearCommingSoon = () => {
        this.commonService.clearToast();   
    }

}
