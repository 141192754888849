import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from 'src/pages/auth-module/login/login.component';
import { EmailVerificationComponent } from 'src/pages/auth-module/email-verification/email-verification.component';
import { ResetPasswordComponent } from 'src/pages/auth-module/reset-password/reset-password.component';
import { ForgotPasswordComponent } from 'src/pages/auth-module/forgot-password/forgot-password.component';
import { UserListComponent } from 'src/pages/admin-module/user-List/user-list.component';
import { DashboardComponent } from 'src/pages/property-manager-module/dashboard/dashboard.component';
import { AdminDashboardComponent } from 'src/pages/admin-module/dashboard/dashboard.component';
import { SettingComponent } from 'src/pages/settings/setting.component';
import { ChangePasswordComponent } from 'src/pages/change-password/change-password.component';
import { DashboardFilterComponent } from 'src/pages/property-manager-module/dashboard-filter/dashboard-filter.component';
import { GoalComponent } from 'src/pages/admin-module/goal/goal.component';
import { CreateUserComponent } from 'src/pages/admin-module/create-user/create-user.component';
import { CanDeactivateGuard } from 'src/pages/admin-module/can-deactivate/can-deactivate.guard';
import { TrendsComponent } from 'src/pages/property-manager-module/trends/trends.component';
import { DelinquencyComponent } from 'src/pages/property-manager-module/delinquencies/delinquencies.component';
import { RentRollComponent } from 'src/pages/property-manager-module/rent-roll/rent-roll.component'
import { RentRollFilterComponent } from 'src/pages/property-manager-module/rent-roll/rent-roll-filter/rent-roll-filter.component';
import { TenantProfileComponent } from 'src/pages/tenant-profile/tenant-profile.component';
import { RenewalsComponent } from 'src/pages/property-manager-module/renewals/renewals.component';

//Admin Role Components
import { ActivityComponent } from 'src/pages/admin-module/activity/activity.component';
import { ActivityFilterComponent } from 'src/pages/admin-module/activity-filter/activity-filter.component';
import { MyProfileComponent } from 'src/pages/my-profile/my-profile.component';
import { EditProfileComponent } from 'src/pages/edit-profile/edit-profile.component';
import { NotificationsComponent } from 'src/pages/notifications/notifications.component';
import { StatsComponent } from 'src/pages/stats/stats.component';

//Eviction Role Components
import { EvictionPipelineComponent } from 'src/pages/eviction-admin-module/eviction-pipeline/eviction-pipeline.component';
import { PipelineFilterComponent } from 'src/pages/eviction-admin-module/pipeline-filter/pipeline-filter.component';
import { ReportsComponent } from 'src/pages/admin-module/reports/reports.component';
import { PMDelinquencyFilterComponent } from 'src/pages/admin-module/reports/pm-delinquency-filter/pm-delinquency-filter.component';
import { PMStatsFilterComponent } from 'src/pages/admin-module/reports/pm-stats-filter/pm-stats-filter.component';
import { ClientDelinquencyFilterComponent } from 'src/pages/admin-module/reports/client-delinquency-filter/client-delinquency-filter.component';

//Mobile View Components
import { TurnListComponent } from 'src/pages/property-manager-module/turn-list/turn-list.component';
import { propertyGroupFilterComponent } from 'src/pages/property-manager-module/trends/property-group-filter/property-group-filter.component';

//const routes: Routes = [];
const routes: Routes = [
    {
        path: 'property-manager-dashboard',
        component: DashboardComponent
    },
    {
        path: 'delinquencies',
        component: DelinquencyComponent
    },
    {
        path: 'admin-dashboard',
        component: AdminDashboardComponent
    },
    {
        pathMatch: 'full',
        path: '',
        component: LoginComponent
    },
    {
        pathMatch: 'full',
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        pathMatch: 'full',
        path: 'email-verification',
        component: EmailVerificationComponent
    },
    {
        pathMatch: 'full',
        path: 'reset-password',
        component: ResetPasswordComponent
    },
    {
        pathMatch: 'full',
        path: 'user-list',
        component: UserListComponent
    },
    {
        pathMatch: 'full',
        path: 'create-user',
        component: CreateUserComponent, canDeactivate: [CanDeactivateGuard]
    },
    {
        pathMatch: 'full',
        path: 'settings',
        component: SettingComponent
    },
    {
        pathMatch: 'full',
        path: 'goals',
        component: GoalComponent
    },
    {
        pathMatch: 'full',
        path: 'change-password',
        component: ChangePasswordComponent
    },
    {
        pathMatch: 'full',
        path: 'dashboard-filter',
        component: DashboardFilterComponent
    },
    {
        pathMatch: 'full',
        path: 'property-group-filter',
        component: propertyGroupFilterComponent
    },
    
    {
        path: 'trends',
        component: TrendsComponent
    },
    {
        path: 'rent-roll',
        component: RentRollComponent
    },
    {
        pathMatch: 'full',
        path: 'rent-roll-filter',
        component: RentRollFilterComponent
    },
    {
        path: 'resident-profile',
        component: TenantProfileComponent
    },
    {
        path: 'renewals',
        component: RenewalsComponent
    },
    {
        path: 'activities',
        component: ActivityComponent
    },  
    {
        pathMatch: 'full',
        path: 'activity-filter',
        component: ActivityFilterComponent
    },
    { 
        pathMatch: 'full',
        path: 'my-profile', 
        component: MyProfileComponent 
    },
    { 
        path: 'edit-profile', 
        component: EditProfileComponent 
    },
    {
        path: 'notifications',
        component: NotificationsComponent
    },
    {
        path: 'stats',
        component: StatsComponent
    } ,
    {
        path: 'eviction-pipeline',
        component: EvictionPipelineComponent
    },
    {
        path: 'pipeline-filter',
        component: PipelineFilterComponent
    },
    {
        path: 'reports',
        component: ReportsComponent
    },
    {
        path: 'turn-list',
        component: TurnListComponent
    },
    {
        path: 'reports-filter',
        component: PMDelinquencyFilterComponent
    },
    {
        path: 'pm-stats-filter',
        component: PMStatsFilterComponent
    },
    {
        path: 'client-delinquency-filter',
        component: ClientDelinquencyFilterComponent
    }    
]

@NgModule( {
    imports: [RouterModule.forRoot( routes, { useHash: true } )],
    exports: [RouterModule]
} )
export class AppRoutingModule { }
