/*--------------------Angular related components---------------*/
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { Observable, timer, throwError } from "rxjs";
import { Router } from "@angular/router";
import { map, catchError, mergeMap } from "rxjs/operators";

/*-----------------App Providers---------------------*/
import { AppSettings } from "../app-settings-service/app-settings.service";
import { Constants } from "../app-settings-service/app-constant.service";
import { LocalStorageService } from "../local-storage-service/local-storage.service";
import { RestService } from "../rest-service/rest.service";
import { CommonService } from "../common-service/common-service";

@Injectable()
export class StatsService {
    errorMsg: string;
    successMsg: string;

    constructor(
        private commonService: CommonService,
        private restService: RestService,
        public locstr: LocalStorageService,
        private constant: Constants,
        //private toaster: Toaster,
        private router: Router
    ) { }

    /**
     * Function to get Renewal data list
     */
    public getStatsRenewalList( param ): Observable<any> {
        console.log("param..", param);
        
        const path = AppSettings.RENEWAL_STATS_LIST_URL + "?month=" + param.month + "&pmId=" + param.pmId;
        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }

    /**
     * Function to get Delinquencies data list
     */
    public getStatsDelinquenciesList( param ): Observable<any> {
        const path = AppSettings.DELINQUENCIES_STATS_LIST_URL + "?month=" + param.month + "&pmId=" + param.pmId;
        return this.restService.getCall( path, false, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
    }
    
}
