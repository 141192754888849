<form #createUserForm="ngForm" class="common-modal-wrap">
    <!-- view for custom modal -->
    <div class="modal-header modal-header-paddding">
        <h3 *ngIf="title" class="modal-title">
            {{title}}
        </h3>
    </div>
    <!-- view for modal body-->
    <div class="modal-body">
        <div class="message-content">
            {{message}}
        </div>

    </div>
    <!-- view for modal footer-->
    <div class="modal-footer">
        <button type="button" class="btn link-btn cancel-btn-font" *ngIf="firstButton" (click)="cancel()">{{firstButton}}</button>
        <div class="common-secondary-btn-content" *ngIf="secondButton">
            <button type="button" class="btn common-btn" *ngIf="secondButton" (click)="ok(createUserForm)">{{secondButton}}</button>
        </div>
    </div>
</form>