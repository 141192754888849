<div class="row user-container">
    <div class="container-fluid">
        <div class="row m-0 full-height">
            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 pl-0">
                <div class="user user-list-cls">
                    <p-table #userLists [value]="userList" selectionMode="single" [scrollable]="true" [style]="{width:'100%'}" class="list-table" [rows]="2" [scrollHeight]="'calc(100vh - 249px)'">
                        <ng-template pTemplate="caption">
                            <tr class="d-block">
                                <th class="d-block">
                                    <div class="user-list-head">
                                        <div class="user-search-div-style">
                                            <div class="search-box">
                                                <i class="search-icon search-icon-cls"></i>
                                                <input id="search-bar" spellcheck="false" autocomplete="false" type="text" name="searchUser" placeholder="Search by name" pInputText [(ngModel)]="userData.searchText" (input)="isSearchCall=true;getUserList();" />
                                            </div>
                                            <!-- <button class="btn btn-icon-cls" *ngIf="selectedUser.length>0"><i class="delete-icon filter-icon-cls"></i></button> -->
                                            <!-- <button class="btn btn-icon-cls" *ngIf="selectedUser.length>0" (click)="blockMultiUserConfimationPopup()"><i class="hide-icon filter-icon-cls"></i></button> -->
                                            <button class="btn btn-icon-cls dropdown-toggle custom-dropdown-toggle pr-0" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="selectedUser.length==0" (click)="openFilterDropdown()"><i class="filter-icon filter-icon-cls" title="Filter Users"></i></button>
                                        </div>

                                        <div class="nav-item custom-dropdown dropdown">
                                            <div id="myDropdown" class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" [ngClass]="{show: show === true}">
                                                <a class="dropdown-item" [ngClass]="{active: isAllUser}" (click)="FilterDataShow('')"> {{ "USER_LIST.ALL_USER" | translate }} </a>
                                                <a class="dropdown-item" [ngClass]="{active: isPropertyManager}" (click)="FilterDataShow('PROPERTY_MANAGER')"> {{ "USER_LIST.PROPERTY_MANAGER" | translate }} </a>
                                                <a class="dropdown-item" [ngClass]="{active: isEvictionAdmin}" (click)="FilterDataShow('EVICTION_ADMIN')"> {{ "USER_LIST.EVICTION_ADMIN" | translate }} </a>
                                                <a class="dropdown-item" [ngClass]="{active: isSubAdmin}" (click)="FilterDataShow('SUB_ADMIN')"> {{ "USER_LIST.SUB_ADMIN" | translate }} </a>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-user>
                            <tr [ngClass]="{'active': user.id==userToView.id }">
                                <td (click)="selectUserToViewEdit(user);">
                                    <div class="user-chk-wrap">
                                        <!-- <p-checkbox class="user-chk-cls" name="user_{{user.id}}" [disabled]="user.blocked ||logedInUser.id==userToView.id" [(ngModel)]="user.checked" #users (onChange)="selectUser(users.checked,user)" binary="true"></p-checkbox> -->
                                        <span class="chk-text"><p class="check-name set-name">{{user?.firstName}} {{user?.lastName}}</p><p class="check-role">{{user?.roleType | replace : '_' : ' ' | titlecase}}</p></span>
                                        <i *ngIf="!user?.isBlocked" class="block2-icon right-end-icon" title="Block User"></i>
                                        <i *ngIf="user?.isBlocked" class="unblock-icon right-end-icon" title="Unblock User"></i>
                                    </div>
                                </td>
                            </tr>

                        </ng-template>
                    </p-table>
                    <div class="control-label" *ngIf="userList.length<1">
                        <div class="no-data-found" *ngIf="isNoData" text-center>
                            <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                        </div>
                    </div>
                    <!-- [disabled]="isCreate" -->
                    <div class="fab position-absolute bottom-right fab-btn-cls">
                        <button class="btn btn-fab" type="button"  (click)="createClick();isCreateClick=true;"><i class="add-fab-btn" title="Add User"></i></button>
                    </div>

                    <!-- <p-paginator [rows]="10" (onLazyLoad)="paginate($event)" (onPageChange)="paginate($event);" [totalRecords]="param.totalRecords" class="list-paginator"></p-paginator> -->
                </div>
                <p-paginator [rows]="10" (onLazyLoad)="paginate($event)" (onPageChange)="paginate($event);" [totalRecords]="param.totalRecords" class="list-paginator"></p-paginator>

            </div>

            <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 p-0">
                <div class="user card crud-card-cls">
                    <div class="control-label">
                        <div class="no-data-found" *ngIf="isNoData" text-center>
                            <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                        </div>
                    </div>
                    <div *ngIf="isDetailView && userList.length>0">
                        <div class="d-flex flex-row m-0 crud-heading">
                            <div class="col-sm-6 d-flex crud-title-cls">
                                <span class="crud-title">User Details </span>
                            </div>
                            <div class="col-sm-6 d-flex crud-btns">
                                <button class="btn btn-icon-cls" (click)="editClick()"><i class="edit-icon" title="Edit User"></i></button>
                                <!-- || logedInUser.id==userToView.id -->
                                <button class="btn btn-icon-cls" *ngIf="!userToView.isBlocked " (click)="blockUnblockUser(true)"><i class="block2-icon" title="Block User"></i></button>
                                <button class="btn btn-icon-cls" *ngIf="userToView.isBlocked " (click)="blockUnblockUser(false)"><i class="unblock-icon" title="Unblock User"></i></button>
                                <button class="btn btn-icon-cls" [disabled]="logedInUser.id==userToView.id" (click)="deleteClick()"><i class="delete-icon" title="Delete User"></i></button>
                            </div>
                        </div>
                        <div class="container">
                            <form class="form-inline crud-form-cls mar-top10">
                                <div class="row form-row-cls">
                                    <div class="col-lg-6 col-md-12 form-gr-cls">
                                        <div class="form-group row">
                                            <label for="inputFirstname" class="col-sm-3 control-label"> {{ "USER_LIST.PHONE_NO" | translate }}: </label>
                                            <div class="col-sm-9 pl-0">
                                                <span class="form-input-txt"> {{userToView?.phoneNumber ? userToView?.phoneNumber: ' - '}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 form-gr-cls">
                                        <div class="form-group row">
                                            <label for="inputFirstname" class="col-sm-3 control-label"> {{ "CREATE_USER.EMAIL" | translate }}: </label>
                                            <div class="col-sm-9 pl-0">
                                                <span class="form-input-txt">{{userToView?.email ? userToView?.email : ' - '}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row form-row-cls">
                                    <div class="col-lg-6 col-md-12 form-gr-cls">
                                        <div class="form-group row">
                                            <label for="inputFirstname" class="col-sm-3 control-label"> {{ "USER_LIST.NAME" | translate }}: </label>
                                            <div class="col-sm-9 pl-0">
                                                <span class="form-input-txt">{{userName ? userToView?.firstName : ' - '}}
                                            {{userToView?.lastName ? userToView?.lastName: ' - '}}  </span>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <div class="col-lg-6 col-md-12 form-gr-cls">
                                        <div class="form-group row">
                                            <label for="inputFirstname" class="col-sm-3 control-label"> {{ "CREATE_USER.ROLE" | translate }}: </label>
                                            <div class="col-sm-9 pl-0">
                                                <span class="form-input-txt"> {{userToView?.roleType ? (userToView?.roleType | replace : '_' : ' ' | titlecase) : ' - '}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="row form-row-cls">
                                    <div class="col-lg-6 col-md-12 form-gr-cls">
                                        <div class="form-group row">
                                            <label for="inputFirstname" class="col-sm-3 control-label"> {{ "CREATE_USER.GENDER" | translate }}: </label>
                                            <div class="col-sm-9 pl-0">
                                                <span class="form-input-txt"> {{userToView?.gender ? userToView?.gender : ' - '}}  </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 form-gr-cls">
                                        <div class="form-group row">
                                            <label for="inputFirstname" class="col-sm-3 control-label"> {{ "USER_LIST.DOB" | translate }}: </label>
                                            <div class="col-sm-9 pl-0">
                                                <span class="form-input-txt">{{userToView?.dob ? (userToView?.dob | date: 'MMM d, y') : ' - '}} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <!-- <div class="row form-row-cls">
                                    <div class="col-lg-6 col-md-12 form-gr-cls">
                                        <div class="form-group row">
                                            <label for="inputFirstname" class="col-sm-3 control-label">Property Group Id: </label>
                                            <div class="col-sm-9 pl-0">
                                                <span class="form-input-txt"> {{userToView?.propertyGroupId ? userToView?.propertyGroupId : ' - '}}  </span>
                                            </div>
                                        </div>
                                    </div>

                                </div> -->
                                <div class="row form-row-cls" *ngIf="userToView.roleType=='SUB_ADMIN'">
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                                        <div class="form-group row">
                                            <label for="inputFirstname" class="col-1 control-label"> {{ "CREATE_USER.TEAM_MEMBERS" | translate }}: </label>
                                            <div class="col-sm-10 pl-0 pr-28">
                                                <span class="form-input-txt no-wrap">{{userToView?.teamMemberList ? userToView.teamMemberList  : ' - '}} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-row-cls" *ngIf="userToView.roleType=='SUB_ADMIN'">
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                                        <div class="form-group row">
                                            <label for="inputFirstname" class="col-1 control-label"> {{ "USER_LIST.USER_ACCESS" | translate }}: </label>
                                            <div class="col-sm-10 pl-0 pr-28">
                                                <span class="form-input-txt no-wrap">{{access ? (access ) : ' - '}} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row form-row-cls">
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-gr-cls">
                                        <div class="form-group row">
                                            <label for="inputFirstname" class="col-1 control-label"> {{ "CREATE_USER.ADDRESS" | translate }}: </label>
                                            <div class="col-sm-10 pl-0">
                                                <span class="form-input-txt"> {{userToView?.address ? userToView?.address : ' - '}}  </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row form-row-cls">
                                    <div class="col-12 d-flex p-0">
                                        <button class="btn common-btn small-btn" *ngIf="userToView.isBlocked" (click)="blockUnblockUser(false)"> {{ "USER_LIST.UNBLOCK" | translate }} </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div *ngIf="!isDetailView">
                        <div class="d-flex flex-row m-0 crud-heading">
                            <div class="col-sm-6 d-flex crud-title-cls">
                                <i class="back-icon cusr-pointer" (click)="cancelClick()"></i> <span class="crud-title" *ngIf="!isCreate"> {{ "USER_LIST.EDIT_USER" | translate }} </span>
                                <span class="crud-title" *ngIf="isCreate"> {{ "USER_LIST.CREATE_USER" | translate }} </span>
                            </div>
                        </div>
                        <!-- <div class="container">
                            <form class="form-inline crud-form" #userForm="ngForm" (clickOutside)="handleOutClick()">
                                <div class="d-flex flex-row form-row">
                                    <div class="col-sm-6 col-12 d-flex form-gr">
                                        <div class="form-group">
                                            <label for="firstName">First Name:</label>
                                            <input type="text" id="fname" [ngStyle]="{'border': (firstName.errors && (firstName.dirty || firstName.touched || formSubmitted)) ? '1px solid red' : '0' }" class="form-control form-input" placeholder="First Name" #firstName="ngModel" [(ngModel)]="userToView.firstName"
                                                name="fname" aria-describedby="first name" pInputText required />
                                            <div class="error" *ngIf="firstName.errors && (firstName.dirty || firstName.touched || formSubmitted)">
                                                <small [hidden]="!firstName.errors.required" class="p-invalid error-msg"> Please enter first name. </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-12 d-flex form-gr">
                                        <div class="form-group">
                                            <label for="lastName">Last Name:</label>
                                            <input type="text" id="lname" [ngStyle]="{'border': (lastName.errors && (lastName.dirty || lastName.touched || formSubmitted)) ? '1px solid red' : '0' }" class="form-control form-input" placeholder="Last Name" #lastName="ngModel" [(ngModel)]="userToView.lastName"
                                                name="lname" aria-describedby="last name" pInputText required/>
                                            <div class="error" *ngIf="lastName.errors && (lastName.dirty || lastName.touched || formSubmitted)">
                                                <small [hidden]="!lastName.errors.required" class="p-invalid error-msg"> Please enter last name. </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="d-flex flex-row form-row">
                                    <div class="col-sm-6 col-12 d-flex form-gr">
                                        <div class="form-group">
                                            <label for="email">Email:</label>
                                            <input id="email" type="text" placeholder="Email" class="form-control form-input" [disabled]="!isCreate" #userEmail="ngModel" [(ngModel)]="userToView.email" id="formEmail" pattern="^(?!\.)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,256}$" name="email"
                                                aria-describedby="Email-help" pInputText required />
                                            <div class="text-left error" *ngIf="userEmail.errors && (userEmail.dirty || userEmail.touched || formSubmitted)">
                                                <small [hidden]="!userEmail.errors.required" class="p-invalid error-msg"> Please enter email address.
                                        </small>
                                                <small [hidden]="!userEmail.errors.pattern" class="p-invalid error-msg"> Please enter a valid email address.
                                        </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-12 d-flex form-gr">
                                        <div class="form-group">
                                            <label for="phone">Phone Number:</label>
                                            <input type="number" id="phone" class="form-control form-input" onKeyPress="if(this.value.length==10) return false;" placeholder="Phone Number (Optional)" #phoneNumber="ngModel" [(ngModel)]="userToView.phoneNumber" name="phone" aria-describedby="phone number"
                                                pInputText/> </div>
                                    </div>

                                </div>

                                <div class="d-flex flex-row form-row">
                                    <div class="col-sm-6 col-12 d-flex form-gr">
                                        <div class="form-group">
                                            <label for="gender">Gender:</label>
                                            <p-dropdown class="form-dropdown" [options]="genderList" [(ngModel)]="userToView.gender" name="selectedGender" #gender="ngModel"  (onChange)="getSelectedGender($event)"></p-dropdown>

                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-12 d-flex form-gr">
                                        <div class="form-group">
                                            <label for="role">Role:</label>
                                            <p-dropdown class="form-dropdown" [options]="roleList" name="role" #roleType="ngModel" [(ngModel)]="userToView.roleType" (onChange)="getSelectedRole($event)" required></p-dropdown>
                                            <div class="error" *ngIf="roleCheck">
                                                <small class="p-invalid error-msg"> Please select role. </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex flex-row form-row">

                                    <div class="col-sm-6 col-12 d-flex form-gr" *ngIf="userToView.roleType=='SUB_ADMIN'">
                                        <div class="form-group">
                                            <label for="access">Access:</label>
                                            <p-multiSelect class="form-dropdown form-multiselect" [defaultLabel]="'Please Select Access'" [options]="accessList" name="access" #userAccessList="ngModel" [(ngModel)]="userToView.userAccessList " (onChange)="getSelectedAccess($event)" required></p-multiSelect>
                                            <div class="error" *ngIf="accessCheck">
                                                <small class="p-invalid error-msg"> Please select accesses. </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            <div class="d-flex flex-row form-row">
                                <div class="col-sm-6 col-12 d-flex form-gr">
                                    <div class="form-group">
                                        <label for="dob">DOB:</label>
                                        <p-calendar id="dateOf" [touchUI]="true" name="dob" [defaultDate]="maxDateValue" placeholder="DOB (Optional)" class="form-calendar custom-calendar-ui" dateFormat="mm/dd/yy" inputId="spanish" [maxDate]="maxDateValue"  [readonlyInput]="true" [showIcon]="true" #dob="ngModel" [(ngModel)]="userToView.dob" [monthNavigator]="true"
                                            [yearNavigator]="true" [yearRange]="range"></p-calendar>

                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-12 d-flex form-gr">
                                        <div class="form-group">
                                            <label for="propertyGroupId">Property Group Id:</label>
                                            <input type="text" id="propertyGroupId " class="form-control form-input" [disabled]="!isCreate" placeholder="Property Group Id" #propertyGroupId="ngModel" [(ngModel)]="userToView.propertyGroupId" name="propertyGroupId" aria-describedby="Property Group"
                                                pInputText required/>
                                            <div class="error" *ngIf="propertyGroupId.errors && (propertyGroupId.dirty || propertyGroupId.touched || formSubmitted)">
                                                <small [hidden]="!propertyGroupId.errors.required" class="p-invalid error-msg"> Please enter property group id. </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-row form-row">
                                    <div class="col-12 d-flex form-gr">
                                        <div class="form-group">
                                            <label for="address">Address:</label>
                                            <input type="text" id="address " class="form-control form-input" placeholder="Address (Optional)" #address="ngModel" [(ngModel)]="userToView.address" name="address" aria-describedby="address" pInputText />

                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-row form-row">
                                    <div class="col-12 d-flex crud-btns">
                                        <button class="btn link-btn" (click)="cancelClick()">CANCEL</button>
                                        <button class="btn common-btn small-btn" (click)="submitUser(userForm)">SUBMIT</button>
                                    </div>
                                </div>

                            </form>
                        </div> -->
                        <create-user [userData]="userData" [userToView]="userToView" [isCreate]="isCreate" (back)="backFromCreate($event)"></create-user>
                    </div>

                </div>
            </div>

        </div>

    </div>
</div>
<app-confirm-popup>

</app-confirm-popup>