/**
 * Admin Role Related Services
 * Created By : #1116
 * Date : 15 January, 2021
 */


import { Injectable } from '@angular/core';
import { AppSettings, RestService } from '../providers';
import { Observable, timer, throwError } from "rxjs";
import { map, catchError, mergeMap } from "rxjs/operators";

@Injectable( {
    providedIn: 'root'
} )
export class AdminService {

    constructor( private restService: RestService ) { }

      /**
      * Function to get Activites data list
      * Actions taken by PM and EA for tenants
      */
     public getActivitesList( payloadData ): Observable<any> {
        const path = AppSettings.GET_ACTIVITIES + "?size=" +
            payloadData.pageSize +
            "&page=" +
            payloadData.pageNumber;
            return this.restService.postCall( path, payloadData, true, 60000 )
            .pipe( map( res => {
                return res;
            } ),
            catchError( e => timer( 500 ).pipe( mergeMap( t => this.restService.handleError( e ) ) ) ) );
        }

   /**
     * Function to export PM Delinquency
     */
    public exportPMDelinquency( param, reportFormat, userId ): Observable<any> {
        const path = AppSettings.PM_DELINQUENCY_EXPORT_URL + "?reportFormat=" +
            reportFormat+
            "&userId=" +
            userId + "&isCurrentMonth=" + param.isCurrentMonth;
        return this.restService.postCall( path, param, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /**
     * Function to export PM Stats
     */
    public exportPMStats( param, reportFormat, userId ): Observable<any> {
        const path = AppSettings.PM_STATS_EXPORT_URL + "?reportFormat=" +
        reportFormat+
        "&userId=" +
        userId + "&isCurrentMonth=" + param.isCurrentMonth;
        return this.restService.postCall( path, param, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

    /**
     * Function to export Cllient Delinquency
     */
    public exportCllientDelinquency( param, reportFormat, userId ): Observable<any> {
        const path = AppSettings.CLIENT_DELINQUENCY_EXPORT_URL + "?reportFormat=" +
        reportFormat+
        "&userId=" +
        userId + "&isCurrentMonth=" + param.isCurrentMonth;
        return this.restService.postCall( path, param, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }

   /**
     * Function to export Admin Activites table data
     */
    public exportActivities( param, reportFormat,userId ): Observable<any> {
        const path = AppSettings.ACTIVITIES_EXPORT_URL + "?reportFormat=" +
            reportFormat+
            "&userId=" +
            userId;
        return this.restService.postCall( path, param, false, 60000 ).pipe(
            map(( res ) => {
                return res;
            } ),
            catchError(( e ) =>
                timer( 500 ).pipe( mergeMap(( t ) => this.restService.handleError( e ) ) )
            )
        );
    }
}
