/**
 * forgot password functionality
 * Created By : #1223
 * Date : 25 November, 2020
 */
/***************************** Angular component *****************************/
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
// import { Toaster } from 'ngx-toast-notifications';

import { AuthService, CommonService } from 'src/providers/providers';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['../auth.scss']
})

export class ForgotPasswordComponent implements OnInit {
  public showPassword:boolean = false;
  formSubmitted: boolean;
  isForgotPassword: boolean;
  forgotData = {
    email: "",
    transactionId: ""
  }
    constructor(private authService: AuthService, private router: Router, private commonService: CommonService) {
      var extras = this.router.getCurrentNavigation().extras;      
    }

  /**
   * Function on page load
   * 
  */
    ngOnInit(): void {
    } 

  /**
   * Function on forgot password
   * 
  */
  forgotPasswordClick = (form: NgForm) =>{    
    this.formSubmitted = true;
    if (form.valid) {
      this.commonService.showLoading();
      this.authService.forgotPassword(this.forgotData).subscribe(
        res => {
            if ( res.code == 200 ) {
              this.commonService.hideLoading();
                console.log( "forgotPasswordComponent :: forgotPasswordClick :: res :: ", res.data );
                var stateData = {
                  state: {
                      transactionId: res.data.transactionId,
                      email: this.forgotData.email
                  }
              }
              this.commonService.clearToast();
              this.commonService.showSuccess("Verification code sent successfully.");
              // this.toaster.open({ text: "Verification code sent successfully.", type: 'success', position: 'top-center' });
              this.router.navigate(['/email-verification'], stateData);
            }
        },
        error => {
          this.commonService.hideLoading();
            console.log( "forgotPasswordComponent :: forgotPasswordClick :: error :: ", error );
            if(error.message) {
              this.commonService.clearToast();
              this.commonService.showError(error.message);
              // this.toaster.open({ text: error.message, type: 'danger', position: 'top-center' });
            } else {
              this.commonService.clearToast();
              this.commonService.showError(error.error_description);
              // this.toaster.open({ text: error.error_description, type: 'danger', position: 'top-center' });
            }
        });
    }

  }

    /**
     * Remove conditional class only for login page
     * */
    ngOnDestroy() {
    }

    

}
