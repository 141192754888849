<div class="row page-wrap">
    <div class="container-fluid">
        <div class="row m-0">
            <div class="col-12 col-sm-12 dashboard-card-cls mar-btm10">
                <div class="card dashboard-card clearfix">
                    <div class="row m-0 profile-user-info">
                        <div class="col-sm-6">
                            <div class="profile-user-details clearfix">
                                <p class="profile-user-details-label">
                                    {{ "TENANT.ADDRESS" | translate }}: <span class="profile-user-details-value"> {{(tenantProfileData && tenantProfileData.propertyAddress ) ? tenantProfileData.propertyAddress : ''}} </span>
                                </p>
                            </div>
                            
                            <div class="profile-user-details clearfix">
                                <p class="profile-user-details-label">
                                    {{ "TENANT.PHONE" | translate }}: <span class="profile-user-details-value"> {{(tenantProfileData && tenantProfileData.phoneNumber) ? tenantProfileData.phoneNumber : ''}} </span>
                                </p>
                                
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="profile-user-details clearfix">
                                <p class="profile-user-details-label">
                                    {{ "TENANT.NAME" | translate }}: <span class="profile-user-details-value"> {{(tenantProfileData && tenantProfileData.tenant) ? tenantProfileData.tenant : ''}} </span>
                                </p>
                            </div>
                            <div class="profile-user-details clearfix">
                                <p class="profile-user-details-label">
                                    {{ "TENANT.EMAIL" | translate }}: <span class="profile-user-details-value"> {{(tenantProfileData && tenantProfileData.emails ) ? tenantProfileData.emails : ''}} </span>
                                </p>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-12 col-sm-12 dashboard-card-cls">
                    <div class="card dashboard-card clearfix">
                        <div class="row m-0 profile-user-info">
                            <div class="col-sm-6">
                                <div class="profile-user-details clearfix">
                                    <p class="profile-user-details-label">
                                        {{ "TENANT.UNIT" | translate }}: <span class="profile-user-details-value"> {{(tenantProfileData && tenantProfileData?.unit ) ? tenantProfileData?.unit : ''}} </span>
                                    </p>
                                </div>
                                
                                <div class="profile-user-details clearfix">
                                    <p class="profile-user-details-label">
                                        {{ "TENANT.TENANT_RENT" | translate }}: <span class="profile-user-details-value">{{tenantProfileData?.tenantRent | currency:'USD':'symbol' :'1.2-2' }} </span>
                                    </p>
                                </div>
                                <div class="profile-user-details clearfix">
                                    <p class="profile-user-details-label">
                                        {{ "TENANT.LEASE_EXPIRATION_DATE" | translate }}: <span class="profile-user-details-value"> {{ tenantProfileData?.leaseTo | date: 'MM/dd/yyyy' : ' '}} </span>
                                    </p>
                                </div>
                                
                                <div class="profile-user-details clearfix">
                                    <p class="profile-user-details-label">
                                        {{ "TENANT.NEXT_FOLLOW_UP_DATE" | translate }}: <span class="profile-user-details-value"> {{ tenantProfileData?.nextFollowUpDate | date: 'MM/dd/yyyy' : ' '}} </span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="profile-user-details clearfix">
                                    <p class="profile-user-details-label">
                                        {{ "TENANT.TOTAL_RENT" | translate }}: <span class="profile-user-details-value"> {{tenantProfileData?.totalRent | currency:'USD':'symbol' :'1.2-2'}} </span>
                                    </p>
                                </div>
                                <div class="profile-user-details clearfix">
                                    <p class="profile-user-details-label">
                                        {{ "TENANT.SUBSIDY_RENT" | translate }}: <span class="profile-user-details-value"> {{tenantProfileData?.subsidyRent | currency:'USD':'symbol' :'1.2-2'}} </span>
                                    </p> 
                                </div>
                                <div class="profile-user-details clearfix">
                                    <p class="profile-user-details-label">
                                        {{ "TENANT.DELINQUENT_BALANCE" | translate }}: <span class="profile-user-details-value"> {{tenantProfileData?.delinquentBalance | currency:'USD':'symbol' :'1.2-2'}} </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        <div class="row m-0">
            <div class="d-flex align-items-center full-width page-bwn-title">
                <div class="col-sm-5 p-0">
                    <h3 class="tenant-title"> {{ "TENANT.ACTIVITY_LOG" | translate }} </h3>
                </div>
                <!-- <div class="search-wrap float-right">
                    <button class="btn btn-icon-bg" (click)="addActivity(null, false)"><i class="add-icon filter-icon-cls" title="Filter"></i></button>
                </div> -->
            </div>

            <div class="card clearfix">

                <p-table class="custom-table-cls" #tl [value]="tenantActivityList" [scrollable]="true" [style]="{width:'100%'}" styleClass="p-datatable-striped" [scrollHeight]="'calc(100vh - 280px)'" [responsive]="true" (onLazyLoad)="customSort($event,'DELINQUENCY')" [lazy]="true">

                    <ng-template pTemplate="header">
                        <tr>
                            <th > {{ "TENANT.PM_EA_NAME" | translate }} 
                            </th>
                            <th> {{ "TENANT.PM_STATUS" | translate }} 
                            </th>
                            <th> {{ "TENANT.RENEWAL_STATUS" | translate }} 
                            </th>
                            <th> {{ "TENANT.EVICTION_STATUS" | translate }} 
                            </th>

                            <th pSortableColumn="actionTaken"> {{ "TENANT.ACTION_TAKEN" | translate }} 
                                <p-sortIcon field="actionTaken"></p-sortIcon>
                            </th>

                            <th pSortableColumn="followUpDate"> {{ "TENANT.FOLLOW_UP_DATE" | translate }} 
                                <p-sortIcon field="followUpDate"></p-sortIcon>
                            </th>
                            <th pSortableColumn="promiseToPay"> {{ "TENANT.PROMISE_TO_PAY" | translate }}
                                <p-sortIcon field="promiseToPay"></p-sortIcon>
                            </th>                                      
                            <th> {{ "TENANT.NOTES" | translate }} </th>
                            <th> {{ "TENANT.UPDATE_AT" | translate }} </th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-tenantActivity>
                        <tr [ngClass]="{'cusr-pointer': userData?.roleType !=='SUPER_ADMIN' && userData?.roleType !=='SUB_ADMIN'}" (click)="addActivity(tenantActivity)">
                            <td title="{{tenantActivity.pmEAName | replace : '_' : ' ' | titlecase }}">{{tenantActivity.pmEAName ? (tenantActivity.pmEAName  | replace : '_' : ' ' | titlecase) : ''}}</td>
                            <td title="{{tenantActivity.pmStatus | replace : '_' : ' ' | titlecase }}">{{tenantActivity.pmStatus ? (tenantActivity.pmStatus  | replace : '_' : ' ' | titlecase) : ''}}</td>
                            <td title="{{tenantActivity.renewalStatus | replace : '_' : ' ' | titlecase }}">{{tenantActivity.renewalStatus ? (tenantActivity.renewalStatus  | replace : '_' : ' ' | titlecase) : ''}}</td>
                            <td title="{{tenantActivity.evictionStatus | replace : '_' : ' ' | titlecase }}">{{tenantActivity.evictionStatus ? (tenantActivity.evictionStatus  | replace : '_' : ' ' | titlecase) : ''}}</td>
                            
                            <td title="{{tenantActivity.lastActionTaken | replace : '_' : ' ' | titlecase }}">{{tenantActivity.lastActionTaken ? (tenantActivity.lastActionTaken  | replace : '_' : ' ' | titlecase) : ''}}</td>
                            <td title="{{tenantActivity.followUpDate | date: 'MM/dd/yyyy' : ' - '}}">{{tenantActivity.followUpDate ? (tenantActivity.followUpDate | date: 'MM/dd/yyyy' : ' ') : ''}}</td>
                            <td title="{{tenantActivity.promiseToPay | date: 'MM/dd/yyyy' : ' - '}}">{{tenantActivity.promiseToPay ? (tenantActivity.promiseToPay | date: 'MM/dd/yyyy' : ' ') : ''}}</td>
                            <td title="{{tenantActivity.tenantNotes }}">{{tenantActivity.tenantNotes? tenantActivity.tenantNotes: '-'}}</td>
                            <td title="{{tenantActivity.updatedAt ? (tenantActivity.updatedAt | date: 'MM/dd/yyyy') : '' }}">{{tenantActivity.updatedAt ? (tenantActivity.updatedAt | date: 'MM/dd/yyyy') : ''}}</td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="control-label" *ngIf="tenantActivityList.length == 0">
                    <div class="no-data-found" *ngIf="isTenantActivityNoData" text-center>
                        <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                    </div>
                </div>                                       
                
            </div>
                        
        </div>
    </div>
    <p-toast class="toast-card" position="top-center"></p-toast>
</div>