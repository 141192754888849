<div class="row page-wrap">
    <div class="container-fluid">

        <div class="row m-0">
        <div class="dashboard-tab-wrap container-fluid p-0">
            <nav class="tabbable mar-rgt15">
                <ul class="nav nav-tabs full-width">
                    <h5 class="stats-head">{{ "STATS.DELINQUENCIES" | translate }} </h5>
                    <li class="search-wrap float-right" >
                        <div class="form-group has-search inline-items">
                            <span class="mar-rl10" *ngIf="userData.roleType =='SUPER_ADMIN' || userData.roleType =='SUB_ADMIN'"><i title="Filter" class="filter-icon filter-icon-cls"></i></span> 
                            <p-dropdown class="form-dropdown" *ngIf="userData.roleType =='SUPER_ADMIN' || userData.roleType =='SUB_ADMIN'" name="pmFilter" [options]="pmEaNameItemList" [(ngModel)]="statsPayload.pmId" (onChange)="getStatsSelectedPMName($event, statsPayload.selectedPmNameObject)"></p-dropdown>
                            
                            <span class="mar-rl10"><i title="Filter" class="calendar-icon filter-icon-cls"></i></span> 
                            <p-dropdown class="form-dropdown " [options]="monthList" name="monthSelection"  [(ngModel)]="statsPayload.selectedMonthObject"
                                (onChange)="getStatsSelectedMonth($event, statsPayload.selectedMonthObject)">
                            </p-dropdown>      
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
        </div>
        <!-- <hr class="panel-divider"> -->
        <div class="container full-wrap">
            <div class="cards"  *ngFor="let delinquencyStats of delinquencystatsData">
                <div class="card-item">
                    <div class="card-info">
                        <h2 class="card-title" *ngIf="(delinquencyStats.label == '# of Balances Accumulating +1.5x Portion')">  {{ delinquencyStats.value | currency:'USD':'symbol' :'1.2-2' }} </h2>
                        <h2 class="card-title" *ngIf="(delinquencyStats.label != '# of Balances Accumulating +1.5x Portion')"> {{ delinquencyStats.value }} </h2>
                        <p class="card-intro"> {{ delinquencyStats.label }} </p>
                    </div>
                </div>
            </div>
            <div *ngIf="delinquencystatsData.length == 0">
                <div class="no-data-stats" text-center>
                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                </div>
          </div>
        </div>

        <div class="pad-top20">
            <h5 class="stats-head">{{ "STATS.RENEWALS" | translate }} </h5>
        </div>
        <!-- <hr class="panel-divider"> -->
        <div class="container full-wrap">
            <div class="cards" *ngFor="let renewalsStats of renewalsStatsData">
                <div class="card-item">
                    <div class="card-info">
                        <h2 class="card-title"> {{ renewalsStats.value }} </h2>
                        <p class="card-intro"> {{ renewalsStats.label }} </p>
                    </div>
                </div>
            </div>
            <div *ngIf="renewalsStatsData.length == 0">
                <div class="no-data-stats" text-center>
                    <div class="no-found-txt"> {{ "COMMON_COMPONENT.NO_DATA_FOUND" | translate }} </div>
                </div>
            </div>
        </div>
    </div>
</div>